import React from "react";
import { makeStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    // color: theme.palette.text.secondary,
    "&:hover > $content": {
      //   backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      //   color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        // backgroundColor: "transparent",
      },
  },
  content: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
    "&>label": {
      margin: 0,
      "&>span": {
        "&:nth-child(1)": {},
        "&:nth-child(2)": {
          fontSize: "14px",
          fontWeight: "400",
          color: "#000",
          lineHeight: "19px",
        },
      },
    },
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  checked: {
    color: "#28a745 !important",
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, handleChange, checked, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.labelIcon}
                checked={checked}
                onChange={handleChange}
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label={labelText}
          />
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "max-content",
    maxHeight: 300,
    flexGrow: 1,
    maxWidth: 400,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export default function TraitsTreeView({ list = [], setTraits, traits }) {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {list.map(([title, descriptionList], index) => {
        return (
          <StyledTreeItem
            nodeId={String(index)}
            key={index}
            labelText={title}
            handleChange={() => {
              if (
                traits[title] &&
                traits[title]?.length === descriptionList?.length
              ) {
                setTraits((pre) =>
                  Object.fromEntries(
                    Object.entries(pre).filter((item) => item[0] !== title)
                  )
                );
              } else {
                setTraits((pre) => ({ ...pre, [title]: descriptionList }));
              }
            }}
            checked={Boolean(
              traits[title] && traits[title]?.length === descriptionList?.length
            )}
          >
            {descriptionList?.map((item, key) => {
              return (
                <StyledTreeItem
                  key={key}
                  nodeId={`${index}-${key}`}
                  labelText={item}
                  handleChange={() => {
                    if (traits[title]) {
                      if (traits[title].includes(item)) {
                        let arr = traits[title].filter((a) => a !== item);
                        if (arr.length) {
                          setTraits((pre) => ({
                            ...pre,
                            [title]: arr,
                          }));
                        } else {
                          setTraits((pre) =>
                            Object.fromEntries(
                              Object.entries(pre).filter(
                                (item) => item[0] !== title
                              )
                            )
                          );
                        }
                      } else {
                        setTraits((pre) => ({
                          ...pre,
                          [title]: [...traits[title], item],
                        }));
                      }
                    } else {
                      setTraits((pre) => ({ ...pre, [title]: [item] }));
                    }
                  }}
                  checked={Boolean(traits[title]?.includes(item))}
                />
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  );
}
