import clsx from "clsx";
import React from "react";
import {
  Typography,
  Grid,
  Drawer,
  makeStyles,
  CircularProgress,
  Box,
} from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProductJourney from "../ProductJourney";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
}));

export default function Timeline({
  open = false,
  onClose = () => {},
  exhibitsList,
}) {
  const classes = useStyle();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Typography variant="h2" bold>
          Timeline
        </Typography>
        {exhibitsList.length === 0 ? (
          <Box className="center" style={{ width: "100%", padding: "20px 0" }}>
            Loading...
            <CircularProgress style={{ marginLeft: 10 }} size={20} />
          </Box>
        ) : (
          <ProductJourney exhibitsList={exhibitsList} />
        )}
      </Grid>
    </Drawer>
  );
}
