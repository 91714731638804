import appImages from "../../constants/AppImage";
import LeftImageSection from "../Common/Section/LeftImageSection";
import RightImageSection from "../Common/Section/RightImageSection";

const {
  images: { ingradinatSuppliersHeader, supplierHowItWorkSection1 },
} = appImages;

export default function IngradinatSupplierSection() {
  const content = [
    {
      href: `/document-protection`,
      content: <>Provide verifiable documentation</>,
    },
    {
      href: `/consumer-connection`,
      content: (
        <>
          Connect directly to consumers and solicit feedback from them with
          batch specificity
        </>
      ),
    },
    {
      href: `/product-origin`,
      content: (
        <>
          Be harmonized with manufacturers and brands as well as retailers for
          batch-specific feedback, recalls and to provide product origin
          information to consumers
        </>
      ),
    },
    {
      href: `/circular-economy`,
      content: <>Participate in the circular economy equation</>,
    },
  ];
  return (
    <>
      <LeftImageSection
        img={supplierHowItWorkSection1}
        content={content}
        heading="Ingredient suppliers are the genesis of health and 
        nutrition and must be able to…."
        notBtn
      />
      <RightImageSection position="left" content="Ingredient suppliers……" />
    </>
  );
}
