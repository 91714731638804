import { Tab, Tabs, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  tabsRoot: {},
  tabRoot: {},
}));

export default function MuiTabs({
  value,
  onChange = () => {},
  labels = [],
  variant = "",
  scrollButtons = "",
}) {
  const classes = useStyle();
  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
      className={classes.tabsRoot}
      variant={variant}
      scrollButtons={scrollButtons}
    >
      {labels.map((label, key) => {
        return <Tab label={label} key={key} className={classes.tabRoot} />;
      })}
    </Tabs>
  );
}
