import React from "react"
import { AppImages, AppConstant } from "../../constants"

const {
    homePageImg: {
        easyImg,
        secureImg,
        trustedImg,
   },
   asSeenInImg: {
        asSeenInImg01,
        asSeenInImg02,
        asSeenInImg03,
        asSeenInImg04,
        asSeenInImg05,
        asSeenInImg06,
   },
   } = AppImages
const {
 homePage: {
 why, healthLoq, textHealthLoq, trusted, textTrusted, secure, textSecure, easy, textEasy
}
} = AppConstant

const HomeContent = () => (
    <div className="container">
        <div className="section-why-healthLoq">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <p className="title">{why} <span className="text-healthLoqc">{healthLoq}</span></p>
                        <p className="text-fortunate">
                            {textHealthLoq}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-why-healthLoq">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <p className="title">How <span className="text-healthLoqc">Blockchain helps</span></p>
                        <p className="text-fortunate">
                        Blockchain-based software platforms are unique in their ability to provide and house trusted information. First, each piece of information is verified by counter parties.  When a supplier of an ingredient posts information about a batch of ingredients sent, the receiving party goes through its own verification and receipt process and can validate or refute the information the ingredient supplier provided to the platform.  Second, there are 3rd party validators on the platform, such as testing labs, contract manufacturers or government agencies, that can provide test results directly to the platform, not through the company posting information about their own product.  Third, the information that is posted about the ingredients sent and aggregated into the product sits in an immutable data store. This is further incentive to provide accurate and auditable information.<br /><br />
                        So...<br /><br /><b>Counterparty validation | Third party validation | Immutable, unchangeable data store.</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-about-healthLoq">
            <div className="row">
                <div className="col-lg-4">
                    <div className="box-featured-content">
                        <img src={trustedImg} alt="" className="img-featured" />
                        <div>
                        <p className="box-featured-title">{trusted}</p>
                        <p className="box-featured-text">{textTrusted}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="box-featured-content">
                        <img src={secureImg} alt="" className="img-featured" />
                        <div>
                        <p className="box-featured-title">{secure}</p>
                        <p className="box-featured-text">{textSecure}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="box-featured-content">
                        <img src={easyImg} alt="" className="img-featured" />
                        <div>
                        <p className="box-featured-title">{easy}</p>
                        <p className="box-featured-text">{textEasy}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-as-seen-in">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <p className="title">As <span className="text-healthLoqc">Seen In</span></p>
                    </div>

                    <div className="row col-md-12 seen-in-img-main">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg01} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg02} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg03} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg04} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg05} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="certified-partners text-center">
                                <div className="bggImgDiv">
                                    <img src={asSeenInImg06} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>*/}
                    </div>


                </div>
            </div>
        </div>
    </div>
)
export default HomeContent
