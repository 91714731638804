import React, { useRef, useState, useEffect } from "react";
import { Grid, makeStyles, Typography, Tooltip } from "..";
import clsx from "clsx";
import Ingredients from "./ProductDetailDrawers/Ingredients";
import Review from "./ProductDetailDrawers/Review";
import Register from "./ProductDetailDrawers/Register";
import Testing from "./ProductDetailDrawers/Testing";
import Timeline from "./ProductDetailDrawers/Timeline";
import Origin from "./ProductDetailDrawers/Origin";
import OtherBatches from "./ProductDetailDrawers/OtherBatches";
import {
  ReviewIcon,
  BatchIcon,
  OriginIcon,
  BlockchainProofIcon,
  CartIcon,
  TimelineIcon,
  TestingIcon,
  RecycleIcon,
  RegisterIcon,
  IngredientsIcon,
} from "../../constants/svgIcons";
import utils from "../../utils/commonUtils";
import { Link, useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  mockupContainer: {},
}));

export default function ProductDetailMobile({
  integrantsBatchesList,
  integrantsDetail: { integrantInfo },
  integrantsDetail,
  dispatchBlockchainProof,
  exhibitsList,
  showProductDetailTour,
  userData,
  recycle_val
}) {
  const btnRef = useRef({});
  let links = [
    {
      title: "origin",
      question: "Where did this product and it's ingredients come from?",
      point: 10,
      icon: <OriginIcon />,
    },
    {
      title: "Buy",
      question: "Where can I buy this product again?",
      point: 2,
      link: integrantInfo?.buy_again_url,
      newTab: true,
      icon: <CartIcon />,
    },
    {
      title: "testing",
      question: "How do I know this product is safe?",
      point: 11,
      icon: <TestingIcon />,
    },
    {
      title: "Review",
      question: "leave an anonymous review of the product",
      point: 1,
      icon: <ReviewIcon />,
      tour: "review",
    },
    {
      title: "ingredients",
      question: "What is in this product?",
      point: 12,
      icon: <IngredientsIcon />,
    },
    {
      title: "Recycle",
      question: "Record how you disposed of the product and packaging",
      point: 24,
      icon: <RecycleIcon />,
      tour: "recycle",
    },
    {
      title: "timeline",
      question: "Timeline",
      point: 13,
      icon: <TimelineIcon />,
    },
    {
      title: "Register",
      question: "Register, connect/share, respond and invite",
      point: 23,
      icon: <RegisterIcon />,
    },
    {
      title: "batches",
      question: "batches",
      point: 14,
      icon: <BatchIcon />,
    },
    {
      title: "proof",
      question: "Verify to check the document on the blockchain.",
      point: 22,
      isDialog: true,
      icon: <BlockchainProofIcon />,
    },
  ];
  const classes = useStyle();
  const [isProductImageLoaded, setIsProductImageLoaded] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");

  const onProductImageLoad = () => {
    setIsProductImageLoaded(true);
    setTimeout(() => {
      links.forEach((item, index) => {
        const el = document.getElementById(`pd-question-${index + 1}`);
        el.classList.add("pd-after-animation-style");
      });
      if (userData?.data?.show_product_detail_tour && btnRef.current)
        btnRef?.current["proof"]?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "center",
        });
      setTimeout(() => showProductDetailTour(), 2000);
    }, 2000);
  };

  const [currentDrawer, setCurrentDrawer] = useState(0);

  const history = useHistory();
  useEffect(() => {
    if(recycle_val){
      setCurrentDrawer(6);
      history.replace(window.location.pathname);
    }
  }, [recycle_val])

  const handleClose = (type) => {
    if (type === "openOrigin") {
      setCurrentDrawer(1);
    } else if (type === "otherBatches") {
      setCurrentDrawer(9);
    } else {
      if (currentDrawer === 9) setSelectedBatch("");
      setCurrentDrawer(0);
    }
  };

  const handleIntegrantBlockchainProof = () => {
    dispatchBlockchainProof({
      id: integrantInfo?.id,
      type: "integrant",
      showCustomLoader: true,
    });
  };

  return (
    <Grid container className={classes.mockupContainer}>
      <div className="bodyMaxWidth">
        <div className="mobile-product-image">
          <div className={clsx("mobile-product-image-wrapper", "center")}>
            <img
              src={integrantInfo?.integrantsType?.thumbnail_url}
              alt={integrantInfo?.title}
              onLoad={onProductImageLoad}
            />
          </div>
        </div>
        <div className="mobile-product-detail-buttons">
          <div>
            {isProductImageLoaded &&
              links.map((el, i) => {
                return (
                  <Tooltip key={i} arrow title={el.question}>
                    <div
                      id={`pd-question-${i + 1}`}
                      className="mobile-product-detail-questions"
                      onClick={() => {
                        if (!el?.link && !el?.isDialog) {
                          setCurrentDrawer(i + 1);
                        }
                        if (el?.isDialog) {
                          handleIntegrantBlockchainProof();
                        }
                      }}
                      ref={(ele) =>
                        (btnRef.current = {
                          ...btnRef.current,
                          [el.title]: ele,
                        })
                      }
                      product-detail-tour={el?.tour || ""}
                      data-toggle={el?.isDialog ? "modal" : ""}
                      data-target={
                        el?.isDialog ? "#IntegrantBlockchainProofDialog" : ""
                      }
                    >
                      {el.link ? (
                        <a
                          href={utils.getValidUrl(el.link)}
                          target={el?.newTab ? "_blank" : "_self"}
                          rel="noreferrer"
                        >
                          <Typography variant="body2" medium>
                            {el?.icon}
                            <span className="ellipsis">{el.title}</span>
                          </Typography>
                        </a>
                      ) : (
                        <Typography variant="body2" medium>
                          {el?.icon}
                          <span className="ellipsis">{el.title}</span>
                        </Typography>
                      )}
                    </div>
                  </Tooltip>
                );
              })}
          </div>
        </div>
      </div>
      <Origin
        open={currentDrawer === 1}
        {...{ ...integrantsDetail, exhibitsList }}
        onClose={handleClose}
      />
      <Testing
        open={currentDrawer === 3}
        {...{
          ...integrantsDetail,
          integrantsBatchesList,
          setSelectedBatch,
        }}
        onClose={handleClose}
      />
      <Ingredients
        open={currentDrawer === 5}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Timeline
        open={currentDrawer === 7}
        onClose={handleClose}
        {...{ exhibitsList, ...integrantsDetail }}
      />
      <OtherBatches
        open={currentDrawer === 9}
        onClose={handleClose}
        {...{
          integrantsBatchesList,
          ...integrantsDetail,
          selectedBatch,
        }}
      />
      <Register
        open={currentDrawer === 8}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Review
        open={currentDrawer === 4}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Review
        open={currentDrawer === 6}
        onClose={handleClose}
        {...integrantsDetail}
      />
    </Grid>
  );
}
