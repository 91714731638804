import React from "react";
import { AppImages } from "../../constants";
import LeftImageSection from "../Common/Section/LeftImageSection";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import { Autoplay } from "swiper";
const {
  grma_image,
  logo,
  logoProud,
  logoNPM,
  logoCRN,
  grama_intiegrity_program: {
    adora,
    alkemist,
    cas,
    chpa,
    delta,
    dollarTreeFamily,
    eurofinsAssurance,
    excelsiorAlchemy,
    firstQuality,
    gaiaHerbs,
    garcoa,
    geminiPharm,
    goed,
    gol,
    heb,
    hMaster,
    jitExperts,
    kroger,
    le,
    naturesWay,
    nsf,
    ohm,
    pharmavite,
    rbs,
    reenaGroup,
    rockline,
    tarored,
    thecoresolution,
    tic,
    tmr,
    topco,
    towerLabs,
    unpa,
    vitacost,
    wegmans,
    wfm,
    yeLogoColor,
    yerbaPrima,
    youtheory,
  },
} = AppImages;

function IntrgrityDetails() {
  const content = [
    {
      href: "https://grmalliance.org/product-integrity-program/#overview",
      content: (
        <>Ensures consumers have access to safe, high-quality products</>
      ),
    },
    {
      href: "https://grmalliance.org/product-integrity-program/#overview",
      content: (
        <>
          Reduces compliance headaches for manufacturers–this program is
          recognized by most major retailers
        </>
      ),
    },
    {
      href: "https://grmalliance.org/product-integrity-program/#overview",
      content: (
        <>
          Enables retailers to trust that product and supplier documentation is
          authentic and legitimate
        </>
      ),
    },
    {
      href: "https://grmalliance.org/product-integrity-program/#overview",
      content: (
        <>
          Leverages the capacity of high-quality labs in the industry to ensure
          an efficient and cost-effective path for products to get to consumers
        </>
      ),
    },
  ];
  return (
    <>
      <LeftImageSection
        img={grma_image}
        content={content}
        heading="GRMA PRODUCT INTEGRITY PROGRAM"
        notBtn
      />
      {/* <div style={{ margin: "50px 0px" }}>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          modules={[Autoplay]}
          loop={true}
        >
          {[
            grma_image,
            adora,
            alkemist,
            cas,
            chpa,
            delta,
            dollarTreeFamily,
            eurofinsAssurance,
            excelsiorAlchemy,
            firstQuality,
            gaiaHerbs,
            garcoa,
            geminiPharm,
            goed,
            gol,
            heb,
            hMaster,
            jitExperts,
            kroger,
            le,
            naturesWay,
            nsf,
            ohm,
            pharmavite,
            rbs,
            reenaGroup,
            rockline,
            tarored,
            thecoresolution,
            tic,
            tmr,
            topco,
            towerLabs,
            unpa,
            vitacost,
            wegmans,
            wfm,
            yeLogoColor,
            yerbaPrima,
            youtheory,
          ].map((data, index) => (
            <SwiperSlide
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={data} width="auto" height="80px" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </>
  );
}

export default IntrgrityDetails;
