import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import {
  LabelSection1,
  LabelSection2,
} from "../../components/LabelLegitimization";
import HealthLoqSection from "../../components/LabelLegitimization/HealthloqSection";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { labelLegitimization },
} = appImages;

export default function LabelLegitimization() {
  return (
    <PageWrapper pageName="label validation">
      <HeaderSection
        headerImgSrc={labelLegitimization}
        headerText="Label Validation"
      />
      <LabelSection1 />
      <LabelSection2 />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
