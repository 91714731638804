import React, { useState } from "react";
import {
  dispatchCurrentBatch,
  dispatchIntegrantsDetail,
} from "../../redux/actions";
import { getOtherBatchList } from "../../redux/actions/productActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import utils from "../../utils/commonUtils";
import clsx from "clsx";
import OtherBatches from "../Products/ProductDetailDrawers/OtherBatches";
import Testing from "../Products/ProductDetailDrawers/Testing";

const ProductListItem = ({
  id,
  description,
  thumbnail_url,
  organization,
  title,
  filterProduct,
  buy_now_url,
  integrantsType,
  current_integrant_id,
  getOtherBatchList,
  otherBatchList,
  dispatchIntegrantsDetail,
  integrantsBatchesList,
  integrantsDetail,
  loaderVisible,
}) => {
  const [openOtherBatchDrawer, setOpenOtherBatchDrawer] = useState(false);
  const [openTestingDrawer, setOpenTestingDrawer] = useState(false);
  let organizationName = <></>,
    productUrl = "",
    batcheText = "N/A";
  if (organization !== null) {
    organizationName = (
      <p className="text-organization">
        {integrantsType[0].organizations.name}
      </p>
    );
    if (integrantsType && integrantsType.length) {
      productUrl = `/p/${integrantsType[0].organizations.short_code}/b/${integrantsType[0].external_id_slug}`;
      if (integrantsType.length >= 1) {
        batcheText = "Other Batches";
      } else {
        batcheText = "Attachments";
      }
    }
  }
  let buyNowUrl =
    integrantsType?.[0]?.buy_again_url || buy_now_url
      ? utils.getValidUrl(integrantsType?.[0]?.buy_again_url || buy_now_url)
      : "";

  const handleOnClick = (type) => {
    if (type === "Other Batches") {
      setOpenOtherBatchDrawer(true);
      getOtherBatchList({
        integrant_id: integrantsType[0]?.id,
        integrant_type_id: id,
      });
    } else {
      setOpenTestingDrawer(true);
      dispatchIntegrantsDetail({
        short_code: integrantsType[0]?.organizations?.short_code,
        external_id_slug: integrantsType[0]?.external_id_slug,
      });
    }
  };
  return (
    <div className="col-md-6 col-lg-3 mb-3" key={id}>
      <div className="item-productlist">
        <div className="d-flex  flex-column justify-content-between h-100">
          <div>
            <Link to={productUrl}>
              <div className="img-productlist">
                <img src={thumbnail_url} alt={title} />
              </div>
            </Link>
            <div className="text-productlist">
              <Link
                to={`/organization-detail/${integrantsType[0].organizations?.id}`}
              >
                {organizationName}
              </Link>
              <Link to={productUrl} className="product-title notranslate">
                {title}
              </Link>
              <p className="product-des text-truncate">{description}</p>
            </div>
          </div>
          <div className="d-flex product-links">
            <buton
              className={clsx("link")}
              onClick={() => handleOnClick(batcheText)}
            >
              {batcheText}
            </buton>
            <a
              href={buyNowUrl}
              target="_blank"
              rel="noreferrer noopener"
              className={`${buyNowUrl ? "link" : "link disable-but"} center`}
            >
              Buy Now
            </a>
          </div>
        </div>
      </div>
      <OtherBatches
        open={openOtherBatchDrawer}
        onClose={() => setOpenOtherBatchDrawer(false)}
        {...{
          integrantsBatchesList: otherBatchList?.data,
          integrantInfo: { external_id: integrantsType[0]?.external_id },
        }}
        isLoading={otherBatchList?.isLoading}
      />
      <Testing
        open={openTestingDrawer}
        onClose={() => setOpenTestingDrawer(false)}
        {...{
          ...integrantsDetail,
          integrantsBatchesList,
        }}
        isLoading={loaderVisible}
      />
    </div>
  );
};

const mapStateToProps = ({
  productReducers: { otherBatchList, integrantsBatchesList, integrantsDetail },
  loaderReducer,
}) => ({
  otherBatchList,
  integrantsBatchesList,
  integrantsDetail,
  loaderVisible: loaderReducer,
});

const mapDispatchToProps = {
  getBatch: dispatchCurrentBatch,
  getOtherBatchList,
  dispatchIntegrantsDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem);
