import { Box, Typography, makeStyles } from "@material-ui/core";
import {
  FemaleProfileIcon,
  SendConnectionIcon,
} from "../../../constants/svgIcons";

// style
const useStyle = makeStyles((theme) => ({
  connectionBoxContainer: {
    borderRadius: 8,
    backgroundColor: "#FDFDFB",
    width: 185,
    height: 165,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    position: "relative",
  },
  connectionName: {
    color: "#000",
    fontWeight: 700,
  },
  registerIcon: {
    position: "absolute",
    top: 7,
    right: 5,
    cursor: "pointer",
  },
}));

export default function ConnectionBox({ name, connections }) {
  // import style
  const classes = useStyle();

  return (
    <Box className={classes.connectionBoxContainer}>
      <SendConnectionIcon className={classes.registerIcon} />
      <Box className={classes.profileContainer}>
        <FemaleProfileIcon className={classes.icon} />
      </Box>
      <Typography variant="body2" className={classes.connectionName}>
        {name}
      </Typography>
      <Typography variant="p">{connections} connections</Typography>
    </Box>
  );
}
