import apiConstants from "./constants";
import Helpers from "../helpers";
import utils from "../utils/commonUtils";

const {
  Utils: { ajax },
} = Helpers;

const {
  URL: {
    INGREDIENTS_IDS,
    INTEGRANTS_LIST,
    INTEGRANTS_DETAIL,
    INTEGRANTS_EXHIBITS_DETAIL,
    INTEGRANTS_BATCHES_LIST,
    PRODUCTLIST,
    BATCHLIST,
    ORGANIZATIONLIST,
    GET_ALL_INTEGRANT_TYPES_LIST,
    BLOCKCHAINPROOF,
    GETALLBLOGNEWSLIST,
    GETTRENDINGORGANIZATIONSLIST,
    VERIFY_DOCUMENT,
    PROFILE_LOGIN,
    PROFILE_SIGNUP,
    PRODUCT_FEEDBACK,
    GET_CATEGORIES,
    GET_USER,
    UPDATE_USER,
    DELETE_USER,
    GET_SURVEY_QUESTIONS,
    GET_INTEGRANT_BATCH_LIST,
    SUBMIT_SURVEY_ANSWERS,
    UPDATE_PASSWORD,
    FORGOT_PASSWORD,
    UPDATE_PROFILE_PICTURE,
    GET_USER_SURVEY_HISTORY,
    GET_INVITE_ORGANIZATION_HISTORY,
    INVITE_ORGANIZATION,
    GET_TRAITS_LIST,
    TOGGLE_PRODUCER_CONNECTIONS,
    VERIFY_OTP,
    LOGOUT,
    RESEND_OTP,
    GET_MY_DATA,
    USERS_LIST,
    CREATE_POST,
    UPDATE_POST,
    DELETE_POST,
    Get_POST_DETAIL,
    POSTS_LIST,
    POST_COMMENT,
    POST_LIKE_DISLIKE,
    GET_POST_COMMENT_LIST,
    GET_POST_LIKED_USERS_LIST,
    DELETE_POST_COMMENT,
    UPDATE_POST_COMMENT,
    FOLLOW_UNFOLLOW_USER,
    SHARE_POST,
    GET_PRODUCT_LIST_FOR_MENTION,
    GET_LINK_PRIVIEW,
    GET_USER_CONNECTIONS,
    GET_POST_HASHTAGS,
    INVITE_USER,
    ORGANIZATION_DETAIL,
    GET_USER_NOTIFICATIONS,
    MARK_AS_READ_USER_NOTIFICATIONS,
    VERIFY_COA_DOCUMENT,
  },
} = apiConstants;

export const ajaxCall = (
  apiMethod,
  apiUrl,
  apiData,
  isProtected = true,
  responseType = "json",
  contentType = "application/json"
) => {
  const { auth_token } = utils.getLocalStorageItems();
  let headers = {
    "content-type": contentType,
    Accept: "application/json",
  };
  if (isProtected) {
    headers = { ...headers, Authorization: `Bearer ${auth_token}` };
  }
  return ajax({
    method: apiMethod,
    url: apiUrl,
    data: apiData,
    headers: headers,
    responseType,
  });
};

export const productAPI = {
  getintegrantsList: (params) => ajaxCall("post", INTEGRANTS_LIST, params),
  getTraitsList: (params) => ajaxCall("post", GET_TRAITS_LIST, params),
  getintegrantsDetail: (params) => ajaxCall("post", INTEGRANTS_DETAIL, params),
  getexhibitsList: (params) =>
    ajaxCall("post", INTEGRANTS_EXHIBITS_DETAIL, params),
  getIngredientsIds: (params) => ajaxCall("post", INGREDIENTS_IDS, params),
  getintegrantsBatches: (params) =>
    ajaxCall("post", INTEGRANTS_BATCHES_LIST, params),
  getproductList: (params) => ajaxCall("post", PRODUCTLIST, params),
  getCurrentBatch: (params) => ajaxCall("post", BATCHLIST, params),
  getOrganizationList: (params) => ajaxCall("post", ORGANIZATIONLIST, params),
  getAllIntegrantTypesList: (params) =>
    ajaxCall("post", GET_ALL_INTEGRANT_TYPES_LIST, params),
  getIntegrantBatchList: (params) =>
    ajaxCall("post", GET_INTEGRANT_BATCH_LIST, params),
  getBlockchainProof: (params) => ajaxCall("post", BLOCKCHAINPROOF, params),
  verifyDocument: (params) => ajaxCall("post", VERIFY_DOCUMENT, params),
  verifyCoaDocument: (params) => ajaxCall("post", VERIFY_COA_DOCUMENT, params),
  toggleProducerConnections: (params) =>
    ajaxCall("post", TOGGLE_PRODUCER_CONNECTIONS, params),
  getProductListForMentions: (params) =>
    ajaxCall("post", GET_PRODUCT_LIST_FOR_MENTION, params),
  getOrganizationDetail: (id) =>
    ajaxCall("get", `${ORGANIZATION_DETAIL}/${id}`),
};

export const blogNewsAPI = {
  getBlogNewsList: () => ajaxCall("get", GETALLBLOGNEWSLIST, {}, false),
};

export const trendingOrganizationsAPI = {
  getTrendingOrganizationsAPIList: (params) =>
    ajaxCall(
      "get",
      `${GETTRENDINGORGANIZATIONSLIST}&sort[1]=id&pagination[page]=${
        params.page
      }&pagination[pageSize]=10${
        params?.q
          ? `&filters[$or][0][name][$startsWith]=${params?.q}&filters[$or][1][name][$endsWith]=${params?.q}`
          : ""
      }`,
      {},
      false
    ),
};

export const postAPIs = {
  createPost: (params) => ajaxCall("post", CREATE_POST, params),
  sharePost: (params) => ajaxCall("post", SHARE_POST, params),
  updatePost: (params) => ajaxCall("post", UPDATE_POST, params),
  deletePost: (params) => ajaxCall("post", DELETE_POST, params),
  getPostDetail: (params) => ajaxCall("post", Get_POST_DETAIL, params),
  getPostsList: (params) => ajaxCall("post", POSTS_LIST, params),
  postLikeDislike: (params) => ajaxCall("post", POST_LIKE_DISLIKE, params),
  postComment: (params) => ajaxCall("post", POST_COMMENT, params),
  updateComment: (params) => ajaxCall("post", UPDATE_POST_COMMENT, params),
  deleteComment: (params) => ajaxCall("post", DELETE_POST_COMMENT, params),
  getPostCommentList: (params) =>
    ajaxCall("post", GET_POST_COMMENT_LIST, params),
  getPostLikedUsersList: (params) =>
    ajaxCall("post", GET_POST_LIKED_USERS_LIST, params),
  getLinkPreview: (params) => ajaxCall("post", GET_LINK_PRIVIEW, params),
  getPostHashtags: (params) => ajaxCall("post", GET_POST_HASHTAGS, params),
};

export const profileAPI = {
  postProfileSignup: (params) => ajaxCall("post", PROFILE_SIGNUP, params),
  postProfileLogin: (params) => ajaxCall("post", PROFILE_LOGIN, params),
  postProductFeedback: (params) => ajaxCall("post", PRODUCT_FEEDBACK, params),
  getCategories: (params) => ajaxCall("post", GET_CATEGORIES, params),
  getUser: (params) => ajaxCall("post", GET_USER, params),
  getMyData: (params) => ajaxCall("get", GET_MY_DATA, params),
  updateUser: (params) => ajaxCall("post", UPDATE_USER, params),
  deleteUser: (params) => ajaxCall("post", DELETE_USER, params),
  getSurveyQuestions: (params) =>
    ajaxCall("post", GET_SURVEY_QUESTIONS, params),
  submitSurveyAnswers: (params) =>
    ajaxCall("post", SUBMIT_SURVEY_ANSWERS, params),
  updatePassword: (params) => ajaxCall("post", UPDATE_PASSWORD, params),
  forgotPassword: (params) => ajaxCall("post", FORGOT_PASSWORD, params),
  verifyOTP: (params) => ajaxCall("post", VERIFY_OTP, params),
  resendOTP: (params) => ajaxCall("post", RESEND_OTP, params),
  logout: (params) => ajaxCall("post", LOGOUT, params),
  updateProfilePicture: (params) =>
    ajaxCall("post", UPDATE_PROFILE_PICTURE, params),
  getUserSurveyHistory: (params) =>
    ajaxCall("post", GET_USER_SURVEY_HISTORY, params),
  inviteOrganization: (params) => ajaxCall("post", INVITE_ORGANIZATION, params),
  inviteUser: (params) => ajaxCall("post", INVITE_USER, params),
  getInviteOrganizationHistory: (params) =>
    ajaxCall("post", GET_INVITE_ORGANIZATION_HISTORY, params),
  getUsersList: (params) => ajaxCall("get", USERS_LIST, params),
  followUnfollowUser: (params) =>
    ajaxCall("post", FOLLOW_UNFOLLOW_USER, params),
  getUserConnections: (params) =>
    ajaxCall("post", GET_USER_CONNECTIONS, params),
  getUserNotifications: (params) =>
    ajaxCall("post", GET_USER_NOTIFICATIONS, params),
  markAsReadUserNotifications: (params) =>
    ajaxCall("post", MARK_AS_READ_USER_NOTIFICATIONS, params),
};
