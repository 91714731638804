import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

export function PostSkeleton({ setRef }) {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      ref={setRef}
      sx={{ width: "100%", p: 2, border: `1px solid #e5e5e5`, mb: 2 }}
    >
      <Box display={"flex"} alignItems="center" justifyContent={"flex-start"}>
        <Skeleton animation="wave" variant="rect" width={35} height={35} />
        <Box
          display={"flex"}
          flexDirection="column"
          sx={{ ml: 1, width: "100%" }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            width={"100%"}
            height={30}
          />
          <Skeleton animation="wave" variant="text" width={"40%"} height={15} />
        </Box>
      </Box>
      <Box
        sx={{ width: "100%", mt: 2 }}
        display="flex"
        flexDirection={"column"}
      >
        <Skeleton animation="wave" variant="rect" width={"100%"} height={200} />
        <Skeleton
          animation="wave"
          variant="text"
          width={"100%"}
          height={20}
          style={{ margin: "10px 0 5px" }}
        />
        <Skeleton animation="wave" variant="text" width={"100%"} height={20} />
      </Box>
    </Box>
  );
}

export function UserSuggestionSkeleton({setRef}){
  return <Box
  display={"flex"}
  flexDirection="column"
  alignItems={"center"}
  justifyContent={"center"}
  ref={setRef}
  sx={{ width: 160, p: 1, border: `1px solid #e5e5e5`, borderRadius: 10 }}
>
  <Skeleton variant="circle" width={50} height={50} />
  <Skeleton variant="text" width={'100%'} height={20} style={{margin:"20px 0 10px"}} />
  <Skeleton variant="text" width={'80%'} height={15} />
</Box>
}
