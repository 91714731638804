import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyle = makeStyles((theme) => ({
  featureIconFrameRoot: {
    width: 130,
    height: 130,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
    background: "#F3F8EB",
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>div": {
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: "50%",
      background: theme.palette.common.white,
      width: "calc(100% - 5px)",
      height: "calc(100% - 5px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&>svg": {
        width: 60,
        height: 60,
      },
    },
  },
  primaryIconFrameRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: 3,
    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: "50%",
      width: 50,
      height: 50,
    },
  },
}));

export function FeaturesIconFrame({ icon = null }) {
  const classes = useStyle();
  return (
    <Box className={classes.featureIconFrameRoot}>
      <Box>{icon}</Box>
    </Box>
  );
}

export function PrimaryIconFrame({ children = null, className = "" }) {
  const classes = useStyle();
  return (
    <Box className={clsx(classes.primaryIconFrameRoot, className)}>
      <Box>{children}</Box>
    </Box>
  );
}
