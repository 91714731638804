import axios from "axios";
import { createBrowserHistory } from "history";
import apiConstants from "../services/constants";

const history = createBrowserHistory();

const {
  HTTP_STATUS_CODES: { UNAUTHORIZED_ACCESS, NOT_FOUND, INTERNAL_SERVER_ERROR },
} = apiConstants;

export default class Utils {
  static ajax = axios;

  static redirectToPage = (status) => {
    if (status === NOT_FOUND) {
      history.push("/error404");
    } else if (status === UNAUTHORIZED_ACCESS) {
      history.push("/error404");
    } else if (status === INTERNAL_SERVER_ERROR) {
      // history.replace("/error404");
      window.location.href = "/error404";
      //window.location.reload()
    }
  };

  static consoleLog = (string) => {
    console.log(string);
  };

  static unCamelCase = (str) => {
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  };
}
