import React from "react";
import clsx from "clsx";
import { Button as MuiButton, CircularProgress } from "@material-ui/core";

export default function Button({
  className = "",
  children,
  type,
  onClick,
  profileBgBtn = false,
  profileTransparentBtn = false,
  profileSmBtn = false,
  startIcon,
  endIcon,
  loading,
  disabled,
  setRef,
  loginWithFacebookBtn,
  loginWithGoogleBtn,
  ...props
}) {
  return (
    <MuiButton
      className={clsx(
        "profile-btn",
        profileBgBtn && "profile-bg-btn",
        profileTransparentBtn && "profile-transparent-btn",
        profileSmBtn && "profile-sm-btn",
        loginWithFacebookBtn && "loginWithFacebookBtn",
        loginWithGoogleBtn && "loginWithGoogleBtn",
        (loading || disabled) && "profile-disabled-btn",
        className
      )}
      onClick={onClick}
      type={type}
      {...props}
      endIcon={endIcon || (loading && <CircularProgress size={20} />)}
      startIcon={startIcon}
      innerRef={setRef}
    >
      {children}
    </MuiButton>
  );
}
