import React from "react";
import { Box, Grid } from "@material-ui/core";
import SEO from "../../components/SEO";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link, useHistory } from "react-router-dom";
import { AuthLinkBox } from "../../components/Auth";
import apiConstants from "../../services/constants";
import appImages from "../../constants/AppImage";

const {
  PRODUCER_APP_URL: { login },
} = apiConstants;

const {
  profileImages: { profileBg },
} = appImages;

export default function Login() {
  const history = useHistory();
  return (
    <>
      <SEO title={"Login - HealthLOQ"} description="Login - HealthLOQ" />
      <Grid
        container
        className={"login-container"}
        style={{ backgroundImage: `url(${profileBg})` }}
      >
        <Grid item className={"auth-rightside"}>
          <Box className="auth-backbtn" onClick={() => history.goBack()}>
            <KeyboardBackspaceIcon />
            <span>Back</span>
          </Box>
          <Box className="auth-content">
            <h1 className="auth-main-heading">Login</h1>
            <p className="auth-main-text">Select where you want to login.</p>
            <AuthLinkBox
              {...{
                heading: "Company",
                link: login,
                type: "login",
              }}
            />
            <AuthLinkBox
              {...{
                heading: "Consumer",
                link: "/profile-login",
                type: "login",
              }}
            />
            <p className="auth-login-link">
              Don't have an account yet? <Link to={"/register"}>Sign up</Link>{" "}
              here.
            </p>
          </Box>
          <Box className="auth-footer-links">
            <Box>
              <Link to={"/terms-and-conditions"} target="_blank">
                Terms & Conditions
              </Link>
              <span></span>
              <Link to={"/privacy"} target="_blank">
                Privacy Policy
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
