import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import {
  IngradinatSupplierSection,
  IngrandiantHeader,
} from "../../components/IngradinatSuppliers";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  images: { ingradinatSuppliersHeader },
} = appImages;

export default function IngradinatSupplier() {
  return (
    <PageWrapper pageName="Manufacturer Brands">
      <HeaderSection
        headerImgSrc={ingradinatSuppliersHeader}
        headerText="Evidence-based confidence to customers of natural products."
        headerImgPosition="center"
        headerDescription="HealthLOQ builds trust with consumers by bringing transparency to supply chains, documentation  and product testing through blockchain technology."
      />
      <IngradinatSupplierSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
