import React from "react";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContent,
  MuiButton,
} from "./DialogCommonComponents";
import clsx from "clsx";
import { Paragraph } from "../Profile";

export default function ProductFeedbackDialog({
  className = "",
  open,
  handleClose,
  handleCreate,
}) {
  return (
    <MuiDialog
      className={clsx(className)}
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
    >
      <MuiDialogTitle onClose={handleClose}>
        Your feedback was submitted successfully.
      </MuiDialogTitle>
      <MuiDialogContent>
        <Paragraph>
          If you would like to leave further feedback on this product, receive
          offers and connect with this company, click here to create your
          consumer identity...
        </Paragraph>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleCreate}>Create Profile</MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}
