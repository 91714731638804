import React from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../constants/svg/back-icon.svg";
import clsx from "clsx";
import { MainHeading } from "./";

export default function BackIconComponent({
  className = "",
  path,
  match,
  pageHeading,
  hideBackIcon = false,
}) {
  const history = useHistory();
  const handleClick = () => {
    if (path) {
      history.push(path);
    } else {
      history.go(-1);
    }
  };
  return (
    <Grid className="profile-header-root">
      {!hideBackIcon && (
        <Grid
          className={clsx("back-btn", "center", className)}
          onClick={handleClick}
        >
          <ArrowBackIosRoundedIcon />
          <BackIcon />
        </Grid>
      )}
      <MainHeading>{pageHeading}</MainHeading>
    </Grid>
  );
}
