import {
  Avatar,
  Grid,
  IconButton,
  Tab,
  TextField,
  makeStyles,
} from "@material-ui/core";
import appImages from "../../constants/AppImage";
import HealthLoqTextField from "../Common/TextField";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useState } from "react";
import { FeaturesIconFrame, PrimaryIconFrame } from "../Common/IconFrames";
import ConnectionBox from "../Common/Connection/ConnectionBox";
import ConnectionPost from "../Common/Connection/ConnectionPost";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { ReactComponent as BackIcon } from "../../constants/svg/back-icon.svg";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";
import clsx from "clsx";

// style
const useStyle = makeStyles((theme) => ({
  container: {
    padding: "25px 20px",
    backgroundColor: theme.palette.common.white,
  },
  connectionTextField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
      "&>input": {
        fontWeight: 600,
        color: "#0F172A",
        fontSize: 16,
        marginBottom: 5,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E8F0",
    },
  },
  connectionHeader: {
    display: "flex",
    gap: 10,
  },
  connectionTabContaier: {
    background: "#F7F7F7",
    padding: 10,
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  tab: {
    "& .Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    // display: "flex",
    // justifyContent: "space-between",
  },
  iconContainer: {
    display: "flex",
    gap: 10,
    height: "fit-content",
    justifyContent: "end",
    width: "100%",
  },
  nextButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#ffff",
    background: theme.palette.common.white,
    "&::after, &::before": {
      fontSize: "20px",
    },
    "&>span": {
      color: theme.palette.grey[500],
    },
  },
  preButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#ffff",
    background: theme.palette.primary.main,
    "&::after, &::before": {
      fontSize: "20px",
    },
  },
}));

const {
  icon: { notification, greaterThanIcon },
  images: { profileIcon },
} = appImages;

export default function ConsumerConnectionDetails() {
  // import style
  const classes = useStyle();

  // local state
  const [value, setValue] = useState("add_to_your_network");
  const [myNetwork, setMyNetWork] = useState(4);
  return (
    <Grid className={clsx(classes.container)}>
      {/* ======= HEADER =======  */}
      <Grid className={classes.connectionHeader}>
        <Avatar src={profileIcon} />
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Say something"
          className={classes.connectionTextField}
        />
      </Grid>

      {/* ======= CONNECTION TAB ======= */}
      <Grid className={classes.connectionTabContaier}>
        {/* ======= TAB PANEL =======  */}
        <Grid style={{ width: "100%" }}>
          <TabContext value={value}>
            <TabList
              value={value}
              className={classes.tab}
              onChange={(e, newValue) => setValue(newValue)}
            >
              <Tab label="ADD TO YOUR NETWORK" value="add_to_your_network" />
              <Tab label={`MY NETWORK (${myNetwork})`} value="my_network" />
              <Grid className={classes.iconContainer}>
                <IconButton>
                  <img src={notification} />
                </IconButton>
                <IconButton
                  className={clsx("swiperBtnPrev", classes.preButton)}
                  color="primary"
                >
                  <ArrowBackIosRounded />
                </IconButton>
                <IconButton
                  className={clsx("swiperBtnNext", classes.nextButton)}
                  color="primary"
                >
                  <ArrowForwardIosRounded />
                </IconButton>
              </Grid>
            </TabList>

            {/* ======= ADD TO YOUR NETWORK TAB ======= */}
            <TabPanel style={{ width: "100%" }} value="add_to_your_network">
              <Swiper
                slidesPerView={5}
                spaceBetween={50}
                modules={[Navigation]}
                navigation={{
                  nextEl: ".swiperBtnNext",
                  prevEl: ".swiperBtnPrev",
                }}
                // breakpoints={{
                //   430: {
                //     slidesPerView: 2,
                //     spaceBetween: 100,
                //   },
                // }}
              >
                {Array.from({ length: 20 }).map((data, index) => (
                  <SwiperSlide key={index}>
                    <ConnectionBox
                      name={`Darneik Lueth${index}`}
                      connections={index}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </TabPanel>

            {/* ======= MY NETWORK TAB ======= */}
            <TabPanel value="my_network">
              <Grid style={{ display: "grid", gap: 10 }}>
                {Array.from({ length: 2 }).map((data) => (
                  <ConnectionPost
                    name="John Doe"
                    timeStamp="8 months ago"
                    postContent="I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!"
                  />
                ))}
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Grid>
  );
}
