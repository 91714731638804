/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react"
import { googleMapsReactKey } from "../../services/config"
import { useDistanceBetween } from '../hooks/useDistanceBetween';

const ExihibitIntegrantMap = ({ exihibitLocation, handlerMap }) => {
  const exihibitList = []
  const corrdsArr = []
  
  const googleMap = useRef(null);

  
  exihibitLocation.forEach((item) => {
    
    const {
      coordinates,
      line_1,
      line_2,
      city,
      state,
      zip,
      country
    } = item
   
    if (coordinates !== "") {
    
      exihibitList.push({
        coordinates,
        line_1,
        line_2,
        city,
        state,
        zip,
        country
      })
      corrdsArr.push(JSON.parse(coordinates));
    }
  })

  let obj = corrdsArr
  
  const { defaultCenter, defaultZoom } = useDistanceBetween(obj);
 
  const defaultCenterConfig = {
    lat: defaultCenter[0],
    lng: defaultCenter[1]
  };
  const optionsConfig = {
    draggable: true,
    scrollwheel: false,
    zoomControl: true,
    fullscreenControl: false
  };
  return (
      <div className="map-container">
          <GoogleMapReact
              bootstrapURLKeys={{ key: googleMapsReactKey }}
              resetBoundsOnResize={true}
              defaultCenter={defaultCenterConfig}
              defaultZoom={defaultZoom}
              options={optionsConfig}
              ref={googleMap}
          >
              {exihibitList.map((item,idx) => {
                const {
                  coordinates,
                  line_1,
                  line_2,
                  city,
                  state,
                  zip,
                  country
                } = item
                let coord = JSON.parse(coordinates);
                return <MapTollTipComponent index={idx} key={idx} handlerMap={handlerMap} lat={coord[0]} lng={coord[1]} text={`${line_1}, ${line_2}, ${city}, ${state} ${country} ${zip}`} />
              })}
          </GoogleMapReact>
      </div>
  )
}
ExihibitIntegrantMap.propTypes = {
  exihibitLocation: PropTypes.instanceOf(Array)
}
ExihibitIntegrantMap.defaultProps = {
  exihibitLocation: []
}

export default ExihibitIntegrantMap

/** Toll Tip* */
const MapTollTipComponent = ({ index, text, handlerMap }) => {
  
  return (
    <div onClick={() => handlerMap(false)} className="map_address">
      <div className="purple-dot-toggle"></div>
    </div>
  )
 }
MapTollTipComponent.propTypes = {
  text: PropTypes.string
}
MapTollTipComponent.defaultProps = {
  text: ""
}
