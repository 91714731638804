import * as Mui from "@material-ui/core";
import clsx from "clsx";
import * as Dom from "react-router-dom";
import { CloseRounded } from "@material-ui/icons";
import * as Lab from "@material-ui/lab";
export * from "@material-ui/core";
export * from "react-router-dom";
export * from "@material-ui/lab";

const getColor = (theme, color) => {
  switch (color) {
    case "primary":
      return theme.palette.primary.main;
    case "secondary":
      return theme.palette.secondary.main;
    default:
      return color;
  }
};

const useStyle = Mui.makeStyles((theme) => ({
  linkRoot: {
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    lineHeight: "30px",
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  hyperlink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  body1Link: {
    fontSize: 22,
    lineHeight: "30px",
  },
  h5Link: {
    fontSize: 26,
    lineHeight: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  removeLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  primaryDarkBtn: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    "&>span>span": {
      "&>svg,&>div": {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      },
    },
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      "&>span>span": {
        "&>svg,&>div": {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  primaryLightBtn: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&>span>span": {
      "&>svg,&>div": {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&>span>span": {
        "&>svg,&>div": {
          color: theme.palette.common.white,
          fill: theme.palette.common.white,
        },
      },
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  medium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  light: {
    fontWeight: theme.typography.fontWeightLight,
  },
  circularProgressRoot: {
    color: (props) => getColor(theme, props?.color),
  },
  dialogTitle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&>h2": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>svg": {
        cursor: "pointer",
        width: 20,
        height: 20,
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  dialogContent: {
    padding: 24,
  },
  select: {
    "&>div": {
      padding: "8px 15px",
    },
    "&>fieldset": {
      top: 0,
    },
  },
  autocompleteOption: {
    padding: 0,
    backgroundColor: "transparent !important",
  },
  autocompletePopper: {
    zIndex: 1110,
  },
  textFieldRoot: {
    width: "100%",
    "&>div": {
      minHeight: 40,
      borderRadius: 5,
      padding: 0,
      "&>input,&>textarea": {
        padding: "8px 10px",
      },
    },
  },
}));

export const TextField = ({
  disableUnderline = true,
  className = "",
  ...props
}) => {
  const classes = useStyle();
  return (
    <Mui.TextField
      {...props}
      InputProps={{ ...props?.InputProps, disableUnderline }}
      className={clsx(classes.textFieldRoot, className)}
    />
  );
};

export const Autocomplete = ({ ...props }) => {
  const classes = useStyle();
  return (
    <Lab.Autocomplete
      {...props}
      classes={{
        option: classes.autocompleteOption,
        popper: classes.autocompletePopper,
      }}
    />
  );
};

export const Dialog = ({
  children,
  onClose = () => {},
  open = false,
  maxWidth = "sm",
  fullWidth = true,
  className = "",
  ...props
}) => {
  return (
    <Mui.Dialog
      {...props}
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={clsx(className)}
    >
      {children}
    </Mui.Dialog>
  );
};

export const DialogTitle = ({
  children,
  onClose = () => {},
  className = "",
  ...props
}) => {
  const classes = useStyle();
  return (
    <Mui.DialogTitle
      {...props}
      className={clsx(classes.dialogTitle, className)}
    >
      {children}
      <CloseRounded onClick={onClose} />
    </Mui.DialogTitle>
  );
};

export const DialogContent = ({ children, className = "", ...props }) => {
  const classes = useStyle();
  return (
    <Mui.DialogContent
      {...props}
      className={clsx(classes.dialogContent, className)}
    >
      {children}
    </Mui.DialogContent>
  );
};

export const Select = ({ children, className = "", ...props }) => {
  const classes = useStyle();
  return (
    <Mui.Select {...props} className={clsx(classes.select, className)}>
      {children}
    </Mui.Select>
  );
};

export const CircularProgress = ({
  size = 20,
  className = "",
  color = "primary",
  ...props
}) => {
  const classes = useStyle({ color });
  return (
    <Mui.CircularProgress
      {...props}
      size={size}
      className={clsx(className, classes.circularProgressRoot)}
    />
  );
};

export const Button = ({
  children,
  variant = "contained",
  primaryDarkBtn = false,
  primaryLightBtn = false,
  className = "",
  loading = false,
  endIcon,
  ...props
}) => {
  const classes = useStyle();
  return (
    <Mui.Button
      {...props}
      variant={variant}
      className={clsx(
        primaryDarkBtn && classes.primaryDarkBtn,
        primaryLightBtn && classes.primaryLightBtn,
        className
      )}
      endIcon={loading ? <CircularProgress size={20} /> : endIcon}
    >
      {children}
    </Mui.Button>
  );
};

export const Link = ({
  to = "",
  children,
  className = "",
  isLink = true,
  variant = "",
  bold = false,
  medium = false,
  light = false,
  hyperlink = false,
  target,
  href,
  ...props
}) => {
  const classes = useStyle();
  return (
    <Dom.Link
      to={to}
      className={clsx(
        !variant && classes.linkRoot,
        variant === "body1" && classes.body1Link,
        variant === "h5" && classes.h5Link,
        !isLink && classes.removeLink,
        hyperlink && classes.hyperlink,
        bold && classes.bold,
        medium && classes.medium,
        light && classes.light,
        className
      )}
      href={href}
      target={target}
      {...props}
    >
      {children}
    </Dom.Link>
  );
};
