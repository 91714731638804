import React from "react";
import CircularProgress from "./CircularProgress";

export default function LoadingTextWithCircularProgress({
  text = "Please wait...",
  borderColor = "#28A745",
  className = "",
}) {
  return (
    <div className={`loading-text-with-circularprogress center ${className}`}>
      <p>{text}</p>
      <CircularProgress
        className="circular-progress-div"
        borderColor={borderColor}
      />
    </div>
  );
}
