/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";
import Callouts from "./Callouts";
import ProductIngredients from "./ProductIngredients";

const ProductCallouts = (props) => {
  const {
    data: { type, title, list },
    sourceLinks,
  } = props;
  if (!Array.isArray(list)) {
    return false;
  }
  return (
    <div className={`product-${type}`}>
      {title && <p className={`text-center ${type}-title`}>{title}</p>}
      {type === "integration" ? (
        sourceLinks?.length > 0 ? (
          <div className="product-ingredients">
            <ProductIngredients list={sourceLinks} device="desktop" />
          </div>
        ) : (
          <p className="zero-state-product-ingredients">No data available!</p>
        )
      ) : (
        <div classN ame={`list-${type}`}>
          {list.map((item, index) => (
            <Callouts item={item} type={type} key={item.title + index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductCallouts;
