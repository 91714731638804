import React from "react";
import { Grid, makeStyles, Typography, Divider } from "..";
import { AppImages } from "../../constants";
import { ULIcon } from "../../constants/svgIcons";

const {
  homePageImg: { blockchainHelp },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  blockchainHelpContainer: {
    backgroundColor: theme.palette.secondary.main,
    margin: "0 auto",
    padding: "110px 0 70px",
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  blockchainHelpContent: {
    marginBottom: 60,
    "&>*": {
      textAlign: "center",
    },
    "&>h2": {
      marginBottom: 20,
      padding: "0 150px",
      "&>span": {
        color: theme.palette.primary.main,
      },
    },
    "&>p": {
      "&:last-child": {
        marginTop: 30,
      },
    },
  },
  blockchainHelpFeaturesDiv: {
    "&>div": {
      alignItems: "center",
      justifyContent: "space-around",
    },
  },
  blockchainHelpFeaturesImage: {
    width: "100%",
    maxWidth: 470,
    height: "auto",
    maxHeight: 470,
    "&>img": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down(980)]: {
      maxWidth: 450,
      maxHeight: 450,
    },
  },
  blockchainHelpFeaturesContent: {
    width: "100%",
    maxWidth: "50%",
    paddingRight: 50,
    "&>div": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      "&>hr": {
        margin: "20px 0",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
      "&>div": {
        "&>h5": {
          marginBottom: 15,
          color: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          "&>svg": {
            width: 20,
            height: 20,
            marginRight: 20,
          },
        },
        "&>p": {
          fontSize: 18,
          lineHeight: "23px",
          color: theme.palette.common.black,
        },
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    blockchainHelpContainer: {
      padding: "90px 0 60px",
    },
    blockchainHelpContent: {
      marginBottom: 40,
      "&>h2": {
        padding: 0,
      },
    },
    blockchainHelpFeaturesDiv: {
      "&>div": {
        flexDirection: "column-reverse",
      },
    },
    blockchainHelpFeaturesContent: {
      paddingRight: 0,
      maxWidth: "unset",
    },
    blockchainHelpFeaturesImage: {
      marginBottom: 20,
    },
  },
  [theme.breakpoints.down("xs")]: {
    blockchainHelpContainer: {
      padding: "70px 0 50px",
    },
    blockchainHelpContent: {
      marginBottom: 20,
    },
  },
  [theme.breakpoints.down(400)]: {
    blockchainHelpContainer: {
      padding: "50px 0 40px",
    },
  },
}));

export default function HealthLOQTechnology() {
  const classes = useStyle();
  return (
    <Grid container className={classes.blockchainHelpContainer}>
      <div className="bodyMaxWidth">
        <Grid item className={classes.blockchainHelpContent}>
          <Typography variant="h2" bold>
            <span>HealthLOQ</span> Technology
          </Typography>
          <Typography variant="body1" light>
            HealthLOQ verifies the authenticity of a product using data directly
            from those who helped make it. Our independent, tamperproof ledger
            technologies can collect information directly from all the parties
            involved in the production, testing, delivery, packaging and
            distribution of the product. The HealthLOQ platform “locks” and
            secures the information, assigns a digital id to the product, tracks
            it's progress, combines and aggregates the information in an easy to
            see timeline and map, and makes the information available for
            consumers with the scan of a QR code or the entering of a lot/batch
            number on the HealthLOQ website.
          </Typography>
          <Typography variant="body1" bold>
            Counterparty validation | Third party validation | Immutable,
            unchangeable data store.
          </Typography>
        </Grid>
        <Grid item className={classes.blockchainHelpFeaturesDiv}>
          <Grid container>
            <Grid item className={classes.blockchainHelpFeaturesContent}>
              <Grid container>
                <Grid item>
                  <Typography variant="h5" bold>
                    <ULIcon />
                    Trusted
                  </Typography>
                  <Typography variant="body2" light>
                    A blockchain-powered platform that enables the good guys to
                    differentiate themselves to consumers through trust and
                    transparency
                  </Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <Typography variant="h5" bold>
                    <ULIcon />
                    Secure
                  </Typography>
                  <Typography variant="body2" light>
                    An immutable, single source of truth for product content,
                    quality and provenance
                  </Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <Typography variant="h5" bold>
                    <ULIcon />
                    Easy
                  </Typography>
                  <Typography variant="body2" light>
                    The ability to track with precision in case of an adverse
                    event or product recall.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.blockchainHelpFeaturesImage}>
              <img src={blockchainHelp} alt="Blockchain Help Img" />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
