import { makeStyles } from "@material-ui/core";
import {
  Solution12,
  SolutionFor1,
  SolutionFor7,
} from "../../constants/svgIcons";
import BoxSectionComponent from "../Common/BoxSectionComponent";

const useStyle = makeStyles((theme) => ({
  icons: {
    "&>path": {
      stroke: theme.palette.primary.light,
    },
  },
}));

export default function Section2() {
  const classes = useStyle();
  const content = [
    {
      icon: <SolutionFor1 className={classes.icons} />,
      content:
        "Retailers (even etailers) can verify resell authorizations and documentation from suppliers",
    },
    {
      icon: <SolutionFor7 className={classes.icons} />,
      content: "Product documentation available at the batch level",
    },
    {
      icon: <Solution12 />,
      content:
        "The authenticity of a supplier and their products can be tracked at multiple levels",
    },
  ];
  return <BoxSectionComponent content={content} heading="" />;
}
