import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Grid,
  Switch,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { Label } from "./Typographys";

export function MuiTextfield({
  value,
  onChange,
  className = "",
  placeholder,
  startIcon,
  endIcon,
  disableUnderline = true,
  helperText = "",
  autoFocus,
  type = "text",
  required,
  disabled,
  error,
  match,
  children,
  multiline,
  minRows,
  maxRows,
  showLockIcon,
  lockIconState,
  handleLockIconClick,
  switchId,
  labelWithPlaceholder = "",
  ...props
}) {
  return (
    <TextField
      autoFocus={autoFocus}
      type={type}
      label={placeholder}
      InputLabelProps={{
        shrink: true,
      }}
      required={required}
      disabled={disabled}
      error={error}
      value={value}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      onChange={onChange}
      placeholder={
        Boolean(labelWithPlaceholder)
          ? labelWithPlaceholder
          : !match
          ? placeholder
          : ""
      }
      className={clsx(
        "textfield-root",
        error && "textfield-error",
        switchId && "switch-textfield",
        className
      )}
      InputProps={{
        startAdornment: startIcon && (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: (endIcon || showLockIcon) && (
          <InputAdornment position="end">
            {endIcon || (
              <PublicPrivateSwitch
                checked={lockIconState}
                id={switchId}
                onChange={handleLockIconClick}
              />
            )}
          </InputAdornment>
        ),
        disableUnderline,
      }}
      helperText={helperText}
      {...props}
    >
      {children}
    </TextField>
  );
}

export function MuiSelect({
  className = "",
  value = "",
  onChange,
  placeholder,
  helperText,
  menuItems = [],
  disableUnderline = true,
  match,
  error,
  endIcon,
  showLockIcon,
  lockIconState,
  handleLockIconClick,
  required,
  loading = false,
  visibleOptionLength = 5,
  optionLength = 36,
  selectTopBottonPadding = 16,
  switchId,
  ...props
}) {
  return (
    <FormControl
      className={clsx(
        "select-root",
        loading && "select-box-with-endicon",
        !showLockIcon && !endIcon && !loading && "add-default-svg-style",
        !value && "mui-select-placeholder",
        switchId && "switch-select",
        className
      )}
    >
      <InputLabel shrink>
        {placeholder}
        {required ? " *" : ""}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty={!match}
        disableUnderline={disableUnderline}
        error={error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight:
                selectTopBottonPadding + optionLength * visibleOptionLength,
            },
          },
        }}
        {...props}
        endAdornment={
          (loading || endIcon || showLockIcon) && (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress style={{ color: "#0ECA5A" }} size={20} />
              ) : (
                endIcon || (
                  <PublicPrivateSwitch
                    checked={lockIconState}
                    id={switchId}
                    onChange={handleLockIconClick}
                  />
                )
              )}
            </InputAdornment>
          )
        }
      >
        {!match && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {menuItems?.map((item, key) => {
          if (typeof item === "object") {
            return (
              <MenuItem
                className="select-menuitem"
                value={item.value}
                key={key}
              >
                {item.label}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem className="select-menuitem" value={item} key={key}>
                {item}
              </MenuItem>
            );
          }
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function MuiSwitch({
  className = "",
  value = false,
  onChange,
  placeholder,
  match,
  required,
  ...props
}) {
  return (
    <Grid className={clsx("switch-root", className)}>
      <Label>{placeholder}</Label>
      <Grid>
        <Grid item>No</Grid>
        <Grid item>
          <Switch
            required={required}
            checked={value}
            onChange={onChange}
            {...props}
          />
        </Grid>
        <Grid item>Yes</Grid>
      </Grid>
    </Grid>
  );
}

export function PublicPrivateSwitch({
  onChange,
  checked,
  id,
  className,
  isMasterToggle,
  title = "",
  onText = "",
  offText = "",
  isConnectionToggle = false,
  isMasterConnectionToggle = false,
  style = {},
}) {
  return (
    <Tooltip title={title} arrow placement="top">
      <div
        className={clsx(
          "form-group switch_wrapper",
          isMasterToggle && "master-toggle-switch",
          isConnectionToggle && "connection-toggle-switch",
          isMasterConnectionToggle && "master-connection-toggle-switch",
          className
        )}
        style={style}
      >
        <div className="button-switch">
          <input
            type="checkbox"
            onChange={onChange}
            id={id}
            className="switch"
            checked={checked}
            tabIndex={-1}
          />
          <label htmlFor={id} className="lbl-off">
            {offText ? offText : isMasterToggle ? "Private All" : "Private"}
          </label>
          <label htmlFor={id} className="lbl-on">
            {onText ? onText : isMasterToggle ? "Public All" : "Public"}
          </label>
        </div>
      </div>
    </Tooltip>
  );
}
