import { useState } from 'react';
import { distance } from '../utils/Distance';

function midpoint(lat1, long1, lat2, long2, per = 0.5) {
  return [lat1 + (lat2 - lat1) * per, long1 + (long2 - long1) * per];
}

// interface IUseDistanceBetween {
//   sortedCoordinates: [number, number][];
//   rangeInMiles: number;
//   defaultCenter: [number, number];
//   defaultZoom: number;
// }

const useDistanceBetween = (arrayOfCoordinates) => {
  const defaultState = arrayOfCoordinates
    .map((co, key) => {
      return { coordinates: co[0] * co[1], key: key };
    })
    .sort((a, b) => (a.coordinates > b.coordinates ? a.coordinates : b.coordinates));

  const firstItem = defaultState[0];
  const furthestItem = defaultState[defaultState.length - 1];

  const firstLat = arrayOfCoordinates[firstItem.key][0];
  const firstLng = arrayOfCoordinates[firstItem.key][1];

  const furthestLat = arrayOfCoordinates[furthestItem.key][0];
  const furthestLng = arrayOfCoordinates[furthestItem.key][1];

  const defaultCenter = midpoint(furthestLat, furthestLng, firstLat, firstLng);

  const [sortedCoordinates] = useState([arrayOfCoordinates[firstItem.key], arrayOfCoordinates[furthestItem.key]]);

  let defaultZoom = 0;

  const rangeInMiles = distance(firstLat, firstLng, furthestLat, furthestLng);
  if (rangeInMiles > 2000) {
    defaultZoom = 0;
  } else if (rangeInMiles > 1000 && rangeInMiles < 2000) {
    defaultZoom = 3.5;
  } else if (rangeInMiles > 500 && rangeInMiles < 1000) {
    defaultZoom = 4;
  } else if (rangeInMiles > 300 && rangeInMiles < 500) {
    defaultZoom = 5;
  } else if (rangeInMiles > 200 && rangeInMiles < 300) {
    defaultZoom = 6;
  } else if (rangeInMiles > 100 && rangeInMiles < 200) {
    defaultZoom = 7;
  } else if (rangeInMiles > 50 && rangeInMiles < 100) {
    defaultZoom = 9;
  } else if (rangeInMiles < 50) {
    defaultZoom = 9;
  }

  return {
    sortedCoordinates,
    rangeInMiles,
    defaultCenter,
    defaultZoom
  };
};

export { useDistanceBetween };
