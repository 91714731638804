import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";

const {
  images: { docAuthenticatorDashboard },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function HealthLoqSection() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>HealthLOQ</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            HealthLOQ connects organizations with their customers using a
            fully-permissioned, social-media based platform. Consumers are in
            complete control of their profile data and can both extend and
            retract access to their information at any time. Organizations can
            reach out to specific audiences, tailor survey interactions, and
            offer incentives to consumers for participation. Consumers can
            connect with other product users and share experiences.
            Practitioners can connect with patients and brands they trust to
            make recommendations and offer their expertise. The focus is on
            connection.
          </Typography>
          <Box className={classes.btns}>
            <Button href="/contact-us">Contact Us</Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
