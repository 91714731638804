import React from "react";

export default function CircularProgress({
  width = 20,
  height = 20,
  borderWidth = 3,
  borderStyle = "solid",
  borderColor = "#fff",
  className = "",
}) {
  return (
    <div
      className={`loaderRoot ${className}`}
      style={{
        width,
        height,
        borderWidth,
        borderStyle,
        borderColor: "transparent",
        borderTopColor: borderColor,
        borderRightColor: borderColor,
      }}
    ></div>
  );
}
