import React from "react";
import { Grid, Typography, makeStyles, Divider, Link } from "../";
import { ULIcon } from "../../constants/svgIcons";

const useStyle = makeStyles((theme) => ({
  healthloqUserContainer: {
    backgroundColor: theme.palette.common.white,
    margin: "0 auto",
    padding: "100px 0",
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: 25,
    "&>span": {
      color: theme.palette.primary.main,
    },
  },
  userPointsList: {
    "&>hr": {
      width: "100%",
      margin: "20px 0",
      backgroundColor: "#44444480",
    },
    "&>div": {
      width: "100%",
      "&>h5": {
        marginBottom: 15,
        color: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        "&>svg": {
          width: 20,
          height: 20,
          marginRight: 20,
        },
        "&>a": {
          color: theme.palette.primary.main,
          textDecoration: "underline",
        },
      },
      "&>p": {
        lineHeight: "23px",
        color: theme.palette.common.black,
        fontSize: 18,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    healthloqUserContainer: {
      padding: "70px 0",
    },
    heading: {
      marginBottom: 15,
    },
  },
  [theme.breakpoints.down("xs")]: {
    healthloqUserContainer: {
      padding: "40px 0",
    },
  },
  [theme.breakpoints.down(400)]: {
    healthloqUserContainer: {
      padding: "30px 0",
    },
  },
}));

export default function AsHealthLOQUserICan() {
  const classes = useStyle();
  return (
    <Grid container className={classes.healthloqUserContainer}>
      <div className="bodyMaxWidth">
        <Typography variant="h2" bold className={classes.heading}>
          As a <span>HealthLOQ</span> user, I can…
        </Typography>
        <Grid container className={classes.userPointsList}>
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Make a difference
            </Typography>
            <Typography variant="body2" light>
              by holding companies accountable. As consumers, we need to let our
              voice and feedback be heard and demand legitimacy and transparency
              from those producing the products we purchase and consume.
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Search
            </Typography>
            <Typography variant="body2" light>
              for product information. Not just label information or brochure
              information but information provided directly by the companies
              that provided ingredients or services to bring the product to your
              doorstep
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Invite
            </Typography>
            <Typography variant="body2" light>
              companies or brands that you would like to see on the platform
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Provide feedback
            </Typography>
            <Typography variant="body2" light>
              on specific batches products so those involved can really
              incorporate that information for improvements
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Respond
            </Typography>
            <Typography variant="body2" light>
              to surveys from companies on the platform or considering the
              platform in exchange for compensation
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Be involved
            </Typography>
            <Typography variant="body2" light>
              in helping the planet by recording the proper disposal of product
              and packaging and see who was involved in what aspects of the
              product's creation for&nbsp;
              <b>
                <i>real</i>
              </b>
              &nbsp;sustainability information
            </Typography>
          </Grid>
          <Divider />
          {/* <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Help
            </Typography>
            <Typography variant="body2" light>
              encourage companies to be honest. As consumers, we need to let our
              voice and feedback be heard and demand legitimacy and transparency
              from those producing the products we purchase and consume.
            </Typography>
          </Grid>
          <Divider /> */}
          <Grid item>
            <Typography variant="h5" bold>
              <ULIcon />
              Next Step?….
              <Link to={"/create-profile"} variant="h5">
                Register as a consumer
              </Link>
              !
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
