import { combineReducers } from "redux";
import productReducers from "./product";
import loaderReducer from "./loader";
import blogNewsReducers from "./blogNews";
import ProfileReducers from "./profileReducers";
import OrganizationReducers from "./organization";
import CommonReducers from "./common";
import PostReducer from "./post";

// const allReducers = combineReducers({
//   loaderReducer,
//   productReducers,
//   blogNewsReducers,
//   ProfileReducers,
//   OrganizationReducers,
//   CommonReducers,
//   PostReducer,
// });

const allReducers = (state = {}, action) => {
  return {
    loaderReducer: loaderReducer(state.loaderReducer, action, state),
    productReducers: productReducers(state.productReducers, action, state),
    blogNewsReducers: blogNewsReducers(state.blogNewsReducers, action, state),
    ProfileReducers: ProfileReducers(state.ProfileReducers, action, state),
    OrganizationReducers: OrganizationReducers(
      state.OrganizationReducers,
      action,
      state
    ),
    CommonReducers: CommonReducers(state.CommonReducers, action, state),
    PostReducer: PostReducer(state.PostReducer, action, state),
  };
};

export default allReducers;
