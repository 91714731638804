import { PageWrapper } from "../../components/Wrappers";
import HowItWorksValidDocuments from "../../components/DocumentProtection/HowItWorksValidDocuments";
import ValidDocumentExample from "../../components/DocumentProtection/ValidDocumentExample";
import HowItWorksInvalidDocuments from "../../components/DocumentProtection/HowItWorksInvalidDocuments";
import FraudulentDocumentExample from "../../components/DocumentProtection/FraudulentDocumentExample";
import BulkDocumentProtection from "../../components/DocumentProtection/BulkDocumentProtection";
import HealthLoqSection from "../../components/DocumentProtection/HealthloqSection";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";

const {
  headerImages: { documentProtection },
} = appImages;

export default function DocumentProtection() {
  return (
    <PageWrapper pageName="document-protection">
      <HeaderSection
        headerImgSrc={documentProtection}
        headerText="Simple, Easy Document Protection without the document ever leaving your secure perimeter."
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <HowItWorksValidDocuments />
      <ValidDocumentExample />
      <HowItWorksInvalidDocuments />
      <FraudulentDocumentExample />
      <BulkDocumentProtection />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
