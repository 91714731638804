import appImages from "../../constants/AppImage";
import LeftImageSection from "../Common/Section/LeftImageSection";
import RightImageSection from "../Common/Section/RightImageSection";
import { Button } from "@material-ui/core";

const {
  images: { ConsumerProfileImg },
} = appImages;
export default function RetailerSection() {
  const content = [
    {
      href: `/document-verification`,
      content: (
        <>
          Verify a suppliers' credentials and the authenticity of their products
          that they sell
        </>
      ),
    },
    {
      href: `/counterfeit-prevention`,
      content: (
        <>
          Automate the verification of documentation, credentials, no manual
          searches
        </>
      ),
    },
    {
      href: `/consumer-connection`,
      content: (
        <>
          Connect with customers individually with proper privacy, consent
          management, and product feedback
        </>
      ),
    },
    {
      href: `/product-origin`,
      content: (
        <>
          Connect with customers individually with proper privacy, consent
          management, and product feedback
        </>
      ),
    },
  ];
  return (
    <>
      <LeftImageSection
        heading="Retailers - the champion, advocate, and protector of the consumer!"
        content={content}
        img={ConsumerProfileImg}
        notBtn
        customBtn={
          <Button href="/document-verification">Verify a Document</Button>
        }
      />
      <RightImageSection
        position="left"
        content="HealthLOQ verifies the authenticity of a product using data directly from those who helped make it. Our independent, tamperproof ledger technologies can collect information directly from all the parties involved in the production, testing, delivery, packaging and distribution of the product. The HealthLOQ platform “locks” and secures the information, assigns a digital id to the product, tracks it's progress, combines and aggregates the information in an easy to see timeline and map."
      />
    </>
  );
}
