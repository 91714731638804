import { Box, Button, Grid, Link, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import appImages from "../../constants/AppImage";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentBox: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: "25px",
  },
  content: {
    marginTop: "auto",
    marginBottom: "0",
  },
  sectionImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    marginTop: 30,
    ustifyContent: "center",
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

const {
  images: { ComplianceDashboardImage },
} = appImages;

export default function ComplianceDashboardExample() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Box className={classes.heading}>
          <PrimaryHeading>Compliance Dashboard</PrimaryHeading>
        </Box>
        <Grid className={classes.contentBox} container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Box className={classes.sectionImage}>
              <img src={ComplianceDashboardImage} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.content}>
            <Box className={classes.btns}>
              <Button>Learn More</Button>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
              <Link href={`${process.env.REACT_APP_PRODUCER_APP_URL}/plans`}>
                <Button>Subscribe</Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
