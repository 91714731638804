import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import CalendlyPage from "../Common/CalendlyPage";

const {
  images: { supplierHowItWorkSection2 },
} = appImages;

const useStyle = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  root: {
    padding: "70px 0",
  },
  heading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  textContaint: {
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

export default function HowItWorkSection2() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>How it works</PrimaryHeading>
            </Box>
            <Typography className={classes.textContaint} variant="h1">
              Valid Document Example from an internal or third-party lab.
            </Typography>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={supplierHowItWorkSection2} alt="Image" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
