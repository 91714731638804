import clsx from "clsx";
import React from "react";
import { Typography, Grid, Drawer, makeStyles } from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProductIngredients from "../ProductIngredients";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
        "&>h2": {
          marginBottom: 20,
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  ingredientList: {
    width: "100%",
  },
}));

export default function Ingredients({
  open = false,
  onClose = () => {},
  sourceLinks,
  integrantInfo,
}) {
  const classes = useStyle();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Typography variant="h2" bold>
          Batch Ingredients
        </Typography>
        {sourceLinks.length > 0 ? (
          <Grid item className={clsx(classes.ingredientList)}>
            <ProductIngredients
              list={sourceLinks}
              integrantInfo={integrantInfo}
              device="desktop"
              onClose={() => onClose("openOrigin")}
              location="ingredientDrawer"
            />
          </Grid>
        ) : (
          <Typography variant="body1" light>
            Ingredients not available
          </Typography>
        )}
      </Grid>
    </Drawer>
  );
}
