import React from "react";
import { SectionWithTreePlan } from "../SectionWrappers";
import { Box, Typography, makeStyles, Link } from "@material-ui/core";
import appImages from "../../../constants/AppImage";

const {
  headerImages: { imageShadow, imageShadowRight },
  logo,
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: 600,
    maxHeight: 1000,
    backgroundImage: (props) => `url(${props?.headerImgSrc})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundPosition: (props) => props?.headerImgPosition,
    "&>div": {
      width: "100%",
      height: "100%",
      minHeight: 600,
      backgroundImage: (props) =>
        `url(${
          props?.headerTextPosition === "right"
            ? props?.imageShadowRight
            : props?.imageShadow
        })`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "left",
      display: "flex",
      alignItems: "center",
      justifyContent: (props) =>
        props?.headerTextPosition === "left" ? "flex-start" : "flex-end",
      "&>div": {
        width: "100%",
        maxWidth: "50%",
        padding: "0 50px",
        display: "flex",
        alignItems: "flex-start",
        "&>img": {
          width: 36,
          height: 36,
          marginRight: 10,
          marginTop: 3,
          filter: `brightness(0) invert(1)`,
        },
        "&>div": {
          display: "flex",
          flexDirection: "column",
          "&>h1": {
            color: theme.palette.common.white,
          },
          "&>p": {
            color: theme.palette.common.white,
            marginTop: 10,
          },
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "60%",
          padding: "0 40px",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "70%",
          padding: "0 30px",
          "&>img": {
            width: 24,
            height: 24,
            marginTop: 2,
          },
          "&>div": {
            "&>h1": {
              fontSize: 24,
            },
            "&>p": {
              fontSize: 14,
            },
          },
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          padding: "0 20px",
          justifyContent: "center",
        },
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        "&>div": {
          maxWidth: "100%",
          padding: "0 20px",
        },
      },
    },
    [theme.breakpoints.down(768)]: {
      minHeight: 500,
      "&>div": {
        minHeight: 500,
      },
    },
  },
}));

export default function HeaderSection({
  headerImgSrc = "",
  headerText = "",
  headerTextPosition = "left",
  headerImgPosition = "center top",
  headerDescription = "",
  headerLink = "",
}) {
  const classes = useStyle({
    headerImgSrc,
    imageShadow,
    imageShadowRight,
    headerTextPosition,
    headerImgPosition,
  });
  return (
    <SectionWithTreePlan position="left">
      <Box className={classes.root}>
        <Box>
          <Box>
            <img src={logo} alt="HealthLOQ Logo" />
            <Box>
              <Typography variant="h1">
                {headerLink ? (
                  <Link
                    href={headerLink}
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    {headerText}
                  </Link>
                ) : (
                  headerText
                )}
              </Typography>
              {Boolean(headerDescription) && (
                <Typography variant="body1">{headerDescription}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionWithTreePlan>
  );
}
