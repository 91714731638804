import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Button from "../../components/Profile/Button";
import { login } from "../../redux/actions/profileActions";
import { MuiTextfield } from "../../components/Profile";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { GoogleIcon, FacebookIcon } from "../../constants/svgIcons";
import utils, { verifyEnvironment } from "../../utils/commonUtils";
import app from "../../configs/firebaseConfig";
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { NotificationManager } from "react-notifications";
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
facebookProvider.setCustomParameters({
  prompt: "select_account",
});
googleProvider.addScope("email");
facebookProvider.addScope("email");
facebookProvider.addScope("user_friends");

const useStyle = makeStyles((theme) => ({
  hLoginFormOR: {
    marginTop: "10px !important",
    width: "100% !important",
    "&>p": {
      textAlign: "center",
      position: "relative",
      "&:before": {
        top: "50%",
        width: "80%",
        height: "1px",
        content: "''",
        position: "absolute",
        transform: "translate(-50%,-50%)",
        left: "50%",
        backgroundColor: "#28a745",
      },
      "&>span": {
        padding: "0 5px",
        position: "relative",
        backgroundColor: "#fff",
      },
    },
  },
}));

let loginFlag = false;

const auth = getAuth();
export const ProfileLogin = (props) => {
  const classes = useStyle();
  const { match, login, profileLogin, history } = props;
  const emailRegex = new RegExp(
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,6})+$/
  );
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ email: "", password: "" });
  const [togglePasswordVisibility, setTogglePasswordVisibility] =
    useState(false);
  const handlePasswordVisibilityChange = () =>
    setTogglePasswordVisibility(!togglePasswordVisibility);
  const handleVerify = () => {
    let obj = { ...error };
    let isValid = true;
    if (!password) {
      isValid = false;
      obj["password"] = "Password is a required field.";
    }
    if (password && (password?.length < 8 || password?.length > 20)) {
      isValid = false;
      obj["password"] = "Password length should be 8 to 20 characters long.";
    }
    if (!email) {
      isValid = false;
      obj["email"] = "Email is a required field.";
    }
    if (email && !emailRegex.test(email)) {
      isValid = false;
      obj["email"] = "Please enter valid email.";
    }
    setError(obj);
    return isValid;
  };
  const handleSubmit = () => {
    if (handleVerify()) {
      login({ password, email, user_type: "normal" });
      loginFlag = true;
    }
  };
  useEffect(() => {
    if (profileLogin?.status === "1" && loginFlag) {
      setPassword("");
      setEmail("");
      setError({ email: "", password: "" });
      history.push("/verify-otp");
      loginFlag = false;
    }
  }, [profileLogin]);

  // const handleLogin = async (formValue, type) => {
  //   console.log("formValue------------------------>", formValue)
  //   console.log("type------------------------>", type)
  //   let payload = "";
  //   if (type == "google") {
  //     payload = {
  //       email: formValue?.email || "",
  //       password: "",
  //       google_uid: formValue?.platform_id || "",
  //       type: type,
  //     };
  //   } else {
  //     payload = {
  //       email: formValue?.email || "",
  //       password: "",
  //       facebook_uid: formValue?.platform_id || "",
  //       type: type,
  //     };
  //   }
  //   console.log("payload--------------------------->", payload);
  //   login(payload);
  // };

  const handleSocialLogin = (type, provider) => {
    signInWithPopup(auth, provider)
      .then(async (res) => {
        const email =
          res.user?.providerData[0]?.email ||
          res.user?.email ||
          (type === "facebook" && res.user?.providerData[0]?.phoneNumber);
        if (!email && !res.user?.providerData[0]?.phoneNumber) {
          return NotificationManager.error(
            "Your Email or phone number not found. please check your account.",
            "",
            5000
          );
        }
        let payload = {
          email,
          platform_id: res?.user?.providerData[0]?.uid,
          user_type: type,
        };
        await login(payload);
        loginFlag = true;
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid className="psl-root">
      <Grid className="psl-form-div">
        <Grid>
          <MuiTextfield
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError({ ...error, email: "" });
            }}
            placeholder={"Email"}
            match={match}
            required
            error={Boolean(error?.email)}
            helperText={error?.email}
            type="email"
            autoFocus
          />
          <MuiTextfield
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError({ ...error, password: "" });
            }}
            type={togglePasswordVisibility ? "text" : "password"}
            endIcon={
              togglePasswordVisibility ? (
                <VisibilityOff onClick={handlePasswordVisibilityChange} />
              ) : (
                <Visibility onClick={handlePasswordVisibilityChange} />
              )
            }
            placeholder={"Password"}
            match={match}
            required
            error={Boolean(error?.password)}
            helperText={error?.password}
          />
        </Grid>
        <Grid className="psl-footer">
          <Button
            profileBgBtn
            onClick={handleSubmit}
            loading={
              profileLogin?.loading &&
              profileLogin?.data?.user_type === "normal"
            }
          >
            Login
          </Button>

          <Grid className={classes.hLoginFormOR}>
            <Typography variant="body1" color="grey50">
              <span>OR</span>
            </Typography>
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            {(verifyEnvironment("dev") ||
              verifyEnvironment("local") ||
              verifyEnvironment("qa")) && (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleSocialLogin("facebook", facebookProvider)
                  }
                  startIcon={
                    profileLogin?.loading &&
                    profileLogin?.data?.user_type === "facebook" ? (
                      <CircularProgress size={20} />
                    ) : (
                      <FacebookIcon color="white" />
                    )
                  }
                  loginWithFacebookBtn
                >
                  Sign in with Facebook
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleSocialLogin("google", googleProvider)}
                loginWithGoogleBtn
                startIcon={
                  profileLogin?.loading &&
                  profileLogin?.data?.user_type === "google" ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <GoogleIcon color="white" />
                  )
                }
              >
                Sign in with Google
              </Button>
            </Grid>
          </Grid>

          <p className="psl-footer-link">
            Forgot password?{" "}
            <Link to={"/forgot-password"}>Click to recover</Link>
          </p>
          <p className="psl-footer-link">
            Don't have an account yet?{" "}
            <Link to={"/create-profile"}>Sign up</Link> here.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ ProfileReducers: { profileLogin } }) => ({
  profileLogin,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLogin);
