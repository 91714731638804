import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { PrimaryIconFrame } from "../IconFrames";
import { SectionWithTreePlan } from "../SectionWrappers";
import PrimaryHeading from "../Typography/PrimaryHeading";
import CalendlyPage from "../CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p:not(:nth-child(2))": {
      marginBottom: 15,
    },
    "&>p": {
      color: "#707070",
      "&>span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  heading: {
    display: "  grid",
    width: "80%",
    textAlign: "center",
    justifyItems: "center",
    margin: "auto",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "434px",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  customBtn: {
    display: "flex",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
    "&>p": {
      wordBreak: "break-word",
    },
  },
  contentBoxWithLink: {
    "&>a": {
      width: "100%",
      padding: "15px 20px",
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
      "&>div": {
        marginRight: 15,
        "&>div": {
          fontSize: 30,
        },
      },
      "&>p": {
        wordBreak: "break-word",
      },
    },
  },
  contentContainer: {
    marginTop: 75,
  },
}));

export default function LeftImageSection({
  content = [],
  heading,
  notBtn = false,
  img,
  customBtn = null,
}) {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Box className={classes.heading}>
          <PrimaryHeading>{heading}</PrimaryHeading>
        </Box>
        <Grid className={classes.contentContainer} container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={img} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Grid container spacing={3}>
              {content?.map((item, key) => {
                return (
                  <Grid item style={{ width: "100%" }} key={key}>
                    <Box
                      className={
                        item?.href
                          ? classes.contentBoxWithLink
                          : classes.contentBox
                      }
                    >
                      {item?.href ? (
                        <Link href={item?.href}>
                          <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                          <Typography variant="body2">
                            {item?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <>
                          <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                          <Typography variant="body2">{item}</Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {!notBtn && (
              <Box className={classes.btns}>
                <CalendlyPage>
                  <Button>Request a Demo</Button>
                </CalendlyPage>
              </Box>
            )}
            {Boolean(customBtn) && (
              <Box className={classes.customBtn}>{customBtn}</Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
