import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import CalendlyPage from "../Common/CalendlyPage";

const {
  images: { invalidCoa },
} = appImages;

const useStyle = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  root: {
    padding: "70px 0",
  },
  heading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    "&>img": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  textContaint: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

export default function HowItWorksInvalidDocuments() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={invalidCoa} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>
                How it works - Fraudulent Documents Example
              </PrimaryHeading>
            </Box>
            <Typography variant="body2" className={classes.textContaint}>
              This image shows the results (Not a verified match) when the
              interested party tries to validate the tampered, fraudulent COA
              through the portal to the blockchain.
            </Typography>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
