import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntegrantBlockchainProof } from "../Common";
import LoadingTextWithCircularProgress from "../Common/LoadingTextWithCircularProgress";

function IntegrantBlockchainProofDialog({
  blockchainProof_loading,
  blockchainProof,
  integrant_id,
  type = "",
}) {
  const [blockchainData, setBlockchainData] = useState(null);

  const handleClose = () => {
    setBlockchainData(null);
  };

  useEffect(() => {
    if (
      Object.keys(blockchainProof).length !== 0 &&
      ((type === "organization_exhibit" &&
        integrant_id === blockchainProof?.data?.OrganizationExhibitId) ||
        integrant_id === blockchainProof?.data?.IntegrantId)
    ) {
      setBlockchainData(blockchainProof);
    }
  }, [blockchainProof]);

  return (
    <div
      className={`modal fade bd-example-modal-lg`}
      id="IntegrantBlockchainProofDialog"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="IntegrantBlockchainProofDialogLabel"
      aria-hidden="true"
      style={{ zIndex: 1100 }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="IntegrantBlockchainProofDialogLabel"
            >
              {type === "organization_exhibit" || type === "document_hash"
                ? "Organization blockchain proof"
                : " Product blockchain proof"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              {blockchainProof_loading ? (
                <LoadingTextWithCircularProgress />
              ) : (
                blockchainData && (
                  <IntegrantBlockchainProof
                    blockchainData={blockchainData}
                    className="mb20"
                  />
                )
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary model-close-button center"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  productReducers: { blockchainProof_loading, blockchainProof },
}) => ({
  blockchainProof_loading,
  blockchainProof,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrantBlockchainProofDialog);
