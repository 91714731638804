import { Box, makeStyles, Grid, Typography, Link } from "@material-ui/core";
import React from "react";
import {
  SolutionFor1,
  SolutionFor2,
  SolutionFor3,
  SolutionFor4,
  SolutionFor5,
  SolutionFor6,
  SolutionFor7,
  SolutionFor8,
  SolutionFor9,
  SolutionFor10,
  SolutionFor11,
} from "../../constants/svgIcons";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import {
  SectionBottomUI,
  SectionWithTreePlan,
} from "../Common/SectionWrappers";
import { PrimaryIconFrame } from "../Common/IconFrames";
import appImages from "../../constants/AppImage";

const {
  images: { solutionsForIncluding },
} = appImages;

const useStyle = makeStyles((theme) => ({
  sFIRoot: {
    background: "#F7F7F7",
    padding: "50px 0 70px",
  },
  sFIRightSide: {
    display: "flex",
    flexDirection: "column",
  },
  sFIHeading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
  },
  sFISolutionList: {
    marginBottom: 30,
  },
  sFIListItem: {
    "&>a": {
      width: "100%",
      maxWidth: 250,
      minWidth: 250,
      display: "flex",
      alignItems: "center",
      "&>p": {
        marginLeft: 10,
      },
    },
  },
  sFILeftSide: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function SolutionForIncluding() {
  const classes = useStyle();
  let solutionsForList = [
    {
      icon: <SolutionFor1 />,
      content: "Retailers",
      link: `/retailers`,
    },
    {
      icon: <SolutionFor2 />,
      content: "Manufacturers/Brands",
      link: `/manufacturer-brands`,
    },
    {
      icon: <SolutionFor3 />,
      content: "Labs and Certifying Organizations",
      link: `/labs`,
    },
    {
      icon: <SolutionFor4 />,
      content: "Ingredient Suppliers",
      link: `/ingredient-supplier`,
    },
    {
      icon: <SolutionFor5 />,
      content: "Consumers",
      link: `/consumer`,
    },
  ];
  let includingList = [
    {
      icon: <SolutionFor6 />,
      content: "Counterfeit Prevention & Reseller Authorization",
      link: `/counterfeit-prevention`,
    },
    {
      icon: <SolutionFor7 />,
      content: "Document Protection",
      link: `/document-protection`,
    },
    {
      icon: <SolutionFor7 />,
      content: "Document Verification",
      link: `/document-verification`,
    },
    {
      icon: <SolutionFor8 />,
      content: "Product Origin",
      link: `/product-origin`,
    },
    {
      icon: <SolutionFor9 />,
      content: "Consumer Connection",
      link: `/consumer-connection`,
    },
    {
      icon: <SolutionFor10 />,
      content: "Label Validation",
      link: `/label-validation`,
    },
    {
      icon: <SolutionFor11 />,
      content: "Circular Economy",
      link: `/circular-economy`,
    },
  ];
  return (
    <SectionWithTreePlan position="left" className={classes.sFIRoot}>
      <SectionBottomUI>
        <Box className="bodyMaxWidth">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Box className={classes.sFILeftSide}>
                <img src={solutionsForIncluding} alt="Image" width="100%" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.sFIRightSide}>
              <Box className={classes.sFIHeading}>
                <PrimaryHeading>Solutions for</PrimaryHeading>
              </Box>
              <Grid container spacing={3} className={classes.sFISolutionList}>
                {solutionsForList?.map((item, key) => {
                  return (
                    <Grid key={key} item xs={12} sm={12} md={6}>
                      <Box className={classes.sFIListItem}>
                        <Link href={item?.link}>
                          <PrimaryIconFrame>{item?.icon}</PrimaryIconFrame>
                          <Typography variant="body2">
                            {item?.content}
                          </Typography>
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Box className={classes.sFIHeading}>
                <PrimaryHeading>Including</PrimaryHeading>
              </Box>
              <Grid container spacing={3}>
                {includingList?.map((item, key) => {
                  return (
                    <Grid key={key} item xs={12} sm={12} md={6}>
                      <Box className={classes.sFIListItem}>
                        <Link href={item?.link}>
                          <PrimaryIconFrame>{item?.icon}</PrimaryIconFrame>
                          <Typography variant="body2">
                            {item?.content}
                          </Typography>
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SectionBottomUI>
    </SectionWithTreePlan>
  );
}
