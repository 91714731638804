import ReactGA from "react-ga";
import actionType from "../actionsType";
import toggleLoader from "./Loader";
import { blogNewsAPI } from "../../services";
import apiConstants from "../../services/constants";

const { GET_BLOG_NEWS_LIST } = actionType;

const {
  HTTP_STATUS_CODES: { SUCCESS },
} = apiConstants;

export const dispatchBlogNewsList = () => (dispatch) => {
  dispatch(toggleLoader(true));
  return blogNewsAPI
    .getBlogNewsList()
    .then((response) => {
      const {
        status,
        data: { data },
      } = response;
      if (status === SUCCESS) {
        dispatch(toggleLoader(false));
        dispatch({
          type: GET_BLOG_NEWS_LIST,
          payload: data,
        });
        return true;
      }
      return false;
    })
    .catch((error) => {
      ReactGA.exception({
        error,
        description: "An error ocurred",
        fatal: true,
      });
      dispatch(toggleLoader(false));
      // alert("News and blogs not available.");
    });
};
