import React from "react";
import { makeStyles } from "../";
import clsx from "clsx";
import SEO from "../SEO";
import Navbar from "../Header/Navbar";
import Footer from "../Footer";
import { AppConstant } from "../../constants";
import Header from "../Header";

const {
  homePage: { pageTitle },
} = AppConstant;

const useStyle = makeStyles((theme) => ({
  bodyContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    "&>div": {
      width: "100%",
      height: "auto",
      minHeight: "calc(100% - 100px)",
      marginTop: 100,
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function Body({
  className = "",
  children,
  seoTitle = "",
  seoDescription = "",
  hideFooter = false,
}) {
  const classes = useStyle();
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : pageTitle}
        description={seoDescription}
      />
      <div className={clsx(classes.bodyContainer, className)}>
        {/* <Navbar /> */}
        <Header />
        <div>
          {children}
          {!hideFooter && <Footer />}
        </div>
      </div>
    </>
  );
}
