import { Box, Tab, Tabs, makeStyles } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useState } from "react";
import ConsumerProfileDetails from "./ConsumerProfileDetails";
import ConsumerConnectionDetails from "./ConsumerConnectionDetails";
import ConsumerFeedBackDetails from "./ConsumerFeedBackDetails";

const useStyle = makeStyles((theme) => ({
  rootTabContainer: {
    padding: "70px 142px",
    background: "#F7F7F7",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  tab: {
    "& .Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabPanel: {
    "& .MuiTabPanel-root": {
      border: "2px solid red",
    },
  },
}));

export default function ConsumerDetails() {
  // for css
  const classes = useStyle();

  //   local state
  const [value, setValue] = useState("profile");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.rootTabContainer}>
      <TabContext value={value}>
        <TabList
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.tab}
        >
          <Tab label="PROFILE" value="profile" />
          <Tab label="FEEDBACK" value="feedback" />
          <Tab label="CONNECTIONS" value="connections" />
        </TabList>
        <TabPanel value="profile">
          <ConsumerProfileDetails />
        </TabPanel>
        <TabPanel value="connections">
          <ConsumerConnectionDetails />
        </TabPanel>
        <TabPanel value="feedback">
          <ConsumerFeedBackDetails />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
