import React from "react";
import { PageWrapper } from "../../components/Wrappers";
import {
  Features,
  Healthloq,
  SolutionForIncluding,
} from "../../components/Solutions";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";
import TextSection from "../../components/Common/Section/TextSection";
import { Button, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { AppImages } from "../../constants";
import clsx from "clsx";
const { grma_image } = AppImages;

const {
  headerImages: { solutions },
} = appImages;

const useStyle = makeStyles((theme) => ({
  grma_section: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    padding: "0px 25px",
  },
  grma_section_container: {
    background: "#F3F8EB",
    padding: 10,
    // margin: "0px 0px",
    marginTop: "10px",
  },
  grma_text: {
    width: "100%",
    fontSize: "24px",
    fontWeight: 600,
    padding: 10,
  },
}));

export default function Solutions() {
  const classes = useStyle();
  return (
    <PageWrapper pageName="solutions">
      <Grid className={classes.grma_section_container}>
        <Grid className={clsx(classes.grma_section, "bodyMaxWidth")}>
          {/* <img src={grma_image} height="70px" width="250px" /> */}
          <Typography className={classes.grma_text}>
            <Link
              style={{
                color: "#28A745",
                marginLeft: "15px",
                wordBreak: "break-word",
              }}
              href="/product-integrity-program"
            >
              HealthLOQ and GRMA announce the GRMA Product Integrity Program!
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <HeaderSection
        headerImgSrc={solutions}
        headerText={
          <>
            HealthLOQ’s Compliance and Transparency Blockchain Dashboard!
            <Link
              style={{
                color: "#28A745",
                marginLeft: "15px",
                wordBreak: "break-word",
              }}
              href="/compliance-dashboard"
            >
              <span>(more...)</span>
            </Link>
          </>
        }
      />
      <Features />
      <SolutionForIncluding />
      <TextSection
        heading="Document Protection"
        description="The document protection process is effective for any type of document in any industry. The privacy of the document content is preserved since there is no need to expose sensitive or proprietary information outside the security perimeter of the organization that creates the original document work product. No document export is required and the tool can be implemented in just a few hours. Retailers don't have to manually validate - it happens automatically!"
        treePanPosition="right"
        redirectTo="/document-protection"
      />
      <TextSection
        heading="Document Verification"
        description="HealthLOQ allows an individual or organization to verify product documentation (e.g. certificate of analysis or 'COA'), including the label. With HealthLOQ's verification capability, you can either manually or automatically verify the legitimacy of a document. You can even verify the legitimate of the organization that issued the GMP or ISO certification to the lab or manufacturer. And with our 'Product Origin' solution, you can tie these verified document to specific products. and that the certifying organization itself is in fact been accredited by a known standards body. Manual, single document verification can happen with the click of a button. A retailer can automate the document verification process with a very simple download of the HealthLOQ verification dashboard which can be implemented in just a few hours. Retailers don't have to manually validate - it happens automatically!"
        redirectTo="/document-verification"
        backgroundColor
      />
      <TextSection
        heading="Product Origin"
        description="HealthLOQ is a third party trust and transparency platform that uses the latest digital technologies to create a tamper-proof audit trail of information about a product's origin, ingredients, testing and journey."
        redirectTo="/product-origin"
        treePanPosition="right"
      />
      <TextSection
        heading="Consumer Connection"
        description="HealthLOQ connects organizations with their customers using a fully-permissioned, social-media based platform. Consumers are in complete control of their profile data and can both extend and retract access to their information at any time. Organizations can reach out to specific audiences, tailor survey interactions, and offer incentives to consumers for participation. Consumers can connect with other product users and share experiences. Practitioners can connect with patients and brands they trust to make recommendations and offer their expertise. The focus is on connection."
        redirectTo="/consumer-connection"
        backgroundColor
      />
      <TextSection
        heading="Label Validation"
        description="HealthLOQ is a third-party trust and transparency platform that uses the latest digital technologies to create a tamper-proof audit trail of information about a product's label, origin, ingredients, testing and journey."
        treePanPosition="right"
        redirectTo="/label-validation"
      />
      <TextSection
        heading="Circular Economy"
        description="HealthLOQ connects organizations with their customers using a fully-permissioned, social-media based platform. Consumers are in complete control of their profile data and can both extend and retract access to their information at any time. Organizations can reach out to specific audiences, tailor survey interactions, and offer incentives to consumers for participation. Consumers can connect with other product users and share experiences. Practitioners can connect with patients and brands they trust to make recommendations and offer their expertise. The focus is on connection."
        redirectTo="/circular-economy"
        backgroundColor
      />
      <TextSection
        heading="Counterfeit Prevention"
        description="HealthLOQ allows an individual or organization to verify that product documentation (e.g. certificate of analysis or 'COA'), including the label, is legitimate and that the certifying organization itself is in fact been accredited by a known standards body. The document protection process is effective for any type of document in any industry. The privacy of the document content is preserved since there is no need to expose sensitive or proprietary information outside the security perimeter of the organization that creates the original document work product. No document export is required and the tool can be implemented in just a few hours. Retailers don't have to manually validate - it happens automatically!"
        treePanPosition="right"
        redirectTo="/counterfeit-prevention"
      />
      <Healthloq />
      <AsSeenInSection />
    </PageWrapper>
  );
}
