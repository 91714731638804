import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./style.scss";
import { connect } from "react-redux";
import { dispatchBlogNewsList } from "../../redux/actions/blogNews";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import Loader from "../../components/Loader";
import { Body } from "../../components/Common";

const BlogNewsDetail = ({
  blogNewsList,
  dispatchBlogNewsList,
  loaderReducer,
}) => {
  const params = useParams();
  const [detail, setDetail] = useState(null);
  useEffect(() => {
    if (blogNewsList?.length === 0) {
      dispatchBlogNewsList();
    } else if (blogNewsList?.length !== 0) {
      blogNewsList?.forEach((item) => {
        if (params.detail === item?.slug) {
          setDetail(item);
        }
      });
    }
  }, [blogNewsList]);
  if (!loaderReducer && !detail) {
    return <Loader />;
  }
  return (
    <Body
      seoTitle={detail?.attributes?.title + " - HealthLOQ"}
      seoDescription={detail?.attributes?.subTitle}
    >
      <div className="section-blog-detail">
        <ul className="breadcrumb-list">
          <li>
            <Link to="/blog-news/all">Blog & News</Link>
          </li>
          <li>
            <Link to={"/blog-news/" + detail?.attributes?.type}>
              {detail?.attributes?.type}
            </Link>
          </li>
          <li>{detail?.attributes?.title}</li>
        </ul>
        <h1 className="sbd-title">{detail?.attributes?.title}</h1>
        <ul className="ibn-list">
          <li>
            {moment(detail?.attributes?.publishedAt).format("MM/DD/YYYY")}
          </li>
          <li>
            By <strong>{detail?.attributes?.createdby}</strong>
          </li>
        </ul>
        {detail?.attributes?.image?.data && (
          <img
            src={`https://newsroom.healthloq.com${detail?.attributes?.image?.data?.attributes?.url}`}
            alt=""
            className="img-fluid sbd-img"
          />
        )}
        {detail?.attributes?.subTitle && (
          <div className="sbd-subtitle">{detail?.attributes?.subTitle}</div>
        )}
        {detail?.attributes?.description && (
          <ReactMarkdown>{detail?.attributes?.description}</ReactMarkdown>
        )}
      </div>
    </Body>
  );
};

const mapStateToProps = ({
  blogNewsReducers: { blogNewsList },
  loaderReducer,
}) => ({
  blogNewsList,
  loaderReducer,
});

const mapDispatchToProps = {
  dispatchBlogNewsList: dispatchBlogNewsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogNewsDetail);
