import React from "react";
import { PageWrapper } from "../../components/Wrappers";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import ProductIntegrityHeader from "../../components/ProducIntegrity/ProductIntegrityHeader";
import IntrgrityDetails from "../../components/ProducIntegrity/IntegrityDetails";

const ProductIntegrity = () => {
  return (
    <PageWrapper pageName="product intigrity program">
      <ProductIntegrityHeader />
      <IntrgrityDetails />
      <AsSeenInSection />
    </PageWrapper>
  );
};

export default ProductIntegrity;
