import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    margin: "auto",
  },
  heading: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    background: theme.palette.common.white,
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
  },
}));

const {
  images: { fraudulentDocumentExample },
} = appImages;

export default function FraudulentDocumentExample() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={fraudulentDocumentExample} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>Fraudulent Document Example</PrimaryHeading>
            </Box>
            <Box className={classes.contentBox}>
              <Typography variant="body2">
                This image mage below shows the blockchain proof of a valid
                document. The protection tool allows an interested party to
                compare the document in question against the original held by
                the issuer.
              </Typography>
            </Box>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
