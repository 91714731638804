import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { dispatchUpdateMapDataIntegrantId } from "../../redux/actions";
import { connect } from "react-redux";
import { IconButton, useMediaQuery, useTheme, Tooltip, Box } from "../";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function ProductIngredients({
  list = [],
  dispatchUpdateMapDataIntegrantId,
  device = "mobile",
  setActiveSmFooterTab,
  location = "",
  onClose = () => {},
  integrantInfo,
}) {
  // const { short_code, external_id } = useParams();
  const history = useHistory();
  // const handleGreaterThanIconClick = (integrant_ids) => {
  //   dispatchUpdateMapDataIntegrantId({ ids: integrant_ids, location });
  //   if (location !== "ingredientDrawer") {
  //     history.push(`/p/${short_code}/b/${external_id}/origin`);
  //   } else {
  //     onClose();
  //   }
  //   if (location === "infotab") {
  //     setActiveSmFooterTab(2);
  //   }
  // };
  const theme = useTheme();
  let paddingLeft = 30;
  if (useMediaQuery(theme.breakpoints.down(768))) {
    paddingLeft = 20;
  }
  if (useMediaQuery(theme.breakpoints.down(600))) {
    paddingLeft = 15;
  }
  const getIngredients = (nodes, index, parentId) => {
    return nodes.map((item) => {
      return (
        <>
          <li
            className="ingredients-li"
            style={{ paddingLeft: `${index * paddingLeft}px` }}
          >
            <div className="ingredients-li-div">
              <img
                src={item?.sourceInfo?.image}
                alt={"Ingredient-img"}
                title={"Ingredient Image"}
              />
              <div>
                <h5>{item?.sourceInfo?.title}</h5>
                <Tooltip
                  arrow
                  title={
                    item?.sourceInfo?.is_public
                      ? "View full detail"
                      : "Ingredients publicly not available."
                  }
                >
                  <Box>
                    <IconButton
                      color={"primary"}
                      onClick={() => {
                        if (item?.sourceInfo?.is_public) {
                          history.push(
                            `/p/${integrantInfo?.organizations?.short_code}/b/${item?.sourceInfo?.external_id_slug}`
                          );
                        }
                        // parentId.unshift(item?.source);
                        // handleGreaterThanIconClick(parentId);
                      }}
                      disabled={!item?.sourceInfo?.is_public}
                    >
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </div>
            </div>
          </li>
          {item?.nodes
            ? getIngredients(
                item?.nodes,
                index + 1,
                parentId.concat(item?.source)
              )
            : null}
        </>
      );
    });
  };
  return (
    <div
      className={`ingredients-content ${
        device === "desktop" ? "desktop-ingredients" : ""
      }`}
    >
      <div className="ingredient-heading">
        <h5>Ingredient Name</h5>
      </div>
      <ul className="ingredient-root-ul">
        {getIngredients(list, 0, [])}
        {/* {list?.map((item, key) => {
          return (
            <div className="ingredient-root-ul-div" key={key}>
              <li className="ingredients-li">
                <div className="ingredients-li-div">
                  <img
                    src={item?.childLink?.integrantsType?.image_url}
                    alt={"Ingredient-img"}
                    title={"Ingredient Image"}
                  />
                  <div>
                    <h5>{item?.childLink?.title}</h5>
                    <img
                      src={greaterThanIcon}
                      alt="Greater Than Icon"
                      onClick={() =>
                        handleGreaterThanIconClick([item?.child_integrant_id])
                      }
                    />
                  </div>
                </div>
              </li>
              {item?.childData?.length > 0 && (
                <ul className="ingredient-child-ul">
                  {item?.childData?.map((subItem, subKey) => {
                    return (
                      <li className="ingredients-li" key={subKey}>
                        <div className="ingredients-li-div">
                          <img
                            src={subItem?.childLink?.integrantsType?.image_url}
                            alt={"Ingredient-img"}
                            title={"Ingredient Image"}
                          />
                          <div>
                            <h5>{subItem?.childLink?.title}</h5>
                            <img
                              src={greaterThanIcon}
                              alt="Greater Than Icon"
                              onClick={() =>
                                handleGreaterThanIconClick([
                                  subItem?.child_integrant_id,
                                  item?.child_integrant_id,
                                ])
                              }
                            />
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}  */}
      </ul>
    </div>
  );
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {
  dispatchUpdateMapDataIntegrantId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductIngredients);
