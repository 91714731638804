import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
} from "./DialogCommonComponents";
import { MuiTextfield, Button } from "../Profile";
import { ReactComponent as RightSignIcon } from "../../constants/svg/icon-right-sign.svg";
import { inviteUser } from "../../redux/actions/profileActions";
import { useTheme, useMediaQuery } from "@material-ui/core";
import jwt from "jsonwebtoken";
const nl2br = require("nl2br");

function InviteUser(props) {
  const {
    open,
    handleClose,
    inviteUser,
    inviteUserData,
    userData,
    mentionSearchText,
  } = props;

  const theme = useTheme();
  const match = !useMediaQuery(theme.breakpoints.down(769));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mail_text, setMailText] = useState(
    "Hi…\n\nI'm a customer of HealthLOQ which provides evidence-based confidence to consumers about the safety, origin, and sustainability of a product and its ingredients. \n\nSee HealthLOQ.com for more information and reach out to them at contact@healthloq.com."
  );

  const emailRegex = new RegExp(
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,6})+$/
  );
  const [error, setError] = useState({
    name: "",
    email: "",
    mail_text: "",
  });
  const handleVerify = () => {
    let obj = { ...error };
    let isValid = true;
    if (!name) {
      isValid = false;
      obj["name"] = "Name is a required field.";
    }
    if (!mail_text) {
      isValid = false;
      obj["mail_text"] = "Message is a required field.";
    }
    if (!email) {
      isValid = false;
      obj["email"] = "Email is a required field.";
    }
    if (email && !emailRegex.test(email)) {
      isValid = false;
      obj["email"] = "Please enter valid email.";
    }
    setError(obj);
    return isValid;
  };
  const handleSubmit = () => {
    if (handleVerify()) {
      let obj = {
        name,
        email,
        mail_text: nl2br(mail_text),
      };
      inviteUser(obj);
    }
  };

  const handleCloseDialog = () => {
    setEmail("");
    setName("");
    setMailText(mail_text);
    handleClose();
    setError({ email: "", name: "", mail_text: "" });
  };

  const [copyText, setCopyText] = useState("");
  let jwt_token_url = "";
  if (userData?.data?.invite_qr_url) {
    jwt_token_url = userData?.data?.invite_qr_url;
  } else {
    let jwt_token = jwt.sign(
      { user_id: userData?.data?.id },
      process.env.REACT_APP_JWT_SECRET
    );
    jwt.sign(
      { user_id: userData?.data?.id },
      process.env.REACT_APP_JWT_SECRET,
      function (err, token) {
        jwt_token = token;
      }
    );
    jwt_token_url = `${process.env.REACT_APP_PRODUCER_APP_URL}/plans/${jwt_token}`;
  }
  const copyURL = async () => {
    setCopyText("Copied");
    navigator.clipboard.writeText(jwt_token_url);
    setTimeout(() => {
      setCopyText("");
    }, 5000);
  };

  useEffect(() => {
    if (!inviteUserData?.loading && inviteUserData.status === "1") {
      handleCloseDialog();
    }
  }, [inviteUserData]);

  useEffect(() => {
    if (mentionSearchText) {
      setName(mentionSearchText);
    }
  }, [mentionSearchText]);

  return (
    <MuiDialog open={open} onClose={handleCloseDialog} maxWidth={"sm"}>
      <MuiDialogTitle onClose={handleCloseDialog}>Invite User</MuiDialogTitle>
      <MuiDialogContent>
        <MuiTextfield
          match={match}
          placeholder="Name"
          required
          value={name}
          error={Boolean(error?.name)}
          helperText={error?.name}
          onChange={(e) => {
            setName(e.target.value);
            setError({ ...error, name: "" });
          }}
        />
        <MuiTextfield
          match={match}
          placeholder="Email"
          labelWithPlaceholder={"Please enter user's email address here"}
          required
          value={email}
          error={Boolean(error?.email)}
          helperText={error?.email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError({ ...error, email: "" });
          }}
        />
        <MuiTextfield
          match={match}
          multiline
          minRows={4}
          placeholder="Message"
          required
          value={mail_text}
          error={Boolean(error?.mail_text)}
          helperText={error?.mail_text}
          onChange={(e) => {
            setMailText(e.target.value);
            setError({ ...error, mail_text: "" });
          }}
        />
        <Button
          onClick={handleSubmit}
          profileBgBtn
          loading={inviteUserData.loading}
          disabled={inviteUserData.loading}
        >
          Invite
        </Button>
        {copyText == "Copied" ? (
          <span className="copy-link-section" style={{ marginLeft: "10px" }}>
            <RightSignIcon style={{ height: "20px", width: "20px" }} />{" "}
            {copyText}
          </span>
        ) : (
          <span className="copy-link-section" style={{ marginLeft: "10px" }}>
            Or{" "}
            <span
              title={copyText}
              style={{ cursor: "pointer", color: "#28a745" }}
              onClick={copyURL}
            >
              Click here
            </span>{" "}
            to copy the link to clipboard.
          </span>
        )}
      </MuiDialogContent>
    </MuiDialog>
  );
}

const mapStateToProps = ({
  ProfileReducers: { inviteUserData, userData },
}) => ({
  inviteUserData,
  userData,
});

const mapDispatchToProps = {
  inviteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteUser);
