import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Link,
  Typography,
  Button,
  Drawer,
  Divider,
  Toolbar,
  useScrollTrigger,
} from "..";
import { AppBar } from "@material-ui/core";
import { AppImages } from "../../constants";
import MenuIcon from "@material-ui/icons/Menu";
import utils from "../../utils/commonUtils";
import { useLocation } from "react-router-dom";
import { dispatchOrganizationList } from "../../redux/actions";

const useStyle = makeStyles((theme) => ({
  headerAppContainer: {
    backgroundColor: theme.palette.secondary.main,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerContainer: {
    width: "100%",
    padding: 0,
    minHeight: 100,
    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  healthloqLogo: {
    "&>a": {
      "&>img": {
        width: 60,
        height: 60,
      },
    },
  },
  headerLinks: {
    padding: "0px 20px",
    "&>*": {
      textAlign: "center",
    },
    "&>span": {
      margin: "0 10px",
      fontSize: 18,
      color: theme.palette.text.primary,
      lineHeight: "30px",
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
    },
    "&>p": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      flexGrow: 1,
      justifyContent: "flex-end !important",
      "&>*:not(:last-child)": {
        display: "none !important",
      },
      "&>div": {},
    },
  },
  headerButtons: {
    "&>*:not(:last-child)": {
      marginRight: 15,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  menuDiv: {
    display: "none",
    "&>svg": {
      cursor: "pointer",
      width: 40,
      height: 40,
      marginLeft: 10,
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  languageSelectBox: {
    cursor: "pointer",
    "&>div": {
      fontSize: 18,
      lineHeight: "30px",
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
    },
  },
  languageLi: {
    fontSize: 18,
    lineHeight: "30px",
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
  },
  navbarDrawerContainer: {
    width: 300,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
  drawerHealthloqLogo: {
    margin: "65px auto",
    "&>a": {
      "&>img": {
        width: 80,
        height: 80,
        cursor: "pointer",
      },
    },
  },
  drawerLinks: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
    "&>a,&>p": {
      padding: "15px 0",
      "&>button": {
        width: "100%",
      },
    },
    "&>hr": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const { logo } = AppImages;

export const Navbar = (props) => {
  const { dispatchOrganizationList } = props;
  const classes = useStyle();
  const location = useLocation();
  const path = location.pathname?.split("/")[1];
  const [open, setOpen] = useState(false);
  const [openDocumentVerificationDialog, setOpenDocumentVerificationDialog] =
    useState(false);
  const handleDrawerClose = () => setOpen(false);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: "en,es,fr,de,it",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
    const mainWrapperDiv = document.getElementById("google_translate_element");
    const subDiv = mainWrapperDiv.firstElementChild;
    subDiv.lastChild.remove();
    subDiv.lastChild.remove();
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    setTimeout(() => {
      let el = document.querySelector(".goog-te-combo");
      if (el) {
        el?.addEventListener("change", (e) => {
          if (e.target.value) {
            if (e.target.value === "en") {
              window.location.reload();
            }
            utils.handleGoogleTranslateLanguage(e.target.value);
          }
        });
      }
    }, 2000);
    utils.handleGoogleTranslateLanguage();
  }, []);

  const handleVerifyBlockchainBtnClick = () => {
    setOpenDocumentVerificationDialog(true);
    dispatchOrganizationList({ showCustomLoader: true });
  };

  return (
    <>
      <ElevationScroll>
        <AppBar className={classes.headerAppContainer}>
          <Toolbar className={classes.headerContainer}>
            <div className="bodyMaxWidth">
              <Grid item className={classes.healthloqLogo}>
                <Link to="/">
                  <img src={logo} alt="HealthLOQ" />
                </Link>
              </Grid>
              <Grid item className={clsx("center", classes.headerLinks)}>
                <Link
                  to={"/products"}
                  className={clsx(path === "products" && classes.activeLink)}
                >
                  Products
                </Link>
                <span className="center">|</span>
                <Link
                  to={"/partners"}
                  className={clsx(path === "partners" && classes.activeLink)}
                >
                  Partners
                </Link>
                <span className="center">|</span>
                <Link
                  to={"/track-and-trace"}
                  className={clsx(
                    path === "track-and-trace" && classes.activeLink
                  )}
                >
                  Track & Trace
                </Link>
                {/*<span className="center">|</span>
                <Typography
                  variant="body2"
                  light
                  onClick={handleVerifyBlockchainBtnClick}
                >
                  Authenticator
                </Typography>*/}
                <span className="center">|</span>
                <div id="google_translate_element"></div>
              </Grid>
              <Grid item className={clsx("center", classes.headerButtons)}>
                <Link to="/login" isLink={false}>
                  <Button primaryLightBtn={true}>Login</Button>
                </Link>
                <Link to="/register" isLink={false}>
                  <Button primaryDarkBtn={true}>Register</Button>
                </Link>
              </Grid>
              <Grid item className={clsx(classes.menuDiv)}>
                <MenuIcon
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Grid>
            </div>
            <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
              <Grid container className={classes.navbarDrawerContainer}>
                <Grid item className={classes.drawerHealthloqLogo}>
                  <Link to="/">
                    <img src={logo} alt="HealthLOQ" />
                  </Link>
                </Grid>
                <Grid item className={classes.drawerLinks}>
                  <Link
                    to={"/products"}
                    className={clsx(path === "products" && classes.activeLink)}
                    onClick={handleDrawerClose}
                  >
                    Products
                  </Link>
                  <Divider />
                  <Link
                    to={"/partners"}
                    className={clsx(path === "partners" && classes.activeLink)}
                  >
                    Partners
                  </Link>
                  <Divider />
                  <Link
                    to={"/track-and-trace"}
                    className={clsx(
                      path === "track-and-trace" && classes.activeLink
                    )}
                  >
                    Track & Trace
                  </Link>
                  {/*<Typography
                    variant="body2"
                    light
                    onClick={() => {
                      handleDrawerClose();
                      handleVerifyBlockchainBtnClick();
                    }}
                  >
                    Authenticator
                  </Typography>*/}
                  <Link to="/login" isLink={false} onClick={handleDrawerClose}>
                    <Button primaryLightBtn={true}>Login</Button>
                  </Link>
                  <Link
                    to="/register"
                    isLink={false}
                    onClick={handleDrawerClose}
                  >
                    <Button primaryDarkBtn={true}>Register</Button>
                  </Link>
                </Grid>
              </Grid>
            </Drawer>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  dispatchOrganizationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
