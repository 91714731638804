import React from "react"
import GoogleMapReact from "google-map-react"
import { AppConstant } from "../../constants"
import { googleMapsReactKey } from "../../services/config"

const { homeFooter: { address, email }, homePage: { contactUs } } = AppConstant

const officeLocation = {
    coordinates: [33.4641858, -111.924277],
    line_1: "1475 N",
    line_2: "Scottsdale Rd",
    state: "Arizona",
    city: "Scottsdale",
    zip: "85257"
}

const BodyContact = () => (
    <div className="contact-body">
        <div className="section-address d-flex align-items-center justify-content-center">
            <div className="text-left">
                <div className="title-address">{contactUs}</div>
                <p className="txt-address-full"><i className="material-icons md-18">pin_drop</i> {address}</p>
                <p className="txt-email-contact"><a href={`mailto:${email}`} target="_blank" rel="noreferrer"><i className="material-icons md-18">email</i> {email}</a></p>
            </div>
        </div>
        <div className="map-container">
            <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapsReactKey }}
                defaultCenter={officeLocation.coordinates}
                defaultZoom={16}
            >
                <div className="map_address">
                    <div className="tolltip-map-address">{`${officeLocation.line_1}, ${officeLocation.line_2}, ${officeLocation.city}, ${officeLocation.state} ${officeLocation.zip}`}</div>
                </div>
            </GoogleMapReact>
        </div>
    </div>
)
export default BodyContact
