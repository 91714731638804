import { PageWrapper } from "../../components/Wrappers";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import RetailerCompliance from "../../components/ComplianceDashboard/RetailerCompliance";
import ComplianceDashboardExample from "../../components/ComplianceDashboard/ComplianceDashboardExample";
import appImages from "../../constants/AppImage";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HealthLoqSection from "../../components/ComplianceDashboard/HealthloqSection";
import { Typography } from "@material-ui/core";

const {
  images: { retailersHeader },
} = appImages;

export default function ComplianceDashboard() {
  return (
    <PageWrapper pageName="compliance-dashboard">
      <HeaderSection
        headerImgSrc={retailersHeader}
        headerText={
          <>
            <Typography style={{ fontWeight: 600, fontSize: "54px",color : "#fff" }}>
              Compliance Dashboard
            </Typography>
            Automatically verify supplier products, related documents, and
            credentials
          </>
        }
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <RetailerCompliance />
      <ComplianceDashboardExample />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
