import React from "react";
import PropTypes from "prop-types";
import { AppImages } from "../../constants";

const {
  icon: { notification },
  product: { productHeaderImg },
} = AppImages;

const ProductBanner = ({
  notificationLine,
  productImg,
  productBGImg,
  productBGColor,
}) => (
  <div className="product-header">
    <div
      className="notification-title"
      style={{
        background: productBGColor
          ? productBGColor
          : "linear-gradient(90deg, #fdbc00 0%, #fdc500 38%, #df8901 100%)",
      }}
    >
      <img src={notification} alt="" className="icon-notification" />
      {notificationLine}
    </div>
    <div
      // className="product-header"
      // style={{
      //   backgroundImage: productBGImg
      //     ? `url(${productBGImg})`
      //     : `url(${productHeaderImg})`,
      // }}
      className="product-img-wrapper product-detail-image-div"
      style={{
        backgroundImage: productBGImg
          ? `url(${productBGImg})`
          : `url(${productHeaderImg})`,
        textAlign: "center",
      }}
    >
      {/* <img src={productImg.image_url} alt="" className="product-img" /> */}
      {productImg && productImg.image_url ? (
        <img
          src={productImg.image_url}
          className="product-image product-detail-image"
          data-aos="fade-down"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          alt=""
        />
      ) : (
        ""
      )}
    </div>
  </div>
);

ProductBanner.propTypes = {
  notificationLine: PropTypes.string,
  productImg: PropTypes.instanceOf(Object),
};

ProductBanner.defaultProps = {
  notificationLine: "",
  productImg: "",
};

export default ProductBanner;
