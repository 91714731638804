import { Link, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    width: "max-content",
    "&>button": {
      height: "100%",
    },
  },
}));

export default function CalendlyPage({ children }) {
  const classes = useStyle();
  return (
    <Link
      href={`https://calendly.com/healthloq/demo?month=${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }`}
      target="_blank"
      className={classes.root}
      underline="none"
    >
      {children}
    </Link>
  );
}
