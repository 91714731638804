import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, InputLabel } from "@material-ui/core";
import { Paragraph, MuiTextfield, Button } from "../../components/Profile";
import { ProductFeedbackDialog } from "../../components/Dialogs";
import { postProductFeedback } from "../../redux/actions/profileActions";
import utils from "../../utils/commonUtils";
import { useParams } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";

let productFeedbackFlag = false;

export const ProductFeedback = (props) => {
  const { productDetails } = utils.getLocalStorageItems();
  const { match, history, postProductFeedback, productFeedback } = props;
  const { organization_id, integrant_id } = useParams();
  const [openProductFeedbackDialog, setOpenProductFeedbackDialog] =
    useState(false);
  const [product_buying_info, setProduct_buying_info] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState({
    message: false,
    product_buying_info: false,
    rating: false,
  });
  const verifyFields = () => {
    let isVerified = true;
    let error = { ...errors };
    if (!message) {
      error["message"] = true;
      isVerified = false;
    }
    if (!product_buying_info) {
      error["product_buying_info"] = true;
      isVerified = false;
    }
    if (!rating) {
      error["rating"] = true;
      isVerified = false;
    }
    setErrors(error);
    return isVerified;
  };
  const handleSubmit = (e) => {
    if (verifyFields()) {
      const { user_id, selectedIntegrantTypeId } = utils.getLocalStorageItems();
      postProductFeedback({
        message,
        product_buying_info,
        rating,
        user_id,
        organization_id: window.atob(organization_id),
        integrant_id: window.atob(integrant_id),
        integrant_type_id: selectedIntegrantTypeId,
      });
      productFeedbackFlag = true;
    }
  };
  const handleClose = () => {
    setOpenProductFeedbackDialog(true);
    setMessage("");
    setProduct_buying_info("");
    setRating(0);
  };
  const handleRatingChange = (i) => {
    setRating(i + 1);
    setErrors({ ...errors, rating: false });
  };
  useEffect(() => {
    if (productFeedback?.status === "1" && productFeedbackFlag) {
      productFeedbackFlag = false;
      handleClose();
    }
  }, [productFeedback]);
  return (
    <Grid className="pf-root">
      <Paragraph>
        Provide feedback to the brand and suppliers about this specific product
        and complete profile to receive rewards.
      </Paragraph>
      <Grid className="pf-product-info">
        <img src={productDetails?.image} alt="product" />
        <Grid>
          <p className="notranslate">
            <span>Product Name:</span>
            {productDetails?.title}
          </p>
          <p>
            <span>Batch Number:</span>
            {productDetails?.external_id}
          </p>
        </Grid>
      </Grid>
      <Grid className="pf-form-div">
        <Grid className="feedback-rating-div">
          <InputLabel className={errors.rating ? "error" : ""}>
            Rate this product *
          </InputLabel>
          <Grid>
            {[...Array(5)].map((el, i) => {
              return rating >= i + 1 ? (
                <StarIcon
                  onClick={() => handleRatingChange(i)}
                  style={{ color: "#ffe11b" }}
                  key={i}
                />
              ) : (
                <StarOutlineIcon
                  key={i}
                  onClick={() => handleRatingChange(i)}
                />
              );
            })}
          </Grid>
        </Grid>
        <MuiTextfield
          placeholder={"Where did you buy this product? "}
          match={match}
          value={product_buying_info}
          onChange={(e) => {
            setProduct_buying_info(e.target.value);
            setErrors({ ...errors, product_buying_info: false });
          }}
          required
          error={errors?.product_buying_info}
        />
        <MuiTextfield
          placeholder={"Please provide feedback"}
          match={match}
          multiline
          minRows={4}
          maxRows={6}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setErrors({ ...errors, message: false });
          }}
          required
          error={errors?.message}
        />
        <Button
          onClick={handleSubmit}
          profileBgBtn
          loading={productFeedback?.loading}
        >
          Submit
        </Button>
      </Grid>
      <ProductFeedbackDialog
        open={openProductFeedbackDialog}
        handleClose={() => {
          handleClose();
          history.push(
            `/p/${productDetails.short_code}/b/${productDetails.external_id_slug}`
          );
          utils.removeItemsFromSessionStorage("productDetails");
        }}
        handleCreate={() => {
          handleClose();
          history.push("/create-profile");
          utils.removeItemsFromSessionStorage("productDetails");
        }}
      />
    </Grid>
  );
};

const mapStateToProps = ({ ProfileReducers: { productFeedback } }) => ({
  productFeedback,
});

const mapDispatchToProps = {
  postProductFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFeedback);
