import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { CloseOutlined } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  swiperContainer: {
    "&.swiper": {
      zIndex: "0 !important",
    },
    "& .swiper-slide": {
      height: "auto",
      maxHeight: "100vh",
    },
    "& .swiper-button-prev, .swiper-button-next": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      color: theme.palette.primary.main,
      background: `#8A929480`,
      "&:hover": {
        background: `#00000070`,
        color: theme.palette.primary.main,
      },
      "&::after, &::before": {
        fontSize: "20px",
      },
    },
    "& .swiper-pagination-horizontal": {
      bottom: "0px !important",
      height: "unset !important",
      marginBottom: "24px !important",
    },
  },
  mediaBox: {
    height: "-webkit-fill-available",
    overflow: "hidden !important",
    maxHeight: "100%",
    marginTop: 10,
    boxSizing: "border-box",
    "&>img": {
      width: "100%",
      height: "100%",
      maxHeight: "100vh",
      objectFit: "contain",
    },
  },
  dialogRoot: {
    zIndex: `9999 !important`,
    "&>div": {
      "&:nth-child(3)": {
        display: "flex",
        "-webkit-box-pack": "center",
        justifyContent: "center",
        "-webkit-box-align": "center",
        "align-items": "center",
        "&>div": {},
      },
    },
  },
  dialogContent: {
    height: "100vh",
    padding: "0 !important",
    width: "100%",
    background: "transparent",
    position: "relative",
    "&>button": {
      position: "absolute",
      zIndex: 2,
      top: 10,
      right: 10,
    },
    "& .swiper-pagination-horizontal": {
      marginBottom: "10px !important",
    },
    "& .swiper": {
      zIndex: "1 !important",
    },
  },
  postMediaFileBox: {
    position: "relative",
    "&>img": {
      cursor: "pointer",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "&>span": {
      position: "absolute",
      width: "calc(100% - 8px)",
      height: "calc(100% - 8px)",
      backgroundColor: "#00000099",
      color: "#fff",
      cursor: "pointer",
      fontSize: 30,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  postMediaSliderContainer: {
    margin: "10px 0",
  },
}));

export default function PostMediaSlider({ mediaList }) {
  const classes = useStyle();
  const [openFullScreenSlider, setOpenFullScreenSlider] = useState(false);
  const handleClose = () => setOpenFullScreenSlider(false);
  const getSlider = () => {
    return (
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true, dynamicBullets: true }}
        className={classes.swiperContainer}
      >
        {mediaList?.map((file, key) => {
          return (
            <SwiperSlide key={key}>
              {file?.type === "image" ? (
                <Box className={classes.mediaBox}>
                  <img src={file.url} alt="Post-Img" />
                </Box>
              ) : null}
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };
  return (
    <Box className={classes.postMediaSliderContainer}>
      <Grid container spacing={1}>
        {mediaList
          ?.slice(0, mediaList?.length === 3 ? 2 : 4)
          ?.map((file, key, arr) => {
            return (
              <Grid
                key={key}
                item
                xs={6}
                className={classes.postMediaFileBox}
                onClick={() => setOpenFullScreenSlider(true)}
              >
                {arr.length - 1 === key &&
                  mediaList?.length - arr.length > 0 && (
                    <span className={classes.extraFileCount}>
                      +{mediaList?.length - arr.length}
                    </span>
                  )}
                <img src={file.url} alt="post-media-file" />
              </Grid>
            );
          })}
      </Grid>
      <Dialog
        open={openFullScreenSlider}
        onClose={handleClose}
        fullScreen
        className={classes.dialogRoot}
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton color="primary" onClick={handleClose}>
            <CloseOutlined fontSize="large" />
          </IconButton>
          {getSlider()}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
