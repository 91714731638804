import {
  PROFILE_LOGIN_FAIL,
  PROFILE_LOGIN_START,
  PROFILE_LOGIN_SUCCESS,
  PROFILE_SIGNUP_FAIL,
  PROFILE_SIGNUP_START,
  PROFILE_SIGNUP_SUCCESS,
  PRODUCT_FEEDBACK_FAIL,
  PRODUCT_FEEDBACK_START,
  PRODUCT_FEEDBACK_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_USER_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_START,
  UPDATE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  GET_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_QUESTIONS_START,
  GET_SURVEY_QUESTIONS_SUCCESS,
  SUBMIT_SURVEY_ANSWERS_FAIL,
  SUBMIT_SURVEY_ANSWERS_START,
  SUBMIT_SURVEY_ANSWERS_SUCCESS,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PROFILE_PICTURE_START,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  GET_USER_SURVEY_HISTORY_START,
  GET_USER_SURVEY_HISTORY_SUCCESS,
  GET_INVITE_ORGANIZATION_HISTORY_START,
  GET_INVITE_ORGANIZATION_HISTORY_SUCCESS,
  INVITE_ORGANIZATION_FAIL,
  INVITE_ORGANIZATION_START,
  INVITE_ORGANIZATION_SUCCESS,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  RESEND_OTP_START,
  RESEND_OTP_SUCCESS,
  GET_USERS_LIST_START,
  GET_USERS_LIST_SUCCESS,
  FOLLOW_UNFOLLOW_USER_START,
  FOLLOW_UNFOLLOW_USER_SUCCESS,
  GET_USER_CONNECTIONS_START,
  GET_USER_CONNECTIONS_SUCCESS,
  INVITE_USER_START,
  INVITE_USER_SUCCESS,
  GET_USER_NOTIFICATIONS_START,
  GET_USER_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_USER_NOTIFICATIONS_START,
} from "../actionsType/profileActionType";
import { UPDATE_PROFILE_POST } from "../actionsType/post";
import { profileAPI } from "../../services/index";
import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";
import utils from "../../utils/commonUtils";
import { catchHandler } from ".";

export const markAsReadUserNotifications = (params) => async (dispatch) => {
  try {
    dispatch({
      type: MARK_AS_READ_USER_NOTIFICATIONS_START,
    });
    const response = await profileAPI.markAsReadUserNotifications(params);
    dispatch({
      type: MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
    if (response?.data?.status !== "1") {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "User notification",
      action: "Mark as read user notifications.",
    });
  } catch (error) {
    catchHandler(dispatch, error, MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS);
  }
};

export const getUserNotifications = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_NOTIFICATIONS_START,
      payload: params,
    });
    const response = await profileAPI.getUserNotifications(params);
    dispatch({
      type: GET_USER_NOTIFICATIONS_SUCCESS,
      payload: { ...response.data, ...params },
    });
    if (response?.data?.status !== "1") {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "User notification",
      action: "Get user notifications.",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_USER_NOTIFICATIONS_SUCCESS);
  }
};

export const inviteUser = (params) => async (dispatch) => {
  try {
    dispatch({
      type: INVITE_USER_START,
    });
    const response = await profileAPI.inviteUser(params);
    dispatch({
      type: INVITE_USER_SUCCESS,
      payload: response.data,
    });
    if (response?.data?.status === "1") {
      NotificationManager.success(response?.data?.message, "", 5000);
    } else {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "User",
      action: "Invite external users into healthLOQ.",
    });
  } catch (error) {
    catchHandler(dispatch, error, INVITE_USER_SUCCESS);
  }
};

export const getUserConnections = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_CONNECTIONS_START,
    });
    const response = await profileAPI.getUserConnections(params);
    dispatch({
      type: GET_USER_CONNECTIONS_SUCCESS,
      payload: response.data,
    });
    if (response?.data?.status !== "1") {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Connections",
      action: "Get user connections list.",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_USER_CONNECTIONS_SUCCESS);
  }
};

export const followUnfollowUser = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FOLLOW_UNFOLLOW_USER_START,
    });
    const response = await profileAPI.followUnfollowUser(params);
    dispatch({
      type: FOLLOW_UNFOLLOW_USER_SUCCESS,
      payload: {
        ...response?.data,
        data: response?.data?.data || params,
      },
    });
    if (response?.data?.status !== "1") {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Profile",
      action: "Follow unfollow user.",
    });
  } catch (error) {
    catchHandler(dispatch, error, FOLLOW_UNFOLLOW_USER_SUCCESS);
  }
};

export const getUsersList = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_LIST_START,
    });
    const response = await profileAPI.getUsersList(data);
    dispatch({
      type: GET_USERS_LIST_SUCCESS,
      payload: response?.data,
    });
    if (response?.data?.status === "1") {
      ReactGA.event({
        category: "Profile",
        action: "Get users suggestion list.",
      });
    } else {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, GET_USERS_LIST_SUCCESS);
  }
};

export const resendOTP = (data) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_OTP_START,
    });
    const response = await profileAPI.resendOTP(data);
    dispatch({
      type: RESEND_OTP_SUCCESS,
      payload: response?.data,
    });
    if (response?.data?.status === "1") {
      ReactGA.event({
        category: "Authentication",
        action: "Resend OTP.",
      });
      NotificationManager.success(response?.data?.message, "", 5000);
    } else {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, RESEND_OTP_SUCCESS);
  }
};

export const logout = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_START,
    });
    const response = await profileAPI.logout(data);
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: response?.data,
    });
    utils.removeItemsFromLocalStorage(["auth_token", "otpVerified"]);
    if (response?.data?.status === "1") {
      ReactGA.event({
        category: "Authentication",
        action: "Logout.",
      });
    } else {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, LOGOUT_SUCCESS);
  }
};

export const verifyOTP = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFY_OTP_START,
    });
    const response = await profileAPI.verifyOTP(data);
    if (response?.data?.status === "1") {
      utils.addItemsIntoLocalStorage(
        { auth_token: response?.data?.data?.token, otpVerified: true },
        false
      );
      utils.addItemsIntoLocalStorage({
        user_id: response?.data?.data?.user?.id,
      });
      ReactGA.event({
        category: "Authentication",
        action: "Verify 2FA OTP.",
      });
    } else {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    dispatch({
      type: VERIFY_OTP_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    catchHandler(dispatch, error, VERIFY_OTP_SUCCESS);
  }
};

export const signup = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_SIGNUP_START,
      payload: {
        user_type: data?.user_type,
      },
    });
    const signupData = await profileAPI.postProfileSignup(data);
    if (signupData?.data?.status === "1") {
      dispatch({
        type: PROFILE_SIGNUP_SUCCESS,
        payload: signupData?.data,
      });
      ReactGA.event({
        category: "Profile",
        action: "Profile signup",
      });
      utils.addItemsIntoLocalStorage({ is_signup: true }, false);

      if (
        signupData?.data?.data?.user_type == "facebook" ||
        signupData?.data?.data?.user_type == "google"
      ) {
        utils.addItemsIntoLocalStorage(
          { auth_token: signupData?.data?.data?.token },
          false
        );
        utils.addItemsIntoLocalStorage({ otpVerified: true }, false);
        utils.addItemsIntoLocalStorage({
          user_id: signupData?.data?.data?.user?.id,
        });
      } else {
        utils.addItemsIntoLocalStorage({ is_verified: "false" }, false);
        utils.addItemsIntoLocalStorage({ is_register: "true" }, false);
      }

      // NotificationManager.success(signupData?.data?.message, "", 5000);
    } else {
      dispatch({
        type: PROFILE_SIGNUP_FAIL,
        payload: signupData?.data,
      });
      NotificationManager.error(signupData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, PROFILE_SIGNUP_FAIL);
  }
};
export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOGIN_START,
      payload: {
        user_type: data?.user_type,
      },
    });
    const loginData = await profileAPI.postProfileLogin({
      ...data,
      user_id: utils.getLocalStorageItems().user_id,
    });
    if (loginData?.data?.status === "1") {
      utils.addItemsIntoLocalStorage(
        { auth_token: loginData?.data?.data?.token },
        false
      );
      if (
        loginData?.data?.data?.user_type == "facebook" ||
        loginData?.data?.data?.user_type == "google"
      ) {
        utils.addItemsIntoLocalStorage({ otpVerified: true }, false);
        utils.addItemsIntoLocalStorage({
          user_id: loginData?.data?.data?.user?.id,
        });
      }

      dispatch({
        type: PROFILE_LOGIN_SUCCESS,
        payload: loginData?.data,
      });
      ReactGA.event({
        category: "Profile",
        action: "Profile signin",
      });
      NotificationManager.success(loginData?.data?.message, "", 5000);
    } else {
      dispatch({
        type: PROFILE_LOGIN_FAIL,
        payload: loginData?.data,
      });
      NotificationManager.error(loginData?.data?.message, "", 5000);
    }
  } catch (error) {
    console.log("error------------------------------->", error);
    catchHandler(dispatch, error, PROFILE_LOGIN_FAIL);
  }
};
export const postProductFeedback = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_FEEDBACK_START,
    });
    const feedbackData = await profileAPI.postProductFeedback(data);
    if (feedbackData?.data?.status === "1") {
      dispatch({
        type: PRODUCT_FEEDBACK_SUCCESS,
        payload: feedbackData?.data,
      });
      const { user_id } = utils.getLocalStorageItems();
      if (!user_id) {
        utils.addItemsIntoLocalStorage({
          user_id: feedbackData?.data?.data?.user_id,
        });
      }
      ReactGA.event({
        category: "Product",
        action: "Submit product feedback",
      });
    } else {
      dispatch({
        type: PRODUCT_FEEDBACK_FAIL,
        payload: feedbackData?.data,
      });
      NotificationManager.error(feedbackData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, PRODUCT_FEEDBACK_FAIL);
  }
};
export const getCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORIES_START,
    });
    const categoryData = await profileAPI.getCategories();
    if (categoryData?.data?.status === "1") {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: categoryData?.data,
      });
      ReactGA.event({
        category: "Category",
        action: "Get categories list",
      });
    } else {
      dispatch({
        type: GET_CATEGORIES_FAIL,
        payload: categoryData?.data,
      });
      NotificationManager.error(categoryData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, GET_CATEGORIES_FAIL);
  }
};
export const getUserDetails = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_START,
    });
    const userData = params?.user_id
      ? await profileAPI.getUser(params)
      : await profileAPI.getMyData(params);
    if (userData?.data?.status === "1") {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: userData?.data,
      });
      ReactGA.event({
        category: "User",
        action: "Get user details",
      });
    } else {
      dispatch({
        type: GET_USER_FAIL,
        payload: userData?.data,
      });
    }
  } catch (error) {
    catchHandler(dispatch, error, GET_USER_FAIL, { hide_notification: true });
  }
};
export const getAllUserDetails = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USER_START,
    });
    const userData = params?.user_id
      ? await profileAPI.getUser(params)
      : await profileAPI.getMyData(params);
    dispatch({
      type: GET_ALL_USER_SUCCESS,
      payload: userData?.data,
    });
    dispatch({
      type: UPDATE_PROFILE_POST,
      payload: userData?.data?.data?.posts || [],
    });
    ReactGA.event({
      category: "User",
      action: "Get user details",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_ALL_USER_SUCCESS);
  }
};
export const updateUser = (params, obj) => async (dispatch) => {
  try {
    let hideNotification = obj?.hideNotification || false;
    dispatch({
      type: UPDATE_USER_START,
    });
    const userData = await profileAPI.updateUser(params);
    if (userData?.data?.status === "1") {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: userData?.data,
      });
      ReactGA.event({
        category: "User",
        action: "Update user details",
      });
      if (!hideNotification) {
        NotificationManager.success(userData?.data?.message, "", 5000);
      }
    } else {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: userData?.data,
      });
      if (!hideNotification) {
        NotificationManager.error(userData?.data?.message, "", 5000);
      }
    }
  } catch (error) {
    catchHandler(dispatch, error, UPDATE_USER_FAIL);
  }
};
export const deleteUser = () => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_START,
    });
    const userData = await profileAPI.deleteUser({
      user_id: utils.getLocalStorageItems().user_id,
    });
    if (userData?.data?.status === "1") {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: userData?.data,
      });
      ReactGA.event({
        category: "User",
        action: "Delete user details",
      });
      NotificationManager.success(userData?.data?.message, "", 5000);
    } else {
      dispatch({
        type: DELETE_USER_FAIL,
        payload: userData?.data,
      });
      NotificationManager.error(userData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, DELETE_USER_FAIL);
  }
};
export const getSurveyQuestions = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_QUESTIONS_START,
    });
    const surveyData = await profileAPI.getSurveyQuestions({
      integrant_type_id: utils.getLocalStorageItems().selectedIntegrantTypeId,
      ...params,
    });
    if (surveyData?.data?.status === "1") {
      dispatch({
        type: GET_SURVEY_QUESTIONS_SUCCESS,
        payload: surveyData?.data,
      });
      ReactGA.event({
        category: "Survey",
        action: "Get survey details",
      });
    } else {
      dispatch({
        type: GET_SURVEY_QUESTIONS_FAIL,
        payload: surveyData?.data,
      });
      NotificationManager.error(surveyData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, GET_SURVEY_QUESTIONS_FAIL);
  }
};
export const submitSurveyAnswers = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_SURVEY_ANSWERS_START,
    });
    const surveyData = await profileAPI.submitSurveyAnswers(params);
    if (surveyData?.data?.status === "1") {
      dispatch({
        type: SUBMIT_SURVEY_ANSWERS_SUCCESS,
        payload: surveyData?.data,
      });
      ReactGA.event({
        category: "Survey",
        action: "Submit survey answers",
      });
      NotificationManager.success(surveyData?.data?.message, "", 5000);
    } else {
      dispatch({
        type: SUBMIT_SURVEY_ANSWERS_FAIL,
        payload: surveyData?.data,
      });
      NotificationManager.error(surveyData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, SUBMIT_SURVEY_ANSWERS_FAIL);
  }
};
export const updatePassword = (params) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PASSWORD_START,
    });
    const passwordData = await profileAPI.updatePassword(params);
    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: passwordData?.data,
    });
    ReactGA.event({
      category: "Profile",
      action: "Create password",
    });

    if (passwordData?.data?.status === "1") {
      if (passwordData?.data?.data?.token) {
        utils.addItemsIntoLocalStorage(
          { auth_token: passwordData?.data?.data?.token, otpVerified: false },
          false
        );
        utils.addItemsIntoLocalStorage({
          user_id: passwordData?.data?.data?.user_id,
        });
      }
      utils.removeItemsFromLocalStorage(["is_verified"]);
      // window.open("/verify-otp", "_self")
      NotificationManager.success(passwordData?.data?.message, "", 5000);
    } else {
      NotificationManager.error(passwordData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, UPDATE_PASSWORD_SUCCESS);
  }
};
export const forgotPassword = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_START,
    });
    const forgotPasswordData = await profileAPI.forgotPassword(params);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: forgotPasswordData?.data,
    });
    ReactGA.event({
      category: "Profile",
      action: "Forgot password",
    });
    if (forgotPasswordData?.data?.status === "1") {
      NotificationManager.success(forgotPasswordData?.data?.message, "", 5000);
    } else {
      NotificationManager.error(forgotPasswordData?.data?.message, "", 5000);
    }
  } catch (error) {
    catchHandler(dispatch, error, FORGOT_PASSWORD_SUCCESS);
  }
};
export const updateProfilePicture = (params) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE_PICTURE_START,
    });
    const updateProfilePictureData = await profileAPI.updateProfilePicture(
      params
    );
    dispatch({
      type: UPDATE_PROFILE_PICTURE_SUCCESS,
      payload: updateProfilePictureData?.data,
    });
    ReactGA.event({
      category: "Profile",
      action: "Update profile picture",
    });
    if (updateProfilePictureData?.data?.status === "1") {
      NotificationManager.success(
        updateProfilePictureData?.data?.message,
        "",
        5000
      );
    } else {
      NotificationManager.error(
        updateProfilePictureData?.data?.message,
        "",
        5000
      );
    }
  } catch (error) {
    catchHandler(dispatch, error, UPDATE_PROFILE_PICTURE_SUCCESS);
  }
};
export const getUserSurveyHistory = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_SURVEY_HISTORY_START,
    });
    const userSurveyHistory = await profileAPI.getUserSurveyHistory({
      user_id: utils.getLocalStorageItems().user_id,
      ...params,
    });
    dispatch({
      type: GET_USER_SURVEY_HISTORY_SUCCESS,
      payload: userSurveyHistory?.data,
    });
    ReactGA.event({
      category: "Profile",
      action: "List user survey history",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_USER_SURVEY_HISTORY_SUCCESS);
  }
};
export const inviteOrganization = (params) => async (dispatch) => {
  try {
    dispatch({
      type: INVITE_ORGANIZATION_START,
    });
    const inviteOrganizationData = await profileAPI.inviteOrganization({
      user_id: utils.getLocalStorageItems().user_id,
      ...params,
    });
    if (inviteOrganizationData?.data?.status === "1") {
      dispatch({
        type: INVITE_ORGANIZATION_SUCCESS,
        payload: inviteOrganizationData?.data,
      });
      ReactGA.event({
        category: "Invite Organization",
        action: "Invite Organization details",
      });
      NotificationManager.success(
        inviteOrganizationData?.data?.message,
        "",
        5000
      );
    } else {
      dispatch({
        type: INVITE_ORGANIZATION_FAIL,
        payload: inviteOrganizationData?.data,
      });
      NotificationManager.error(
        inviteOrganizationData?.data?.message,
        "",
        5000
      );
    }
  } catch (error) {
    catchHandler(dispatch, error, INVITE_ORGANIZATION_FAIL);
  }
};
export const getInviteOrganizationHistory = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INVITE_ORGANIZATION_HISTORY_START,
    });
    const inviteOrganizationHistory =
      await profileAPI.getInviteOrganizationHistory({
        user_id: utils.getLocalStorageItems().user_id,
        ...params,
      });
    dispatch({
      type: GET_INVITE_ORGANIZATION_HISTORY_SUCCESS,
      payload: inviteOrganizationHistory?.data,
    });
    ReactGA.event({
      category: "Profile",
      action: "List invite organization history",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_INVITE_ORGANIZATION_HISTORY_SUCCESS);
  }
};
