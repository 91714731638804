import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const sortByObj = [
  { label: "Newest First", value: ["updated_on", "DESC"] },
  { label: "Ascending A - Z", value: ["title", "ASC"] },
  { label: "Descending Z - A", value: ["title", "DESC"] },
];
const PageTitle = ({
  sortBy,
  productListData,
  limit,
  offset,
  handleSort,
  isData,
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(productListData?.count || 0);
  }, [productListData?.count]);
  return (
    <div className="head-product-list">
      <ul className="list-unstyled d-flex breadcrumb01">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="active">Products</li>
      </ul>
      <div className="text-authenticates">
        <span>
          Using <strong>Blockchain Technology</strong> to be your Accountability
          Partner
        </span>
      </div>
      {isData ? (
        <div className="d-md-flex justify-content-between align-items-end head-title-product-list">
          <ul className="list-unstyled d-flex list-sort-by">
            <li>Sort By</li>
            <li className="sortby-li">
              <select
                value={JSON.stringify(sortBy)}
                onChange={(e) => handleSort(JSON.parse(e?.target?.value))}
              >
                {sortByObj.map((item, key) => {
                  return (
                    <option key={key} value={JSON.stringify(item?.value)}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </li>
            {sortByObj.map((item) => {
              return (
                <li
                  key={item.value}
                  className={
                    JSON.stringify(item.value) === JSON.stringify(sortBy)
                      ? "active sortByWeb"
                      : "sortByWeb"
                  }
                  onClick={() => handleSort(item.value)}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
          <div className="text-show">
            Showing {limit + offset - (limit - 1)} –{" "}
            {count > limit ? limit + offset : count} products of {count}{" "}
            products
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = ({ productReducers: { productListData } }) => ({
  productListData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
