import {
  GET_TRAITS_LIST_START,
  GET_TRAITS_LIST_SUCCESS,
  GET_PRODUCT_LIST_FOR_MENTIONS,
  GET_PRODUCT_OTHER_BATCHES,
} from "../actionsType/productActionType";
import { productAPI } from "../../services/index";
import ReactGA from "react-ga";
import { catchHandler } from ".";

export const getOtherBatchList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_OTHER_BATCHES,
    });
    const response = await productAPI.getintegrantsBatches(params);
    dispatch({
      type: GET_PRODUCT_OTHER_BATCHES,
      payload: response,
    });
    ReactGA.event({
      category: "Product List",
      action: "Get other batches list",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_PRODUCT_OTHER_BATCHES);
  }
};

export const getTraitsList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRAITS_LIST_START,
    });
    const traitsData = await productAPI.getTraitsList(params);
    dispatch({
      type: GET_TRAITS_LIST_SUCCESS,
      payload: traitsData.data,
    });
    ReactGA.event({
      category: "Product List",
      action: "Get traits by organization",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_TRAITS_LIST_SUCCESS);
  }
};

export const getProductMentions = async (params) => {
  const response = await productAPI.getProductListForMentions(params);
  return response?.data?.data || [];
};

export const getProductListForMentions = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_LIST_FOR_MENTIONS,
    });
    const response = await productAPI.getProductListForMentions(params);
    dispatch({
      type: GET_PRODUCT_LIST_FOR_MENTIONS,
      payload: response.data || {},
    });
    ReactGA.event({
      category: "Integrant",
      action: "Get Integrants List for Mention in post.",
    });
    return response?.data?.data || [];
  } catch (error) {
    catchHandler(dispatch, error, GET_PRODUCT_LIST_FOR_MENTIONS);
    return [];
  }
};
