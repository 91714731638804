import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import {
  ProductSection1,
  ProductSection2,
  ProductSection3,
} from "../../components/ProductOrigin";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { productOrigin },
} = appImages;

export default function ProductOrigin() {
  return (
    <PageWrapper>
      <HeaderSection
        headerImgSrc={productOrigin}
        headerText="Provide your customers with verifiable information about product origin"
      />
      <ProductSection1 />
      <ProductSection2 />
      <ProductSection3 />
      <AsSeenInSection />
    </PageWrapper>
  );
}
