import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Body } from "../../components/Common";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import {
  dispatchIntegrantsDetail,
  dispatchIntegrantsDetailClean,
  dispatchexhibitsList,
  dispatchIngredientsIds,
} from "../../redux/actions";
import {
  ProductBasicDetail,
  ProductDetailDesktop,
  ProductExtraDetails,
} from "../../components/Products";
import { makeStyles, useMediaQuery, useTheme } from "../../components";
import { dispatchBlockchainProof } from "../../redux/actions/product";
import IntegrantBlockchainProofDialog from "../../components/Dialogs/IntegrantBlockchainProofDialog";
import ProductDetailMobile from "../../components/Products/ProductDetailMobile";
import ProductDetailTour from "../../components/Tours/ProductDetailTour";
import { resetTour } from "../../redux/actions/common";
import { getUserDetails } from "../../redux/actions/profileActions";
import utils from "../../utils/commonUtils";
import ReactGA4 from "react-ga4";

const useStyle = makeStyles((theme) => ({
  productDetailContainer: {
    width: "100%",
    height: "100%",
  },
}));

export const ProductDetailV5 = (props) => {
  const {
    integrantsDetail: { integrantInfo },
    integrantsDetail,
    getIntegrantsDetail,
    cleanIntegrantsDetail,
    integrantsBatchesList,
    blockchainProof,
    dispatchBlockchainProof,
    dispatchexhibitsList,
    dispatchIngredientsIds,
    ingredientsIds,
    exhibitsList,
    resetTour,
    userData,
    getUserDetails,
  } = props;
  const match = useMediaQuery(useTheme().breakpoints.down(900));
  const classes = useStyle();
  const params = queryString.parse(window.location.search);
  const { short_code, external_id } = useParams();
  let recycle_val = '';
  if(params.recycle){
    recycle_val = params.recycle;
  }

  useEffect(() => {
    let obj = { short_code, external_id_slug: external_id };
    if (params.preview && window.atob(params.preview) === "true") {
      obj["preview"] = true;
    }
    getIntegrantsDetail(obj);
    return () => {
      cleanIntegrantsDetail();
    };
  }, [
    getIntegrantsDetail,
    cleanIntegrantsDetail,
    external_id,
    params.preview,
    short_code,
  ]);
  useEffect(() => {
    if (integrantInfo?.id) {
      dispatchIngredientsIds({ integrant_id: integrantInfo?.id });
    }
  }, [integrantInfo?.id]);

  useEffect(() => {
    if (ingredientsIds.length > 0) {
      dispatchexhibitsList(ingredientsIds);
    }
  }, [ingredientsIds]);

  useEffect(() => {
    ReactGA4.gtag("event", "view_product_detail_page", {
      organization_name:
        window.location?.pathname?.split("/p/")?.[1]?.split("/b/")?.[0] || "",
    });
    if (window.location?.pathname?.split("/p/")?.[1]?.split("/b/")?.[0]) {
      ReactGA4.gtag(
        "event",
        window.location?.pathname?.split("/p/")?.[1]?.split("/b/")?.[0],
        {
          organization_name:
            window.location?.pathname?.split("/p/")?.[1]?.split("/b/")?.[0] ||
            "",
        }
      );
    }
  }, []);

  const showProductDetailTour = () => {
    // if (userData?.data?.show_product_detail_tour) resetTour("productDetail");
  };

  // useEffect(() => {
  // getUserDetails();
  // }, []);
  return (
    <Body
      seoTitle={integrantInfo?.title}
      seoDescription={integrantInfo?.description}
      hideFooter
    >
      {integrantInfo && (
        <div className={classes.productDetailContainer}>
          <ProductBasicDetail
            {...{
              integrantsDetail,
              integrantsBatchesList,
              blockchainProof,
              dispatchBlockchainProof,
            }}
          />
          {!match ? (
            <ProductDetailDesktop
              {...{
                integrantsDetail,
                integrantsBatchesList,
                dispatchBlockchainProof,
                exhibitsList,
                showProductDetailTour,
                userData,
                recycle_val
              }}
            />
          ) : (
            <ProductDetailMobile
              {...{
                integrantsDetail,
                integrantsBatchesList,
                dispatchBlockchainProof,
                exhibitsList,
                showProductDetailTour,
                userData,
                recycle_val
              }}
            />
          )}
          <ProductExtraDetails
            {...{
              integrantsDetail,
            }}
          />
        </div>
      )}
      <IntegrantBlockchainProofDialog integrant_id={integrantInfo?.id} />
      <ProductDetailTour />
    </Body>
  );
};

const mapStateToProps = ({
  productReducers: {
    integrantsDetail,
    exhibitsList,
    isErrorInAPI,
    integrantsBatchesList,
    blockchainProof,
    ingredientsIds,
  },
  ProfileReducers: { userData },
  loaderReducer,
}) => ({
  integrantsBatchesList,
  integrantsDetail,
  isErrorInAPI,
  exhibitsList,
  blockchainProof,
  ingredientsIds,
  loaderReducer,
  userData,
});

const mapDispatchToProps = {
  getIntegrantsDetail: dispatchIntegrantsDetail,
  cleanIntegrantsDetail: dispatchIntegrantsDetailClean,
  dispatchBlockchainProof,
  dispatchexhibitsList,
  dispatchIngredientsIds,
  resetTour,
  getUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailV5);
