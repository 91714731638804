import { SHOW_TOUR, UPDATE_TOUR_ACTIVE_STEP } from "../actionsType/common";

export const resetTour = (params) => async (dispatch) => {
  dispatch({
    type: SHOW_TOUR,
    payload: params,
  });
};

export const updateTourActiveStep = (params) => async (dispatch) => {
  dispatch({
    type: UPDATE_TOUR_ACTIVE_STEP,
    payload: params,
  });
};
