import React from "react";
import { Body } from "../../components/Common";
import {
  SearchSection,
  WhatIsHealthLOQ,
  HealthLOQTechnology,
  CertifiedPartners,
  AsSeenIn,
  AsHealthLOQUserICan,
} from "../../components/HomePage";

const TrackAndTrace = () => {
  return (
    <Body description={"healthloq.com, HealthLOQ"}>
      <SearchSection />
      <WhatIsHealthLOQ />
      <AsHealthLOQUserICan />
      <HealthLOQTechnology />
      <CertifiedPartners />
      <AsSeenIn />
    </Body>
  );
};

export default TrackAndTrace;
