import actionType from "../actionsType";
import Helpers from "../../helpers";
import utils from "../../utils/commonUtils";
const { urlConvert } = utils;

const {
  Utils: { consoleLog },
} = Helpers;

const defaultState = {
  blogNewsList: [],
};

const { GET_BLOG_NEWS_LIST } = actionType;

const blogNewsReducers = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_BLOG_NEWS_LIST: {
      let data = payload;
      data = data?.map((item) => {
        return { ...item, slug: urlConvert(item?.attributes?.title) };
      });
      try {
        return {
          ...state,
          blogNewsList: data,
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    default:
      return state;
  }
};

export default blogNewsReducers;
