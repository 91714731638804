import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { FeaturesIconFrame } from "../Common/IconFrames";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "./Typography/PrimaryHeading";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  featureBoxList: {
    margin: "50px 0",
  },
  featureBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    maxHeight: 300,
    minHeight: 300,
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    "&>p": {
      marginTop: 25,
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
  heading: {
    display: "  grid",
    width: "80%",
    textAlign: "center",
    justifyItems: "center",
  },
  boxContainer: {
    display: "grid",
    justifyItems: "center",
  },
  root: {
    padding: "58px 0",
  },
  actionBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function BoxSectionComponent({
  content = [],
  heading = "",
  actionBtn = null,
}) {
  const classes = useStyle();

  return (
    <SectionWithTreePlan className={classes.root} position="right">
      <Box className={clsx("bodyMaxWidth", classes.boxContainer)}>
        {Boolean(heading) && (
          <Box className={classes.heading}>
            <PrimaryHeading>{heading}</PrimaryHeading>
          </Box>
        )}
        <Grid container spacing={4} className={classes.featureBoxList}>
          {content?.map((item, key) => {
            return (
              <Grid item key={key} xs={12} sm={12} md={4}>
                <Box className={classes.featureBox}>
                  <FeaturesIconFrame icon={item?.icon} />
                  <Typography variant="body2">{item?.content}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {Boolean(actionBtn) && (
          <Box className={classes.actionBtn}>{actionBtn}</Box>
        )}
      </Box>
    </SectionWithTreePlan>
  );
}
