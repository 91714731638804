import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import { VerifiedUser } from "@material-ui/icons";

const {
  images: { ConsumerProfileImg },
} = appImages;

const useStyle = makeStyles((theme) => ({
  content: {
    marginTop: "55px",
  },
  root: {
    padding: "70px 0",
  },
  heading: {
    display: "grid",
    width: "80%",
    textAlign: "center",
    justifyItems: "center",
    margin: "auto",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "434px",
    "&>img": {
      maxWidth: "100%",
    },
  },
  textContaint: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contentBox: {
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
  },
  shieldIcon: {
    color: "green",
    width: "42px",
    height: "42px",
    marginRight: "5px",
  },
}));

export default function RetailerCompliance() {
  const classes = useStyle();
  const content = [
    <>Verify a brand’s credentials through the issuing organization</>,
    <>
      Automate the verification of CoAs, other documentation, &
      credentials,...without manual searches
    </>,
    <>Check for metals, microbials, and adulterants in products effortlessly</>,
    <>Automate the comparison of the label to the CoA for ingredients listed</>,
  ];

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Box className={classes.heading}>
          <PrimaryHeading>Retailer Compliance Dashboard</PrimaryHeading>
        </Box>
        <Grid className={classes.content} container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={ConsumerProfileImg} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={3}>
              <Typography>
                Blockchain and AI technology that allows retailers to ….
              </Typography>
              {content?.map((item, key) => {
                return (
                  <Grid item style={{ width: "100%" }} key={key}>
                    <Box className={classes.contentBox}>
                      <VerifiedUser className={classes.shieldIcon} />
                      <Typography variant="body2">{item}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
