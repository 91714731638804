import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import jwt from "jsonwebtoken";
var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

export function getProtectedRoutes() {
  return [
    "profile",
    "profile-details",
    "offers",
    "survey",
    "connections",
    "survey-history",
    "feedback",
    "invite",
    "posts",
    "user-detail",
    "profile-signup",
  ];
}

export function getAuthRoutes() {
  return [
    "profile-login",
    "create-profile",
    "forgot-password",
    "verify-otp",
    "reset-password",
    "create-password",
  ];
}

export function getVideoFileFormat() {
  return [
    "webm",
    "mkv",
    "flv",
    "gif",
    "gifv",
    "ogg",
    "ogv",
    "mov",
    "mp4",
    "m4p",
    "m4v",
  ];
}

export const removeDuplicateIngredients = (arr = []) => {
  let filterArr = arr.filter(
    (item) => item?.source !== item?.sourceInfo?.transferIntegrant
  );
  filterArr.forEach((item, index) => {
    let duplicateData = arr.filter(
      (a) => a?.source === item?.sourceInfo?.transferIntegrant
    );
    if (duplicateData.length) {
      for (let a of duplicateData) {
        if (a?.nodes?.length) {
          let data = removeDuplicateIngredients(a?.nodes);
          filterArr[index]["nodes"] = [...filterArr[index]?.nodes, ...data];
        }
      }
    }
  });
  return filterArr;
};

export const verifyEnvironment = (str) => {
  if (str === "live") {
    return window.location.host === "healthloq.com";
  } else if (str === "dev") {
    return window.location.host === "healthloqclientapp-dev.azurewebsites.net";
  } else if (str === "qa") {
    return window.location.host === "healthloqclientapp-qa.azurewebsites.net";
  } else {
    return window.location.hostname === "localhost";
  }
};

const utils = {
  isUrl: (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "(www\\.)?" + // optional www prefix
      "((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])?)\\.)+[a-zA-Z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d{2,5})?" + // port
      "(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // path
      "(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(str);
  },
  getExtension: (url) => {
    return String(url)?.split(".").pop();
  },
  urlConvert: (val) => {
    return val
      .toLowerCase()
      .replace(/\s/g, "-")
      .replace(/[!@#$%^&*~`?,.;:'"]/g, "");
  },
  handleGoogleTranslateLanguage: (lan, type) => {
    if (lan) {
      localStorage.setItem("userLanguage", lan);
      if (type === "mobile") {
        document.cookie = `googtrans=/auto/${lan};path=/`;
      }
    } else {
      const userLan = localStorage.getItem("userLanguage");
      if (userLan) {
        document.cookie = `googtrans=/auto/${userLan};path=/`;
      } else {
        document.cookie = `googtrans=/auto/en;path=/`;
      }
    }
  },
  googleTranslateLanguageHandler: (lan) => {
    if (lan) {
      localStorage.setItem("userLanguage", lan);
      document.cookie = `googtrans=/auto/${lan};path=/`;
    } else {
      const language = localStorage.getItem("userLanguage");
      document.cookie = `googtrans=/auto/${language ? language : "en"};path=/`;
      if (!language) {
        localStorage.setItem("userLanguage", "en");
      }
    }
  },
  handleDocumentViewClick: (id) => {
    let el = document.getElementById(id);
    let extension = el.getAttribute("data-extention");
    let url = el.getAttribute("data-gas_cert_url");
    if (extension === "doc") {
      window.location.href = url;
    } else if (extension === "pdf") {
      window.jQuery("." + id).fancybox({
        width: 900, // or whatever
        height: 620,
        type: "iframe",
      });
      window.jQuery("." + id).attr("href", `${url}#toolbar=0&zoom=100`);
      window.jQuery("." + id).click();
    } else {
      window.jQuery("." + id).fancybox({
        autoScale: true,
      });
      window.jQuery("." + id).attr("href", `${url}#toolbar=0&zoom=100`);
      window.jQuery("." + id).click();
    }
  },
  getLocalStorageItems: () => {
    const user_id = localStorage.getItem("user_id");
    const auth_token = localStorage.getItem("auth_token");
    const otpVerified = localStorage.getItem("otpVerified");
    let selectedIntegrantTypeId = window.sessionStorage.getItem(
      "selectedIntegrantTypeId"
    );
    const is_signup = localStorage.getItem("is_signup");
    const is_verified = localStorage.getItem("is_verified");
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    const survey_token = localStorage.getItem("survey_token");
    const userLanguage = localStorage.getItem("userLanguage");
    const organization_id = window.sessionStorage.getItem("organization_id");
    const integrant_id = window.sessionStorage.getItem("integrant_id");
    const productDetails = window.sessionStorage.getItem("productDetails");
    const is_register = localStorage.getItem("is_register");
    return {
      user_id: user_id ? window.atob(user_id) : null,
      auth_token,
      otpVerified,
      is_signup,
      is_verified,
      latitude,
      longitude,
      userLanguage,
      selectedIntegrantTypeId: selectedIntegrantTypeId
        ? window.atob(selectedIntegrantTypeId)
        : null,
      survey_token,
      organization_id: organization_id ? window.atob(organization_id) : null,
      integrant_id: integrant_id ? window.atob(integrant_id) : null,
      productDetails: productDetails ? JSON.parse(productDetails) : null,
      is_register,
    };
  },
  isProtectedRoutes: (path) => {
    return getProtectedRoutes()?.includes(path?.split("/")[1]);
  },
  getShortUserName: (name) => {
    if (!name) {
      return "";
    }
    const nameArr = name?.trim()?.split(" ");
    if (nameArr?.length > 1) {
      return nameArr[0][0]?.toUpperCase() + nameArr[1][0]?.toUpperCase();
    } else {
      return nameArr[0][0]?.toUpperCase();
    }
  },
  isVideo: (url) => {
    return getVideoFileFormat().includes(url.split(".").pop().toLowerCase());
  },
  removeItemsFromLocalStorage: (keys) => {
    if (typeof keys === "string") {
      localStorage.removeItem(keys);
    } else {
      for (let item of keys) {
        localStorage.removeItem(item);
      }
    }
  },
  removeItemsFromSessionStorage: (keys) => {
    if (typeof keys === "string") {
      window.sessionStorage.removeItem(keys);
    } else {
      for (let item of keys) {
        window.sessionStorage.removeItem(item);
      }
    }
  },
  addItemsIntoLocalStorage: (data = {}, convertToBase64 = true) => {
    for (let item of Object.entries(data)) {
      localStorage.setItem(
        item[0],
        convertToBase64 ? window.btoa(item[1]) : item[1]
      );
    }
  },
  addItemsIntoSessionStorage: (data = {}, convertToBase64 = true) => {
    for (let item of Object.entries(data)) {
      window.sessionStorage.setItem(
        item[0],
        convertToBase64 ? window.btoa(item[1]) : item[1]
      );
    }
  },
  isAuthRoutes: (path) => {
    return getAuthRoutes().includes(path.split("/")[1]);
  },
  decodeJWTToken: async (token) => {
    return await jwt.decode(token, process.env.REACT_APP_AUTH_JWT_SECRET);
  },
  getValidUrl: (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");
    if (/^(:\/\/)/.test(newUrl)) return `http${newUrl}`;
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) return `http://${newUrl}`;
    return newUrl;
  },
};

export default utils;
