import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { AppImages } from "../../constants";
import {
  dispatchVerifyDocument,
  dispatchBlockchainProof,
  getDocumentHashBlockchainProof,
  setInitialBlockchainProofStates,
  updateVerifyDocumentData,
  getOrgExhibitBlockchainProof,
  getExhibitBlockchainProof,
  getLabDocumentHashBlockchainProof,
  dispatchVerifyCoaDocument,
} from "../../redux/actions";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import {
  Grid,
  makeStyles,
  Select,
  MenuItem,
  CircularProgress,
  InputAdornment,
  Typography,
  Box,
  TextField,
} from "../";
import { Autocomplete } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { IntegrantBlockchainProof } from "../Common";
import { producerAppURL } from "../../services/constants";
import HashNotVerifiedErrorMsg from "../Common/BlockchainResults/HashNotVerifiedErrorMsg";
import VerifiedDocumentInfo from "../Common/BlockchainResults/VerifiedDocumentInfo";
import VerifiedOrganizationInfo from "../Common/BlockchainResults/VerifiedOrganizationInfo";
import ExpiredDocument from "../Common/BlockchainResults/ExpiredDocument";

const useStyle = makeStyles((theme) => ({
  healthloqTagLine: {
    marginBottom: 20,
    "&>h3": {
      textAlign: "center",
      textTransform: "capitalize",
      "&>span": {
        color: theme.palette.primary.main,
      },
    },
  },
  Submitted_wrapper: {
    marginTop: 20,
    marginBottom: 20,
    border: "1px solid rgba(40 ,167 ,69 , 1)",
    borderRadius: "5px",
    backgroundColor: "rgba(40 ,167 ,69 , 0.15)",
    padding: "5px 15px",
    maxWidth: "70%",
    margin: "0 auto",
    [theme.breakpoints.down(640)]: {
      flexWrap: "wrap",
      maxWidth: "100%",
    },
  },
  LeftImageWrapper: {
    flex: "0 0 60px",
    height: 60,
    "& > img": {
      objectFit: "cover !important",
    },
  },
  RightContentWrapper: {
    flex: "0 0 calc(100% - 65px)",
    color: "#000",
    flexWrap: "wrap",
    "& > div": {
      width: "100%",
    },
    [theme.breakpoints.down(640)]: {
      flex: "0 0 100%",
      marginTop: 10,
      fontSize: 14,
    },
  },
  dialogContent: {
    flexDirection: "column",
  },
  orgSelectBox: {
    textAlign: "center",
    width: "100%",
  },
  organizationListSelect: {
    width: "100%",
    maxWidth: "60%",
    borderRadius: 10,
    textAlign: "left",
    "&>div:focus": {
      backgroundColor: "transparent",
    },
  },
  uploadverifyBtnWrapper: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "flex-start !important",
    flexDirection: "row !important",
    marginTop: 20,
    marginBottom: 10,
    "&>button": {
      width: 150,
      minWidth: 150,
      margin: "0 10px",
      padding: "18px 50px",
    },
    [theme.breakpoints.down("md")]: {
      "&>button": {
        fontSize: 14,
        padding: 10,
      },
    },
    [theme.breakpoints.down(400)]: {
      flexDirection: "column !important",
      "&>button": {
        marginBottom: 10,
      },
    },
  },
  uploadAndVerifyBtnDragAndDrop: {
    width: '350px',
    height: '120px',
    display: 'flex !important',
    flexDirection: "column",
    alignItems: 'center !important',
    justifyContent: 'center !important',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    transition: 'background-color 0.3s, border-color 0.3s',
    marginTop: "10px",
    "&>p": {
      display: "flex",
      flexDirection: "column",
      color: theme.palette.grey[500]
    }
  },
  uploadAndVerify: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
    width: "100%",
    "&>div:nth-child(1)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: 20,
      "&>p": {
        marginLeft: 10,
        color: "#999",
      },
      "&>button": {
        width: 150,
        minWidth: 150,
        margin: "0 10px",
      },
      [theme.breakpoints.down(768)]: {
        flexDirection: "column",
        alignItems: "unset",
        justifyContent: "unset",
        "&>button": {
          marginBottom: 10,
        },
      },
    },
    "&>button": {
      width: 150,
    },
    "&>p": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 20,
      "&>div": {
        marginLeft: 10,
      },
    },
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 20,
    "&>img": {
      width: 30,
      height: 30,
      marginRight: 10,
    },
  },
  successMsg: {
    color: theme.palette.primary.main,
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
  documentProof: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    organizationListSelect: {
      maxWidth: "unset",
    },
  },
  organizationExhibitDetailBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    "&>div": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginBottom: 15,
      "&>h5": {
        textAlign: "left",
        marginBottom: 5,
      },
      "&>p": {
        textAlign: "left",
      },
    },
    "&>button": {
      width: "max-content",
    },
  },
  documentVerificationResultUl: {
    "&>*:not(:last-child)": {
      marginBottom: 30,
    },
  },
  documentActionBtns: {
    width: "100%",
    flexDirection: "row !important",
    "&>*:not(:last-child)": {
      marginRight: 10,
    },
  },
  bulkVerifyDocumentLink: {
    marginBottom: 10,
    "&>a": {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  },
  producerSelectAutoComplete: {
    maxWidth: "700px",
    margin: "auto",
    "&>div": {
      "&>div": {
        "&>div": {
          "&>button": {
            marginRight: "5px",
            backgroundColor: "transparent !important",
            "&>span": {
              "&>svg": {
                fill: `${theme.palette.primary.main} !important`,
              },
            },
          },
        },
      },
    },
  },
  autoCompleteTextField: {
    border: `1px solid #C4C4C4`,
    borderRadius: "10px",
    padding: "0px 10px",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    // "&:focus": {
    //   border: `1px solid red !important`,
    // },
  },
}));

const {
  icon: { correctIcon, wrongIcon },
} = AppImages;

export const DocumentVerificationDialog = (props) => {
  const {
    dispatchVerifyDocument,
    organizationList,
    organizationListLoading,
    verifyDocumentData,
    getDocumentHashBlockchainProof,
    setInitialBlockchainProofStates,
    updateVerifyDocumentData,
    exhibitBlockchainProof,
    orgExhibitBlockchainProof,
    documentHashBlockchainProof,
    getOrgExhibitBlockchainProof,
    getExhibitBlockchainProof,
    labDocumentHashBlockchainProof,
    getLabDocumentHashBlockchainProof,
    dispatchVerifyCoaDocument,
    verifyCoaDocumentData,
    activeOrgDocuments,
  } = props;
  const classes = useStyle();
  const inputFileRef = useRef(null);
  const [producerValue, setProducerValue] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [blockchainVerifyMsg, setBlockchainVerifyMsg] = useState({
    type: "",
    msg: "",
  });

  const handleProducerOnChange = (e, newVal) => {
    setProducerValue(newVal);
    // if (e.target.value) {
    //   setProducerValue(e.target.value);
    // setInitialBlockchainProofStates();
    // if (blockchainVerifyMsg?.type === "producer_error") {
    //   setBlockchainVerifyMsg({ type: "", msg: "" });
    // }
    // }
  };

  const handleVerify = () => {
    if (!uploadedFile) {
      setBlockchainVerifyMsg({
        type: "upload_file_error",
        msg: "Please upload file for verify.",
      });
    } else {
      // setInitialBlockchainProofStates();
      // const formData = new FormData();
      // formData.append("id", null);
      // formData.append("organization_id", producerValue);
      // formData.append("type", "integrant");
      // formData.append("files[]", uploadedFile);
      // dispatchVerifyDocument(formData);
      const formData = new FormData();
      formData.append("coaFile", uploadedFile);
      if (producerValue?.id) {
        formData.append("organization_id", producerValue.id);
      }
      dispatchVerifyCoaDocument(formData);
    }
  };
  const verifyOrganizationDocument = (data, i) => {
    let arr = [...activeOrgDocuments];
    if (arr[i]) {
      arr = arr.map((a, index) => (index === i ? data?.id : a));
    } else {
      arr = arr.concat(data?.id);
    }
    if (!data?.documentInfo || Object.keys(data?.documentInfo).length < 2) {
      dispatchVerifyCoaDocument(
        {
          document_id: data?.document_id,
        },
        arr
      );
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (verifyDocumentData?.integrantId) {
  //       getExhibitBlockchainProof({
  //         id: verifyDocumentData?.integrantId,
  //         type: "integrant",
  //       });
  //     }
  //     if (verifyDocumentData?.OrganizationExhibitId) {
  //       getOrgExhibitBlockchainProof({
  //         id: verifyDocumentData?.OrganizationExhibitId,
  //         type: "organization_exhibit",
  //       });
  //     }
  //     if (verifyDocumentData?.documentHashId) {
  //       getDocumentHashBlockchainProof({
  //         id: verifyDocumentData?.documentHashId,
  //         type: "document_hash",
  //       });
  //     }
  //     if (verifyDocumentData?.labDocumentHashId) {
  //       getLabDocumentHashBlockchainProof({
  //         id: verifyDocumentData?.labDocumentHashId,
  //         type: "document_hash",
  //       });
  //     }
  //     updateVerifyDocumentData({
  //       blockchainProofApiFlag: false,
  //     });
  //   };
  //   if (
  //     !verifyDocumentData?.loading &&
  //     verifyDocumentData?.blockchainProofApiFlag
  //   ) {
  //     fetchData();
  //   }
  // }, [verifyDocumentData?.blockchainProofApiFlag]);

  // useEffect(() => {
  //   return () => setInitialBlockchainProofStates();
  // }, []);

  const getBlockchainProofs = (data, i = 0) => {
    if (!data) return <></>;
    return (
      <>
        {data?.loading && (
          <Typography variant="body2">
            Please wait while we are verifying the document...
            <CircularProgress size={20} />
          </Typography>
        )}
        {data?.status === "1" &&
          !data?.loading &&
          (data?.isVerifyDocument ? (
            <>
              {data?.is_expired ? (
                <ExpiredDocument {...data} />
              ) : (
                <VerifiedDocumentInfo {...data} />
              )}
              {!data?.hide_org &&
                (data?.govEntity?.length ? (
                  <>
                    <VerifiedOrganizationInfo
                      {...data}
                      onOrganizationClick={(a) =>
                        verifyOrganizationDocument(a, i)
                      }
                    />
                    {getBlockchainProofs(
                      data?.govEntity?.filter(
                        (a) => a?.id === activeOrgDocuments[i]
                      )?.[0]?.documentInfo || null,
                      i + 1
                    )}
                  </>
                ) : (
                  <HashNotVerifiedErrorMsg hashType="Organization" {...data} />
                ))}
            </>
          ) : (
            <HashNotVerifiedErrorMsg
              hashType={data?.isOrganizationDoc ? "Organization" : "Document"}
              {...data}
            />
          ))}
      </>
    );
  };

  const [dragOver, setDragOver] = useState(false);

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0];
    if (!verifyCoaDocumentData?.loading && file) {
      setUploadedFile(file);
      setBlockchainVerifyMsg({ type: "", msg: "" });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  return (
    <>
      <Grid container className={classes.dialogContent}>
        <Grid item className={classes.orgSelectBox}>
          <Autocomplete
            value={producerValue}
            onChange={handleProducerOnChange}
            loading={organizationListLoading}
            className={classes.producerSelectAutoComplete}
            options={organizationList || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select producer"
                className={classes.autoCompleteTextField}
                organizationListLoading
              />
            )}
          />
        </Grid>
        <Grid item className={classes.uploadAndVerify}>
          {uploadedFile && (
            <Typography variant="body2">{uploadedFile?.name}</Typography>
          )}
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            // className={classes.uploadverifyBtnWrapper}
            className={classes.uploadAndVerifyBtnDragAndDrop}
          >
            <Typography variant="body2">
              Drag and drop a file here, or
            </Typography>
            <Grid style={{ display: "flex", gap: "12px", marginTop: "10px" }}>
              <Button
                startIcon={<CloudUploadOutlinedIcon />}
                onClick={() => inputFileRef?.current?.click()}
                disabled={verifyCoaDocumentData?.loading}
              >
                Upload
              </Button>
              {uploadedFile && (
                <Button
                  onClick={handleVerify}
                  endIcon={
                    verifyCoaDocumentData?.loading && (
                      <CircularProgress size={20} />
                    )
                  }
                  disabled={verifyCoaDocumentData?.loading}
                >
                  {verifyCoaDocumentData?.loading ? "Verifying..." : "Verify"}
                </Button>
              )}
            </Grid>
          </div>
          <Typography
            variant="body2"
            className={classes.bulkVerifyDocumentLink}
          >
            To verify documents in bulk,&nbsp;
            <a
              href={`${producerAppURL}/plans?tab=2`}
              target={"_blank"}
              rel="noreferrer"
            >
              Click here
            </a>
          </Typography>
          <Typography
            variant="body2"
            className={classes.bulkVerifyDocumentLink}
          >
            Want to protect your documents?&nbsp;
            <a
              href={`${producerAppURL}/plans?tab=1`}
              target={"_blank"}
              rel="noreferrer"
            >
              Click here
            </a>
          </Typography>
          {/* {verifyDocumentData?.message && !verifyDocumentData?.loading && (
            <Box sx={{ my: 1 }}>
              <Typography
                variant="body1"
                color={
                  verifyDocumentData?.isVerifyDocument ? "primary" : "error"
                }
                display="flex"
                alignItems={"center"}
              >
                {verifyDocumentData?.message}
                {verifyDocumentData?.isVerifyDocument ? (
                  <img
                    src={correctIcon}
                    alt="currect-icon"
                    style={{ marginLeft: 5 }}
                  />
                ) : (
                  <img
                    src={wrongIcon}
                    alt="wrong-icon"
                    style={{ marginLeft: 5 }}
                  />
                )}
              </Typography>
            </Box>
          )} */}
        </Grid>
        <Box className="blockchain-proof-ul">
          {!verifyCoaDocumentData?.loading &&
            getBlockchainProofs(verifyCoaDocumentData, 0)}
          {exhibitBlockchainProof?.isLoading && (
            <Typography variant="body2">
              Please wait while we are fetching the product detail...
              <CircularProgress size={20} />
            </Typography>
          )}
          {verifyDocumentData?.integrantId &&
            !exhibitBlockchainProof?.isLoading &&
            typeof exhibitBlockchainProof?.result === "boolean" && (
              <Grid item className={classes.documentProof}>
                <IntegrantBlockchainProof
                  blockchainData={exhibitBlockchainProof}
                />
              </Grid>
            )}
          {orgExhibitBlockchainProof?.isLoading &&
            !exhibitBlockchainProof?.isLoading && (
              <Typography variant="body1">
                Please wait while we are fetching the organization document
                detail...
                <CircularProgress size={20} />
              </Typography>
            )}
          {!orgExhibitBlockchainProof?.isLoading &&
            typeof orgExhibitBlockchainProof?.result === "boolean" && (
              <IntegrantBlockchainProof
                blockchainData={orgExhibitBlockchainProof}
              />
            )}
          {documentHashBlockchainProof?.isLoading &&
            !orgExhibitBlockchainProof?.isLoading &&
            !exhibitBlockchainProof?.isLoading && (
              <Typography variant="body1">
                Please wait while we are fetching the document detail...
                <CircularProgress size={20} />
              </Typography>
            )}
          {!documentHashBlockchainProof?.isLoading &&
            typeof documentHashBlockchainProof?.result === "boolean" && (
              <IntegrantBlockchainProof
                blockchainData={documentHashBlockchainProof}
              />
            )}
          {labDocumentHashBlockchainProof?.isLoading &&
            !documentHashBlockchainProof?.isLoading &&
            !orgExhibitBlockchainProof?.isLoading &&
            !exhibitBlockchainProof?.isLoading && (
              <Typography variant="body1">
                Please wait while we are fetching the lab document detail...
                <CircularProgress size={20} />
              </Typography>
            )}
          {!labDocumentHashBlockchainProof?.isLoading &&
            typeof labDocumentHashBlockchainProof?.result === "boolean" && (
              <IntegrantBlockchainProof
                blockchainData={labDocumentHashBlockchainProof}
              />
            )}
        </Box>
      </Grid>
      <input
        className="display-none"
        type={"file"}
        onChange={(e) => {
          if (e.target.files[0]) {
            setUploadedFile(e.target.files[0]);
            setBlockchainVerifyMsg({ type: "", msg: "" });
          }
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
        ref={inputFileRef}
      />
    </>
  );
};

const mapStateToProps = ({
  productReducers: {
    organizationList,
    organizationListLoading,
    verifyDocumentData,
    blockchainProof_loading,
    blockchainProof,
    exhibitBlockchainProof,
    orgExhibitBlockchainProof,
    documentHashBlockchainProof,
    labDocumentHashBlockchainProof,
    verifyCoaDocumentData,
    activeOrgDocuments,
  },
}) => ({
  organizationList,
  organizationListLoading,
  verifyDocumentData,
  blockchainProof_loading,
  blockchainProof,
  exhibitBlockchainProof,
  orgExhibitBlockchainProof,
  documentHashBlockchainProof,
  labDocumentHashBlockchainProof,
  verifyCoaDocumentData,
  activeOrgDocuments,
});

const mapDispatchToProps = {
  dispatchVerifyDocument,
  dispatchBlockchainProof,
  getDocumentHashBlockchainProof,
  setInitialBlockchainProofStates,
  updateVerifyDocumentData,
  getOrgExhibitBlockchainProof,
  getExhibitBlockchainProof,
  getLabDocumentHashBlockchainProof,
  dispatchVerifyCoaDocument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentVerificationDialog);
