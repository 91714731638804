import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Link,
  Box,
  Divider,
} from "@material-ui/core";
import { AppImages, AppConstant } from "../constants";

const { logo, logoCRN, logoNPM, logoProud } = AppImages;
const {
  homeFooter: { address, textReach, email, footerLinks },
} = AppConstant;

const useStyle = makeStyles((theme) => ({
  footerRoot: {
    display: "flex",
    flexDirection: "column",
  },
  footerCopyRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "10px 15px",
    "&>p": {
      color: theme.palette.common.white,
      textAlign: "center",
    },
  },
  footerContent: {
    width: "100%",
    padding: "0 15px",
    "&>div": {
      maxWidth: "1074px",
      width: "100%",
      margin: "0 auto",
      "&>hr": {
        height: "2px",
      },
    },
  },
  footerLinks: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    margin: "22px 0 36px 0",
    "&>a": {
      width: "max-content",
    },
  },
  footerAddress: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "15px",
    "&>p": {
      marginLeft: "15px",
    },
  },
  footerLogos: {
    width: "100%",
    padding: "40px 20px",
    borderRadius: "10px",
    margin: "60px 0 70px 0",
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
    "&>div": {
      justifyContent: "center",
    },
  },
  footerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "&>div": {
      borderRadius: "4px",
      border: `1px solid ${theme.palette.primary.main}`,
      padding: "25px",
      width: "100%",
      maxWidth: "350px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&>img": {
        maxWidth: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "50px",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    footerLinks: {
      justifyContent: "center",
    },
  },
}));

export default function Footer() {
  const classes = useStyle();
  return (
    <Box className={classes.footerRoot}>
      <Box className={classes.footerContent}>
        <Box>
          <Box className={classes.footerLogos}>
            <Grid container spacing={4}>
              {[logoCRN, logoProud, logoNPM].map((logo, key) => {
                return (
                  <Grid item key={key} xs={12} sm={6} md={4} lg={4}>
                    <Box className={classes.footerLogo}>
                      <Box className={classes.footerLogo}>
                        <img src={logo} key={key} alt="" />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box className={classes.footerAddress}>
            <Link href="/">
              <img src={logo} width={53} height={53} alt="HealthLOQ" />
            </Link>
            <Typography variant="body2">
              {address}&nbsp;
              {textReach}&nbsp;:&nbsp;
              <Link href={`mailto:email:${email}`} variant="body2">
                {email}
              </Link>
            </Typography>
          </Box>
          <Divider />
          <Box
            className={classes.footerLinks}
            gridRowGap={10}
            gridColumnGap={20}
          >
            {footerLinks?.map((item, key) => {
              return (
                <Link href={item?.href} key={key} variant="body2">
                  {item?.label}
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.footerCopyRight}>
        <Typography variant="body2">
          © Copyright {new Date().getFullYear()} HealthLOQ LLC. All rights
          reserved
        </Typography>
      </Box>
    </Box>
  );
}
