import clsx from "clsx";
import React, { useState } from "react";
import {
  Typography,
  Grid,
  Drawer,
  makeStyles,
  Box,
  List,
  ListItem,
  Button,
  IconButton,
  CircularProgress,
} from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { BlockchainProofIcon, PdfIcon } from "../../../constants/svgIcons";
import Fancybox from "../../Common/Fancybox";
import utils from "../../../utils/commonUtils";
import BlockchainProofDialog from "../../Dialogs/BlockchainProofDialog";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
        "&>h2,&>h6": {
          marginBottom: 20,
        },
        "&>h5": {
          marginBottom: 10,
          "&:not(:nth-child(3))": {
            marginTop: 20,
          },
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  li: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>p": {
      marginBottom: 10,
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "&>button": {
        "&:first-child": {
          marginRight: 10,
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: 10,
        },
      },
    },
  },
  otherBatchTitle: {
    marginTop: 30,
  },
  batchLi: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
    border: `1px solid rgba(0,0,0,0.1)`,
    "&:nth-child(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&>p": {
      "&>span": {
        marginRight: 10,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  loading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>div": {
      marginLeft: 5,
    },
  },
}));

export default function Testing({
  open = false,
  onClose = () => {},
  exhibitsInfo,
  integrantInfo,
  integrantsBatchesList,
  setSelectedBatch,
  isLoading = false,
}) {
  let sustainability = [];
  let quality = [];
  let diversity = [];
  if (!isLoading && open) {
    sustainability = exhibitsInfo?.filter(
      (item) => item.title === "Sustainability"
    );
    sustainability = sustainability?.length ? sustainability[0] : [];
    quality = exhibitsInfo?.filter((item) => item.title === "Quality");
    quality = quality?.length ? quality[0] : [];
    diversity = exhibitsInfo?.filter((item) => item.title === "Diversity");
    diversity = diversity?.length ? diversity[0] : [];
  }
  const classes = useStyle();
  const [exhibitId, setExhibitId] = useState("");
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Typography variant="h2" bold>
          Testing
        </Typography>
        {isLoading ? (
          <Typography variant="body2" className={classes.loading}>
            Loading...
            <CircularProgress />
          </Typography>
        ) : (
          open && (
            <>
              {sustainability?.exhibitsType?.length > 0 && (
                <>
                  <Typography variant="h5" light>
                    Sustainability
                  </Typography>
                  <Typography variant="h6" bold>
                    Want to know the ecological impact of producing your&nbsp;
                    {integrantInfo?.external_id} product?
                  </Typography>
                  <List component={"ol"}>
                    {sustainability?.exhibitsType?.map((item, i) => {
                      return (
                        <ListItem key={i} className={classes.li}>
                          <Typography variant="body1" light>
                            {i + 1}.&nbsp;{item.title}
                          </Typography>
                          <Box>
                            <Button
                              data-extention={utils.getExtension(item?.url)}
                              data-gas_cert_url={item?.url}
                              id={"sustainability-document-view-" + item.id}
                              onClick={() => {
                                utils.handleDocumentViewClick(
                                  "sustainability-document-view-" + item.id
                                );
                              }}
                              primaryLightBtn
                              startIcon={<PdfIcon />}
                            >
                              View Documents
                            </Button>
                            <Fancybox
                              className={
                                "sustainability-document-view-" + item.id
                              }
                            />
                            <Button
                              primaryDarkBtn
                              onClick={() => setExhibitId(item.id)}
                              startIcon={<BlockchainProofIcon />}
                              data-toggle="modal"
                              data-target="#productBlockchainProofDialog"
                            >
                              Verify Document
                            </Button>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}
              {quality?.exhibitsType?.length > 0 && (
                <>
                  <Typography variant="h5" light>
                    Quality
                  </Typography>
                  <Typography variant="h6" bold>
                    Want to know how batch&nbsp;
                    {integrantInfo?.external_id} was tested?
                  </Typography>
                  <List component={"ol"}>
                    {quality?.exhibitsType?.map((item, i) => {
                      return (
                        <ListItem key={i} className={classes.li}>
                          <Typography variant="body1" light>
                            {i + 1}.&nbsp;{item.title}
                          </Typography>
                          <Box>
                            <Button
                              data-extention={utils.getExtension(item?.url)}
                              data-gas_cert_url={item?.url}
                              id={"quality-document-view-" + item.id}
                              onClick={() => {
                                utils.handleDocumentViewClick(
                                  "quality-document-view-" + item.id
                                );
                              }}
                              primaryLightBtn
                              startIcon={<PdfIcon />}
                            >
                              View Documents
                            </Button>
                            <Fancybox
                              className={"quality-document-view-" + item.id}
                            />
                            <Button
                              primaryDarkBtn
                              onClick={() => setExhibitId(item.id)}
                              startIcon={<BlockchainProofIcon />}
                              data-toggle="modal"
                              data-target="#productBlockchainProofDialog"
                            >
                              Verify Document
                            </Button>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}
              {diversity?.exhibitsType?.length > 0 && (
                <>
                  <Typography variant="h5" light>
                    Diversity
                  </Typography>
                  <Typography variant="h6" bold>
                    Want to know the social impact of producing your&nbsp;
                    {integrantInfo?.external_id} product?
                  </Typography>
                  <List component={"ol"}>
                    {diversity?.exhibitsType?.map((item, i) => {
                      return (
                        <ListItem key={i} className={classes.li}>
                          <Typography variant="body1" light>
                            {i + 1}.&nbsp;{item.title}
                          </Typography>
                          <Box>
                            <Button
                              data-extention={utils.getExtension(item?.url)}
                              data-gas_cert_url={item?.url}
                              id={"diversity-document-view-" + item.id}
                              onClick={() => {
                                utils.handleDocumentViewClick(
                                  "diversity-document-view-" + item.id
                                );
                              }}
                              primaryLightBtn
                              startIcon={<PdfIcon />}
                            >
                              View Documents
                            </Button>
                            <Fancybox
                              className={"diversity-document-view-" + item.id}
                            />
                            <Button
                              primaryDarkBtn
                              onClick={() => setExhibitId(item.id)}
                              startIcon={<BlockchainProofIcon />}
                              data-toggle="modal"
                              data-target="#productBlockchainProofDialog"
                            >
                              Verify Document
                            </Button>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}
              {sustainability?.length === 0 &&
                quality?.length === 0 &&
                diversity?.length === 0 && (
                  <Typography variant="body1">
                    Attachments not available.
                  </Typography>
                )}
              {Boolean(integrantsBatchesList?.length) && (
                <>
                  <Typography variant="h5" className={classes.otherBatchTitle}>
                    Want to see documents from a different batch?
                  </Typography>
                  {integrantsBatchesList?.map((item, key) => {
                    return (
                      <Grid item key={key} className={classes.batchLi}>
                        <Typography variant="body1">
                          <span>Batch No:</span>
                          {item?.external_id}
                        </Typography>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedBatch(item?.id);
                            onClose("otherBatches");
                          }}
                        >
                          <ArrowForwardRoundedIcon />
                        </IconButton>
                      </Grid>
                    );
                  })}
                </>
              )}
            </>
          )
        )}
      </Grid>
      <BlockchainProofDialog
        exhibitId={exhibitId}
        setExhibitId={setExhibitId}
      />
    </Drawer>
  );
}
