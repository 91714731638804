import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { useState } from "react";
import { MuiSwitch, MuiTextfield, PublicPrivateSwitch } from "../Profile";
import HealthLoqTextField from "../Common/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const {
  images: { profileIcon },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: "42px 30px",
  },
  profileHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  textMargin: {
    marginTop: 10,
  },
  colorText: {
    color: theme.palette.primary.main,
  },
  profileDetailsContainer: {
    backgroundColor: "#F8F8F8",
    padding: "13px 20px",
    "& .MuiOutlinedInput-multiline": {
      padding: "10px 0px",
    },

    "&>div": {
      display: "flex",
      gap: 34,
      "&>div": {
        marginTop: 12,
        width: "-webkit-fill-available",
      },
      [theme.breakpoints.down("md")]: {
        display: "grid",
        gap: 0,
      },
    },
  },
  btn: {
    background: theme.palette.common.white,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: 15,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  switch: {
    border: "2px solid red",
  },
  profileTextField: {
    "&>div": {
      background: theme.palette.common.white,
      "&>fieldset": {
        top: 0,
      },
    },
  },
}));

export default function ConsumerProfileDetails() {
  const classes = useStyle();

  // USE THEME BREAKPOINT
  const theme = useTheme();
  const match = !useMediaQuery(theme.breakpoints.down(769));

  // LOCAL STATE
  const [chip, setChip] = useState(true);

  // VALIDATION SCHEMA
  const profileValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Enter only number.")
      .matches(/^[0-9]\d{9}$/, {
        message: "Enter valid phone number",
      }),
    phone_number1: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Enter only number.")
      .matches(/^[0-9]\d{9}$/, {
        message: "Enter valid phone number.",
      }),
  });

  // DELETE ACCOUNT
  const handleDeleteAccount = () => {
    console.log("Delete account");
  };

  // SAVE ACCOUNT
  const handleSaveAccount = (data) => {
    console.log(data);
  };

  return (
    <Grid className={classes.root}>
      <Grid>
        <Grid className={classes.profileHeader}>
          <Grid>
            <PrimaryHeading>Consumer Profile</PrimaryHeading>
            <Typography color="primary" variant="body2">
              Your Data, Your Control, Your Rewards.
            </Typography>
          </Grid>
          <img src={profileIcon} />
        </Grid>
        <Typography variant="body2">
          You have full control over your own health and wellness profile!
          Change how you share anytime you want.
        </Typography>
        <Typography variant="body2">
          The more information you enter and are willing to share with
          companies, the more valuable your profile becomes. Companies will find
          your profile and extend offers based on the information you are
          willing to share.
        </Typography>
        <Typography className={classes.textMargin} variant="body2">
          You are sharing your data with 1 company.{" "}
          <Link href="#" className={classes.colorText}>
            Click here
          </Link>{" "}
          to manage.
        </Typography>
        <PublicPrivateSwitch
          checked={chip}
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: 29,
          }}
          onChange={() => setChip(!chip)}
          isMasterToggle={true}
        />
      </Grid>

      <Formik
        initialValues={{
          name: null,
          phone_number: null,
          phone_number1: null,
          gender: null,
          race: null,
          marital_status: null,
          age: null,
          family_and_dependents: null,
          education_level: null,
          general_wellness: null,
          height: null,
          family_and_dependents1: null,
          profile_bio: null,
        }}
        validationSchema={profileValidationSchema}
        onSubmit={handleSaveAccount}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          errors,
          setValues,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Grid className={classes.profileDetailsContainer}>
              <Grid>
                <HealthLoqTextField
                  match={match}
                  name="name"
                  label="Name"
                  required
                  showLockIcon
                  value={values.name}
                  onChange={handleChange}
                  error={
                    touched.name && errors?.name !== undefined ? true : false
                  }
                  helperText={touched.name && errors?.name}
                  onBlur={handleBlur}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  name="phone_number"
                  label="Phone Number"
                  required
                  showLockIcon
                  value={values.phone_number}
                  onChange={handleChange}
                  error={
                    touched.phone_number && errors?.phone_number !== undefined
                      ? true
                      : false
                  }
                  helperText={touched.phone_number && errors?.phone_number}
                  onBlur={handleBlur}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Phone Number"
                  name="phone_number1"
                  required
                  showLockIcon
                  value={values.phone_number1}
                  onChange={handleChange}
                  error={
                    touched.phone_number1 && errors?.phone_number1 !== undefined
                      ? true
                      : false
                  }
                  helperText={touched.phone_number1 && errors?.phone_number1}
                  onBlur={handleBlur}
                  className={classes.profileTextField}
                />
              </Grid>
              <Grid>
                <HealthLoqTextField
                  match={match}
                  label="Gender"
                  name="gender"
                  showLockIcon
                  value={values.gender}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Race"
                  name="race"
                  showLockIcon
                  value={values.race}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Age (in years)"
                  name="age"
                  showLockIcon
                  value={values.age}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Marital status"
                  name="marital_status"
                  showLockIcon
                  value={values.marital_status}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
              </Grid>
              <Grid>
                <HealthLoqTextField
                  match={match}
                  label="Family and Dependents"
                  name="family_and_dependents"
                  showLockIcon
                  value={values.family_and_dependents}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Education Level"
                  name="education_level"
                  showLockIcon
                  value={values.education_level}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="General Wellness"
                  name="general_wellness"
                  showLockIcon
                  value={values.general_wellness}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Height (in inches)"
                  name="height"
                  showLockIcon
                  value={values.height}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
              </Grid>

              <Grid>
                <HealthLoqTextField
                  match={match}
                  label="Family and Dependents"
                  name="family_and_dependents1"
                  showLockIcon
                  value={values.family_and_dependents1}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
                <HealthLoqTextField
                  match={match}
                  label="Profile Bio"
                  name="profile_bio"
                  showLockIcon
                  value={values.profile_bio}
                  onChange={handleChange}
                  className={classes.profileTextField}
                />
              </Grid>
            </Grid>

            <Grid style={{ marginTop: 20, display: "flex", gap: 10 }}>
              <Button type="submit">Save</Button>
              <Button
                className={classes.btn}
                onClick={() => handleDeleteAccount()}
              >
                Delete Account
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
