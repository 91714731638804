import React, { useEffect } from "react";
import { Badge, Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as HistoryIcon } from "../../constants/svg/icon-history.svg";
import { ReactComponent as ProfileIcon } from "../../constants/svg/icon-profile.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import utils, { verifyEnvironment } from "../../utils/commonUtils";
import { AppImages } from "../../constants";
import { Link } from "react-router-dom";
import {
  LocalOffer,
  FeedbackOutlined,
  PersonAddRounded,
} from "@material-ui/icons";
import PeopleIcon from "@material-ui/icons/People";
import PostIcon from "@material-ui/icons/Dashboard";
import { connect } from "react-redux";

const { logo } = AppImages;

const useStyle = makeStyles((theme) => ({
  notificationCountBadge: {
    top: "50%",
    right: (props) =>
      props?.badgeContent < 10
        ? "-20%"
        : props?.badgeContent < 100
        ? "-30%"
        : "-40%",
  },
}));

function Sidebar({ activePage, history, userData }) {
  const classes = useStyle({
    badgeContent: userData?.data?.userUnreadNotificationCount || 0,
  });
  let data = [
    {
      label: "Offers",
      icon: <LocalOffer />,
      activePage: 4,
      path: "/profile",
      tourKey: "offers",
    },
    {
      label: "Companies",
      icon: <PeopleIcon />,
      activePage: 12,
      path: "/connections",
      tourKey: "connections",
    },
    {
      label: "Feedback",
      icon: <FeedbackOutlined />,
      activePage: 13,
      path: "/feedback",
      tourKey: "feedback",
    },
    {
      label: "Profile",
      icon: <ProfileIcon />,
      activePage: 5,
      path: "/profile-details",
      tourKey: "profile",
    },
    {
      label: "Invite",
      icon: <PersonAddRounded />,
      activePage: 14,
      path: "/invite",
      tourKey: "invite",
    },
    {
      label: "History",
      icon: <HistoryIcon />,
      activePage: 11,
      path: "/survey-history",
      tourKey: "history",
    },
    {
      label: "Logout",
      icon: <ExitToAppIcon />,
      activePage: null,
      path: "/profile-login",
    },
  ];
  data.splice(6, 0, {
    label: "Social",
    icon: <PostIcon />,
    activePage: 16,
    path: "/posts",
  });

  return (
    <Grid item sm={3} className="sidebar-root">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="sidebar-healthloq-icon"
      >
        <Link to={"/"} title="HealthLOQ Logo">
          <img src={logo} alt="HealthLOQ-logo" />
        </Link>
      </Grid>
      {data.map((item, key) => {
        return (
          <Grid
            key={key}
            className={clsx(
              "sidebar-icon-root",
              activePage === item.activePage && "active"
            )}
            onClick={() => {
              if (key === data.length - 1) {
                utils.removeItemsFromLocalStorage([
                  "auth_token",
                  "otpVerified",
                ]);
                window.sessionStorage.clear();
                window.location.href = "/profile-login";
              } else {
                history.push(item.path);
              }
            }}
            profile-tour={item?.tourKey || ""}
          >
            {item.icon}
            <div>
              {item?.label === "Social" ? (
                <Badge
                  badgeContent={userData?.data?.userUnreadNotificationCount}
                  color="primary"
                  classes={{ badge: classes.notificationCountBadge }}
                >
                  <p>{item.label}</p>
                </Badge>
              ) : (
                <p>{item.label}</p>
              )}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}

const mapStateToProps = ({ ProfileReducers: { userData } }) => ({ userData });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
