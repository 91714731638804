import clsx from "clsx";
import React from "react";
import { Typography, Grid, Drawer, makeStyles, Button } from "../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Fancybox from "./Fancybox";
import utils from "../../utils/commonUtils";
import { PdfIcon } from "../../constants/svgIcons";

const locationFormat = (location) => {
  if (location && typeof location === "object") {
    const { line_1, line_2, city, country, state, zip } = location;
    return (
      <div>
        {line_1} {line_2} {city} {state} {country} {zip && "-"} {zip}
      </div>
    );
  }
  return;
};

const dateFormat = (dateVal) => {
  const date = new Date(dateVal);
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return (m <= 9 ? "0" + m : m) + "/" + (d <= 9 ? "0" + d : d) + "/" + y;
};

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
        "&>h2": {
          textAlign: "center",
          marginBottom: 20,
          width: "100%",
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  imageDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    "&>img": {
      maxWidth: 300,
      width: "100%",
      height: "100%",
    },
  },
}));

export default function MapboxGLPointDrawer({
  open = false,
  onClose = () => {},
  data,
}) {
  const classes = useStyle();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Grid className={classes.imageDiv}>
          <img src={data?.data?.metadata?.integrantType} alt="" />
        </Grid>
        <Typography variant="h2" bold>
          {data?.title}
        </Typography>
        <div
          className="doc-list active"
          style={{ margin: 0, padding: 0, marginBottom: 20 }}
        >
          {data?.data?.type === "transit" || data?.data?.type === "transfer" ? (
            <div className="doc-title d-flex">
              <strong>{dateFormat(data?.data?.effectiveDate)}</strong>
              <span className="doc-ll">-</span>
              <div>
                <span className="text-capitalize">{data?.data?.type}</span>
                <div className="d-flex _pt5">
                  <div className="w-ll">From-</div>
                  <div>
                    {data?.data?.type === "transfer" && (
                      <b className="text-capitalize">
                        {data?.data?.metadata?.fromOrganization.name}
                      </b>
                    )}
                    {locationFormat(data?.data?.metadata?.fromLocation)}
                  </div>
                </div>
                <div className="d-flex _pt5">
                  <div className="w-ll">To-</div>
                  <div>
                    {data?.data?.type === "transfer" && (
                      <b className="text-capitalize">
                        {data?.data?.metadata?.toOrganization.name}
                      </b>
                    )}
                    {locationFormat(data?.data?.metadata?.toLocation)}
                  </div>
                </div>
              </div>
            </div>
          ) : data?.data?.type === "exhibit" ? (
            <div className="doc-title d-flex">
              <strong>{dateFormat(data?.data?.effectiveDate)}</strong>
              <span className="doc-ll">-</span>
              <div>
                <span className="text-capitalize">{data?.data?.type}</span>
                <div className="d-flex _pt5">
                  <div>{locationFormat(data?.data?.metadata?.location)}</div>
                </div>
                <div className="d-flex _pf5">
                  <div>
                    <Button
                      primaryDarkBtn
                      data-extention={utils.getExtension(
                        data?.data?.metadata?.url
                      )}
                      data-gas_cert_url={data?.data?.metadata?.url}
                      id={"exhibit-point-drawer-document-" + data?.data?.id}
                      onClick={() => {
                        utils.handleDocumentViewClick(
                          "exhibit-point-drawer-document-" + data?.data?.id
                        );
                      }}
                      startIcon={<PdfIcon />}
                      style={{ padding: "5px 15px", marginTop: 10 }}
                    >
                      View Document
                    </Button>
                  </div>
                  <Fancybox
                    className={
                      "exhibit-point-drawer-document-" + data?.data?.id
                    }
                  />
                </div>
              </div>
            </div>
          ) : data?.data?.type === "batch" ? (
            <div className="doc-title d-flex">
              <strong>{dateFormat(data?.data?.effectiveDate)}</strong>
              <span className="doc-ll">-</span>
              <div>
                <span className="text-capitalize">{data?.data?.type}</span>
                <div className="d-flex _pt5">
                  <div>{locationFormat(data?.data?.metadata?.location)}</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Typography variant="body2" light>
          {data?.data?.description || data?.data?.text}
        </Typography>
      </Grid>
    </Drawer>
  );
}
