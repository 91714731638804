import React from "react";
import { Grid } from "@material-ui/core";

export default function RightSideForm({ children }) {
  return (
    <Grid item sm={9} className="right-side-root">
      {children}
    </Grid>
  );
}
