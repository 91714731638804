/* eslint-disable react/prop-types */
import React from "react"
import "./style.scss"

const LayerWrapper = ({ children }) => (
    <div className="main-wrapper">
        {children}
    </div>
    )
export default LayerWrapper
