import actionType from "../actionsType";
import Helpers from "../../helpers";
import {
  GET_TRAITS_LIST_START,
  GET_TRAITS_LIST_SUCCESS,
  GET_PRODUCT_LIST_FOR_MENTIONS,
  GET_PRODUCT_OTHER_BATCHES,
} from "../actionsType/productActionType";
import {
  removeDuplicateIngredients,
  verifyEnvironment,
} from "../../utils/commonUtils";

const {
  Utils: { consoleLog },
} = Helpers;

const defaultState = {
  integrantsList: [],
  integrantsBatchesList: [],
  exhibitsList: [],
  integrantsDetail: {},
  integrantsListIsLoading: false,
  productListData: {
    loading: false,
    list: [],
    count: 0,
    filterLoading: [],
  },
  batchList: [],
  organizationList: [],
  organizationListLoading: false,
  getAllintegrantTypesList: {
    loading: false,
    status: "",
    message: "",
    data: [],
  },
  getIntegrantBatchList: {
    loading: false,
    status: "",
    message: "",
    data: [],
  },
  blockchainProof: {},
  blockchainProof_loading: false,
  ingredientsIds: [],
  isErrorInAPI: {
    error: { isError: false, errorMessage: null },
  },
  productCurrentTab: "",
  verifyDocumentData: {
    loading: false,
    data: null,
    blockchainProofApiFlag: false,
    isVerifyDocument: null,
    integrantId: null,
    OrganizationExhibitId: null,
    documentHashId: null,
    message: "",
  },
  verifyCoaDocumentData: {
    loading: false,
  },
  activeOrgDocuments: [],
  mapData: {
    data: [],
    integrant_ids: [],
    location: "",
    centerCoords: [],
  },
  traitsList: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
  mentionsProductList: {
    loading: false,
    status: "",
    message: "",
    data: [],
  },
  organizationDetail: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
  documentHashBlockchainProof: {
    isLoading: false,
    result: null,
    data: null,
    blockAddress: null,
  },
  orgExhibitBlockchainProof: {
    isLoading: false,
    result: null,
    data: null,
    blockAddress: null,
  },
  exhibitBlockchainProof: {
    isLoading: false,
    result: null,
    data: null,
    blockAddress: null,
    documentHashId: null,
  },
  labDocumentHashBlockchainProof: {
    isLoading: false,
    result: null,
    data: null,
    blockAddress: null,
  },
  otherBatchList: {
    isLoading: false,
    data: [],
  },
};

const {
  INTEGRANTS_EXHIBITS_DETAIL,
  INTEGRANTS_LIST,
  INTEGRANTS_DETAIL,
  INTEGRANTS_LIST_IS_LOADING,
  INTEGRANTS_ERROR,
  INTEGRANTS_BATCHES_LIST,
  PRODUCTLIST,
  PRODUCTLIST_LOADING,
  BATCHLIST,
  ORGANIZATIONLIST,
  ORGANIZATIONLISTLOADING,
  GET_ALL_INTEGRANT_TYPES_LIST_START,
  GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS,
  BLOCKCHAINPROOF,
  BLOCKCHAINPROOF_LOADING,
  INGREDIENTS_IDS,
  PRODUCT_CURRENT_TAB,
  VERIFY_DOCUMENT_LOADING,
  VERIFY_DOCUMENT_SUCCESS,
  VERIFY_COA_DOCUMENT_LOADING,
  VERIFY_COA_DOCUMENT_SUCCESS,
  UPDATE_MAPDATA_INTEGRANT_ID,
  GET_INTEGRANT_BATCH_LIST_START,
  GET_INTEGRANT_BATCH_LIST_SUCCESS,
  GET_ORGANIZATION_DETAIL_START,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
  GET_EXHIBIT_BLOCKCHAIN_PROOF_START,
  GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START,
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
  UPDATE_VERIFY_DOCUMENT,
  SET_INITIAL_BLOCKCHAIN_DATA,
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
} = actionType;

const updateCoaData = (data, payload, i) => {
  return {
    ...data,
    govEntity: (data?.govEntity || [])?.map((org) => {
      if (payload.activeOrgDocuments[i] === org?.id) {
        if (payload.activeOrgDocuments?.length - 1 === i) {
          return {
            ...org,
            documentInfo: {
              ...(org?.documentInfo || {}),
              ...payload,
            },
          };
        } else {
          return {
            ...org,
            documentInfo: updateCoaData(
              org?.documentInfo || {},
              payload,
              i + 1
            ),
          };
        }
      }
      return org;
    }),
  };
};

const productReducers = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCT_OTHER_BATCHES: {
      return {
        ...state,
        otherBatchList: {
          ...state.otherBatchList,
          isLoading: !state.otherBatchList.isLoading,
          ...payload,
        },
      };
    }
    case UPDATE_VERIFY_DOCUMENT: {
      return {
        ...state,
        verifyDocumentData: {
          ...state.verifyDocumentData,
          ...payload,
        },
      };
    }
    case SET_INITIAL_BLOCKCHAIN_DATA: {
      return {
        ...state,
        verifyDocumentData: defaultState.verifyDocumentData,
        exhibitBlockchainProof: defaultState.exhibitBlockchainProof,
        orgExhibitBlockchainProof: defaultState.orgExhibitBlockchainProof,
        documentHashBlockchainProof: defaultState.documentHashBlockchainProof,
        labDocumentHashBlockchainProof:
          defaultState.labDocumentHashBlockchainProof,
      };
    }
    case GET_EXHIBIT_BLOCKCHAIN_PROOF_START: {
      return {
        ...state,
        exhibitBlockchainProof: {
          ...state.exhibitBlockchainProof,
          isLoading: true,
        },
      };
    }
    case GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS: {
      return {
        ...state,
        exhibitBlockchainProof: {
          ...state.exhibitBlockchainProof,
          isLoading: false,
          ...payload,
        },
      };
    }
    case GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START: {
      return {
        ...state,
        orgExhibitBlockchainProof: {
          ...state.orgExhibitBlockchainProof,
          isLoading: true,
        },
      };
    }
    case GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS: {
      return {
        ...state,
        orgExhibitBlockchainProof: {
          ...state.orgExhibitBlockchainProof,
          isLoading: false,
          ...payload,
        },
      };
    }
    case GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START: {
      return {
        ...state,
        documentHashBlockchainProof: {
          ...state.documentHashBlockchainProof,
          isLoading: true,
        },
      };
    }
    case GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS: {
      return {
        ...state,
        documentHashBlockchainProof: {
          ...state.documentHashBlockchainProof,
          isLoading: false,
          ...payload,
        },
      };
    }
    case GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START: {
      return {
        ...state,
        labDocumentHashBlockchainProof: {
          ...state.labDocumentHashBlockchainProof,
          isLoading: true,
        },
      };
    }
    case GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS: {
      return {
        ...state,
        labDocumentHashBlockchainProof: {
          ...state.labDocumentHashBlockchainProof,
          isLoading: false,
          ...payload,
        },
      };
    }
    case GET_ORGANIZATION_DETAIL_START: {
      return {
        ...state,
        organizationDetail: {
          ...defaultState.organizationDetail,
          loading: true,
        },
      };
    }
    case GET_ORGANIZATION_DETAIL_SUCCESS: {
      return {
        ...state,
        organizationDetail: {
          ...state.organizationDetail,
          loading: false,
          ...payload,
        },
      };
    }
    case GET_PRODUCT_LIST_FOR_MENTIONS: {
      try {
        return {
          ...state,
          mentionsProductList: {
            ...state.mentionsProductList,
            ...payload,
            loading: !state.mentionsProductList.loading,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_LIST: {
      try {
        return {
          ...state,
          integrantsList: [...payload],
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case PRODUCT_CURRENT_TAB: {
      try {
        return {
          ...state,
          productCurrentTab: payload,
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_DETAIL: {
      let data = { ...payload };
      if (payload?.sourceLinks?.length && !verifyEnvironment("live")) {
        data["sourceLinks"] = removeDuplicateIngredients(payload?.sourceLinks);
      }
      try {
        return {
          ...state,
          integrantsDetail: data,
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_ERROR: {
      try {
        return { ...state, isErrorInAPI: { ...payload } };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_LIST_IS_LOADING: {
      try {
        return { ...state, integrantsListIsLoading: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_EXHIBITS_DETAIL: {
      try {
        let mapData = { ...state.mapData };
        const integrantsDetail = { ...state.integrantsDetail };
        let data = [];
        let mapPointId = 0;
        let centerCoords = [];
        if (payload?.length > 0) {
          payload.forEach((item) => {
            const {
              history,
              organization_id,
              organization_name,
              organization_logo_url,
              integrant_id,
            } = item;
            let isMainProduct =
              integrantsDetail?.integrantInfo?.id === integrant_id;
            history &&
              history.forEach((subItem) => {
                const { metadata, type, text } = subItem;
                let isMainProductLocation = isMainProduct && type === "batch";
                // let hideTransit =
                //   isMainProduct &&
                //   type === "transit" &&
                //   metadata?.fromLocation?.id ===
                //     integrantsDetail?.integrantInfo?.location_id;
                if (typeof metadata === "object") {
                  const { location, fromLocation, toLocation } = metadata;
                  if (location) {
                    const { coordinates } = location;
                    data.push({
                      id: mapPointId,
                      origin: JSON.parse(coordinates)
                        .reverse()
                        ?.map((item) => parseFloat(item)),
                      type,
                      title: text,
                      data: subItem,
                      organizationData: {
                        organization_id,
                        organization_name:
                          organization_id ===
                            "7c9b7838-1a17-4421-a29b-2e3675315b5c" &&
                          type === "exhibit"
                            ? "Intertek"
                            : organization_name,
                        organization_logo_url,
                      },
                      integrant_id,
                      isMainProductLocation,
                    });
                    if (isMainProductLocation) {
                      mapData["centerCoords"] = JSON.parse(coordinates)
                        .reverse()
                        ?.map((item) => parseFloat(item));
                    }
                    mapPointId++;
                  }
                  if (fromLocation) {
                    const { coordinates } = fromLocation;
                    data.push([
                      {
                        id: mapPointId,
                        origin: JSON.parse(coordinates)
                          .reverse()
                          ?.map((item) => parseFloat(item)),
                        type,
                        title: text,
                        data: subItem,
                        organizationData: {
                          organization_id,
                          organization_name,
                          organization_logo_url,
                        },
                        integrant_id,
                        isMainProductLocation,
                        isFromLocation: true,
                      },
                    ]);
                    mapPointId++;
                  }
                  if (toLocation) {
                    const { coordinates } = toLocation;
                    data[data?.length - 1].push({
                      id: mapPointId,
                      origin: JSON.parse(coordinates)
                        .reverse()
                        ?.map((item) => parseFloat(item)),
                      type,
                      title: text,
                      data: subItem,
                      organizationData: {
                        organization_id,
                        organization_name,
                        organization_logo_url,
                      },
                      integrant_id,
                      isMainProductLocation,
                    });
                    mapPointId++;
                  }
                }
              });
          });
        }
        mapData["data"] = data;
        return { ...state, exhibitsList: payload, mapData };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case UPDATE_MAPDATA_INTEGRANT_ID: {
      try {
        return {
          ...state,
          mapData: {
            ...state.mapData,
            integrant_ids: payload.ids,
            location: payload.location,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case INTEGRANTS_BATCHES_LIST: {
      try {
        return { ...state, integrantsBatchesList: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case PRODUCTLIST_LOADING: {
      try {
        return {
          ...state,
          productListData: {
            ...state.productListData,
            loading: true,
            filterLoading: payload,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case PRODUCTLIST: {
      try {
        return {
          ...state,
          productListData: {
            ...state.productListData,
            ...payload,
            loading: false,
            filterLoading: [],
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case BATCHLIST: {
      try {
        return { ...state, batchList: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case ORGANIZATIONLIST: {
      try {
        return { ...state, organizationList: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case ORGANIZATIONLISTLOADING: {
      try {
        return { ...state, organizationListLoading: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case GET_ALL_INTEGRANT_TYPES_LIST_START: {
      try {
        return {
          ...state,
          getAllintegrantTypesList: {
            ...state.getAllintegrantTypesList,
            loading: true,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply loading false for action ${type}`);
      }
      break;
    }
    case GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS: {
      try {
        return {
          ...state,
          getAllintegrantTypesList: {
            ...payload,
            data: payload.data ? payload.data : [],
            loading: false,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case GET_INTEGRANT_BATCH_LIST_START: {
      try {
        return {
          ...state,
          getIntegrantBatchList: {
            ...state.getIntegrantBatchList,
            loading: true,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply loading false for action ${type}`);
      }
      break;
    }
    case GET_INTEGRANT_BATCH_LIST_SUCCESS: {
      try {
        return {
          ...state,
          getIntegrantBatchList: {
            ...payload,
            data: payload.data ? payload.data : [],
            loading: false,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case GET_TRAITS_LIST_START: {
      return {
        ...state,
        traitsList: {
          ...state.traitsList,
          loading: true,
        },
      };
    }
    case GET_TRAITS_LIST_SUCCESS: {
      return {
        ...state,
        traitsList: {
          ...state.traitsList,
          loading: false,
          ...payload,
        },
      };
    }
    case BLOCKCHAINPROOF: {
      try {
        return { ...state, blockchainProof: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case BLOCKCHAINPROOF_LOADING: {
      try {
        return { ...state, blockchainProof_loading: payload };
      } catch (e) {
        consoleLog(`Failed to apply loading false for action ${type}`);
      }
      break;
    }
    case INGREDIENTS_IDS: {
      try {
        return { ...state, ingredientsIds: payload };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case VERIFY_DOCUMENT_LOADING: {
      try {
        return {
          ...state,
          verifyDocumentData: { data: null, loading: payload },
        };
      } catch (e) {
        consoleLog(`Failed to apply loading true for action ${type}`);
      }
      break;
    }
    case VERIFY_DOCUMENT_SUCCESS: {
      try {
        return {
          ...state,
          verifyDocumentData: {
            data: payload,
            loading: false,
            ...payload,
            message:
              !state.verifyDocumentData.loading === false
                ? payload?.isVerifyDocument
                  ? "Verified match on the blockchain."
                  : "Not a verified match on the blockchain."
                : null,
            blockchainProofApiFlag:
              !state.verifyDocumentData.loading === false &&
              payload?.isVerifyDocument
                ? true
                : false,
          },
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    case VERIFY_COA_DOCUMENT_LOADING: {
      try {
        return {
          ...state,
          activeOrgDocuments: payload?.activeOrgDocuments || [],
          verifyCoaDocumentData: !payload?.activeOrgDocuments?.length
            ? {
                loading: payload?.loading,
              }
            : updateCoaData(state.verifyCoaDocumentData, payload, 0),
        };
      } catch (e) {
        consoleLog(`Failed to apply loading true for action ${type}`);
      }
      break;
    }
    case VERIFY_COA_DOCUMENT_SUCCESS: {
      try {
        let verifyCoaDocumentData = payload;
        if (payload?.isOrganizationDoc) {
          verifyCoaDocumentData = updateCoaData(
            state.verifyCoaDocumentData,
            payload,
            0
          );
          // if (!verifyCoaDocumentData.is_expired) {
          //   verifyCoaDocumentData.is_expired = (
          //     verifyCoaDocumentData?.govEntity || []
          //   ).reduce((result, item) => {
          //     const fetchResult = (data, result1) => {
          //       result1 = data?.documentInfo?.is_expired || result1;
          //       if (!result1 && data?.documentInfo?.govEntity?.length) {
          //         return (data?.documentInfo?.govEntity || []).reduce(
          //           (r, a) => fetchResult(a, r),
          //           result1
          //         );
          //       }
          //       return result1;
          //     };
          //     return fetchResult(item, result);
          //   }, verifyCoaDocumentData.is_expired);
          // }
        }
        return {
          ...state,
          activeOrgDocuments: payload?.activeOrgDocuments || [],
          verifyCoaDocumentData,
        };
      } catch (e) {
        consoleLog(`Failed to apply payload ${payload} for action ${type}`);
      }
      break;
    }
    default:
      return state;
  }
};

export default productReducers;
