import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { ExpandMore } from "@material-ui/icons";

export default function MuiAccordion({ data, expanded, onClick }) {
  return (
    <Accordion className="accordion-root">
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={clsx("accordion-summary", expanded && "accordion-expanded")}
        onClick={onClick}
      >
        <Typography variant="body2" className="notranslate">
          {data?.integrant?.title}
        </Typography>
        <Typography variant="body2">
          {moment(data?.created_on).format("DD MMMM, YYYY hh:mm A")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Grid>
          <Typography variant="body2">
            <span>Batch Id:</span>
            {data?.integrant?.external_id}
          </Typography>
          {data?.offer && (
            <Typography variant="body2">
              <span>Offer:</span>
              {data?.offer}
            </Typography>
          )}
        </Grid>
        <Grid className="surveyQAns">
          {data?.questionAnswers?.map((item, key) => {
            return (
              <Grid key={key}>
                <Typography variant="body2">Q: {item?.question}</Typography>
                <Typography variant="body2">
                  <span>Ans:</span>
                  {item?.answer}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
