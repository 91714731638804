import { PageWrapper } from "../../components/Wrappers";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import Authenticator from "../Authenticator";

export default function VerifyADocument() {
  return (
    <PageWrapper pageName="document-verification">
      <Authenticator />
      <AsSeenInSection />
    </PageWrapper>
  );
}
