import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid, InputLabel } from "@material-ui/core";
import {
  MainHeading,
  Paragraph,
  MuiTextfield,
  Button,
  MuiSelect,
  SmProfileFooter,
} from "../../components/Profile";
import {
  getSurveyQuestions,
  submitSurveyAnswers,
} from "../../redux/actions/profileActions";
import { dispatchGetIntegrantBatchList } from "../../redux/actions/product";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import utils from "../../utils/commonUtils";
import { useParams } from "react-router-dom";
import {
  BadReviewIcon,
  OkReviewIcon,
  GoodReviewIcon,
  GreatReviewIcon,
} from "../../constants/svgIcons";
import clsx from "clsx";

let submitSurveyFlag = false;
let getsurveyquestionsFlag = false;

export const Survey = (props) => {
  const {
    match,
    getSurveyQuestions,
    surveyQuestions,
    surveyAnswersData,
    submitSurveyAnswers,
    history,
    dispatchGetIntegrantBatchList,
    getIntegrantBatchList,
    activePage,
  } = props;
  const { token } = useParams();
  const { auth_token, otpVerified } = utils.getLocalStorageItems();
  if (token && !(auth_token && otpVerified)) {
    utils.addItemsIntoLocalStorage({ survey_token: token }, false);
  }
  const [integrant_id, setIntegrant_id] = useState("");
  const [survey_answer, setSurvey_answer] = useState([]);
  const handleChange = (question_id, value) => {
    setSurvey_answer((pre) =>
      pre.map((item) =>
        item?.id === question_id
          ? { ...item, answer: value, error: false }
          : item
      )
    );
  };

  const handleSubmit = () => {
    let isValid = true;
    let arr = [...survey_answer];
    for (let i in arr) {
      if (!arr[i].answer) {
        isValid = false;
        arr[i]["error"] = true;
      }
    }
    if (isValid) {
      const { user_id, selectedIntegrantTypeId, integrant_id } =
        utils.getLocalStorageItems();
      submitSurveyAnswers({
        login_user_id: user_id,
        organization_id: arr[0].organization_id,
        integrant_type_id: selectedIntegrantTypeId,
        integrant_id,
        offer: surveyQuestions?.offer?.offer,
        survey_answer: survey_answer?.map((item) => {
          let answer = Math.floor(parseInt(item.answer) / 10);
          return {
            question_id: item?.id,
            answer:
              item.input_type === "Score"
                ? answer > 0
                  ? answer
                  : 1
                : item?.answer,
          };
        }),
      });
      submitSurveyFlag = true;
    } else {
      setSurvey_answer(arr);
    }
  };

  const handleIntegrantChange = (id) => {
    setIntegrant_id(id);
    utils.addItemsIntoSessionStorage({ integrant_id: id });
  };

  const handleSliderChange = (id, key) => (e) => {
    let value = e.target.value;
    handleChange(id, value);
    let range = document.querySelector(".range" + key);
    let gradientStop = document.querySelector("#gradient-stop" + key);
    let gradientGrey = document.querySelector("#gradient-grey" + key);
    gradientGrey.setAttribute("offset", 100 - value + "%");

    if (value >= 0 && value <= 20) {
      range.closest(".survey-score-div").classList.add("bad");
      range
        .closest(".survey-score-div")
        .classList.remove("ok", "great", "good");
      gradientStop.setAttribute("stop-color", "#ff5722");
    }
    if (value > 20 && value <= 50) {
      range.closest(".survey-score-div").classList.add("ok");
      range
        .closest(".survey-score-div")
        .classList.remove("bad", "great", "good");
      gradientStop.setAttribute("stop-color", "#ff9800");
    }
    if (value > 50 && value <= 80) {
      range.closest(".survey-score-div").classList.add("good");
      range.closest(".survey-score-div").classList.remove("ok", "great", "bad");
      gradientStop.setAttribute("stop-color", "#36d896");
    }
    if (value > 80 && value <= 100) {
      range.closest(".survey-score-div").classList.add("great");
      range.closest(".survey-score-div").classList.remove("ok", "bad", "good");
      gradientStop.setAttribute("stop-color", "#3f51b5");
    }
  };

  useEffect(() => {
    if (surveyAnswersData.status === "1" && submitSurveyFlag) {
      setSurvey_answer([]);
      submitSurveyFlag = false;
      utils.removeItemsFromSessionStorage([
        "organization_id",
        "selectedIntegrantTypeId",
        "integrant_id",
      ]);
      history.push("/profile");
    }
  }, [surveyAnswersData]);

  useEffect(() => {
    if (surveyQuestions?.status === "1" && getsurveyquestionsFlag) {
      setSurvey_answer(
        surveyQuestions?.data?.map((item) => {
          return {
            ...item,
            answer: "",
            options:
              item?.input_type === "Selection" && item?.options
                ? JSON.parse(item?.options)
                : item?.input_type === "Score"
                ? [...Array(10)].map((el, i) => i + 1)
                : [],
            error: false,
          };
        })
      );
      getsurveyquestionsFlag = false;
    }
  }, [surveyQuestions]);

  useEffect(() => {
    const storeDataFromToken = async () => {
      const tokenPayload = await utils.decodeJWTToken(token);
      utils.addItemsIntoSessionStorage({
        selectedIntegrantTypeId: tokenPayload.integrant_type_id,
        organization_id: tokenPayload.organization_id,
      });
      dispatchGetIntegrantBatchList({
        integrant_type_id: tokenPayload.integrant_type_id,
      });
      getSurveyQuestions({ integrant_type_id: tokenPayload.integrant_type_id });
      getsurveyquestionsFlag = true;
    };
    if (token) {
      storeDataFromToken();
    } else {
      getSurveyQuestions();
      getsurveyquestionsFlag = true;
    }
  }, []);

  return (
    <Grid className="survey-root">
      <div>
        <MainHeading>Survey example</MainHeading>
        {surveyQuestions.loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ padding: "20px 0px", flex: 1 }}
          >
            <MuiCircularProgress />
          </Grid>
        ) : (
          <>
            {surveyQuestions?.offer?.offer && (
              <Paragraph>{surveyQuestions?.offer?.offer}</Paragraph>
            )}
            {token && (
              <Grid className="batch-dropdown">
                <MuiSelect
                  match={match}
                  placeholder="Select batch"
                  menuItems={getIntegrantBatchList?.data?.map((item) => {
                    return { value: item?.id, label: item?.external_id };
                  })}
                  loading={getIntegrantBatchList?.loading}
                  onChange={(e) => handleIntegrantChange(e.target.value)}
                  value={integrant_id}
                />
              </Grid>
            )}
            {(token && integrant_id) || !token ? (
              <Grid className="survey-form-div">
                {survey_answer?.map((item, key) => {
                  if (item?.input_type === "Text") {
                    return (
                      <MuiTextfield
                        match={match}
                        key={key}
                        placeholder={item?.question}
                        value={item.answer}
                        onChange={(e) => handleChange(item.id, e.target.value)}
                        error={item?.error}
                      />
                    );
                  } else if (item?.input_type !== "Score") {
                    return (
                      <MuiSelect
                        key={key}
                        match={match}
                        placeholder={item?.question}
                        value={item.answer}
                        onChange={(e) => handleChange(item.id, e.target.value)}
                        error={item?.error}
                        menuItems={item?.options}
                      />
                    );
                  } else {
                    const answer = Math.floor(parseInt(item.answer) / 10);
                    return (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className="survey-score-container"
                      >
                        <InputLabel error={item.error}>
                          {item?.question}
                        </InputLabel>
                        <Box className="survey-score-div">
                          <Box>
                            {parseInt(item.answer || 0) <= 20 && (
                              <BadReviewIcon className={"bad-review-icon"} />
                            )}
                            {parseInt(item.answer) > 20 &&
                              parseInt(item.answer) <= 50 && (
                                <OkReviewIcon className={"ok-review-icon"} />
                              )}
                            {parseInt(item.answer) > 50 &&
                              parseInt(item.answer) <= 80 && (
                                <GoodReviewIcon
                                  className={"good-review-icon"}
                                />
                              )}
                            {parseInt(item.answer) > 80 &&
                              parseInt(item.answer) <= 100 && (
                                <GreatReviewIcon
                                  className={"great-review-icon"}
                                />
                              )}
                          </Box>
                          <Box className={clsx("range", "range" + key)}>
                            <svg
                              width="360"
                              height="15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <defs>
                                <linearGradient
                                  id={"linearGradient" + key}
                                  x1="1"
                                  y1="0"
                                  x2="0"
                                  y2="0"
                                >
                                  <stop
                                    id={"gradient-grey" + key}
                                    offset="0%"
                                    stop-color="#EAEEF4"
                                  />
                                  <stop
                                    id={"gradient-stop" + key}
                                    offset="0%"
                                    stop-color="#EAEEF4"
                                  />
                                </linearGradient>
                              </defs>
                              <path
                                d="M0 7.65377C0 6.22069 1.1207 5.01982 2.5 5L177.5 2.5L352.776 0.000723075C356.75 -0.0563631 360 3.27402 360 7.40212C360 11.5262 356.756 14.855 352.786 14.8037L177.5 13L2.5 10.5C1.11931 10.4821 0 9.08826 0 7.65377Z"
                                fill={`url(#linearGradient${key})`}
                              />
                            </svg>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              value={item.answer || "0"}
                              class="slider"
                              onChange={handleSliderChange(item.id, key)}
                            />
                          </Box>
                          {item.answer && (
                            <Box>{`(${answer > 0 ? answer : 1}/10)`}</Box>
                          )}
                        </Box>
                      </Box>
                    );
                  }
                })}
              </Grid>
            ) : null}
          </>
        )}
        {!surveyQuestions.loading && ((token && integrant_id) || !token) && (
          <Grid container className="survey-footer" alignItems="center">
            <Button
              profileBgBtn
              loading={surveyAnswersData?.loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        )}
      </div>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  ProfileReducers: { surveyQuestions, surveyAnswersData },
  productReducers: { getIntegrantBatchList },
}) => ({
  surveyQuestions,
  surveyAnswersData,
  getIntegrantBatchList,
});

const mapDispatchToProps = {
  getSurveyQuestions,
  submitSurveyAnswers,
  dispatchGetIntegrantBatchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
