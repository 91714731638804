/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Link, useParams } from "react-router-dom"

const AttachmentsIntegrantTypeListPage = ({ list }) => {
  const urlParams = useParams()
  if (list) {
    return list.map((item) => {
      const { id, updated_on, title, attachments, url, description } = item
      return (
          <div key={id} className="attachments-list">
              <div className="attachments-date">{moment(updated_on).format("l")}</div>
              <div className="attachments-status">
                  {url ? <a href={url} target="_blank" rel="noreferrer" className="link-doc-title">{title}</a> : title}
                  <br />
                  {description}
              </div>
              {attachments
            && attachments.map(subItem => {
              if (subItem.id) {
                const { integrantsType: { image_url } } = subItem
                const { short_code, external_id } = urlParams
                return (
                    <div>
                          <Link to={`/p/${short_code}/b/${external_id}/${subItem.id}`} className="d-flex link-sub-attachments align-items-center">
                              <div className="icon-attachments">
                                  {image_url ? <img src={image_url} alt="" /> : subItem.title && subItem.title.charAt(0)}
                              </div>
                              {subItem.title}
                          </Link>
                    </div>
)
              }
              return null
              // return (
              //     <a href={subItem.url} className="attachments-list-link" target="_blank" download rel="noreferrer">
              //         <div className="icon-folder">
              //             <i className="material-icons md-18">folder</i>
              //         </div>
              //         {`${subItem.title} ${index + 1}`}
              //     </a>
              // )
            })}
          </div>
      )
    })
  }
  return null
}
AttachmentsIntegrantTypeListPage.propTypes = {
  list: PropTypes.instanceOf(Array)
}
AttachmentsIntegrantTypeListPage.defaultProps = {
  list: []
}

export default AttachmentsIntegrantTypeListPage
