import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { Body } from "../../components/Common";
import { makeStyles, Skeleton, Box } from "../../components";
import OrganizationBasicDetail from "../../components/organization/OrganizationBasicDetail";
import { connect } from "react-redux";
import { getOrganizationDetail } from "../../redux/actions/product";
import { useParams } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  orgDetailContainer: {
    backgroundColor: theme.palette.common.white,
    "& .bodyMaxWidth": {
      display: "flex",
      flexDirection: "column",
    },
  },
  orgBgImg: {
    "&>div": {
      width: "100%",
      height: "100%",
      minHeight: 300,
      maxHeight: 300,
      backgroundColor: "#DDDDDD",
      marginBottom: 35,
      "&>img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
}));

export function OrganizationDetail({
  getOrganizationDetail,
  organizationDetail,
}) {
  const classes = useStyle();
  const params = useParams();
  useEffect(() => {
    getOrganizationDetail(params?.id);
  }, []);
  return (
    <Body
      seoTitle={organizationDetail?.data?.name}
      description={organizationDetail?.data?.description}
    >
      <Grid container className={classes.orgDetailContainer}>
        <Grid item xs={12} className={classes.orgBgImg}>
          <Box>
            {organizationDetail?.loading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                width={"100%"}
                height={300}
              />
            ) : (
              <img src={organizationDetail?.data?.bg_image} alt="" />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div className="bodyMaxWidth">
            <OrganizationBasicDetail />
          </div>
        </Grid>
      </Grid>
    </Body>
  );
}

const mapStateToProps = ({ productReducers: { organizationDetail } }) => ({
  organizationDetail,
});

const mapDispatchToProps = { getOrganizationDetail };

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetail);
