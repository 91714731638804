import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";

export function MainHeading({ children, className = "", ...props }) {
  return (
    <Typography
      variant="h1"
      className={clsx("main-heading", className)}
      {...props}
    >
      {children}
    </Typography>
  );
}

export function SubHeading({ children, className = "",align="center", ...props }) {
  return (
    <Typography
      variant="h4"
      className={clsx("sub-heading", className)}
      {...props}
      align={align}
    >
      {children}
    </Typography>
  );
}

export function Paragraph({ children, className = "", ...props }) {
  return (
    <Typography className={clsx("paragraph-root", className)} {...props}>
      {children}
    </Typography>
  );
}

export function Label({ children, className = "", ...props }) {
  return (
    <Typography className={clsx("label-root", className)} {...props}>
      {children}
    </Typography>
  );
}

export function LinkTypography({ children, to, className = "", ...props }) {
  return (
    <Link to={to} className={clsx("link-root", className)} {...props}>
      {children}
    </Link>
  );
}
