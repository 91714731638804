import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import App from "./containers/App/App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import apiConstants from "./services/constants";
import { ThemeProvider } from "./components";
import { theme } from "./theme/theme";
const { trackingID } = apiConstants;
ReactGA.initialize(trackingID);
ReactGA4.initialize("G-KYP6KHE3DE");
const customHistory = createBrowserHistory();
customHistory.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA4.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  ReactGA4.send({ hitType: "pageview", page: location.pathname });
});
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme()}>
          <Router history={customHistory}>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
