import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Avatar, Tooltip } from "@material-ui/core";
import {
  MainHeading,
  MuiTextfield,
  MuiSelect,
  Button,
  MuiLinearProgress,
  Paragraph,
  CategoryBox,
  PublicPrivateSwitch,
  SubHeading,
  SmProfileFooter,
} from "../../components/Profile";
import {
  updateUser,
  getUserDetails,
  getCategories,
  updateProfilePicture,
} from "../../redux/actions/profileActions";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import UserDeleteDialog from "../../components/Dialogs/UserDeleteDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "../../components";
import { CloseRounded, CloudUploadRounded } from "@material-ui/icons";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
let updateUserFlag = false;

export const ProfileDetail = (props) => {
  const {
    userData,
    match,
    updateUserData,
    updateUser,
    getUserDetails,
    profileProgress,
    profileData,
    setProfileData,
    getCategories,
    categoriesList,
    updateProfilePictureData,
    updateProfilePicture,
    history,
    activePage,
  } = props;
  let loginUserId = userData?.data?.id;
  const [selectedCat, setSelectedCat] = useState([]);
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [pracBio, setPracBio] = useState("");
  const [pracLink, setPracLink] = useState("");
  const [pracCredentials, setPracCredentials] = useState(null);
  const [pracPictuure, setPracPictuure] = useState(null);
  const [pracDeletedFilesData, setPracDeletedFilesData] = useState({
    credentials: "",
    pictuure: "",
  });

  const handleCategoryChange = (id) => {
    if (selectedCat.includes(id)) {
      setSelectedCat((pre) => pre.filter((item) => item !== id));
    } else {
      setSelectedCat((pre) => [...pre, id]);
    }
  };
  const handleProfilePictureUpdate = (e) => {
    if (e?.target?.files?.length > 0) {
      const formData = new FormData();
      formData.append("id", userData?.data?.id);
      formData.append("profile_picture", e.target.files[0]);
      updateProfilePicture(formData);
    }
  };
  const handleProfileDataValidation = (key, value) => {
    switch (key) {
      case "phone":
        return value?.replace(/[^\d\+\-\(\)]/g, "");
      case "weight":
        return value?.replace(/\D/g, "");
      default:
        return value;
    }
  };
  const handleChange = (key, value) => {
    if (key === "practitioner") {
      setIsPractitioner(value === "Yes" ? true : false);
    }
    setProfileData((pre) =>
      pre?.map((item) => {
        return item?.key === key
          ? {
              ...item,
              value: handleProfileDataValidation(key, value),
              error: false,
            }
          : item;
      })
    );
  };
  const handleVisibilityChange = (key) => {
    setProfileData((pre) =>
      pre?.map((item) => {
        return item?.key === key
          ? { ...item, isPublic: !item?.isPublic }
          : item;
      })
    );
  };
  const verifyEditProfileForm = (arr) => {
    let isValid = true;
    let profileDataArr = arr?.map((item) => {
      if (item?.value || (!item?.required && !item?.value)) {
        if (item?.key === "phone" && item?.value?.length < 10) {
          isValid = false;
          return {
            ...item,
            error: "Phone number at least 10 digits long.",
          };
        } else {
          return item;
        }
      } else {
        isValid = false;
        return {
          ...item,
          error: `${item?.placeholder} is the required field.`,
        };
      }
    });
    setProfileData(profileDataArr);
    return isValid;
  };
  const handleSubmit = () => {
    if (verifyEditProfileForm(profileData)) {
      let formData = new FormData();
      // let formDataObj = {};
      let obj = {
        data_visibility: {},
      };
      for (let item of profileData) {
        // formDataObj[item?.key] = item?.value;
        formData.append(item?.key, item?.value);
        obj["data_visibility"] = {
          ...obj?.data_visibility,
          [item?.key]: item?.isPublic,
        };
      }
      // console.log("formDataObj------------------------->", formDataObj);
      // console.log("profileData------------------------->", profileData);
      formData.append("data_visibility", JSON.stringify(obj?.data_visibility));
      formData.append("category_ids", JSON.stringify(selectedCat));
      formData.append("user_id", loginUserId);

      // formDataObj["data_visibility"] = JSON.stringify(obj?.data_visibility);
      // formDataObj["category_ids"] = JSON.stringify(selectedCat);
      // formDataObj["user_id"] = loginUserId;
      if (isPractitioner) {
        pracBio && formData.append("practitioner_bio", pracBio);
        pracLink && formData.append("practitioner_link", pracLink);
        // pracCredentials && formData.append("practitioner_credentials", pracCredentials);
        // pracPictuure && formData.append("practitioner_pictuure",pracPictuure);

        if (pracPictuure && pracPictuure?.name) {
          pracPictuure.file_name = "practitioner_credentials";
        }
        // if(pracCredentials && pracCredentials?.name){
        //   pracCredentials.file_name = 'practitioner_pictuure'
        // }

        pracCredentials &&
          typeof pracCredentials !== "string" &&
          formData.append("practitioner_credentials", pracCredentials);
        pracPictuure &&
          typeof pracPictuure !== "string" &&
          formData.append("practitioner_pictuure", pracPictuure);
        (pracDeletedFilesData.credentials || pracDeletedFilesData.pictuure) &&
          formData.append(
            "pracDeletedFilesData",
            JSON.stringify(pracDeletedFilesData)
          );
        // pracPictuure && formData.append('practitioner_pictuure', pracPictuure);
        // pracCredentials && formData.append('fileName', pracPictuure);

        // formDataObj["practitioner_bio"] = (pracBio) ? pracBio : null;
        // formDataObj["practitioner_link"] = (pracLink) ? pracLink : null;
        // formDataObj["practitioner_credentials"] = (pracCredentials) ? pracCredentials : null;
        // formDataObj["practitioner_pictuure"] = (pracPictuure) ? pracPictuure : null;
      }
      // updateUser(formDataObj);
      updateUser(formData);
      updateUserFlag = true;
    }
  };
  const handleMasterToggle = (e) => {
    setProfileData((pre) =>
      pre?.map((item) => {
        return { ...item, isPublic: e.target.checked };
      })
    );
  };
  const [openhandleDeleteDialog, setopenhandleDeleteDialog] = useState(false);

  useEffect(() => {
    if (updateUserData?.status === "1" && updateUserFlag) {
      updateUserFlag = false;
      getUserDetails();
    }
  }, [updateUserData]);
  useEffect(() => {
    if (userData?.status === "1") {
      setSelectedCat(
        userData?.data?.category_ids
          ? JSON.parse(userData?.data?.category_ids)
          : []
      );
      setIsPractitioner(userData?.data?.practitioner === "Yes" ? true : false);
      setPracBio(userData?.data?.practitioner_bio);
      setPracLink(userData?.data?.practitioner_link);
      setPracCredentials(null);
      setPracPictuure(null);
      setPracDeletedFilesData({
        credentials: "",
        pictuure: "",
      });
    }
  }, [userData]);
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <Grid className="profile-details-root">
      <Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          className="profile-detail-header"
        >
          <MainHeading>Consumer Profile</MainHeading>
          <Grid className="pd-avatar-div">
            <Tooltip arrow placement="bottom" title="Update profile picture">
              {updateProfilePictureData?.loading ||
              (!userData?.data && userData?.loading) ? (
                <Grid className="profile-picture-loading-div center">
                  <MuiCircularProgress />
                </Grid>
              ) : (
                <label
                  className={"user-profile-edit-label"}
                  htmlFor="profile-picture-input-file"
                >
                  <Avatar
                    variant="circular"
                    src={userData?.data?.profile_picture}
                  />
                  <span>
                    <EditRoundedIcon />
                  </span>
                </label>
              )}
            </Tooltip>
          </Grid>
        </Grid>
        <Grid className="category-section">
          <SubHeading align="left">
            Your data, your control, your rewards.
          </SubHeading>
          <Grid className="categories-root">
            {categoriesList?.loading && categoriesList?.data?.length === 0 ? (
              <Grid container justifyContent="center">
                <MuiCircularProgress />
              </Grid>
            ) : categoriesList?.data?.length < 0 ? (
              categoriesList?.data?.map((item, key) => {
                return (
                  <CategoryBox
                    key={key}
                    item={item}
                    handleChange={() => handleCategoryChange(item?.id)}
                    isSelected={selectedCat?.includes(item?.id)}
                  />
                );
              })
            ) : (
              <>
                <Paragraph>
                  You have full control over your own health and wellness
                  profile! Change how you share anytime you want.
                </Paragraph>
                <Paragraph>
                  The more information you enter and are willing to share with
                  companies, the more valuable your profile becomes. Companies
                  will find your profile and extend offers based on the
                  information you are willing to share.
                </Paragraph>
                <Paragraph style={{ marginTop: 20 }}>
                  {userData?.data?.producerConnectionCount === 0
                    ? "You are not sharing your data with any company"
                    : userData?.data?.producerConnectionCount === 1
                    ? "You are sharing your data with 1 company"
                    : `You are sharing your data with ${userData?.data?.producerConnectionCount} companies`}
                  .&nbsp;
                  <Link to="/connections" hyperlink={true}>
                    Click here
                  </Link>
                  &nbsp; to manage.
                </Paragraph>
              </>
            )}
          </Grid>
        </Grid>
        <Grid className="pd-form-div">
          <Grid container alignItems="center" justifyContent="flex-end">
            <PublicPrivateSwitch
              checked={
                profileData.length - 1 ===
                profileData.filter(
                  (item) => item.key !== "name" && item?.isPublic
                ).length
              }
              onChange={handleMasterToggle}
              isMasterToggle={true}
              id="temp"
            />
          </Grid>
          {profileData?.map((item, key) => {
            if (item?.inputType === "dropdown") {
              return (
                <MuiSelect
                  key={key}
                  value={item?.value}
                  onChange={(e) => handleChange(item?.key, e.target.value)}
                  menuItems={item?.options}
                  placeholder={item?.placeholder}
                  match={match}
                  required={item.required}
                  error={Boolean(item?.error)}
                  showLockIcon={["name"].includes(item?.key) ? false : true}
                  lockIconState={item?.isPublic}
                  handleLockIconClick={(e) => handleVisibilityChange(item?.key)}
                  switchId={`switch-${key}`}
                  helperText={item?.error || ""}
                />
              );
            }
            return (
              <MuiTextfield
                key={key}
                value={item?.value}
                onChange={(e) => handleChange(item?.key, e.target.value)}
                placeholder={item?.placeholder}
                match={match}
                required={item.required}
                error={Boolean(item?.error)}
                showLockIcon={["name"].includes(item?.key) ? false : true}
                lockIconState={item?.isPublic}
                handleLockIconClick={(e) => handleVisibilityChange(item?.key)}
                switchId={`switch-${key}`}
                helperText={item?.error || ""}
              />
            );
          })}
          <MuiTextfield
            value={pracBio}
            onChange={(e) => setPracBio(e.target.value)}
            placeholder={"Profile Bio"}
            match={match}
            showLockIcon={false}
            multiline
            minRows={4}
            maxRows={4}
          />

          {isPractitioner && (
            <>
              <MuiTextfield
                value={pracLink}
                onChange={(e) => setPracLink(e.target.value)}
                placeholder={"Practitioner Website Link"}
                match={match}
                showLockIcon={false}
              />
              <Grid
                container
                alignItems="center"
                className={"practitioner-files"}
              >
                <Grid item>
                  <label htmlFor="practitioner-credentials-file">
                    <CloudUploadRounded style={{ marginRight: 5 }} />
                    {pracCredentials
                      ? pracCredentials?.name
                      : "Upload Practitioner Credentials"}
                  </label>
                  {pracCredentials ||
                  (userData?.data?.practitioner_credentials &&
                    !pracDeletedFilesData.credentials) ? (
                    <CloseRounded
                      onClick={() => {
                        if (pracCredentials) setPracCredentials(null);
                        else
                          setPracDeletedFilesData((pre) => ({
                            ...pre,
                            credentials:
                              userData?.data?.practitioner_credentials,
                          }));
                      }}
                      style={{ margin: "0px 5px" }}
                    />
                  ) : null}
                </Grid>
                <Grid item>
                  {(pracCredentials &&
                    pracCredentials?.type?.split("/")[0] === "image") ||
                  (userData?.data?.practitioner_credentials &&
                    !pracDeletedFilesData.credentials &&
                    userData?.data?.practitioner_credentials
                      ?.split(".")
                      .pop() !== "pdf") ? (
                    <img
                      width={60}
                      height={60}
                      src={
                        Boolean(pracCredentials)
                          ? URL.createObjectURL(pracCredentials)
                          : userData?.data?.practitioner_credentials
                      }
                      alt="practitioner_credentials"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={"practitioner-files"}
              >
                <Grid item>
                  <label htmlFor="practitioner-pictuure-file">
                    <CloudUploadRounded style={{ marginRight: 5 }} />
                    {pracPictuure
                      ? pracPictuure?.name
                      : "Upload Practitioner Pictuure"}
                  </label>
                  {pracPictuure ||
                  (userData?.data?.practitioner_pictuure &&
                    !pracDeletedFilesData.pictuure) ? (
                    <CloseRounded
                      onClick={() => {
                        if (pracPictuure) setPracPictuure(null);
                        else
                          setPracDeletedFilesData((pre) => ({
                            ...pre,
                            pictuure: userData?.data?.practitioner_pictuure,
                          }));
                      }}
                      style={{ margin: "0px 5px" }}
                    />
                  ) : null}
                </Grid>
                <Grid item>
                  {pracPictuure ||
                  (userData?.data?.practitioner_pictuure &&
                    !pracDeletedFilesData.pictuure) ? (
                    <img
                      width={60}
                      height={60}
                      src={
                        Boolean(pracPictuure)
                          ? URL.createObjectURL(pracPictuure)
                          : userData?.data?.practitioner_pictuure
                      }
                      alt="practitioner_pictuure"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </>
          )}
          <div className="profile-hading-root">
            <Button
              profileBgBtn
              loading={updateUserData?.loading}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              profileBgBtn
              onClick={() => setopenhandleDeleteDialog(true)}
              className="delete-btn"
            >
              Delete Profile <DeleteIcon />
            </Button>
          </div>
        </Grid>
        <UserDeleteDialog
          open={openhandleDeleteDialog}
          handleClose={() => setopenhandleDeleteDialog(false)}
        />
        {/* <Grid className="pd-progress-div">
          <MuiLinearProgress value={profileProgress} />
          <Paragraph>Enrich your profile to unlock these offers </Paragraph>
        </Grid> */}
      </Grid>
      <input
        type={"file"}
        id="profile-picture-input-file"
        className="display-none"
        onChange={handleProfilePictureUpdate}
      />
      <input
        type={"file"}
        accept="image/*,application/pdf"
        id="practitioner-credentials-file"
        className="display-none"
        onChange={(e) => {
          e?.target?.files?.length > 0 &&
            setPracCredentials(e?.target?.files[0]);
        }}
        onClick={(e) => (e.target.value = null)}
      />
      <input
        onClick={(e) => (e.target.value = null)}
        type={"file"}
        accept="image/*"
        id="practitioner-pictuure-file"
        className="display-none"
        onChange={(e) =>
          e?.target?.files?.length > 0 && setPracPictuure(e?.target?.files[0])
        }
      />
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  ProfileReducers: { updateUserData, categoriesList, updateProfilePictureData },
}) => ({
  updateUserData,
  categoriesList,
  updateProfilePictureData,
});

const mapDispatchToProps = {
  updateUser,
  getUserDetails,
  getCategories,
  updateProfilePicture,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);
