export const profileLog = [
  {
    selector: '[profile-tour="offers"]',
    content:
      "See offers that are available to you (based on your profile information).  Search for  companies with current offers.",
  },
  {
    selector: '[profile-tour="connections"]',
    content:
      "Connect with companies you like and agree to share your profile information with them.  Only attributes you have marked as public will be shared.  You are in control and can revoke access at any time.",
  },
  {
    selector: '[profile-tour="feedback"]',
    content:
      "Leave feedback on the products you've purchased.  And this feedback is not just generic feedback, it's specific to the lot/batch of the product you purchased.  The companies may also have a survey, specific to that product or to their brand, and an incentive tied to that survey.",
  },
  {
    selector: '[profile-tour="profile"]',
    content:
      "This is a secure profile that you manage. You can connect and disconnect with brands as you like with the certainty that you control your own information and connections.",
  },
  {
    selector: '[profile-tour="invite_any_organization"]',
    content:
      "Invite companies you would like to see share product information on the HealthLOQ platform.",
  },
  {
    selector: '[profile-tour="invite_trending_organization"]',
    content:
      "Join other HealthLOQ community members encouraging trending companies to participate on HealthLOQ.",
  },
  {
    selector: '[profile-tour="share_on_social_media"]',
    content:
      "Share the HealthLOQ opportunity and connect with family, friends, and colleagues.",
  },
  {
    selector: '[profile-tour="history"]',
    content:
      "Access your full history of actions on the platform; feedback you've provided, offers received, surveys completed, and invitations extended.",
  },
];

export const productDetailLog = [
  {
    selector: '[product-detail-tour="review"]',
    content:
      "Leave feedback on the products you've purchased.  And this feedback is not just generic feedback, it's specific to the lot/batch of the product you purchased. The companies may also have a survey, specific to that product or to their brand, and an incentive tied to that survey.",
  },
  {
    selector: '[product-detail-tour="recycle"]',
    content:
      "In addition to leaving feedback to companies about your experience with their products, we also allow you to report your recycling efforts.",
  },
];
