/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useRef, useState, useMemo } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl, { Popup } from "!mapbox-gl";
import * as turf from "@turf/turf";
import Triangle from "../../constants/svg/triangle.svg";
// import Testing from "../../constants/svg/map-testing-icon.svg";
import Testing from "../../constants/svg/map-testing-icon.png";
import moment from "moment";
import { connect } from "react-redux";
import { dispatchUpdateMapDataIntegrantId } from "../../redux/actions/product";
import MapboxGLPointDrawer from "./MapboxGLPointDrawer";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_ACCESS_TOKEN;

const getColorByType = (type) => {
  switch (type) {
    case "batch":
      return "rgb(53, 162, 151)";
    case "exhibit":
      return "rgb(201, 95, 61)";
    case "transfer":
      return "rgb(176, 21, 96)";
    case "transit":
      return "rgb(115, 92, 124)";
    default:
      return "#000";
  }
};

const visiblePoints = ["batch", "transit", "transfer", "exhibit"];

function MapboxGL({
  mapData,
  startReplyAnimation = false,
  stopReplyAnimation = () => {},
  dispatchUpdateMapDataIntegrantId,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [openPointDrawerData, setOpenPointDrawerData] = useState({
    open: false,
    data: null,
  });

  const getImageData = (data) => {
    if (["batch", "transit"].includes(data?.type)) {
      const { integrantTypeThumb, integrantType } = data?.data?.metadata;
      return {
        image: integrantTypeThumb || integrantType,
        isThumbAvailable: Boolean(integrantTypeThumb),
      };
    } else if (data?.type === "transfer") {
      if (data?.isFromLocation) {
        const org_thumb_url =
          data?.data?.metadata?.fromOrganization?.org_thumb_url || null;
        const logo_url =
          data?.data?.metadata?.fromOrganization?.logo_url || null;
        return {
          image: org_thumb_url || logo_url,
          isThumbAvailable: Boolean(org_thumb_url),
        };
      } else {
        const org_thumb_url =
          data?.data?.metadata?.toOrganization?.org_thumb_url || null;
        const logo_url = data?.data?.metadata?.toOrganization?.logo_url || null;
        return {
          image: org_thumb_url || logo_url,
          isThumbAvailable: Boolean(org_thumb_url),
        };
      }
    } else if (data?.type === "exhibit") {
      let image = "";
      let isThumbAvailable = true;
      const { org_thumb_url, organization_logo_url } = data?.data;
      if (data?.data?.testingLab) {
        image =
          data?.data?.testingLab?.map_thumb_url ||
          data?.data?.testingLab?.logo_url;
        isThumbAvailable = Boolean(data?.data?.testingLab?.map_thumb_url);
      } else {
        image = org_thumb_url || organization_logo_url;
        isThumbAvailable = Boolean(org_thumb_url);
      }
      return {
        image,
        isThumbAvailable,
      };
    }
  };

  const handleSortAnimationData = (data, integrant_ids) => {
    if (integrant_ids?.length) {
      let newData = [];
      for (let item of data) {
        if (integrant_ids?.includes(item?.properties?.integrant_id)) {
          newData?.unshift(item);
        } else {
          newData?.push(item);
        }
      }
      return newData?.reverse();
    } else {
      return data;
    }
  };

  const mapBoxData = useMemo(() => {
    let points = [];
    let lines = [];
    let routes = [];
    let trianglePoints = [];
    const isDuplicatePoint = (data) => {
      for (let i = 0; i < points.length; i++) {
        const item = points[i];
        if (
          item?.geometry?.coordinates?.join(",") === data?.origin?.join(",")
        ) {
          if (data?.isMainProductLocation) {
            points.splice(i, 1);
            return false;
          } else if (
            data?.type === "batch" &&
            item?.properties?.type !== "batch"
          ) {
            points.splice(i, 1);
            return false;
          } else {
            return true;
          }
        }
      }
    };
    const getExhibitProductInfo = (data) => {
      return (
        mapData?.data?.filter(
          (item) =>
            item?.integrant_id === data?.integrant_id && item?.type === "batch"
        )[0] || null
      );
    };
    const isDuplicateLine = (data) => {
      return (
        routes?.filter(
          (item) => item?.geometry?.coordinates?.join(",") === data?.join(",")
        ).length > 0
      );
    };
    const getPointTitle = (data) => {
      return data?.type === "exhibit"
        ? data?.data?.testingLab
          ? data?.data?.testingLab?.name[0]?.toUpperCase() +
            data?.data?.testingLab?.name?.slice(1)
          : data?.organizationData?.organization_name[0]?.toUpperCase() +
            data?.organizationData?.organization_name?.slice(1)
        : data?.title[0]?.toUpperCase() + data?.title?.slice(1);
    };
    if (mapData.data?.length > 0) {
      mapData?.data?.forEach((item, index) => {
        if (item?.length) {
          item?.forEach((el, elIndex) => {
            if (visiblePoints.includes(el.type)) {
              if (!isDuplicatePoint(el)) {
                const imageData = getImageData(el);
                points.push({
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: el?.origin,
                  },
                  properties: {
                    ...el,
                    title: el?.title[0]?.toUpperCase() + el?.title?.slice(1),
                    image: imageData?.image,
                    imageName: el?.type + index + elIndex,
                    isThumbAvailable: imageData?.isThumbAvailable,
                  },
                });
              }
            }
            if (el?.isFromLocation) {
              trianglePoints.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [],
                },
                properties: el,
              });
              lines.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [el?.origin],
                },
                properties: {
                  title: el?.title[0]?.toUpperCase() + el?.title?.slice(1),
                  ...el,
                  showTriangle: false,
                  showHoverTriangle: false,
                },
              });
              routes.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [el?.origin, item[1]?.origin],
                },
                properties: el,
              });
            }
          });
        } else {
          if (visiblePoints.includes(item.type)) {
            if (!isDuplicatePoint(item)) {
              const imageData = getImageData(item);
              const title = getPointTitle(item);
              points.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: item?.origin,
                },
                properties: {
                  ...item,
                  title,
                  image: imageData?.image,
                  imageName: item?.type + index,
                  isThumbAvailable: imageData?.isThumbAvailable,
                },
              });
            }
            const exhibitProductInfo = getExhibitProductInfo(item);
            if (
              item?.type === "exhibit" &&
              exhibitProductInfo &&
              item?.origin?.join(",") !==
                exhibitProductInfo?.origin?.join(",") &&
              !isDuplicateLine([item?.origin, exhibitProductInfo?.origin])
            ) {
              trianglePoints.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [],
                },
                properties: item,
              });
              lines.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [item?.origin],
                },
                properties: {
                  title: item?.title[0]?.toUpperCase() + item?.title?.slice(1),
                  ...item,
                  showTriangle: false,
                  showHoverTriangle: false,
                },
              });
              routes.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [item?.origin, exhibitProductInfo?.origin],
                },
                properties: item,
              });
            }
          }
        }
      });
    }
    const popup = new Popup({
      closeButton: false,
      closeOnClick: false,
    });
    return {
      points,
      lines: handleSortAnimationData(lines, mapData?.integrant_ids),
      routes: handleSortAnimationData(routes, mapData?.integrant_ids),
      trianglePoints,
      popup,
    };
  }, [mapData, startReplyAnimation]);

  const setZoom = (zoom) => map.current.setZoom(zoom || 3);
  const stopReplyAnimationIfExist = () =>
    startReplyAnimation && stopReplyAnimation();
  const setCenter = (center) =>
    map.current.setCenter(center || mapData?.centerCoords);

  const handleResetSourceData = () => {
    const { lines } = mapBoxData;
    handleSourceDataChange("line-source", lines);
  };

  const setDefaultIntegrantIds = () => {
    if (mapData?.integrant_ids?.length > 0) {
      dispatchUpdateMapDataIntegrantId({ ids: [], location: "" });
    }
  };

  const handleOpenPointDrawer = (data) => {
    setOpenPointDrawerData({ open: true, data });
  };
  const handleClosePointDrawer = () => {
    setOpenPointDrawerData({ open: false, data: null });
  };

  const showUSmapAsdefault = () => {
    map.current.fitBounds(
      [
        [-125, 25],
        [-66, 49],
      ],
      {
        padding: 20,
      }
    );
  };

  const handleZoomOut = (coordsArr) => {
    for (let coords of coordsArr) {
      if (!map.current.getBounds().contains(coords)) {
        const bounds = map.current.getBounds();
        bounds.extend(coords);
        map.current.fitBounds(bounds, {
          padding: 20,
        });
      }
    }
  };

  const handleSourceDataChange = (source, data) => {
    map.current.getSource(source).setData({
      type: "FeatureCollection",
      features: data,
    });
  };

  const playAnimation = (index) => {
    let { routes, lines, points } = mapBoxData;
    let arc = [];
    const start = routes[index]?.geometry?.coordinates[0]?.map((item) =>
      parseFloat(item)
    );
    const end = routes[index]?.geometry?.coordinates[1]?.map((item) =>
      parseFloat(item)
    );
    // const distance = turf.distance(start, end, {
    //   units: "miles",
    // });
    let steps = 0;
    const steps_devide = 50;
    // if (distance < 5000) {
    //   steps = 50000;
    //   const lineDistance = turf.length(routes[index]);
    //   for (let i = 50; i < lineDistance + 50; i += lineDistance / steps) {
    //     const segment = turf.along(routes[index], i);
    //     arc.push(segment.geometry.coordinates);
    //   }
    // } else {
    steps = 500;
    //   const midpoint = turf.midpoint(start, end);
    //   const destination = turf.destination(midpoint, distance / 2, 10, {
    //     units: "miles",
    //   });

    // curvedLine gets rendered to the page
    const curvedLine = turf.bezierSpline(turf.lineString([start, end]));
    arc = curvedLine?.geometry?.coordinates;
    //}
    handleZoomOut(routes[index]?.geometry?.coordinates);
    routes[index].geometry.coordinates = arc;
    let counter = 0;
    function animate() {
      const start =
        routes[index].geometry.coordinates[
          counter > steps ? arc.length - 1 : counter
        ];
      const end =
        routes[index].geometry.coordinates[
          counter >= steps ? arc.length - 1 : counter + 1
        ];
      if (!start || !end) {
        if (index < lines?.length) {
          lines[index]["properties"]["showTriangle"] = true;
          handleSourceDataChange("line-source", lines);
          playAnimation(index + 1);
        }
        if (index >= lines?.length - 1) {
          stopReplyAnimationIfExist();
          setTimeout(() => {
            setCenter();
            setZoom();
          }, 1000);
        }
        return;
      }
      lines[index].geometry.coordinates.push([start[0], end[1]]);
      handleSourceDataChange("line-source", lines);
      if (counter < steps) {
        requestAnimationFrame(animate);
      } else {
        if (index < lines?.length) {
          lines[index]["properties"]["showTriangle"] = true;
          handleSourceDataChange("line-source", lines);
          playAnimation(index + 1);
        }
        if (index >= lines?.length - 1) {
          stopReplyAnimationIfExist();
          setTimeout(() => {
            setCenter();
            setZoom();
          }, 1000);
        }
      }
      counter = counter + steps / steps_devide;
    }
    function noAnimate() {
      for (let i = 0; i < steps; i = i + steps / steps_devide) {
        const start =
          routes[index].geometry.coordinates[i > steps ? arc.length - 1 : i];
        const end =
          routes[index].geometry.coordinates[
            i >= steps ? arc.length - 1 : i + 1
          ];
        if (start && end) {
          lines[index].geometry.coordinates.push([start[0], end[1]]);
        }
      }
      handleSourceDataChange("line-source", lines);
      if (index < lines?.length) {
        lines[index].properties.showTriangle = true;
        handleSourceDataChange("line-source", lines);
        playAnimation(index + 1);
      }
      if (index >= lines?.length - 1) {
        setZoom();
        setCenter();
        setDefaultIntegrantIds();
      }
    }
    if (mapData?.integrant_ids?.length) {
      if (
        mapData?.integrant_ids?.includes(lines[index]?.properties?.integrant_id)
      ) {
        animate(counter);
      } else {
        noAnimate(counter);
      }
    } else {
      animate(counter);
    }
  };

  useEffect(() => {
    let mapObj = {
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10?optimize=true",
      zoom: 3,
    };
    map.current = new mapboxgl.Map(mapObj);
    showUSmapAsdefault();
  }, []);

  useEffect(() => {
    if (startReplyAnimation) {
      handleResetSourceData();
      playAnimation(0);
    }
  }, [startReplyAnimation]);

  useEffect(() => {
    if (map?.current) {
      map?.current?.on("load", () => {
        let { points, lines, trianglePoints, popup } = mapBoxData;
        let isMultiPointPopup = false;
        // Add points source to map
        if (!map.current.getSource("point-source")) {
          map.current.addSource("point-source", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: points,
            },
          });
        }
        // Add point image to map
        for (let item of points) {
          if (item?.properties?.image) {
            map.current.loadImage(item?.properties?.image, (error, image) => {
              if (error) throw error;
              if (!map.current.hasImage(item?.properties?.imageName)) {
                map.current.addImage(item?.properties?.imageName, image);
              }
            });
          }
        }
        // Add line source to map
        if (!map.current.getSource("line-source")) {
          map.current.addSource("line-source", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: lines,
            },
          });
        }
        // Add line layer to map
        if (!map.current.getLayer("line-layer")) {
          map.current.addLayer({
            id: "line-layer",
            type: "line",
            source: "line-source",
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#735c7c",
              "line-width": 2,
              "line-opacity": 0.8,
            },
          });
        }
        // Add triangle source to map
        if (!map.current.getSource("triangle-source")) {
          map.current.addSource("triangle-source", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: trianglePoints,
            },
          });
        }
        // Add triangle hover image to map
        let triangleHoverImg = new Image(35, 35);
        triangleHoverImg.onload = () => {
          if (!map.current.hasImage("triangle-hover-img")) {
            map.current.addImage("triangle-hover-img", triangleHoverImg, {
              sdf: true,
            });
          }
        };
        triangleHoverImg.src = Triangle;
        // Add testing image to map
        let testingImg = new Image(50, 50);
        testingImg.onload = () => {
          if (!map.current.hasImage("testing-img")) {
            map.current.addImage("testing-img", testingImg, {
              sdf: true,
            });
          }
        };
        // testingImg.src = "https://healthloq.blob.core.windows.net/dev-environment/acetylcholine_brain_food-950214de-7973-4edd-85f7-3a2f0e38cd1d.png";

        map.current.loadImage(Testing, (error, image) => {
          // if (error) throw error;
          // if (!map.current.hasImage(item?.properties?.imageName)) {
          map.current.addImage("test-testing-img", image);
          // }
        });

        // triangle hover image styles
        const hoverIconImageColorExpression = [
          "case",
          ["==", ["get", "type"], "batch"],
          "rgb(53, 162, 151)",
          ["==", ["get", "type"], "exhibit"],
          "rgb(201, 95, 61)",
          ["==", ["get", "type"], "transfer"],
          "rgb(176, 21, 96)",
          ["==", ["get", "type"], "transit"],
          "rgb(115, 92, 124)",
          "#000",
        ];
        const hoverTriangleLayerPaintProperty = {};
        hoverTriangleLayerPaintProperty["icon-color"] =
          hoverIconImageColorExpression;
        hoverTriangleLayerPaintProperty["icon-opacity"] = 0.3;
        // Add triangle hover image to map
        if (!map.current.getLayer("triangle-hover-layer")) {
          map.current.addLayer({
            id: "triangle-hover-layer",
            source: "line-source",
            type: "symbol",
            filter: ["==", ["get", "showHoverTriangle"], true],
            layout: {
              "icon-image": "triangle-hover-img",
              "icon-rotation-alignment": "map",
              "symbol-placement": "line-center",
              "icon-allow-overlap": true,
              "icon-rotate": -90,
            },
            paint: hoverTriangleLayerPaintProperty,
          });
        }
        // Add triangle image to map
        let triangleImage = new Image(20, 20);
        triangleImage.onload = () => {
          if (!map.current.hasImage("triangle")) {
            map.current.addImage("triangle", triangleImage, { sdf: true });
          }
        };
        
        triangleImage.src = Triangle;
        // triangle image styles
        const iconImageColorExpression = [
          "case",
          ["==", ["get", "type"], "batch"],
          "rgb(53, 162, 151)",
          ["==", ["get", "type"], "exhibit"],
          "rgb(201, 95, 61)",
          ["==", ["get", "type"], "transfer"],
          "rgb(176, 21, 96)",
          ["==", ["get", "type"], "transit"],
          "rgb(115, 92, 124)",
          "#000",
        ];
        const triangleLayerPaintProperty = {};
        triangleLayerPaintProperty["icon-color"] = iconImageColorExpression;
        if (!map.current.getLayer("triangle-layer")) {
          map.current.addLayer({
            id: "triangle-layer",
            source: "line-source",
            type: "symbol",
            filter: ["==", ["get", "showTriangle"], true],
            layout: {
              "symbol-placement": "line-center",
              "icon-image": "triangle",
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              "icon-rotate": -90,
            },
            paint: triangleLayerPaintProperty,
          });
        }
        // Add point layer to map
        if (!map.current.getLayer("point-layer")) {
          map.current.addLayer({
            id: "point-layer",
            source: "point-source",
            type: "symbol",
            layout: {
              "icon-image": ["get", "imageName"],
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": [
                "case",
                ["==", ["get", "isThumbAvailable"], true],
                0.8,
                0.05,
              ],
            },
          });
        }
        // Add point text layer to map
        if (!map.current.getLayer("point-text-layer")) {
          map.current.addLayer({
            id: "point-text-layer",
            source: "point-source",
            type: "symbol",
            layout: {
              "text-field": ["get", "title"],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 1.9],
              "text-anchor": "top",
              "text-size": 12,
            },
            paint: {
              "text-color": "rgba(0,0,0,0.75)",
            },
          });
        }
        // testing-lab-icon
        if (!map.current.getLayer("testing-lab-icon-layer")) {
          map.current.addLayer({
            id: "testing-lab-icon-layer",
            source: "point-source",
            type: "symbol",
            filter: ["==", ["get", "type"], "exhibit"],
            layout: {
              // "symbol-placement": "line-center",
              // "icon-image": "testing-img",
              "icon-image": "test-testing-img",
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              // "icon-anchor": "bottom-left",
              "text-anchor": "bottom",
              "icon-size": 0.7,
              // "icon-offset": [10, 41],
              "icon-offset": [25, -25],
            },
            // paint: {"icon-opacity": 0.8},
          });
        }
        // Add mouseenter and mouseleave event listeners on point layer and triangle layer
        map.current.on("mouseenter", ["point-layer", "triangle-layer"], (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const layer = e?.features[0]?.layer?.id;
          const id = e?.features[0]?.properties?.id;
          if (layer === "triangle-layer") {
            lines = lines?.map((item) => {
              return item?.properties?.id === id
                ? {
                    ...item,
                    properties: {
                      ...item?.properties,
                      showHoverTriangle: true,
                    },
                  }
                : item;
            });
            handleSourceDataChange("line-source", lines);
          }
          showTooltip(e);
        });
        map.current.on(
          "mouseleave",
          ["point-layer", "triangle-layer", "triangle-hover-layer"],
          (e) => {
            map.current.getCanvas().style.cursor = "";
            lines = lines?.map((item) => {
              return {
                ...item,
                properties: {
                  ...item?.properties,
                  showHoverTriangle: false,
                },
              };
            });
            handleSourceDataChange("line-source", lines);
            if (!isMultiPointPopup) {
              popup.remove();
            }
          }
        );
        // Add click event listener on map
        map.current.on("click", function (e) {
          let circleLayerData = map.current.queryRenderedFeatures(e.point, {
            layers: ["point-layer"],
          });
          let triangleLayerData = map.current.queryRenderedFeatures(e.point, {
            layers: ["triangle-layer", "triangle-hover-layer"],
          });
          if (!circleLayerData.length && !triangleLayerData?.length) {
            popup.remove();
            isMultiPointPopup = false;
            return;
          }
          if (triangleLayerData?.length) {
            handleOpenPointDrawer({
              ...triangleLayerData[0]?.properties,
              origin: JSON.parse(triangleLayerData[0]?.properties?.origin),
              organizationData: JSON.parse(
                triangleLayerData[0]?.properties?.organizationData
              ),
              data: JSON.parse(triangleLayerData[0]?.properties?.data),
            });
          } else {
            showTooltip(
              {
                features: [
                  {
                    ...circleLayerData[0]?.properties,
                    origin: JSON.parse(circleLayerData[0]?.properties?.origin),
                    organizationData: JSON.parse(
                      circleLayerData[0]?.properties?.organizationData
                    ),
                    data: JSON.parse(circleLayerData[0]?.properties?.data),
                  },
                ],
              },
              "click"
            );
          }
        });

        if (lines.length) {
          playAnimation(0);
        }

        const showTooltip = (e, event) => {
          const { popup } = mapBoxData;
          const id = e?.features[0]?.properties?.id;
          const origin =
            event === "click"
              ? e?.features[0]?.properties?.origin
              : JSON.parse(e?.features[0]?.properties?.origin).join(",");
          const sameLocationData = mapData?.data?.filter(
            (item) =>
              item?.origin?.join(",") === origin && item?.type === "batch"
          );
          if (sameLocationData?.length > 1) {
            let el = document.getElementsByClassName("tooltipUl");
            if (!el[0]) {
              let html = `<div class="tooltipUl" id="tooltipUl"></div>`;
              popup
                .setLngLat(e?.features[0]?.geometry?.coordinates)
                .setHTML(html)
                .addTo(map.current);
              isMultiPointPopup = true;
              for (let item of sameLocationData) {
                let li = document.createElement("div");
                li.classList.add("tooltipLi");
                li.onclick = () => {
                  handleOpenPointDrawer(item);
                  popup.remove();
                };
                li.innerHTML = `<span style="background:${getColorByType(
                  item?.type
                )}"></span>
                            <div>
                              <p>${item?.title}</p>
                              <p>${moment(item?.data?.effectiveDate).format(
                                "DD-MM-YYYY"
                              )}</p>
                            </div>`;
                document.getElementById("tooltipUl").appendChild(li);
              }
            }
          } else if (event === "click") {
            handleOpenPointDrawer(e?.features[0]);
          } else {
            const { exhibitsType, effectiveDate, description } = JSON.parse(
              e?.features[0]?.properties?.data
            );
            let html = `<div class="tooltipRootDiv">
                      <p>${e?.features[0]?.properties?.title}</p>
                      ${
                        ["triangle-layer"].includes(
                          e?.features[0]?.layer?.id
                        ) && exhibitsType
                          ? `<p>${exhibitsType}</p>`
                          : ""
                      }
                      ${
                        ["transit", "transfer"].includes(
                          e?.features[0]?.properties?.type
                        ) ||
                        (["exhibit"].includes(
                          e?.features[0]?.properties?.type
                        ) &&
                          ["triangle-layer"].includes(
                            e?.features[0]?.layer?.id
                          ))
                          ? `<p>${description}</p>`
                          : ""
                      }
                      ${
                        (["exhibit"].includes(
                          e?.features[0]?.properties?.type
                        ) &&
                          ["triangle-layer"].includes(
                            e?.features[0]?.layer?.id
                          )) ||
                        ["batch", "transit", "transfer"].includes(
                          e?.features[0]?.properties?.type
                        )
                          ? `<p>${moment(effectiveDate).format(
                              "DD-MM-YYYY"
                            )}</p>`
                          : ""
                      }
                      
                    </div>`;
            popup
              .setLngLat(
                e?.features[0]?.layer?.id === "point-layer"
                  ? e?.features[0]?.geometry?.coordinates
                  : [e?.lngLat?.lng, e?.lngLat?.lat]
              )
              .setHTML(html)
              .addTo(map.current);
            isMultiPointPopup = false;
          }
        };
      });
    }
  }, [map]);

  return (
    <React.Fragment>
      <div ref={mapContainer} className={"mapContainer"}></div>
      <MapboxGLPointDrawer
        {...openPointDrawerData}
        onClose={handleClosePointDrawer}
      />
    </React.Fragment>
  );
}

const mapStateToProps = ({ productReducers: { mapData } }) => ({
  mapData,
});

const mapDispatchToProps = {
  dispatchUpdateMapDataIntegrantId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapboxGL);
