import { makeStyles } from "@material-ui/core";
import appImages from "../../constants/AppImage";
import LeftImageSection from "../Common/Section/LeftImageSection";

const {
  images: { supplierHowItWorkSection1 },
} = appImages;

const useStyle = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

export default function ConsumerSolutionSection() {
  const classes = useStyle();
  const content = [
    {
      href: `/products?product_type=all`,
      content: <>Verify a Product's Origin</>,
    },
    {
      href: `/label-validation`,
      content: (
        <>
          Compare a Label with the one published at the NIH (National Institute
          of Health)
        </>
      ),
    },
    {
      href: `/circular-economy`,
      content: <>Report Recycling</>,
    },
    {
      href: `/consumer-connection`,
      content: (
        <>Provide feedback on products directly to the manufacturer and brand</>
      ),
    },
    {
      href: `/consumer-connection`,
      content: (
        <>Invite companies to participate and show product origin information</>
      ),
    },
    {
      href: `/consumer-connection`,
      content: <>Share what works for you.</>,
    },
  ];
  return (
    <>
      <LeftImageSection
        img={supplierHowItWorkSection1}
        content={content}
        heading="As a consumer, you can …."
        notBtn
      />
    </>
  );
}
