import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const PopupModal = ({
 onClose, isVisible, children, title, bgBlur, closeBtnClass
}) => {
  useEffect(() => {
    const onEscKeyPressClosePopupWindow = (event) => {
      if (event.keyCode === 27) {
        onClose(false)
      }
    }
    window.addEventListener("keydown", onEscKeyPressClosePopupWindow)
    return () => {
      window.removeEventListener("keydown", onEscKeyPressClosePopupWindow)
    }
  }, [onClose])

  return (
      <>
          {isVisible && (
          <div className={`popup-bg d-flex justify-content-center align-items-center ${bgBlur && "bg-transparent"}`}>
              <div className="d-flex flex-column element">
                  <div className="popup-modal d-flex flex-column">
                      {title && (
                      <header>
                          <div className="mr-auto">
                              <span>{title}</span>
                          </div>
                          <div className="close-btn">
                              <button
                                  type="button"
                                  onClick={() => {
                        onClose(false)
                      }}
                              >
                                  <i id="slider-icon" className="fa fa-close" />
                              </button>
                          </div>
                      </header>
              )}

                      <div className="content">{Object.keys(children).length !== 0 && children}</div>
                      {!title && (
                      <div className="close-btn text-center">
                          <button
                              type="button"
                              className={closeBtnClass}
                              onClick={() => {
                        onClose(false)
                      }}
                          >
                              Close
                          </button>
                      </div>
)}
                  </div>
              </div>
          </div>
      )}
      </>
  )
}

PopupModal.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  children: PropTypes.shape({}),
  title: PropTypes.string,
  bgBlur: PropTypes.string,
  closeBtnClass: PropTypes.string
}

PopupModal.defaultProps = {
  onClose: () => {},
  isVisible: false,
  children: {},
  title: "",
  bgBlur: "",
  closeBtnClass: ""
}

export default PopupModal
