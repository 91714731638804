import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  Box,
} from "@material-ui/core";
import { Button, SmProfileFooter } from "../../components/Profile";
import { getTrendingOrganizationsList } from "../../redux/actions/organization";
import utils from "../../utils/commonUtils";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import InviteOrganization from "../../components/Dialogs/InviteOrganization";
import { ReactComponent as ShareIcon } from "../../constants/svg/icon-share.svg";
import { InlineShareButtons } from "sharethis-reactjs";
import jwt from "jsonwebtoken";
import apiConstants from "../../services/constants";
import { useIntersection } from "../../hooks/useIntersection";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FacebookIcon } from "../../constants/svgIcons";
const {
  PRODUCER_APP_URL: { subscription },
} = apiConstants;

const useStyle = makeStyles((theme) => ({
  summaryMainHeading: {
    fontSize: "34px",
    lineHeight: "40px",
    fontWeight: 700,
    color: "#000",
    marginBottom: 10,
    [theme.breakpoints.down(768)]: {
      padding: "20px 20px 0",
    },
  },
  tableContainer: {
    margin: "15px auto 20px",
    maxHeight: 440,
  },
  tableHead: {
    background: "#FBFBFB",
    "&>tr": {
      "&>th": {
        fontWeight: 700,
        [theme.breakpoints.down(768)]: {
          padding: 0,
        },
      },
    },
  },
  table: {
    [theme.breakpoints.down(768)]: {
      // minWidth: "max-content",
    },
  },
  tableRow: {
    "&>td": {
      padding: "5px 5px 5px 10px",
      "&>img": {
        width: 50,
        height: 50,
      },
      [theme.breakpoints.down(768)]: {
        padding: 0,
        fontSize: 16,
        lineHeight: "20px",
      },
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
        lineHeight: "18px",
        "&>img": {
          width: 35,
          height: 35,
        },
      },
    },
    height: 60,
  },
  inviteSection: {
    margin: "20px 0",
    "&>p": {
      marginBottom: 5,
    },
    [theme.breakpoints.down(768)]: {
      padding: "0 20px",
    },
  },
  searchTextfield: {
    marginLeft: "auto",
    margin: "10px 0 0",
    [theme.breakpoints.down(768)]: {
      width: "100%",
      "&>div": {
        width: "100%",
      },
    },
  },
  inviteCompaniesSection: {
    "&>h5,&>p": {
      [theme.breakpoints.down(768)]: {
        padding: "0 20px",
      },
    },
  },
  inviteFbFriends: {
    width: "max-content !important",
    backgroundColor: "#1B74E4",
    color: theme.palette.common.white,
    padding: "10px 15px !important",
    "& svg": {
      fill: theme.palette.common.white,
    },
    "&:hover": {
      backgroundColor: "#1B74E4",
      color: theme.palette.common.white,
    },
  },
  inviteSocialMediaSection: {
    "&>div": {
      "&:nth-child(2)": {
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
          textAlign: "left",
          marginBottom: 10,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& button": {
        width: "100% !important",
        maxWidth: "max-content",
      },
    },
  },
}));

export const InvitePage = (props) => {
  const {
    userData,
    activePage,
    match,
    history,
    getTrendingOrganizationsList,
    trendingOrganizationList,
    inviteOrganizationData,
  } = props;
  const classes = useStyle();
  const { user_id } = utils.getLocalStorageItems();

  useEffect(() => {
    getTrendingOrganizationsList({ page: 1 });
  }, []);

  const [showInlineShareButtons, setShowInlineShareButtons] = useState(false);
  const [openInviteOrganizationDialog, setOpenInviteOrganizationDialog] =
    useState(false);
  const [trendingOrganizationData, setTrendingOrganizationData] =
    useState(null);

  let jwt_token_url = "";
  if (userData?.data?.invite_qr_url) {
    jwt_token_url = userData?.data?.invite_qr_url;
  } else {
    let jwt_token = jwt.sign(
      { user_id: user_id },
      process.env.REACT_APP_JWT_SECRET
    );
    jwt.sign(
      { user_id: user_id },
      process.env.REACT_APP_JWT_SECRET,
      function (err, token) {
        jwt_token = token;
      }
    );
    jwt_token_url = `${subscription}/${jwt_token}`;
  }

  const handleFbInviteFriend = () => {
    window.open(
      `http://www.facebook.com/dialog/send?
    app_id=${process.env.REACT_APP_HEALTHLOQ_FACEBOOK_APP_ID}&link=${jwt_token_url}&redirect_uri=${window.location.origin}/invite`,
      "_blank"
    );
  };

  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const [trendingOrganizationSearch, setTrendingOrganizationSearch] =
    useState("");

  useEffect(() => {
    const { isIntersecting } = entry;
    if (
      isIntersecting &&
      trendingOrganizationList?.data?.meta?.pagination?.total -
        trendingOrganizationList?.data?.data.length >
        0
    ) {
      let obj = {
        page: trendingOrganizationList?.data?.meta?.pagination?.page + 1,
      };
      if (trendingOrganizationSearch) {
        obj["q"] = trendingOrganizationSearch;
      }
      getTrendingOrganizationsList(obj);
    }
  }, [entry]);

  const handleSearchTrendingOrganization = (type) => {
    if (type === "icon-click" || type === "clear" || type.keyCode === 13) {
      if (type !== "clear") {
        getTrendingOrganizationsList({
          page: 1,
          q: trendingOrganizationSearch,
        });
      } else {
        getTrendingOrganizationsList({ page: 1 });
      }
    }
  };

  const [checkBoxSelectedAll, setCheckBoxSelectedAll] = useState(false);
  useEffect(() => {
    if (
      trendingOrganizationList?.data?.data?.length > 0 &&
      checkBoxSelectedAll
    ) {
      setCheckBoxSelected(
        trendingOrganizationList?.data?.data
          ?.filter((item, key) => item?.attributes?.email)
          .map((item, key) => item?.attributes?.email)
      );
    }
  }, [trendingOrganizationList]);

  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const handleSelectCheckBox = (email) => (event) => {
    if (event.target.checked) {
      if (email) {
        setCheckBoxSelected((pre) => [...pre, email]);
      } else {
        setCheckBoxSelectedAll(true);
        setCheckBoxSelected(
          trendingOrganizationList?.data?.data
            ?.filter((item, key) => item?.attributes?.email)
            .map((item, key) => item?.attributes?.email)
        );
      }
    } else {
      setCheckBoxSelectedAll(false);
      if (email) {
        setCheckBoxSelected((pre) => pre.filter((item) => item !== email));
      } else {
        setCheckBoxSelected([]);
      }
    }
  };

  useEffect(() => {
    if (inviteOrganizationData?.status == "1") {
      setCheckBoxSelectedAll(false);
      setCheckBoxSelected([]);
    }
  }, [inviteOrganizationData]);

  return (
    <Grid className="profile-root">
      <Grid className="profile-page-content">
        <Typography variant="h1" className={classes.summaryMainHeading}>
          Invite Companies and Friends
        </Typography>
        <div className={classes.inviteSection}>
          <Grid
            container
            spacing={1}
            className={classes.inviteSocialMediaSection}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="h5">Invite friends!</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Button
                className={classes.inviteFbFriends}
                startIcon={<FacebookIcon />}
                onClick={handleFbInviteFriend}
              >
                Invite Facebook Friends
              </Button>
            </Grid>
          </Grid>
          <p>
            Invite friends, family and colleagues to help promote HealthLOQ.
          </p>
          <Grid className="share-button">
            <span
              className={
                showInlineShareButtons
                  ? "profile-page-share share-btn-active"
                  : "profile-page-share"
              }
              title="Share this"
              profile-tour="share_on_social_media"
            >
              <Button
                className="share-btn"
                onClick={() =>
                  setShowInlineShareButtons(!showInlineShareButtons)
                }
              >
                <span style={{ marginRight: "5px" }}>Share </span> <ShareIcon />
              </Button>
              <InlineShareButtons
                config={{
                  action_pos: "left",
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  labels: null,
                  language: "en",
                  networks: ["whatsapp", "facebook", "linkedin", "twitter"],
                  padding: 8,
                  radius: 50,
                  size: 32,
                  spacing: 8,

                  url: jwt_token_url,
                  image:
                    "https://healthloq.com/assets/images/logo/healthloq.png",
                  description: "HealthLOQ Producer App",
                  title: "HealthLOQ",
                }}
              />
            </span>
          </Grid>
        </div>
        <div className={classes.inviteCompaniesSection}>
          <Typography variant="h5">Invite Companies!</Typography>
          <p>
            Refer HealthLOQ to companies whose products you would like to see on
            the HealthLOQ product origin platform
          </p>
        </div>
        <Grid item className="trending-organization-container">
          <div className="trending-organization-header">
            <Typography variant="h5">Trending Organizations</Typography>
            <div className={classes.searchTextfield}>
              <TextField
                placeholder="Search"
                value={trendingOrganizationSearch}
                onChange={(e) => {
                  setTrendingOrganizationSearch(e.target.value);
                  if (e.target.value === "") {
                    handleSearchTrendingOrganization("clear");
                  }
                }}
                onKeyDown={(e) => handleSearchTrendingOrganization(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        onClick={() =>
                          handleSearchTrendingOrganization("icon-click")
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {checkBoxSelected.length > 0 && (
            <div className="trending-organization-header">
              <div>
                <Button
                  className="share-btn"
                  onClick={() => {
                    setOpenInviteOrganizationDialog(true);
                    // setTrendingOrganizationData(item?.attributes);
                  }}
                >
                  <span>Invite All</span>
                </Button>
              </div>
            </div>
          )}
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{
              height: trendingOrganizationList?.loading ? 440 : "unset",
            }}
          >
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="left" width="10%">
                    <Tooltip title="Select all" arrow>
                      <span>
                        <Checkbox
                          color="primary"
                          checked={
                            checkBoxSelected.length == 0
                              ? false
                              : trendingOrganizationList?.data?.data
                                  ?.filter(
                                    (item, key) => item?.attributes?.email
                                  )
                                  .map((item, key) => item?.attributes?.email)
                                  .length === checkBoxSelected.length
                          }
                          onChange={handleSelectCheckBox()}
                        />
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" width="20%">
                    Logo
                  </TableCell>
                  <TableCell align="left" width="55%">
                    Name
                  </TableCell>
                  <TableCell align="center" width="15%">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trendingOrganizationList?.data?.data?.map((item, key) => {
                  return (
                    <TableRow className={classes.tableRow} key={key}>
                      <TableCell align="left" width="10%">
                        <Tooltip
                          title={
                            item?.attributes?.email
                              ? ""
                              : "Email not available!"
                          }
                          arrow
                        >
                          <span>
                            <Checkbox
                              color="primary"
                              disabled={item?.attributes?.email ? false : true}
                              checked={checkBoxSelected.includes(
                                item?.attributes?.email
                              )}
                              onChange={handleSelectCheckBox(
                                item?.attributes?.email
                              )}
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" width="20%">
                        {item?.attributes?.logo?.data?.attributes?.url ? (
                          <img
                            style={{ objectFit: "contain" }}
                            src={`https://newsroom.healthloq.com${item?.attributes?.logo?.data?.attributes?.url}`}
                          />
                        ) : (
                          "---"
                        )}
                      </TableCell>
                      <TableCell align="left" width="55%">
                        {item?.attributes.name}
                      </TableCell>
                      <TableCell align="right" width="15%">
                        <Button
                          profile-tour={
                            key === 0 ? "invite_trending_organization" : ""
                          }
                          className="share-btn"
                          onClick={() => {
                            setCheckBoxSelectedAll(false);
                            setCheckBoxSelected([]);
                            setOpenInviteOrganizationDialog(true);
                            setTrendingOrganizationData(item?.attributes);
                          }}
                        >
                          <span>Invite</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {trendingOrganizationList?.data?.meta?.pagination?.total -
                  trendingOrganizationList?.data?.data.length >
                  0 && (
                  <TableRow ref={setTarget} className={classes.tableRow}>
                    <TableCell colSpan={4} align="center">
                      <MuiCircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!trendingOrganizationList?.data &&
                  !trendingOrganizationList?.loading && (
                    <TableRow ref={setTarget} className={classes.tableRow}>
                      <TableCell colSpan={4} align="center">
                        Data not found!
                      </TableCell>
                    </TableRow>
                  )}
                {!trendingOrganizationList?.data &&
                  trendingOrganizationList?.loading && (
                    <TableRow ref={setTarget} className={classes.tableRow}>
                      <TableCell colSpan={4} align="center">
                        <MuiCircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div profile-tour={"invite_trending_organization"}>Hello</div> */}
        </Grid>
        <Grid className="invite-organization-text">
          <p profile-tour="invite_any_organization">
            Don't see the organization you're looking for?{" "}
            <span
              onClick={() => {
                setCheckBoxSelectedAll(false);
                setCheckBoxSelected([]);
                setOpenInviteOrganizationDialog(true);
                setTrendingOrganizationData();
              }}
              style={{ cursor: "pointer", color: "#28a745" }}
            >
              Click here
            </span>{" "}
            to invite them to HealthLOQ.
          </p>
          <InviteOrganization
            jwt_token_url={jwt_token_url}
            open={openInviteOrganizationDialog}
            handleClose={() => setOpenInviteOrganizationDialog(false)}
            trendingOrganizationData={trendingOrganizationData}
            checkBoxSelected={checkBoxSelected}
            trendingOrganizationList={trendingOrganizationList?.data?.data}
          />
        </Grid>
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  OrganizationReducers: { trendingOrganizationList },
  ProfileReducers: { inviteOrganizationData },
}) => ({
  trendingOrganizationList,
  inviteOrganizationData,
});

const mapDispatchToProps = {
  getTrendingOrganizationsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
