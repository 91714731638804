import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { ExpandMore } from "@material-ui/icons";

export default function MuiAccordion({ data, expanded, onClick }) {
  return (
    <Accordion className="accordion-root" onClick={onClick}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={clsx(
          "accordion-summary",
          expanded
            ? data?.invite_organization?.name
              ? "accordion-expanded"
              : "accordion-expanded-grey"
            : ""
        )}
      >
        <Typography variant="body2">{data?.organization_name}</Typography>
        <Typography variant="body2">
          {moment(data?.created_on).format("DD MMMM, YYYY hh:mm A")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Grid>
          <Typography variant="body2">
            <span>Invitee email:</span>
            {data?.email}
          </Typography>
          <Typography variant="body2">
            <span>Message:</span>
            <div dangerouslySetInnerHTML={{ __html: data?.mail_text }}></div>
          </Typography>
          {data?.invite_organization && (
            <>
              <br />
              <Typography variant="body2">
                <span>Organization:</span>
                {data?.invite_organization?.name}
              </Typography>
              <Typography variant="body2">
                <span>Joined at:</span>
                {moment(data?.invite_organization?.created_on).format(
                  "DD MMMM, YYYY hh:mm A"
                )}
              </Typography>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
