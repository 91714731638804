import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import { SectionWithTreePlan } from "../Common/SectionWrappers";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function HealthLoqSection() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>HealthLOQ</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            HealthLOQ allows an individual or organization to verify that
            product documentation (e.g. certificate of analysis or "COA"),
            including the label, is legitimate and that the certifying
            organization itself is in fact been accredited by a known standards
            body. The document protection process is effective for any type of
            document in any industry. The privacy of the document content is
            preserved since there is no need to expose sensitive or proprietary
            information outside the security perimeter of the organization that
            creates the original document work product. No document export is
            required and the tool can be implemented in just a few hours.
            Retailers don't have to manually validate - it happens
            automatically!
          </Typography>
          <Box className={classes.btns}>
            <Button href="/contact-us">Contact Us</Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
