import { PageWrapper } from "../../components/Wrappers";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import {
  DocumentationSection,
  HowItWorkSection1,
} from "../../components/DocumentVerification";
import Authenticator from "../Authenticator";
import ManualDocumentVerification from "../../components/DocumentVerification/ManualDocumentVerification";
import HealthLoqSection from "../../components/DocumentVerification/HealthloqSection";
import BulkVerification from "../../components/DocumentVerification/BulkVerification";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";

const {
  headerImages: { documentVerification },
} = appImages;

export default function DocumentVerification() {
  return (
    <PageWrapper pageName="document-verification">
      <HeaderSection
        headerImgSrc={documentVerification}
        headerText="Verify the legitimacy of documents provided by suppliers, manufacturers or brands."
      />
      <DocumentationSection />
      <ManualDocumentVerification />
      <Authenticator />
      <HowItWorkSection1 />
      <BulkVerification />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
