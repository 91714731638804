import { useTheme } from "@material-ui/core";
import React from "react";
import Tour from "reactour";

export default function DefaultTour({
  disableInteraction = true,
  closeWithMask = false,
  disableKeyboardNavigation = true,
  showCloseButton = true,
  rounded = 5,
  ...otherProps
}) {
  const theme = useTheme();
  const accentColor = theme.palette.primary.main;

  return (
    <Tour
      rounded={rounded}
      accentColor={accentColor}
      disableInteraction={disableInteraction}
      closeWithMask={closeWithMask}
      disableKeyboardNavigation={disableKeyboardNavigation}
      showCloseButton={showCloseButton}
      {...otherProps}
    />
  );
}

export const allRegisteredTour = {
  profile: {
    key: "profile",
    label: "Profile",
    initialUrl: "/profile",
  },
  productDetail: {
    key: "productDetail",
    label: "Product Detail",
  },
};

export const genericFeatureTour = {
  filter: {
    key: "filter",
    label: "Filter",
  },
  multipleAction: {
    key: "multipleAction",
    label: "Multiple Action",
  },
};
