import {
  GET_ORGANIZATION_LIST_START,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_TRENDING_ORGANIZATION_START,
  GET_TRENDING_ORGANIZATION_SUCCESS,
  TOGGLE_PRODUCER_CONNECTIONS_START,
  TOGGLE_PRODUCER_CONNECTIONS_SUCCESS,
} from "../actionsType/organization";

const initialState = {
  organizationList: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  toggleProducerConnectionsData: {
    loading: false,
    message: "",
    status: "",
  },
  trendingOrganizationList: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
};

const OrganizationReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_LIST_START: {
      return {
        ...previousState,
        organizationList: {
          ...previousState.organizationList,
          loading: true,
        },
      };
    }
    case GET_ORGANIZATION_LIST_SUCCESS: {
      return {
        ...previousState,
        organizationList: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_TRENDING_ORGANIZATION_START: {
      return {
        ...previousState,
        trendingOrganizationList: {
          ...previousState.trendingOrganizationList,
          loading: true,
        },
      };
    }
    case GET_TRENDING_ORGANIZATION_SUCCESS: {
      const page = action?.payload?.data?.meta?.pagination?.page;
      let arrayData = previousState?.trendingOrganizationList?.data?.data || [];
      if (action?.payload?.data) {
        if (page != 1) {
          arrayData = [...arrayData, ...action?.payload?.data?.data];
        } else {
          arrayData = [...action?.payload?.data?.data];
        }
      }
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        trendingOrganizationList: {
          loading: false,
          status,
          message,
          data: data ? { ...data, data: arrayData } : data,
          // ...action.payload,
        },
      };
    }
    case TOGGLE_PRODUCER_CONNECTIONS_START: {
      return {
        ...previousState,
        toggleProducerConnectionsData: {
          ...previousState.toggleProducerConnectionsData,
          loading: true,
          status: "",
          message: "",
        },
      };
    }
    case TOGGLE_PRODUCER_CONNECTIONS_SUCCESS: {
      return {
        ...previousState,
        toggleProducerConnectionsData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    default:
      return previousState || initialState;
  }
};
export default OrganizationReducers;
