import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import { ManufacturerBrandsSection } from "../../components/ManufacturerBrands";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { manufacturarBrands },
} = appImages;

export default function ManufacturerBrands() {
  return (
    <PageWrapper pageName="Manufacturer Brands">
      <HeaderSection
        headerImgSrc={manufacturarBrands}
        headerText="Differentiate with Transparency"
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <ManufacturerBrandsSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
