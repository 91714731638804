import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Link,
  CircularProgress,
  Box,
  Autocomplete,
} from "../../components";
import { AppImages } from "../../constants";
import { Body } from "../../components/Common";
import { DocumentVerificationDialog } from "../../components/Dialogs";
import { dispatchOrganizationList } from "../../redux/actions";

const { imageTC } = AppImages;
const {
  icon: { correctIcon },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    // backgroundColor: "#F7F7F7",
    "&>div": {
      display: "flex",
      flexDirection: "column",
      marginTop: 100,
    },
  },
  aboutTrustDiv: {
    marginBottom: 20,
    "&>hr": {
      backgroundColor: "#28A74540",
      width: 250,
      height: "2px !important",
    },
    "&>h4": {
      margin: "0px 10px",
      textAlign: "center",
    },
  },
  healthloqTagLine: {
    marginBottom: 50,
    "&>h1": {
      textAlign: "center",
      fontSize: 70,
      lineHeight: "80px",
      "&>span": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 60,
        lineHeight: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 48,
        lineHeight: "60px",
      },
    },
  },
  searchDiv: {
    marginBottom: 40,
    margin: "0 auto",
    width: "100%",
    "&>div": {
      width: "100%",
      maxWidth: 770,
    },
  },
  searchTextfield: {
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#EEEEEE",
    "&>div": {
      width: "100%",
      height: 80,
      padding: "0 50px",
      "&>input": {
        fontSize: 22,
        lineHeight: "29px",
        fontWeight: theme.typography.fontWeightLight,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        "&::placeholder": {
          color: "#44444480",
        },
      },
      "&>div": {
        "&>svg": {
          color: "#28A74580",
          width: 30,
          height: 30,
          cursor: "pointer",
        },
      },
    },
  },
  searchResultDiv: {
    position: "absolute",
    paddingTop: 5,
    maxHeight: 300,
    zIndex: 3,
    width: "100%",
    "&>div": {
      width: "100%",
      overflowY: "auto",
      height: "100%",
      borderRadius: 10,
      backgroundColor: theme.palette.common.white,
      boxShadow: "#32325d1a 0px 7px 14px 0px, #00000012 0px 3px 6px 0px",
    },
  },
  searchResultLoading: {
    "&>div": {
      marginLeft: 10,
    },
  },
  searchResultZeroStateMsg: {
    "&>a": {
      color: theme.palette.primary.main,
    },
  },
  orgInfoDiv: {
    width: "100%",
    cursor: "pointer",
    padding: "7px 10px",
    "&>a": {
      width: "100%",
      "&>h6": {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  productInfoDiv: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "7px 10px",
    paddingLeft: 30,
    "&>img": {
      width: 40,
      height: 40,
      borderRadius: "50%",
      marginRight: 10,
    },
    "&>a": {
      width: "100%",
      fontSize: 16,
      lineHeight: "20px",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  searchResultNotFirstLi: {
    borderTop: "1px solid #eeeeee",
  },
  descriptionDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 220,
    padding: "0 30px",
    width: "100%",
    "&>p": {
      width: "100%",
      textAlign: "center",
      "&>a": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
      "&:not(:last-child)": {
        marginBottom: 20,
      },
    },
    "&>div": {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 125,
      },
    },
    healthloqTagLine: {
      marginBottom: 30,
    },
    searchDiv: {
      marginBottom: 30,
    },
    descriptionDiv: {
      padding: 0,
      marginBottom: 180,
    },
    searchContainer: {
      "&>div": {
        marginTop: 80,
      },
    },
    searchTextfield: {
      "&>div": {
        height: 60,
        padding: "0 30px",
        "&>input": {
          fontSize: 18,
          lineHeight: "23px",
        },
        "&>div": {
          "&>svg": {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  [theme.breakpoints.down("xs")]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 65,
      },
    },
    searchContainer: {
      "&>div": {
        marginTop: 60,
      },
    },
    descriptionDiv: {
      marginBottom: 140,
    },
    searchTextfield: {
      "&>div": {
        height: 50,
        padding: "0 20px",
        "&>input": {
          fontSize: 16,
          lineHeight: "20px",
        },
      },
    },
  },
  [theme.breakpoints.down(400)]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 30,
      },
    },
    searchContainer: {
      "&>div": {
        marginTop: 40,
      },
    },
    descriptionDiv: {
      marginBottom: 100,
    },
  },
}));

// const Authenticator = () => (
export const Authenticator = (props) => {
  const classes = useStyle();
  const { dispatchOrganizationList } = props;

  useEffect(() => {
    dispatchOrganizationList({ showCustomLoader: true, type: "authenticator" });
  }, []);

  return (
    <>
      {/* <Body seoTitle="Authenticator - HealthLOQ"> */}
      <Grid container className={classes.searchContainer}>
        <div className="bodyMaxWidth">
          <Grid item className={clsx(classes.healthloqTagLine, "center")}>
            <Typography variant="h1" bold>
              Verify a Document
            </Typography>
          </Grid>
          <Grid item className={clsx(classes.descriptionDiv, "center")}>
            <Typography variant="body1" light>
              <b>HealthLOQ</b> authenticates legitimate electronic documents and
              detects fraudulent or tampered versions using blockchain
              technology:
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              Automated, API-driven process, operates as background service
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              100% testing of documents, vs. spot-checking or sample basis
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              Effective for any document, any industry
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              Privacy of content is preserved, no exposure of
              sensitive/proprietary data
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              No export of documents required
            </Typography>
            <Typography variant="body1" light>
              <img src={correctIcon} alt="" style={{ marginRight: 10 }} />
              Easy to implement, turnkey solution
            </Typography>
            <Typography variant="body1" light></Typography>
            <Grid item style={{ marginBottom: 0 }}>
              <DocumentVerificationDialog />
            </Grid>
          </Grid>
        </div>
      </Grid>
      {/* </Body> */}
    </>
  );
};
// );

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  dispatchOrganizationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);
