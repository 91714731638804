import { Button, Grid } from "@material-ui/core";
import React from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import clsx from "clsx";

export default function AuthLinkBox({
  description = "",
  link = "",
  heading = "",
  className = "",
  type = "",
}) {
  return (
    <Grid container className={clsx("authlinkbox", className)}>
      <h4>
        {type === "login" ? "Login" : "Register"} as a <span>{heading}</span>
      </h4>
      {Boolean(description) && <p>{description}</p>}
      <a
        href={link}
        target={link.split("/").length === 2 ? "_self" : "_blank"}
        rel="noreferrer"
      >
        <Button variant="contained" endIcon={<KeyboardBackspaceIcon />}>
          {type === "login" ? "Login" : "Get Started"}
        </Button>
      </a>
    </Grid>
  );
}
