import appImages from "../../constants/AppImage";
import LeftImageSection from "../Common/Section/LeftImageSection";
import RightImageSection from "../Common/Section/RightImageSection";

const {
  images: { supplierHowItWorkSection1 },
} = appImages;

export default function ManufacturerBrandsSection() {
  const content = [
    {
      href: `/document-verification`,
      content: (
        <>
          Verify a suppliers' credentials and the authenticity of their
          ingredients
        </>
      ),
    },

    {
      href: `/counterfeit-prevention`,
      content: (
        <>
          Automate the verification of documentation, credentials,... no manual
          searches
        </>
      ),
    },

    {
      href: `/consumer-connection`,
      content: (
        <>
          Connect with customers directly individually with proper privacy,
          consent management, and feedback
        </>
      ),
    },
    {
      href: `/product-origin`,
      content: (
        <>
          Know and be able to showcase your product's origin - including the
          origin of the ingredients
        </>
      ),
    },
  ];
  return (
    <>
      <LeftImageSection
        img={supplierHowItWorkSection1}
        content={content}
        heading="Manufacturers and Brands - differentiation through transparency and visibility"
      />
      <RightImageSection
        position="left"
        content={`HealthLOQ verifies the authenticity of a product using data directly from those who helped make it. Our independent, tamperproof ledger technologies can collect information directly from all the parties involved in the production, testing, delivery, packaging and distribution of the product. The HealthLOQ platform "locks" and secures the information, assigns a digital id to the product, tracks it's progress, combines and aggregates the information in an easy to see timeline and map.`}
      />
    </>
  );
}
