import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Drawer,
  makeStyles,
  Box,
  Button,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  CircularProgress,
} from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fancybox from "../../Common/Fancybox";
import utils from "../../../utils/commonUtils";
import { BlockchainProofIcon, PdfIcon } from "../../../constants/svgIcons";
import BlockchainProofDialog from "../../Dialogs/BlockchainProofDialog";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
        "&>h2": {
          marginBottom: 20,
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  batchUl: {
    width: "100%",
  },
  batchLi: {
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .Mui-expanded": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& button": {
      width: "max-content",
    },
  },
  accordionDetail: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 20,
    "&>h2,&>h6": {
      marginBottom: 20,
    },
    "&>h5": {
      marginBottom: 10,
      "&:not(:nth-child(3))": {
        marginTop: 20,
      },
    },
  },
  li: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>p": {
      marginBottom: 10,
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "&>button": {
        "&:first-child": {
          marginRight: 10,
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: 10,
        },
      },
    },
  },
  accordionDetailZeroStateMsg: {
    margin: "0 auto",
  },
  loading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>div": {
      marginLeft: 5,
    },
  },
}));

export default function OtherBatches({
  open = false,
  onClose = () => {},
  integrantsBatchesList = [],
  integrantInfo,
  selectedBatch = "",
  isLoading = false,
}) {
  const classes = useStyle();
  const [exhibitId, setExhibitId] = useState("");
  const [selectedBatches, setSelectedBatches] = useState([]);
  useEffect(() => {
    if (selectedBatch) setSelectedBatches((pre) => [selectedBatch]);
  }, [selectedBatch]);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Typography variant="h2" bold>
          Other Batches
        </Typography>
        {isLoading ? (
          <Typography variant="body2" className={classes.loading}>
            Loading...
            <CircularProgress />
          </Typography>
        ) : integrantsBatchesList.length > 0 ? (
          <Box className={classes.batchUl}>
            {integrantsBatchesList.map((item, i) => {
              const sustainability = item?.exhibitIntegrant?.filter(
                (el) => el?.exhibitsType?.title === "Sustainability"
              );
              const quality = item?.exhibitIntegrant?.filter(
                (el) => el?.exhibitsType?.title === "Quality"
              );
              const diversity = item?.exhibitIntegrant?.filter(
                (el) => el?.exhibitsType?.title === "Diversity"
              );
              return (
                <Accordion
                  key={i}
                  className={classes.batchLi}
                  onClick={() =>
                    setSelectedBatches((pre) =>
                      pre?.includes(item?.id)
                        ? pre?.filter((sId) => sId !== item?.id)
                        : [...pre, item?.id]
                    )
                  }
                  expanded={selectedBatches?.includes(item?.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"panel1a-content" + i}
                    id={"panel1a-header" + i}
                  >
                    <Typography variant="body2" light>
                      Batch No: {item?.external_id}
                    </Typography>
                    <Link
                      to={`/p/${item?.organizations?.short_code}/b/${item?.external_id_slug}`}
                      isLink={false}
                    >
                      <Button primaryDarkBtn>View</Button>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetail}>
                    {item?.exhibitIntegrant?.length > 0 ? (
                      <>
                        {sustainability?.length > 0 && (
                          <>
                            <Typography variant="h5" light>
                              Sustainability
                            </Typography>
                            <Typography variant="h6" bold>
                              Want to know the ecological impact of producing
                              your&nbsp;
                              {integrantInfo?.external_id} product?
                            </Typography>
                            <List component={"ol"}>
                              {sustainability?.map((el, key) => {
                                return (
                                  <ListItem key={key} className={classes.li}>
                                    <Typography variant="body1" light>
                                      {key + 1}.&nbsp;{el?.title}
                                    </Typography>
                                    <Box>
                                      <Button
                                        data-extention={utils.getExtension(
                                          el?.url
                                        )}
                                        data-gas_cert_url={el?.url}
                                        id={
                                          "sustainability-document-view-" +
                                          el?.id
                                        }
                                        onClick={() => {
                                          utils.handleDocumentViewClick(
                                            "sustainability-document-view-" +
                                              el?.id
                                          );
                                        }}
                                        primaryLightBtn
                                        startIcon={<PdfIcon />}
                                      >
                                        View Documents
                                      </Button>
                                      <Fancybox
                                        className={
                                          "sustainability-document-view-" +
                                          el?.id
                                        }
                                      />
                                      <Button
                                        primaryDarkBtn
                                        onClick={() => setExhibitId(el?.id)}
                                        startIcon={<BlockchainProofIcon />}
                                        data-toggle="modal"
                                        data-target="#productBlockchainProofDialog"
                                      >
                                        Verify Document
                                      </Button>
                                    </Box>
                                  </ListItem>
                                );
                              })}
                              ss
                            </List>
                          </>
                        )}
                        {quality?.length > 0 && (
                          <>
                            <Typography variant="h5" light>
                              Quality
                            </Typography>
                            <Typography variant="h6" bold>
                              Want to know how batch&nbsp;
                              {integrantInfo?.external_id} was tested?
                            </Typography>
                            <List component={"ol"}>
                              {quality?.map((el, key) => {
                                return (
                                  <ListItem key={key} className={classes.li}>
                                    <Typography variant="body1" light>
                                      {key + 1}.&nbsp;{el?.title}
                                    </Typography>
                                    <Box>
                                      <Button
                                        data-extention={utils.getExtension(
                                          el?.url
                                        )}
                                        data-gas_cert_url={el?.url}
                                        id={"quality-document-view-" + el?.id}
                                        onClick={() => {
                                          utils.handleDocumentViewClick(
                                            "quality-document-view-" + el?.id
                                          );
                                        }}
                                        primaryLightBtn
                                        startIcon={<PdfIcon />}
                                      >
                                        View Documents
                                      </Button>
                                      <Fancybox
                                        className={
                                          "quality-document-view-" + el?.id
                                        }
                                      />
                                      <Button
                                        primaryDarkBtn
                                        onClick={() => setExhibitId(el?.id)}
                                        startIcon={<BlockchainProofIcon />}
                                        data-toggle="modal"
                                        data-target="#productBlockchainProofDialog"
                                      >
                                        Verify Document
                                      </Button>
                                    </Box>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </>
                        )}
                        {diversity?.length > 0 && (
                          <>
                            <Typography variant="h5" light>
                              Diversity
                            </Typography>
                            <Typography variant="h6" bold>
                              Want to know the social impact of producing
                              your&nbsp;
                              {integrantInfo?.external_id} product?
                            </Typography>
                            <List component={"ol"}>
                              {diversity?.map((el, key) => {
                                return (
                                  <ListItem key={key} className={classes.li}>
                                    <Typography variant="body1" light>
                                      {key + 1}.&nbsp;{el?.title}
                                    </Typography>
                                    <Box>
                                      <Button
                                        data-extention={utils.getExtension(
                                          el?.url
                                        )}
                                        data-gas_cert_url={el?.url}
                                        id={"diversity-document-view-" + el?.id}
                                        onClick={() => {
                                          utils.handleDocumentViewClick(
                                            "diversity-document-view-" + el?.id
                                          );
                                        }}
                                        primaryLightBtn
                                        startIcon={<PdfIcon />}
                                      >
                                        View Documents
                                      </Button>
                                      <Fancybox
                                        className={
                                          "diversity-document-view-" + el?.id
                                        }
                                      />
                                      <Button
                                        primaryDarkBtn
                                        onClick={() => setExhibitId(el?.id)}
                                        startIcon={<BlockchainProofIcon />}
                                        data-toggle="modal"
                                        data-target="#productBlockchainProofDialog"
                                      >
                                        Verify Document
                                      </Button>
                                    </Box>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </>
                        )}
                      </>
                    ) : (
                      <Typography
                        variant="body1"
                        className={classes.accordionDetailZeroStateMsg}
                      >
                        Documents not available.
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        ) : (
          <Typography variant="body1" light>
            Batches not available
          </Typography>
        )}
      </Grid>
      <BlockchainProofDialog
        exhibitId={exhibitId}
        setExhibitId={setExhibitId}
      />
    </Drawer>
  );
}
