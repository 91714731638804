import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
// var jwt = require('jsonwebtoken');
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContent,
  MuiButton,
} from "./DialogCommonComponents";
import clsx from "clsx";
import { Paragraph, MuiTextfield, Button } from "../Profile";
import { ReactComponent as RightSignIcon } from "../../constants/svg/icon-right-sign.svg";
import { inviteOrganization } from "../../redux/actions/profileActions";
import { useTheme, useMediaQuery } from "@material-ui/core";
import utils from "../../utils/commonUtils";
import apiConstants from "./../../services/constants";
const {
  PRODUCER_APP_URL: { subscription },
} = apiConstants;
const nl2br = require("nl2br");

function InviteOrganization(props) {
  const {
    inviteOrganization,
    open,
    handleClose,
    inviteOrganizationData,
    jwt_token_url,
    trendingOrganizationData,
    checkBoxSelected = [],
    trendingOrganizationList = [],
  } = props;

  const theme = useTheme();
  const match = !useMediaQuery(theme.breakpoints.down(769));
  const [organization_name, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [mail_text, setMailText] = useState(
    "Hi…\n\nI’m a customer of HealthLOQ which provides evidence-based confidence to consumers about the safety, origin, and sustainability of a product and its ingredients.  \n\nI’d love to see your products on the platform. \n\nSee HealthLOQ.com for more information and reach out to them at contact@healthloq.com."
  );
  const { user_id } = utils.getLocalStorageItems();

  useEffect(() => {
    if (inviteOrganizationData.status == "1") {
      setEmail("");
      setOrganizationName("");
      setMailText(mail_text);
      handleClose();
      setError({ email: "", organization_name: "", mail_text: "" });
    }
  }, [inviteOrganizationData]);

  useEffect(() => {
    if (trendingOrganizationData) {
      setEmail(trendingOrganizationData?.email);
      setOrganizationName(trendingOrganizationData?.name);
      setMailText(mail_text);
      setError({ email: "", organization_name: "", mail_text: "" });
    }
  }, [trendingOrganizationData]);

  useEffect(() => {
    if (checkBoxSelected) {
      let orgNameArray = [];
      for (let value of checkBoxSelected) {
        for (let trending of trendingOrganizationList) {
          if (trending?.attributes?.email === value) {
            orgNameArray.push(trending.attributes.name);
            break;
          }
        }
      }
      setEmail(checkBoxSelected);
      setOrganizationName(orgNameArray);
      setMailText(mail_text);
      setError({ email: "", organization_name: "", mail_text: "" });
    }
  }, [checkBoxSelected]);

  const emailRegex = new RegExp(
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,6})+$/
  );
  const [error, setError] = useState({
    email: "",
    organization_name: "",
    mail_text: "",
  });
  const handleVerify = () => {
    let obj = { ...error };
    let isValid = true;
    if (!organization_name && !trendingOrganizationData) {
      isValid = false;
      obj["organization_name"] = "Organization name is a required field.";
    }
    if (!mail_text) {
      isValid = false;
      obj["mail_text"] = "Message is a required field.";
    }
    if (!email) {
      isValid = false;
      obj["email"] = "Email is a required field.";
    }
    if (email && !emailRegex.test(email)) {
      isValid = false;
      obj["email"] = "Please enter valid email.";
    }
    setError(obj);
    return isValid;
  };
  const handleSubmit = () => {
    if (checkBoxSelected?.length > 0) {
      if (mail_text) {
        let obj = {
          organization_name,
          email,
          mail_text: nl2br(mail_text),
        };
        inviteOrganization(obj);
      } else {
        let obj = { ...error };
        let isValid = true;
        if (!mail_text) {
          isValid = false;
          obj["mail_text"] = "Message is a required field.";
        }
        setError(obj);
      }
    } else if (handleVerify()) {
      let obj = {
        organization_name:
          typeof email === "string"
            ? typeof organization_name === "object"
              ? Boolean(organization_name[0])
                ? organization_name[0]
                : ""
              : organization_name
            : organization_name,
        email,
        mail_text: nl2br(mail_text),
      };
      inviteOrganization(obj);
    }
  };

  const handleCloseDialog = () => {
    setEmail("");
    setOrganizationName("");
    setMailText(mail_text);
    handleClose();
    setError({ email: "", organization_name: "", mail_text: "" });
  };

  // let jwt_token = jwt.sign({ user_id: user_id }, process.env.REACT_APP_JWT_SECRET);
  // jwt.sign({ user_id: user_id }, process.env.REACT_APP_JWT_SECRET, function(err, token) {
  //   jwt_token = token;
  // });
  // let jwt_token_url = `${subscription}/${jwt_token}`;
  const [copyText, setCopyText] = useState("");
  const copyURL = async () => {
    setCopyText("Copied");
    navigator.clipboard.writeText(jwt_token_url);
    setTimeout(() => {
      setCopyText("");
    }, 5000);
  };

  return (
    <MuiDialog open={open} onClose={handleCloseDialog} maxWidth={"sm"}>
      <MuiDialogTitle onClose={handleCloseDialog}>
        Invite Organization
      </MuiDialogTitle>
      <MuiDialogContent>
        {!trendingOrganizationData && checkBoxSelected.length == 0 && (
          <MuiTextfield
            match={match}
            placeholder="Organization name"
            required
            value={organization_name}
            error={Boolean(error?.organization_name)}
            helperText={error?.organization_name}
            onChange={(e) => {
              setOrganizationName(e.target.value);
              setError({ ...error, organization_name: "" });
            }}
          />
        )}
        {checkBoxSelected.length == 0 && (
          <MuiTextfield
            match={match}
            placeholder="Email"
            labelWithPlaceholder={
              "Please enter organization's email address here"
            }
            required
            value={email}
            error={Boolean(error?.email)}
            helperText={error?.email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError({ ...error, email: "" });
            }}
          />
        )}
        <MuiTextfield
          match={match}
          multiline
          minRows={4}
          placeholder="Message"
          required
          value={mail_text}
          error={Boolean(error?.mail_text)}
          helperText={error?.mail_text}
          onChange={(e) => {
            setMailText(e.target.value);
            setError({ ...error, mail_text: "" });
          }}
        />
        <Button
          onClick={handleSubmit}
          profileBgBtn
          loading={inviteOrganizationData.loading}
        >
          Invite
        </Button>
        {copyText == "Copied" ? (
          <span className="copy-link-section" style={{ marginLeft: "10px" }}>
            <RightSignIcon style={{ height: "20px", width: "20px" }} />{" "}
            {copyText}
          </span>
        ) : (
          <span className="copy-link-section" style={{ marginLeft: "10px" }}>
            Or{" "}
            <span
              title={copyText}
              style={{ cursor: "pointer", color: "#28a745" }}
              onClick={copyURL}
            >
              Click here
            </span>{" "}
            to copy the link to clipboard.
          </span>
        )}
      </MuiDialogContent>
    </MuiDialog>
  );
}

const mapStateToProps = ({ ProfileReducers: { inviteOrganizationData } }) => ({
  inviteOrganizationData,
});

const mapDispatchToProps = {
  inviteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteOrganization);
