import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { LeftPanIcon, RightPanIcon } from "../../constants/svgIcons";
import appImages from "../../constants/AppImage";

const {
  images: { sectionBottomUIImage },
} = appImages;

const useStyle = makeStyles((theme) => ({
  sectionWithTreePlanRoot: {
    position: "relative",
    "&>svg": {
      "&:first-child": {
        position: "absolute",
        bottom: (props) => props?.bottom || 0,
        left: (props) => (props?.position === "left" ? 0 : "unset"),
        right: (props) => (props?.position === "right" ? 0 : "unset"),
      },
    },
  },
  sectionBottomIconRoot: {
    position: "relative",
  },
  sectionBottomIcon: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
}));

export function SectionWithTreePlan({
  className = "",
  children = "",
  position = "",
  bottom = 0,
  ...props
}) {
  const classes = useStyle({ position, bottom });
  return (
    <Box
      className={clsx(classes.sectionWithTreePlanRoot, className)}
      {...props}
    >
      {position === "left" && <LeftPanIcon />}
      {position === "right" && <RightPanIcon />}
      {children}
    </Box>
  );
}

export function SectionBottomUI({
  className = "",
  isComponentRootWrapper = false,
  children = null,
  ...props
}) {
  const classes = useStyle();
  return isComponentRootWrapper ? (
    <Box className={clsx(classes.sectionBottomIconRoot, className)} {...props}>
      {children}
      <img
        src={sectionBottomUIImage}
        className={classes.sectionBottomIcon}
        alt="Section Bottom UI Image"
      />
    </Box>
  ) : (
    <>
      {children}
      <img
        src={sectionBottomUIImage}
        className={classes.sectionBottomIcon}
        alt="Section Bottom UI Image"
      />
    </>
  );
}
