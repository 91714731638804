import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";

const {
  images: { productFeedbackFormImage },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 0",
    "&>img": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      maxWidth: "30%",
      width: "100%",
    },
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function ConsumerFeedback() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>Consumer Feedback</PrimaryHeading>
          </Box>
          <Box className={classes.sectionImage}>
            <img src={productFeedbackFormImage} alt="Image" />
          </Box>
          <Box className={classes.btns}>
            <Button href="/products?product_type=all">Learn More</Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
