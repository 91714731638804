import React from "react";
import { Box, Grid } from "@material-ui/core";
import SEO from "../../components/SEO";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link, useHistory } from "react-router-dom";
import { AuthLinkBox } from "../../components/Auth";
import apiConstants from "../../services/constants";
import appImages from "../../constants/AppImage";

const {
  PRODUCER_APP_URL: { subscription },
} = apiConstants;

const {
  profileImages: { profileBg },
} = appImages;

export default function Signup() {
  const history = useHistory();
  return (
    <>
      <SEO title={"Register - HealthLOQ"} description="Register - HealthLOQ" />
      <Grid
        container
        className={"register-container"}
        style={{ backgroundImage: `url(${profileBg})` }}
      >
        <Grid item className={"auth-rightside"}>
          <Box className="auth-backbtn" onClick={() => history.goBack()}>
            <KeyboardBackspaceIcon />
            <span>Back</span>
          </Box>
          <Box className="auth-content">
            <h1 className="auth-main-heading">Register</h1>
            <p className="auth-main-text">Select where you want to register.</p>
            <AuthLinkBox
              {...{
                heading: "Company",
                link: subscription,
                description: (
                  <>
                    If you are a brand or manufacturer who is committed to
                    transparency, sharing information on your products'
                    ingredients, and communicating directly with consumers who
                    can offer direct feedback on the quality and effectiveness
                    of your product at a batch level,&nbsp;
                    <a href={subscription} rel="noreferrer" target="_blank">
                      Get Started
                    </a>
                    &nbsp; here.
                  </>
                ),
              }}
            />
            <AuthLinkBox
              {...{
                heading: "Consumer",
                link: "/create-profile",
                description: (
                  <>
                    If you are a consumer who cares about what you put in or on
                    your body and are interested in potentially being
                    compensated for sharing your experience with the
                    producer,&nbsp;
                    <Link to={"/create-profile"}>Get Started</Link> here.
                  </>
                ),
              }}
            />
            <p className="auth-login-link">
              Already have an account? <Link to={"/login"}>Login</Link> here.
            </p>
          </Box>
          <Box className="auth-footer-links">
            <Box>
              <Link to={"/terms-and-conditions"} target="_blank">
                Terms & Conditions
              </Link>
              <span></span>
              <Link to={"/privacy"} target="_blank">
                Privacy Policy
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
