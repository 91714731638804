import React from "react";
import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";

export default function MuiCircularProgress({
  size = 20,
  color = "#28a745",
  className = "",
  style,
  ...props
}) {
  return (
    <CircularProgress
      size={size}
      style={{ color: color, ...style }}
      className={clsx(className)}
      {...props}
    />
  );
}
