import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AppImages } from "../../constants";
import PopupModal from "../../components/PopupModal";
import Helpers from "../../helpers";
import { dispatchBlockchainProof } from "../../redux/actions";
import { useParams } from "react-router-dom";

const {
  Utils: { unCamelCase },
} = Helpers;

const {
  icon: { iconRight },
} = AppImages;

const BlockchainProof = ({
  blockchainProof,
  exhibitBlockchainProof,
  getblockchainProof,
  modelBlockChainType,
}) => {
  const [isVisible, setModalVisible] = useState(false);
  const urlParam = useParams();
  const { blockchainType } = urlParam;
  useEffect(() => {
    if (blockchainType !== "integrant" && !modelBlockChainType) {
      getblockchainProof({
        id: blockchainType,
        type: "exhibit",
        showCustomLoader: true,
      });
    }
  }, [blockchainType, getblockchainProof]);

  if (blockchainType !== "integrant" && !modelBlockChainType) {
    if (Object.keys(exhibitBlockchainProof).length !== 0) {
      const {
        blockAddress: { IonText },
        data: { Title, ExhibitID, Type, organization_id, organization_name },
      } = exhibitBlockchainProof;
      return (
        <div className="content-blockchain">
          <div className="blockchain-left-content">
            <div className="blockchain-certified">
              <p className="title-bc f">Block Location</p>
              <p>Block ID: {IonText}</p>
              <div className="d-flex align-items-center">
                <div className="w100">
                  <div className="strip-status d-flex align-items-center">
                    <p>Status: Verified</p>
                    <img src={iconRight} alt="" />
                  </div>
                  <p className="title-bc">Exhibit Information</p>
                  <p>Document Name: {Title}</p>
                  <p>Document ID: {ExhibitID}</p>
                  <p>Document Type: {Type}</p>

                  <p className="title-bc">Digitally Signed By</p>
                  <p>Organization Name: {organization_name}</p>
                  <p>Organization Id: {organization_id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }

  /**  Modal* */

  function modalHandler(val) {
    localStorage.setItem("healthloq_intro", val);
    setModalVisible(val);
  }
  /** End Intro Modal* */
  const dataCheck = (d) => {
    if (d) {
      if (typeof d === "boolean") {
        return d ? "Yes" : "No";
      }
      return d;
    }
    return "N/A";
  };

  const facets = (data) => {
    if (data === "[object Object]") {
      return [];
    } else {
      const uf = JSON.parse(JSON.stringify(data));
      if (uf && uf.length !== 0) {
        facetsBeauty(uf);
      }
      return "N/A";
    }
  };

  const facetsBeauty = (data) => {
    if (typeof data !== "string" && data) {
      return data.map((item, index) => {
        return (
          <div key={index} style={{ display: "flex", flexDirection: "column" }}>
            <span>{item.title}:</span>{" "}
            <span
              className="p-m-0"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        );
      });
    }
    return null;
  };

  if (Object.keys(blockchainProof).length !== 0) {
    const {
      blockAddress: { IonText },
      data,
      data: {
        Title,
        ExternalId,
        OrganizationName,
        OrganizationId,
        IntegrantTypeImageUrl,
      },
    } = blockchainProof;
    return (
      <div className="content-blockchain">
        <div className="popup-blockchain">
          <PopupModal
            isVisible={isVisible}
            onClose={modalHandler}
            closeBtnClass="btn btn-success"
          >
            <div className="blockchain-popup-content">
              <table className="table table-bordered ">
                {Object.keys(data).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{ minWidth: "150px", fontWeight: "bold" }}
                        valign="top"
                      >
                        {unCamelCase(item)}
                      </td>
                      <td>
                        {item === "Facets"
                          ? facets(data[item])
                          : item === "OtherFacets"
                          ? facetsBeauty(data[item])
                          : dataCheck(data[item])}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </PopupModal>
        </div>
        <div className="blockchain-left-content">
          <div className="blockchain-certified">
            <p className="title-bc f">Block Location</p>
            <p>Block ID: {IonText}</p>
            <div className="d-flex align-items-center">
              <div className="w100">
                <div className="strip-status d-flex align-items-center">
                  <p>Status: Verified</p>
                  <img src={iconRight} alt="" />
                </div>

                <p className="title-bc">Product Information</p>
                <p>Product Name: {Title}</p>
                <p>Batch ID: {ExternalId}</p>

                <p className="title-bc">Digitally Signed By</p>
                <p>Organization Name: {OrganizationName}</p>
                <p>Organization Id: {OrganizationId}</p>
                <div className="section-btn-proof">
                  <span
                    className="btn btn-success"
                    onClick={() => setModalVisible(true)}
                  >
                    Show Proof
                  </span>
                </div>
              </div>
              <div>
                <img
                  src={IntegrantTypeImageUrl}
                  alt=""
                  className="img-blockchain-certified"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="chain">
          <div>
            {Array(20)
              .fill()
              .map((item, index) => {
                if (index === 4) {
                  return (
                    <div className="active" key={index}>
                      <i className="material-icons">lock_open</i>
                    </div>
                  );
                }
                return (
                  <div key={index}>
                    <i className="material-icons">lock</i>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
  return <Loading />;
};

const Loading = () => {
  return (
    <div className="content-blockchain d-flex align-items-center justify-content-center">
      Please Wait...
    </div>
  );
};

const mapStateToProps = ({ productReducers: { exhibitBlockchainProof } }) => ({
  exhibitBlockchainProof,
});
const mapDispatchToProps = {
  getblockchainProof: dispatchBlockchainProof,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockchainProof);
