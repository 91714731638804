import React, { useState, useRef, useEffect } from "react";
import {
  Fade,
  Grid,
  Popper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as HistoryIcon } from "../../constants/svg/icon-history.svg";
import { ReactComponent as ProfileIcon } from "../../constants/svg/icon-profile.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import utils, { verifyEnvironment } from "../../utils/commonUtils";
import {
  FeedbackOutlined,
  LocalOffer,
  MoreHoriz,
  PersonAddRounded,
} from "@material-ui/icons";
import PeopleIcon from "@material-ui/icons/People";
import PostIcon from "@material-ui/icons/Dashboard";
import { connect } from "react-redux";

const useStyle = makeStyles((theme) => ({
  morePopperLi: {
    "&>div": {
      "&:nth-child(1)": {
        minWidth: "max-content",
        "&>svg": {
          width: 20,
          height: 20,
          marginRight: 10,
        },
      },
      "&:nth-child(2)": {
        "&>span": {
          fontSize: "1rem",
          lineHeight: "1.5",
          fontWeight: 400,
          textTransform: "capitalize",
        },
      },
    },
  },
  activeMorePopperLi: {
    "&>div": {
      "&:nth-child(1)": {
        "&>svg": {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
      },
      "&:nth-child(2)": {
        "&>span": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

function SmProfileFooter({ activePage, history, tourActiveStep }) {
  const classes = useStyle();
  const moreButtonRef = useRef(null);
  let data = [
    {
      label: "Offers",
      icon: <LocalOffer />,
      activePage: 4,
      path: "/profile",
      tourKey: "offers",
    },
    {
      label: "Companies",
      icon: <PeopleIcon />,
      activePage: 12,
      path: "/connections",
      tourKey: "connections",
    },
    {
      label: "Feedback",
      icon: <FeedbackOutlined />,
      activePage: 13,
      path: "/feedback",
      tourKey: "feedback",
    },
    {
      label: "Profile",
      icon: <ProfileIcon />,
      activePage: 5,
      path: "/profile-details",
      tourKey: "profile",
    },
    {
      label: "Invite",
      icon: <PersonAddRounded />,
      activePage: 14,
      path: "/invite",
      tourKey: "invite",
    },
    {
      label: "History",
      icon: <HistoryIcon />,
      activePage: 11,
      path: "/survey-history",
      tourKey: "history",
    },
    {
      label: "Logout",
      icon: <ExitToAppIcon />,
      activePage: null,
      path: "/profile-login",
    },
    {
      label: "More",
      icon: <MoreHoriz />,
    },
  ];

  let otherLinks = ["Profile", "Invite", "History", "Logout"];
    data.splice(6, 0, {
      label: "Social",
      icon: <PostIcon />,
      activePage: 16,
      path: "/posts",
    });
    otherLinks.splice(3, 0, "Social");
  const [openOtherLinks, setOpenOtherLinks] = useState(null);

  const handleClick = (item) => (e) => {
    if (item.label === "Logout") {
      utils.removeItemsFromLocalStorage(["auth_token", "otpVerified"]);
      window.sessionStorage.clear();
      window.location.href = "/profile-login";
    } else if (item.label === "More") {
      setOpenOtherLinks(e.currentTarget);
    } else {
      history.push(item.path);
    }
    if (openOtherLinks) setOpenOtherLinks(null);
  };

  useEffect(() => {
    if (
      moreButtonRef.current &&
      [4, 8].includes(tourActiveStep) &&
      !openOtherLinks
    ) {
      moreButtonRef.current?.click();
    }
  }, [moreButtonRef, tourActiveStep, openOtherLinks]);

  return (
    <Grid className="profile-page-footer">
      {data
        ?.filter((item) => !otherLinks.includes(item.label))
        .map((item, key) => {
          return (
            <Grid
              key={key}
              className={clsx(activePage === item.activePage && "active")}
              onClick={handleClick(item)}
              profile-tour={item?.tourKey || ""}
              innerRef={item?.label === "More" ? moreButtonRef : null}
            >
              {item.icon}
              <p>{item.label}</p>
            </Grid>
          );
        })}
      <Popper
        open={Boolean(openOtherLinks)}
        anchorEl={openOtherLinks}
        placement={"top"}
        transition
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List>
                {data
                  ?.filter((item) => otherLinks.includes(item.label))
                  ?.map((item, key, arr) => {
                    return (
                      <React.Fragment key={key}>
                        <ListItem
                          onClick={handleClick(item)}
                          className={clsx(
                            classes.morePopperLi,
                            item.activePage === activePage &&
                              classes.activeMorePopperLi
                          )}
                          profile-tour={item?.tourKey || ""}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItem>
                        {key !== arr.length - 1 && <Divider />}
                      </React.Fragment>
                    );
                  })}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
}

const mapStateToProps = ({ CommonReducers: { tourActiveStep } }) => ({
  tourActiveStep,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmProfileFooter);
