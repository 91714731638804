import actionType from "../actionsType"

const { TOGGLE_LOADER } = actionType

const toggleLoader = payload => {
    return {
        type: TOGGLE_LOADER,
        payload
    }
}

const dispatchLoader = payload => dispatch => {
    dispatch({
        type: TOGGLE_LOADER,
        payload
    })
}

export default toggleLoader
export { dispatchLoader }
