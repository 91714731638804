import actionType from "../actionsType"

const { TOGGLE_LOADER } = actionType
const loaderReducer = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_LOADER:
            return action.payload
        default:
            return state
    }
}

export default loaderReducer
