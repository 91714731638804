import React from "react";
import { makeStyles, Avatar, Box } from "@material-ui/core";
import { AppImages } from "../../constants";
import clsx from "clsx";

const {
  logo,
  profileImages: { createProfileFemaleImage, createProfileMaleImage },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  avatarContainer: {
    marginRight: 15,
    padding: 3,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default function UserAvatar({
  gender = "",
  src = "",
  onClick,
  size = 35,
  className = "",
  marginRight = 15,
  useCustomStyle = false,
  ...props
}) {
  const classes = useStyle();
  let new_logo = createProfileMaleImage;
  if (gender == "Female") {
    new_logo = createProfileFemaleImage;
  }
  return (
    <Box
      className={clsx(!useCustomStyle && classes.avatarContainer, className)}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "inherit" }}
    >
      <Avatar
        src={src || new_logo}
        style={{ width: size, height: size }}
        {...props}
      ></Avatar>
    </Box>
  );
}
