export const CREATE_POST_START = "CREATE_POST_START";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const SOCKET_CREATE_POST = "SOCKET_CREATE_POST";
export const GET_POSTS_LIST_START = "GET_POSTS_LIST_START";
export const GET_POSTS_LIST_SUCCESS = "GET_POSTS_LIST_SUCCESS";
export const POST_LIKE_DISLIKE_START = "POST_LIKE_DISLIKE_START";
export const POST_LIKE_DISLIKE_SUCCESS = "POST_LIKE_DISLIKE_SUCCESS";
export const SOCKET_LIKE_DISLIKE = "SOCKET_LIKE_DISLIKE";
export const POST_COMMENT_START = "POST_COMMENT_START";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const SOCKET_POST_COMMENT = "SOCKET_POST_COMMENT";
export const GET_POST_COMMENT_LIST_START = "GET_POST_COMMENT_LIST_START";
export const GET_POST_COMMENT_LIST_SUCCESS = "GET_POST_COMMENT_LIST_SUCCESS";
export const UPDATE_POST_START = "UPDATE_POST_START";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const SOCKET_UPDATE_POST = "SOCKET_UPDATE_POST";
export const DELETE_POST_START = "DELETE_POST_START";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const SOCKET_DELETE_POST = "SOCKET_DELETE_POST";
export const GET_POST_DETAIL_START = "GET_POST_DETAIL_START";
export const GET_POST_DETAIL_SUCCESS = "GET_POST_DETAIL_SUCCESS";
export const GET_POST_LIKED_USERS_START = "GET_POST_LIKED_USERS_START";
export const GET_POST_LIKED_USERS_SUCCESS = "GET_POST_LIKED_USERS_SUCCESS";
export const DELETE_POST_COMMENT_START = "DELETE_POST_COMMENT_START";
export const DELETE_POST_COMMENT_SUCCESS = "DELETE_POST_COMMENT_SUCCESS";
export const SOCKET_DELETE_POST_COMMENT = "SOCKET_DELETE_POST_COMMENT";
export const UPDATE_POST_COMMENT_START = "UPDATE_POST_COMMENT_START";
export const UPDATE_POST_COMMENT_SUCCESS = "UPDATE_POST_COMMENT_SUCCESS";
export const SOCKET_UPDATE_POST_COMMENT = "SOCKET_UPDATE_POST_COMMENT";
export const SHARE_POST_START = "SHARE_POST_START";
export const SHARE_POST_SUCCESS = "SHARE_POST_SUCCESS";
export const SOCKET_SHARE_POST = "SOCKET_SHARE_POST";
export const SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST =
  "SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST";
export const GET_LINK_PREVIEW_START = "GET_LINK_PREVIEW_START";
export const GET_LINK_PRIVIEW_SUCCESS = "GET_LINK_PRIVIEW_SUCCESS";
export const UPDATE_PROFILE_POST = "UPDATE_PROFILE_POST";
