/* eslint-disable no-unused-vars */
import ReactGA from "react-ga";
import actionType from "../actionsType";
import toggleLoader from "./Loader";
import { productAPI } from "../../services";
import Helpers from "../../helpers";
import apiConstants from "../../services/constants";
import { NotificationManager } from "react-notifications";

const {
  INTEGRANTS_LIST,
  INTEGRANTS_DETAIL,
  INTEGRANTS_LIST_IS_LOADING,
  INTEGRANTS_ERROR,
  INTEGRANTS_EXHIBITS_DETAIL,
  INTEGRANTS_BATCHES_LIST,
  PRODUCTLIST,
  PRODUCTLIST_LOADING,
  BATCHLIST,
  ORGANIZATIONLIST,
  ORGANIZATIONLISTLOADING,
  GET_ALL_INTEGRANT_TYPES_LIST_START,
  GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS,
  BLOCKCHAINPROOF,
  BLOCKCHAINPROOF_LOADING,
  INGREDIENTS_IDS,
  PRODUCT_CURRENT_TAB,
  VERIFY_DOCUMENT_LOADING,
  VERIFY_DOCUMENT_SUCCESS,
  UPDATE_MAPDATA_INTEGRANT_ID,
  GET_INTEGRANT_BATCH_LIST_START,
  GET_INTEGRANT_BATCH_LIST_SUCCESS,
  GET_ORGANIZATION_DETAIL_START,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
  GET_EXHIBIT_BLOCKCHAIN_PROOF_START,
  GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START,
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
  SET_INITIAL_BLOCKCHAIN_DATA,
  UPDATE_VERIFY_DOCUMENT,
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
  VERIFY_COA_DOCUMENT_LOADING,
  VERIFY_COA_DOCUMENT_SUCCESS,
} = actionType;
const {
  HTTP_STATUS_CODES: { SUCCESS, NOT_FOUND, UNAUTHORIZED_ACCESS, SUCCESS201 },
  ERROR_MESSAGES: { SOMETHING_WRONG },
} = apiConstants;

const {
  Utils: { redirectToPage },
} = Helpers;

export const setInitialBlockchainProofStates = (params) => (dispatch) => {
  dispatch({
    type: SET_INITIAL_BLOCKCHAIN_DATA,
  });
};

export const updateVerifyDocumentData = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_VERIFY_DOCUMENT,
    payload: params,
  });
};

export const getOrgExhibitBlockchainProof = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START,
    });
    const response = await productAPI.getBlockchainProof(params);
    dispatch({
      type: GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
      payload: response.data,
    });
    ReactGA.event({
      category: "Blockchain Proof",
      action: "Get organization exhibit blockchian proof.",
    });
  } catch (error) {
    dispatch({
      type: GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
      payload: {},
    });
  }
};

export const getExhibitBlockchainProof = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EXHIBIT_BLOCKCHAIN_PROOF_START,
    });
    const response = await productAPI.getBlockchainProof(params);
    dispatch({
      type: GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
      payload: response.data,
    });
    ReactGA.event({
      category: "Blockchain Proof",
      action: "Get exhibit blockchian proof.",
    });
  } catch (error) {
    dispatch({
      type: GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS,
      payload: {},
    });
  }
};

export const getDocumentHashBlockchainProof = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
    });
    const response = await productAPI.getBlockchainProof(params);
    dispatch({
      type: GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
      payload: response.data,
    });
    ReactGA.event({
      category: "Blockchain Proof",
      action: "Get document hash blockchian proof.",
    });
  } catch (error) {
    dispatch({
      type: GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
      payload: {},
    });
  }
};

export const getLabDocumentHashBlockchainProof =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START,
      });
      const response = await productAPI.getBlockchainProof(params);
      dispatch({
        type: GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
        payload: response.data,
      });
      ReactGA.event({
        category: "Blockchain Proof",
        action: "Get lab document hash blockchian proof.",
      });
    } catch (error) {
      dispatch({
        type: GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS,
        payload: {},
      });
    }
  };

export const getOrganizationDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_DETAIL_START,
    });
    const response = await productAPI.getOrganizationDetail(id);
    dispatch({
      type: GET_ORGANIZATION_DETAIL_SUCCESS,
      payload: response.data,
    });
    if (response?.data?.status !== "1") {
      NotificationManager.error(response?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Organization",
      action: "Get organization detail.",
    });
  } catch (error) {
    dispatch({
      type: GET_ORGANIZATION_DETAIL_SUCCESS,
      payload: {
        status: "0",
        message: error.message,
      },
    });
  }
};

export const dispatchUpdateProductCurrentTab = (props) => (dispatch) => {
  dispatch({
    type: PRODUCT_CURRENT_TAB,
    payload: props,
  });
};

export const dispatchUpdateMapDataIntegrantId = (props) => (dispatch) => {
  dispatch({
    type: UPDATE_MAPDATA_INTEGRANT_ID,
    payload: props,
  });
};

const dispatchIntegrantsIsLoading = (props) => (dispatch) => {
  dispatch({
    type: INTEGRANTS_LIST_IS_LOADING,
    payload: props,
  });
};

const toggleErrorModal = (dispatch, status, error) => {
  switch (status) {
    case NOT_FOUND:
    case UNAUTHORIZED_ACCESS:
      dispatch({
        type: INTEGRANTS_ERROR,
        payload: {
          error,
        },
      });
      break;
    default:
      redirectToPage(status);
  }
};

const catchHandler = (dispatch, error) => {
  const {
    response: { status, data },
  } = error;
  ReactGA.exception({
    error,
    description: "An error ocurred",
    fatal: true,
  });
  dispatch(toggleLoader(false));
  toggleErrorModal(dispatch, status, {
    isError: true,
    errorMessage: SOMETHING_WRONG,
    data,
  });
};

export const dispatchIntegrantsList = (props) => (dispatch) => {
  dispatch(dispatchIntegrantsIsLoading(true));
  dispatch({
    type: INTEGRANTS_LIST_IS_LOADING,
    payload: true,
  });
  return productAPI
    .getintegrantsList({ searchText: props })
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        dispatch(dispatchIntegrantsIsLoading(false));
        dispatch({
          type: INTEGRANTS_LIST,
          payload: data,
        });
        ReactGA.event({
          category: "Search",
          action: "Search Integrants List",
        });
        return true;
      }
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      catchHandler(dispatch, error);
    });
};

export const dispatchIntegrantsDetail = (props) => (dispatch) => {
  dispatch(toggleLoader(true));
  return productAPI
    .getintegrantsDetail(props)
    .then((response) => {
      const {
        status,
        data,
        data: { integrantInfo },
      } = response;
      if (status === SUCCESS) {
        if (integrantInfo === null) {
          toggleErrorModal(dispatch, 500, {});
        }
        dispatch({
          type: INTEGRANTS_DETAIL,
          payload: data,
        });
        ReactGA.event({
          category: "Integrant",
          action: "Get Integrants Detail",
        });
      }
      return data;
    })
    .then((data) => {
      const {
        integrantInfo: { id, integrant_type_id },
      } = data;
      return productAPI
        .getintegrantsBatches({
          integrant_type_id: integrant_type_id,
          integrant_id: id,
        })
        .then((response) => {
          const { status, data } = response;
          if (status === SUCCESS) {
            dispatch({
              type: INTEGRANTS_BATCHES_LIST,
              payload: data,
            });
            ReactGA.event({
              category: "Integrant",
              action: "Other Batch List",
            });
          }
          dispatch(toggleLoader(false));
        });
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      catchHandler(dispatch, error);
    });
};

export const dispatchexhibitsList = (props) => (dispatch) => {
  dispatch(toggleLoader(true));
  return productAPI
    .getexhibitsList(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        if (data?.length !== 0) {
          dispatch(toggleLoader(false));
        }
        dispatch({
          type: INTEGRANTS_EXHIBITS_DETAIL,
          payload: data,
        });
        ReactGA.event({
          category: "Product",
          action: "Exhibits List",
        });
        return false;
      }
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      catchHandler(dispatch, error);
    });
};

export const dispatchIntegrantsDetailClean = () => (dispatch) => {
  dispatch({
    type: INTEGRANTS_DETAIL,
    payload: {},
  });
};

export const dispatchIntegrantsListClean = (props) => (dispatch) => {
  dispatch({
    type: INTEGRANTS_LIST,
    payload: [],
  });
};

export const dispatchProductList = (props) => (dispatch) => {
  let loadingArr = [];
  if (props?.facetsLoading) {
    loadingArr.push("traits");
  }
  if (props?.productTypeLoading) {
    loadingArr.push("product_type");
  }
  dispatch({
    type: PRODUCTLIST_LOADING,
    payload: loadingArr,
  });
  if (!loadingArr.length) {
    dispatch(toggleLoader(true));
  }
  return productAPI
    .getproductList(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        dispatch(toggleLoader(false));
        dispatch({
          type: PRODUCTLIST,
          payload: data,
        });
        ReactGA.event({
          category: "Product Catalog",
          action: "Get All Product List ",
        });
        return true;
      }
      dispatch({
        type: PRODUCTLIST,
        payload: {
          count: 0,
          list: [],
        },
      });
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      dispatch(toggleLoader(false));
      dispatch({
        type: PRODUCTLIST,
        payload: {
          count: 0,
          list: [],
        },
      });
      catchHandler(dispatch, error);
    });
};

export const dispatchOrganizationList = (props) => (dispatch) => {
  const showCustomLoader = props?.showCustomLoader;
  if (showCustomLoader) {
    dispatch({
      type: ORGANIZATIONLISTLOADING,
      payload: true,
    });
    delete props?.showCustomLoader;
  } else {
    dispatch(toggleLoader(true));
  }
  return productAPI
    .getOrganizationList(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        // dispatch(toggleLoader(false));
        dispatch({
          type: ORGANIZATIONLIST,
          payload: data,
        });
        if (showCustomLoader) {
          dispatch({
            type: ORGANIZATIONLISTLOADING,
            payload: false,
          });
        }
        ReactGA.event({
          category: "Organization Catalog",
          action: "Get All Organization List ",
        });
        return true;
      }
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      //dispatch(toggleLoader(false))
      catchHandler(dispatch, error);
    });
};

export const dispatchGetAllIntegrantTypesList = (props) => (dispatch) => {
  dispatch({
    type: GET_ALL_INTEGRANT_TYPES_LIST_START,
  });
  return productAPI
    .getAllIntegrantTypesList(props)
    .then((response) => {
      const { status, data } = response;
      dispatch({
        type: GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS,
        payload: data,
      });
      ReactGA.event({
        category: "Integrant types Catalog",
        action: "Get All Integrant types List ",
      });
      return true;
    })
    .catch((error) => {
      catchHandler(dispatch, error);
    });
};

export const dispatchGetIntegrantBatchList = (props) => (dispatch) => {
  dispatch({
    type: GET_INTEGRANT_BATCH_LIST_START,
  });
  return productAPI
    .getIntegrantBatchList(props)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: GET_INTEGRANT_BATCH_LIST_SUCCESS,
        payload: data,
      });
      ReactGA.event({
        category: "Integrants Catalog",
        action: "Get Integrant batch List ",
      });
      return true;
    })
    .catch((error) => {
      catchHandler(dispatch, error);
    });
};

export const dispatchVerifyDocument = (props) => (dispatch) => {
  dispatch({
    type: VERIFY_DOCUMENT_LOADING,
    payload: true,
  });
  return productAPI
    .verifyDocument(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        dispatch({
          type: VERIFY_DOCUMENT_SUCCESS,
          payload: data,
        });
        ReactGA.event({
          category: "Verify Document Catalog",
          action: "Verify blockchain proof",
        });
        return true;
      }
      dispatch({
        type: VERIFY_DOCUMENT_LOADING,
        payload: false,
      });
      return false;
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_DOCUMENT_LOADING,
        payload: false,
      });
      NotificationManager.error(
        error?.response?.data?.message ||
          "Something went wrong! Please try after sometime.",
        "",
        5000
      );
      catchHandler(dispatch, error);
    });
};

export const dispatchVerifyCoaDocument =
  (props, activeOrgDocuments = []) =>
  (dispatch) => {
    dispatch({
      type: VERIFY_COA_DOCUMENT_LOADING,
      payload: {
        loading: true,
        activeOrgDocuments,
      },
    });
    return productAPI
      .verifyCoaDocument(props)
      .then((response) => {
        const { status, data } = response;
        if (status === SUCCESS && data?.status === "1") {
          dispatch({
            type: VERIFY_COA_DOCUMENT_SUCCESS,
            payload: { ...data, loading: false, activeOrgDocuments },
          });
          ReactGA.event({
            category: "Verify Document Catalog",
            action: "Verify blockchain proof",
          });
          return true;
        } else {
          dispatch({
            type: VERIFY_COA_DOCUMENT_LOADING,
            payload: {
              loading: false,
              activeOrgDocuments: activeOrgDocuments?.slice(
                0,
                activeOrgDocuments?.length - 1
              ),
            },
          });
          NotificationManager.error(
            data?.message || "Something went wrong! Please try after sometime.",
            "",
            5000
          );
        }
        return false;
      })
      .catch((error) => {
        dispatch({
          type: VERIFY_COA_DOCUMENT_LOADING,
          payload: {
            loading: false,
            activeOrgDocuments: activeOrgDocuments?.slice(
              0,
              activeOrgDocuments?.length - 1
            ),
          },
        });
        NotificationManager.error(
          error?.response?.data?.message ||
            "Something went wrong! Please try after sometime.",
          "",
          5000
        );
        catchHandler(dispatch, error);
      });
  };

export const dispatchCurrentBatch = (props) => (dispatch) => {
  dispatch(toggleLoader(true));
  return productAPI
    .getCurrentBatch(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        dispatch(toggleLoader(false));
        dispatch({
          type: BATCHLIST,
          payload: data,
        });
        ReactGA.event({
          category: "Product Catalog",
          action: "Item Click and get latest Batch",
        });
        return data;
      }
      return false;
    })
    .catch((error) => {
      ReactGA.exception({
        error,
        description: "An error ocurred",
        fatal: true,
      });
      // throw error // to hendal error on component level
      dispatch(toggleLoader(false));
      alert("No Batch Exists for this product.");
      //catchHandler(dispatch, error)
    });
};

export const dispatchBlockchainProof = (props) => (dispatch) => {
  const showCustomLoader = props?.showCustomLoader;
  if (showCustomLoader) {
    dispatch({
      type: BLOCKCHAINPROOF_LOADING,
      payload: true,
    });
  } else {
    dispatch(toggleLoader(true));
  }
  return productAPI
    .getBlockchainProof(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        if (showCustomLoader) {
          dispatch({
            type: BLOCKCHAINPROOF_LOADING,
            payload: false,
          });
        } else {
          dispatch(toggleLoader(false));
        }
        dispatch({
          type: BLOCKCHAINPROOF,
          payload: data,
        });
        return data;
      }
      if (showCustomLoader) {
        dispatch({
          type: BLOCKCHAINPROOF_LOADING,
          payload: false,
        });
      }
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      if (showCustomLoader) {
        dispatch({
          type: BLOCKCHAINPROOF_LOADING,
          payload: false,
        });
      }
      dispatch(toggleLoader(false));
      //catchHandler(dispatch, error)
    });
};

export const dispatchIngredientsIds = (props) => (dispatch) => {
  return productAPI
    .getIngredientsIds(props)
    .then((response) => {
      const { status, data } = response;
      if (status === SUCCESS) {
        dispatch({
          type: INGREDIENTS_IDS,
          payload: data,
        });
        ReactGA.event({
          category: "Ingredients List",
          action: "Get Ingredients Ids",
        });
        return false;
      }
      return false;
    })
    .catch((error) => {
      // throw error // to hendal error on component level
      catchHandler(dispatch, error);
    });
};
