import React from "react";
import ReactPlayer from "react-player";

const PrimaryVideoPlayer = ({ url = "", onError = () => {}, light = "" }) => {
  return (
    <ReactPlayer
      url={url}
      onError={onError}
      controls
      playsinline
      playing={true}
      light={light}
    />
  );
};

export default PrimaryVideoPlayer;
