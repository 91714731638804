import { Button } from "@material-ui/core";
import {
  SolutionFeature1,
  SolutionFeature2,
  SolutionFeature3,
} from "../../constants/svgIcons";
import BoxSectionComponent from "../Common/BoxSectionComponent";

const content = [
  {
    icon: <SolutionFeature1 />,
    content: "Automate the verification of supplier onboarding documentation",
  },
  {
    icon: <SolutionFeature2 />,
    content: "Validate supplier and lab ISO / GMP certifications  ",
  },
  {
    icon: <SolutionFeature3 />,
    content: "Prevent fraudulent COAs and other documentation ",
  },
];
export default function DocumentationSection() {
  return (
    <BoxSectionComponent
      content={content}
      heading="Simplify and automate the verification of supplier documentation"
      actionBtn={
        <Button href={`${process.env.REACT_APP_PRODUCER_APP_URL}/plans?tab=2`}>
          Verify a Document
        </Button>
      }
    />
  );
}
