import { config } from "./config";
const getEnvUrls = () => {
  const {
    REACT_APP_CLIENT_URL_API,
    REACT_APP_PRODUCER_APP_URL,
    REACT_APP_TRACKING_ID,
    REACT_APP_GA_MEASUREMENT_ID,
  } = config;
  return {
    apiURL:
      REACT_APP_CLIENT_URL_API !== "#{CLIENT_URL_API}#"
        ? REACT_APP_CLIENT_URL_API
        : process.env.REACT_APP_CLIENT_URL_API,
    producerAppURL:
      REACT_APP_PRODUCER_APP_URL !== "#{PRODUCER_APP_URL}#"
        ? REACT_APP_PRODUCER_APP_URL
        : process.env.REACT_APP_PRODUCER_APP_URL,
    trackingID:
      REACT_APP_TRACKING_ID !== "#{TRACKING_ID}#"
        ? REACT_APP_TRACKING_ID
        : process.env.REACT_APP_TRACKING_ID,
    measurementId:
      REACT_APP_GA_MEASUREMENT_ID !== "#{MEASUREMENT_ID}#"
        ? REACT_APP_GA_MEASUREMENT_ID
        : process.env.REACT_APP_GA_MEASUREMENT_ID,
  };
};

export const { apiURL, producerAppURL, trackingID, measurementId } =
  getEnvUrls();
const strapiAPIURL = "https://newsroom.healthloq.com";

const apiConstants = {
  URL: {
    INTEGRANTS_LIST: `${apiURL}/client-app/search`,
    GET_TRAITS_LIST: `${apiURL}/client-app/get-traits`,
    INTEGRANTS_DETAIL: `${apiURL}/client-app/integrant-detail`,
    INTEGRANTS_EXHIBITS_DETAIL: `${apiURL}/client-app/exhibits`,
    INGREDIENTS_IDS: `${apiURL}/client-app/get-ingredients-ids`,
    INTEGRANTS_BATCHES_LIST: `${apiURL}/client-app/other-batches`,
    PRODUCTLIST: `${apiURL}/client-app/integrants-type`,
    BATCHLIST: `${apiURL}/client-app/latest-batch-information`,
    ORGANIZATIONLIST: `${apiURL}/client-app/organization-list`,
    ORGANIZATION_DETAIL: `${apiURL}/client-app/organization`,
    GET_ALL_INTEGRANT_TYPES_LIST: `${apiURL}/client-app/integrant-type-list`,
    GET_INTEGRANT_BATCH_LIST: `${apiURL}/client-app/integrant-batch-list`,
    BLOCKCHAINPROOF: `${apiURL}/client-app/verify`,
    GETALLBLOGNEWSLIST: `${strapiAPIURL}/api/blogs?populate=*&sort=createdAt:desc`,
    // GETTRENDINGORGANIZATIONSLIST: `${strapiAPIURL}/api/organizations?populate=*&pagination[page]=1&pagination[pageSize]=10`,
    GETTRENDINGORGANIZATIONSLIST: `${strapiAPIURL}/api/organizations?populate=*`,
    VERIFY_DOCUMENT: `${apiURL}/client-app/verify-document`,
    VERIFY_COA_DOCUMENT: `${apiURL}/client-app/verify-coa-document`,
    TOGGLE_PRODUCER_CONNECTIONS: `${apiURL}/producer-connections/toggle`,
    PROFILE_SIGNUP: `${apiURL}/user/create`,
    PROFILE_LOGIN: `${apiURL}/user/login`,
    PRODUCT_FEEDBACK: `${apiURL}/feedback/create`,
    GET_CATEGORIES: `${apiURL}/category/lists`,
    GET_USER: `${apiURL}/user/detail`,
    GET_MY_DATA: `${apiURL}/user/getMyData`,
    UPDATE_USER: `${apiURL}/user/update`,
    DELETE_USER: `${apiURL}/user/delete`,
    GET_SURVEY_QUESTIONS: `${apiURL}/surveyquestions/lists`,
    SUBMIT_SURVEY_ANSWERS: `${apiURL}/surveyquestionanswers/create`,
    UPDATE_PASSWORD: `${apiURL}/user/password`,
    FORGOT_PASSWORD: `${apiURL}/user/forgot-password`,
    VERIFY_OTP: `${apiURL}/user/verify-otp`,
    RESEND_OTP: `${apiURL}/user/resend-otp`,
    LOGOUT: `${apiURL}/user/logout`,
    UPDATE_PROFILE_PICTURE: `${apiURL}/user/update-profile-picture`,
    GET_USER_CONNECTIONS: `${apiURL}/user/getMyConnections`,
    GET_USER_SURVEY_HISTORY: `${apiURL}/surveyquestionanswers/history`,
    INVITE_ORGANIZATION: `${apiURL}/invite-organization/create`,
    INVITE_USER: `${apiURL}/invite-user/create`,
    GET_INVITE_ORGANIZATION_HISTORY: `${apiURL}/invite-organization/history`,
    USERS_LIST: `${apiURL}/user/lists`,
    FOLLOW_UNFOLLOW_USER: `${apiURL}/user/follow`,
    CREATE_POST: `${apiURL}/post/create`,
    SHARE_POST: `${apiURL}/post/share`,
    UPDATE_POST: `${apiURL}/post/update`,
    DELETE_POST: `${apiURL}/post/delete`,
    Get_POST_DETAIL: `${apiURL}/post/detail`,
    POSTS_LIST: `${apiURL}/post/list`,
    POST_LIKE_DISLIKE: `${apiURL}/post/like-create`,
    GET_POST_LIKED_USERS_LIST: `${apiURL}/post/like-user`,
    POST_COMMENT: `${apiURL}/post/comment-create`,
    GET_POST_COMMENT_LIST: `${apiURL}/post/comment-list`,
    DELETE_POST_COMMENT: `${apiURL}/post/comment-delete`,
    UPDATE_POST_COMMENT: `${apiURL}/post/comment-update`,
    GET_PRODUCT_LIST_FOR_MENTION: `${apiURL}/client-app/product`,
    GET_LINK_PRIVIEW: `${apiURL}/post/link-preview`,
    GET_POST_HASHTAGS: `${apiURL}/post/getPostHashtags`,
    GET_USER_NOTIFICATIONS: `${apiURL}/user-notification/getUserNotifications`,
    MARK_AS_READ_USER_NOTIFICATIONS: `${apiURL}/user-notification/markAsReadUserNotification`,
  },
  HTTP_STATUS_CODES: {
    SUCCESS: 200,
    SUCCESS201: 201,
    NO_RESPONCE: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED_ACCESS: 401,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
  },
  ERROR_MESSAGES: {
    SOMETHING_WRONG:
      "Something went wrong. Please reload the page or re-Login required!",
    PAGE_NOT_FOUND: "Page not found!",
    UNAUTHORIZED_ACCESS_TEXT: "Unauthorized access!",
  },
  PRODUCER_APP_URL: {
    subscription: `${producerAppURL}/plans`,
    login: producerAppURL,
  },
  trackingID,
  measurementId,
};

export default apiConstants;
