import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PageTitle, ProductListItem } from "../../components/ProductsList";
import {
  dispatchProductList,
  dispatchOrganizationList,
} from "../../redux/actions";
import { getTraitsList } from "../../redux/actions/productActions";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Pagination from "rc-pagination";
import TraitsTreeView from "../../components/ProductsList/TraitsList";
import queryString from "query-string";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import { Body } from "../../components/Common";

let integrantTypeFlag = false;

const ProductList = (props) => {
  const {
    getProductList,
    getOrganizationList,
    organizationList,
    productListData,
    getTraitsList,
    traitsList,
  } = props;
  const limit = 8;
  const urlParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { productsId } = urlParams;
  const [productValue, setProductValue] = useState("");
  const [isProductSearch, setProductSearch] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sortType, setSortType] = useState(["updated_on", "DESC"]);
  const [activePage, setActivePage] = useState(1);
  const [traits, setTraits] = useState(
    params["traits"]
      ? Object.fromEntries(
          Object.entries(JSON.parse(params["traits"])).map((item) =>
            typeof item[1] === "string" ? [item[0], [item[1]]] : item
          )
        )
      : {}
  );
  const [product_type, setProduct_type] = useState(
    params["product_type"] ? params["product_type"] : "all"
  );

  const getProductListAPiData = (type) => {
    integrantTypeFlag = true;
    if (type === "clearSearchText") {
      setProductValue("");
    }
    let obj = {
      limit,
      offset,
      order: [sortType],
      searchText: type === "clearSearchText" ? "" : productValue,
      organization_id: typeof productsId === "undefined" ? "" : productsId,
    };
    let queryParams = { ...params };
    // Traits
    if (type === "traits") {
      obj["facetsLoading"] = true;
    }
    if (Object.keys(traits).length) {
      obj["facets"] = traits;
      queryParams["traits"] = JSON.stringify(traits);
    } else {
      if (queryParams["traits"]) {
        delete queryParams?.traits;
      }
    }
    // Product Type
    if (type === "product_type") {
      obj["productTypeLoading"] = true;
    }
    queryParams["product_type"] = product_type;
    if (product_type !== "all") {
      obj["product_type"] = product_type;
    }
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
    getProductList(obj);
  };

  const handleOrganizationChange = (url) => {
    integrantTypeFlag = false;
    setTraits({});
    let queryParams = { ...params };
    if (queryParams["traits"]) {
      delete queryParams?.traits;
    }
    history.replace({
      pathname: url,
      search: queryString.stringify(queryParams),
    });
  };

  useEffect(() => {
    integrantTypeFlag = false;
  }, []);

  useEffect(() => {
    setOffset(0);
    setActivePage(1);
    if (integrantTypeFlag && offset === 0) {
      getProductListAPiData("product_type");
    }
  }, [product_type]);

  useEffect(() => {
    if (integrantTypeFlag) {
      getProductListAPiData("traits");
    }
  }, [traits]);

  useEffect(() => {
    getOrganizationList();
  }, [getOrganizationList]);

  useEffect(() => {
    setOffset(0);
    setActivePage(1);
    if (productsId) {
      getTraitsList({ organization_id: productsId });
    }
  }, [productsId, getTraitsList]);

  useEffect(() => {
    getProductListAPiData();
  }, [sortType, offset, productsId]);

  const isData =
    typeof productListData?.list === "object" && productListData?.list.length;
  return (
    <Body
      seoTitle="HealthLOQ Certified Products"
      hideFooter={true}
      className="product-list"
    >
      <div className="product-list-body">
        <div className="container-fluid">
          <div className="row  no-gutters">
            <div className="col-md-4 col-lg-3 d-sm-block toggleProductFilterAccordion">
              <div className="left-nav-product">
                <p className="lnp-title">Filter(s)</p>
                <div className="lnp-item">
                  <div className="lnp-item-space">
                    <div className="d-flex justify-content-between so-title">
                      <p className="lnp-sub-title">Product</p>
                      {isProductSearch && (
                        <div
                          onClick={() => {
                            setProductSearch(false);
                            getProductListAPiData("clearSearchText");
                          }}
                          className="so-title-clear"
                        >
                          Clear All
                        </div>
                      )}
                    </div>
                    <div className="lnp-search-section d-flex align-items-center">
                      <input
                        placeholder="Search by product name"
                        value={productValue}
                        onChange={(e) => setProductValue(e.target.value)}
                      />
                      <span
                        className="btn-lnp btn"
                        onClick={() => {
                          getProductListAPiData();
                          setProductSearch(true);
                        }}
                      >
                        Go
                      </span>
                    </div>
                  </div>
                </div>
                <div className="section-organization">
                  <div className="d-flex justify-content-between so-title">
                    <p className="lnp-sub-title">Organization(s)</p>
                    {productsId && (
                      <p
                        className="so-title-clear"
                        onClick={() => handleOrganizationChange("/products")}
                      >
                        Clear All
                      </p>
                    )}
                  </div>
                  {organizationList.map((item, index) => {
                    const { id } = item;
                    return (
                      <p
                        className={
                          "link-brand-name " +
                          (id === productsId ? "active" : "")
                        }
                        key={index}
                      >
                        <p
                          key={index}
                          onClick={() =>
                            handleOrganizationChange(`/products/${id}`)
                          }
                        >
                          {item.name}
                        </p>
                      </p>
                    );
                  })}
                </div>
                <div className="section-organization">
                  <div className="d-flex justify-content-between so-title">
                    <p className="lnp-sub-title">
                      Product Type{" "}
                      {productListData?.filterLoading?.includes(
                        "product_type"
                      ) ? (
                        <MuiCircularProgress color="#008F2B" size={15} />
                      ) : null}
                    </p>
                    {product_type !== "Business to Consumer" && (
                      <p
                        className="so-title-clear"
                        onClick={() => {
                          setProduct_type("Business to Consumer");
                        }}
                      >
                        Clear
                      </p>
                    )}
                  </div>
                  <Select
                    value={product_type}
                    onChange={(e) => setProduct_type(e.target.value)}
                    className="product-type-select"
                    disableUnderline
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="Business to Business">
                      Business to Business
                    </MenuItem>
                    <MenuItem value="Business to Consumer">
                      Business to Consumer
                    </MenuItem>
                  </Select>
                </div>
                {productsId ? (
                  <div className="section-organization">
                    <div className="d-flex justify-content-between so-title">
                      <p className="lnp-sub-title">
                        Traits{" "}
                        {productListData?.filterLoading?.includes("traits") ? (
                          <MuiCircularProgress color="#008F2B" size={15} />
                        ) : null}
                      </p>
                      {Object.keys(traits).length > 0 ? (
                        <p
                          className="so-title-clear"
                          onClick={() => setTraits({})}
                        >
                          Clear All Traits
                        </p>
                      ) : null}
                    </div>
                    {traitsList.loading ? (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <MuiCircularProgress />
                      </Grid>
                    ) : traitsList.data &&
                      Object.keys(traitsList?.data).length > 0 ? (
                      <TraitsTreeView
                        list={Object.entries(traitsList?.data)}
                        {...{ setTraits, traits }}
                      />
                    ) : (
                      <p>Traits not available</p>
                    )}
                  </div>
                ) : null}
              </div>
              <Accordion className="left-nav-product traits-accordion-root">
                <AccordionSummary
                  className={"accordionSummary"}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <p className="lnp-title">Filter(s)</p>
                </AccordionSummary>
                <AccordionDetails className={"accordionDetails"}>
                  <div className="lnp-item">
                    <div className="lnp-item-space">
                      <div className="d-flex justify-content-between so-title">
                        <p className="lnp-sub-title">Product</p>
                        {isProductSearch && (
                          <div
                            onClick={() => {
                              setProductSearch(false);
                              getProductListAPiData("clearSearchText");
                            }}
                            className="so-title-clear"
                          >
                            Clear All
                          </div>
                        )}
                      </div>
                      <div className="lnp-search-section d-flex align-items-center">
                        <input
                          placeholder="Search by product name"
                          value={productValue}
                          onChange={(e) => setProductValue(e.target.value)}
                        />
                        <span
                          className="btn-lnp btn"
                          onClick={() => {
                            getProductListAPiData();
                            setProductSearch(true);
                          }}
                        >
                          Go
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="section-organization">
                    <div className="d-flex justify-content-between so-title">
                      <p className="lnp-sub-title">Organization(s)</p>
                      {productsId && (
                        <p
                          className="so-title-clear"
                          onClick={() => handleOrganizationChange("/products")}
                        >
                          Clear All
                        </p>
                      )}
                    </div>
                    {organizationList.map((item, index) => {
                      const { id } = item;
                      return (
                        <p
                          className={
                            "link-brand-name " +
                            (id === productsId ? "active" : "")
                          }
                          key={index}
                        >
                          <p
                            key={index}
                            onClick={() =>
                              handleOrganizationChange(`/products/${id}`)
                            }
                          >
                            {item.name}
                          </p>
                        </p>
                      );
                    })}
                  </div>
                  <div className="section-organization">
                    <div className="d-flex justify-content-between so-title">
                      <p className="lnp-sub-title">
                        Product Type{" "}
                        {productListData?.filterLoading?.includes(
                          "product_type"
                        ) ? (
                          <MuiCircularProgress color="#008F2B" size={15} />
                        ) : null}
                      </p>
                      {product_type !== "Business to Consumer" && (
                        <p
                          className="so-title-clear"
                          onClick={() => {
                            setProduct_type("Business to Consumer");
                          }}
                        >
                          Clear
                        </p>
                      )}
                    </div>
                    <Select
                      value={product_type}
                      onChange={(e) => setProduct_type(e.target.value)}
                      className="product-type-select"
                      disableUnderline
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="Business to Business">
                        Business to Business
                      </MenuItem>
                      <MenuItem value="Business to Consumer">
                        Business to Consumer
                      </MenuItem>
                    </Select>
                  </div>
                  {productsId ? (
                    <div className="section-organization">
                      <div className="d-flex justify-content-between so-title">
                        <p className="lnp-sub-title">
                          Traits{" "}
                          {productListData?.filterLoading?.includes(
                            "traits"
                          ) ? (
                            <MuiCircularProgress color="#008F2B" size={15} />
                          ) : null}
                        </p>
                        {Object.keys(traits).length > 0 ? (
                          <p
                            className="so-title-clear"
                            onClick={() => setTraits({})}
                          >
                            Clear All Traits
                          </p>
                        ) : null}
                      </div>
                      {traitsList.loading ? (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <MuiCircularProgress />
                        </Grid>
                      ) : traitsList.data &&
                        Object.keys(traitsList?.data).length > 0 ? (
                        <TraitsTreeView
                          list={Object.entries(traitsList?.data)}
                          {...{ setTraits, traits }}
                        />
                      ) : (
                        <p>Traits not available</p>
                      )}
                    </div>
                  ) : null}
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="section-items">
                <PageTitle
                  sortBy={sortType}
                  limit={limit}
                  offset={offset}
                  isData={isData}
                  handleSort={setSortType}
                />
                {isData ? (
                  <>
                    <div className="row no-gutters">
                      {productListData?.list?.map((item, index) => (
                        <ProductListItem
                          {...item}
                          key={index}
                          filterProduct={productsId}
                        />
                      ))}
                    </div>
                    <div>
                      <div className="text-center product-pagination">
                        <Pagination
                          activePage={activePage}
                          defaultCurrent={activePage}
                          total={productListData?.count}
                          pageSize={limit}
                          onChange={(value) => {
                            setOffset((value - 1) * limit);
                            setActivePage(value);
                          }}
                          showTitle={false}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="no-data-found">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="no-data-found">No Data Found</div> */}
      </div>
    </Body>
  );
};

const mapStateToProps = ({
  productReducers: { productListData, organizationList, traitsList },
}) => ({
  productListData,
  organizationList,
  traitsList,
});

const mapDispatchToProps = {
  getProductList: dispatchProductList,
  getOrganizationList: dispatchOrganizationList,
  getTraitsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
