import React, { useState } from "react";
import { AppImages } from "../../constants";
import utils from "../../utils/commonUtils";
import Fancybox from "../Common/Fancybox";
import BlockchainProofDialog from "../Dialogs/BlockchainProofDialog";
import IntegrantBlockchainProofDialog from "../Dialogs/IntegrantBlockchainProofDialog";
import { dispatchBlockchainProof } from "../../redux/actions/product";
import ProductIngredients from "./ProductIngredients";
import { connect } from "react-redux";
import { ReactComponent as BlockchainProofIcon } from "../../constants/svg/blockchain-proof.svg";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const {
  icon: { blockchainProofIcon, documentIcon, iconClose, iButtonIcon },
} = AppImages;

function MobileProductDetail({
  integrantInfo,
  exhibitsInfo,
  blockchainProof,
  sourceLinks,
  dispatchBlockchainProof,
  setActiveSmFooterTab,
  location,
}) {
  const {
    title,
    facets,
    description,
    buy_again_url,
    id,
    integrantsType: { image_url, directions, warnings },
  } = integrantInfo;
  const [activeProductTab, setActiveProductTab] = useState(0);
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [blockchainType, setBlockchainType] = useState("");
  let facetsArr = [];
  if (facets) {
    facetsArr = JSON.parse(facets);
  }
  const handleIntegrantBlockchainProof = () => {
    dispatchBlockchainProof({
      id,
      type: "integrant",
      showCustomLoader: true,
    });
  };
  return (
    <div className="mobile-product-detail-root">
      <div className="pd-top">
        <div className="product-image center">
          <img src={image_url} alt="Product Pic" />
        </div>
        <div className="product-title pdlr-padding">
          <h2 className="ellipsis notranslate" title={title}>
            {title}
          </h2>
          <div>
            {showProductDetail ? (
              <div
                className="i-button"
                onClick={() => setShowProductDetail(!showProductDetail)}
                title="Hide Product Details"
              >
                <img
                  src={iconClose}
                  className="i-button-close"
                  alt="Close Icon"
                />
              </div>
            ) : (
              <img
                className="i-button-icon"
                src={iButtonIcon}
                alt="I Button Icon"
                onClick={() => setShowProductDetail(!showProductDetail)}
                title="View Product Details"
              />
            )}
            <div
              title="View Integrant Blockchain Proof"
              data-toggle="modal"
              data-target="#IntegrantBlockchainProofDialog"
              onClick={() => handleIntegrantBlockchainProof()}
              className="blockchain-proof-icon center"
            >
              <BlockchainProofIcon />
            </div>
          </div>
        </div>
        {showProductDetail ? (
          <div className="product-details-div pdlr-padding">
            <h5 className="product-details-heading">Description</h5>
            <p className="product-details-text">{description}</p>
            <h5 className="product-details-heading">Directions</h5>
            <p className="product-details-text">{directions}</p>
            <h5 className="product-details-heading">Warning</h5>
            <p className="product-details-text">
              {warnings ? warnings : "None"}
            </p>
          </div>
        ) : (
          <>
            <div className="product-detail-tabs pdlr-padding">
              {["INGREDIENTS", "INFORMATION", "Documents"]?.map((item, key) => {
                return (
                  <h5
                    className={`product-detail-tab ${
                      activeProductTab === key ? "active" : ""
                    }`}
                    key={key}
                    onClick={() => setActiveProductTab(key)}
                  >
                    {item}
                  </h5>
                );
              })}
            </div>
            {activeProductTab === 0 && (
              <div className="ingredients pdlr-padding">
                {sourceLinks?.length ? (
                  <ProductIngredients
                    list={sourceLinks}
                    setActiveSmFooterTab={setActiveSmFooterTab}
                    location={location}
                  />
                ) : (
                  <p className="zero-state-message">No data available!</p>
                )}
              </div>
            )}
            {activeProductTab === 1 && (
              <div className="information pdlr-padding">
                {facetsArr?.length ? (
                  facetsArr?.map((item, key) => {
                    return (
                      <div className="information-li" key={key}>
                        <p style={{ display: "flex", flexDirection: "column" }}>
                          <span>{item?.title}:</span>
                          <span
                            className="p-m-0"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className="zero-state-message">No data available!</p>
                )}
              </div>
            )}
            {activeProductTab === 2 && (
              <div className="documents pdlr-padding">
                {exhibitsInfo?.length ? (
                  exhibitsInfo?.map((item, key) => {
                    return (
                      <div key={key}>
                        <ol>
                          {item?.exhibitsType?.map((subItem, subKey) => {
                            return (
                              <li className="documents-li" key={subKey}>
                                <h5 className="documents-name">
                                  {`${subItem?.title} - ${item?.title}`}
                                </h5>
                                <div className="documents-buttons">
                                  {subItem?.url &&
                                    !utils.isVideo(subItem?.url) && (
                                      <>
                                        <button
                                          className="documents-btn"
                                          data-extention={utils.getExtension(
                                            subItem?.url
                                          )}
                                          data-gas_cert_url={subItem?.url}
                                          id={
                                            "product-document-view-icon_" +
                                            +key +
                                            subKey
                                          }
                                          onClick={() => {
                                            utils.handleDocumentViewClick(
                                              "product-document-view-icon_" +
                                                key +
                                                subKey
                                            );
                                          }}
                                        >
                                          <img
                                            src={documentIcon}
                                            alt="View Document Icon"
                                          />
                                          View Documents
                                        </button>
                                        <Fancybox
                                          className={
                                            "product-document-view-icon_" +
                                            key +
                                            subKey
                                          }
                                        />
                                      </>
                                    )}
                                  <button
                                    className="documents-btn"
                                    onClick={() => {
                                      setBlockchainType(subItem?.id);
                                    }}
                                    data-toggle="modal"
                                    data-target="#productBlockchainProofDialog"
                                  >
                                    <img
                                      src={blockchainProofIcon}
                                      alt="Blockchain Proof Icon"
                                    />
                                    Blockchain Proof
                                  </button>
                                  {/* <a
                                    href={subItem?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    title="Download"
                                  >
                                    <button className="documents-btn">
                                      <img src={download} alt="Download Icon" />
                                      Download
                                    </button>
                                  </a> */}
                                </div>
                                {utils.isVideo(subItem?.url) && (
                                  <div className="documents-video-div">
                                    <video src={subItem?.url} controls></video>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    );
                  })
                ) : (
                  <p className="zero-state-message">No data available!</p>
                )}
              </div>
            )}
            <Grid container className="privacy-policy-link">
              <Typography variant="body1">
                Please check our
                <Link to={"/privacy"} target="_blank">
                  Privacy policy
                </Link>
                and
                <Link to={"/terms-and-conditions"} target="_blank">
                  Terms and conditions
                </Link>
                here.
              </Typography>
            </Grid>
          </>
        )}
      </div>
      {/* <div className="buynow-button pdlr-padding">
        <button className={`${buy_again_url ? "" : "buynow-disabled-button"}`}>
          Buy Now
        </button>
      </div> */}
      <BlockchainProofDialog
        blockchainProof={blockchainProof}
        blockchainType={blockchainType}
      />
      <IntegrantBlockchainProofDialog integrant_id={id} />
    </div>
  );
}

const mapStateToProps = ({ productReducers: {} }) => ({});

const mapDispatchToProps = {
  dispatchBlockchainProof,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileProductDetail);
