import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  MuiSelect,
  Button,
  MainHeading,
  SmProfileFooter,
  OrganizationBox,
} from "../../components/Profile";
import {
  dispatchGetAllIntegrantTypesList,
  dispatchGetIntegrantBatchList,
} from "../../redux/actions/product";
import { getOrganizationList } from "../../redux/actions/organization";
import utils from "../../utils/commonUtils";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import { Link } from "react-router-dom";
import appImages from "../../constants/AppImage";
import { SwiperSlider } from "../../components/Common";
const { logo } = appImages;

let organizationListFlag = false;
let integrantTypeListFlag = false;
let integrantListFlag = false;

export const ProfilePage = (props) => {
  const {
    userData,
    activePage,
    match,
    dispatchGetAllIntegrantTypesList,
    getAllintegrantTypesList,
    history,
    getOrganizationList,
    organizationList,
    dispatchGetIntegrantBatchList,
    getIntegrantBatchList,
  } = props;
  const { selectedIntegrantTypeId, organization_id, integrant_id, user_id } =
    utils.getLocalStorageItems();
  const [selectedOrg_id, setSelectedOrg_id] = useState(organization_id);
  const [selectedIntegrantTypeIdState, setSelectedIntegrantTypeId] =
    useState("");
  const [selectedIntegrantId, setSelectedIntegrantId] = useState("");

  const handleOrganizationClick = (org_id) => {
    setSelectedOrg_id(org_id);
    utils.addItemsIntoSessionStorage({ organization_id: org_id });
    dispatchGetAllIntegrantTypesList({ organization_id: org_id, user_id });
    integrantTypeListFlag = true;
  };

  const handleIntegrantTypeChange = (id) => {
    setSelectedIntegrantTypeId(id);
    dispatchGetIntegrantBatchList({ integrant_type_id: id });
    integrantListFlag = true;
    utils.addItemsIntoSessionStorage({
      selectedIntegrantTypeId: id,
    });
  };
  const handleIntegrantChange = (id) => {
    setSelectedIntegrantId(id);
    utils.addItemsIntoSessionStorage({
      integrant_id: id,
    });
  };
  useEffect(() => {
    if (getIntegrantBatchList?.status === "1" && integrantListFlag) {
      integrantListFlag = false;
      for (let item of getIntegrantBatchList?.data) {
        if (item?.id === integrant_id) {
          setSelectedIntegrantId(item?.id);
          break;
        }
      }
    }
  }, [getIntegrantBatchList]);
  useEffect(() => {
    if (getAllintegrantTypesList?.status === "1" && integrantTypeListFlag) {
      integrantTypeListFlag = false;
      for (let item of getAllintegrantTypesList?.data) {
        if (item?.id === selectedIntegrantTypeId) {
          dispatchGetIntegrantBatchList({ integrant_type_id: item?.id });
          integrantListFlag = true;
          setSelectedIntegrantTypeId(item?.id);
          break;
        }
      }
    }
  }, [getAllintegrantTypesList]);
  useEffect(() => {
    if (
      organizationList?.status === "1" &&
      organizationList?.data?.length > 0 &&
      organizationListFlag
    ) {
      for (let item of organizationList?.data) {
        if (item?.id === organization_id && item?.hasSurvey) {
          dispatchGetAllIntegrantTypesList({ organization_id, user_id });
          integrantTypeListFlag = true;
          break;
        }
      }
      organizationListFlag = false;
    }
  }, [organizationList]);
  useEffect(() => {
    getOrganizationList({ type: "profile_dashboard", user_id });
    organizationListFlag = true;
  }, []);

  return (
    <Grid className="profile-root">
      <Grid className="profile-page-content">
        <Grid className="profile-page-heading">
          <div className="profile-page-share-main">
            <div>
              <MainHeading style={{ textAlign: "left" }}>
                Hi {userData?.data?.name}
              </MainHeading>
              <p>
                Search for current offers that are available to you - based on
                the profile information you selected to share.
              </p>
            </div>
            <div className="profile-page-header-div">
              <Link to={"/"} title="HealthLOQ Logo">
                <img src={logo} alt="HealthLOQ-logo" />
              </Link>
            </div>
          </div>
        </Grid>
        <Grid className="organization-section">
          <p>Select any organization to complete a new survey</p>
          {organizationList.loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ padding: "20px 0px" }}
            >
              <MuiCircularProgress />
            </Grid>
          ) : organizationList?.data?.length > 0 ? (
            <SwiperSlider>
              {organizationList?.data?.map((item, key) => {
                return (
                  <OrganizationBox
                    key={key}
                    data={item}
                    onClick={() => handleOrganizationClick(item?.id)}
                    isSelected={selectedOrg_id === item?.id}
                  />
                );
              })}
            </SwiperSlider>
          ) : (
            ""
          )}
        </Grid>
        {selectedOrg_id &&
          !organizationList?.loading &&
          (getAllintegrantTypesList?.data?.length > 0 ||
            getAllintegrantTypesList?.loading) && (
            <Grid className="product-section">
              <MuiSelect
                match={match}
                placeholder="Select product"
                menuItems={getAllintegrantTypesList?.data?.map((item) => {
                  return {
                    value: item?.id,
                    label: (
                      <p className="product-list-li-offer-label">
                        {item?.title}
                        {item?.integrant_type_offer && <span>offer</span>}
                      </p>
                    ),
                  };
                })}
                loading={getAllintegrantTypesList?.loading}
                onChange={(e) => handleIntegrantTypeChange(e.target.value)}
                value={selectedIntegrantTypeIdState}
                className="notranslate"
              />
            </Grid>
          )}
        {selectedOrg_id &&
          !organizationList?.loading &&
          getAllintegrantTypesList?.data?.length > 0 &&
          !getAllintegrantTypesList?.loading &&
          (getIntegrantBatchList?.data?.length > 0 ||
            getIntegrantBatchList?.loading) && (
            <Grid className="product-section">
              <MuiSelect
                match={match}
                placeholder="Select batch"
                menuItems={getIntegrantBatchList?.data?.map((item) => {
                  return { value: item?.id, label: item?.external_id };
                })}
                loading={getIntegrantBatchList?.loading}
                onChange={(e) => handleIntegrantChange(e.target.value)}
                value={selectedIntegrantId}
              />
            </Grid>
          )}
        {selectedOrg_id &&
        !organizationList?.loading &&
        selectedIntegrantTypeIdState &&
        selectedIntegrantId ? (
          <Grid container alignItems="center" className="profile-btns">
            <Button profileBgBtn onClick={() => history.push("/survey")}>
              Next
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  productReducers: { getAllintegrantTypesList, getIntegrantBatchList },
  OrganizationReducers: { organizationList },
}) => ({
  getAllintegrantTypesList,
  organizationList,
  getIntegrantBatchList,
});

const mapDispatchToProps = {
  dispatchGetAllIntegrantTypesList,
  getOrganizationList,
  dispatchGetIntegrantBatchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
