import React from "react";
import { PageWrapper } from "../../components/Wrappers";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import { Section3, Section2 } from "../../components/CounterfeitPrevention";
import HealthLoqSection from "../../components/CounterfeitPrevention/HealthloqSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";

const {
  headerImages: { counterfeitPrevention },
} = appImages;

export default function CounterfeitPrevention() {
  return (
    <PageWrapper pageName="Counterfeit Prevention">
      <HeaderSection
        headerImgSrc={counterfeitPrevention}
        headerText="Prevent counterfeit products and unauthorized resell"
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <Section2 />
      <Section3 />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
