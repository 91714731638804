import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { MainHeading, Button, MuiTextfield } from "../../components/Profile";
import { updatePassword } from "../../redux/actions/profileActions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import utils from "../../utils/commonUtils";

export function Password(props) {
  const { match, updatePassCodeData, updatePassword, history, type } = props;
  const { is_verified } = utils.getLocalStorageItems();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({ password: "", confirm_password: "" });
  const [togglePasswordVisibility, setTogglePasswordVisibility] =
    useState(false);
  const handlePasswordVisibility = () =>
    setTogglePasswordVisibility(!togglePasswordVisibility);
  const { token } = useParams();

  const handleVerify = () => {
    let isValid = true;
    let obj = { ...error };
    if (!password) {
      isValid = false;
      obj["password"] = "Password is a required field.";
    }
    if (password && (password?.length < 8 || password?.length > 20)) {
      isValid = false;
      obj["password"] = "Password length should be 8 to 20 characters long.";
    }
    if (!confirm_password) {
      isValid = false;
      obj["confirm_password"] = "Confirm password is a required field.";
    }
    if (confirm_password && password && confirm_password !== password) {
      isValid = false;
      obj["confirm_password"] = "Confirm password should be same as password.";
    }
    setError(obj);
    return isValid;
  };
  const handleSubmit = async () => {
    if (handleVerify()) {
      await updatePassword({ password, token, type });
      history.push("/verify-otp");
    }
  };
  useEffect(() => {
    if (updatePassCodeData.status === "1") {
      if (Boolean(is_verified)) {
        history.push("/profile-signup");
      } else {
        history.push("/profile-login");
      }
    }
  }, [updatePassCodeData]);
  return (
    <Grid className="ac-root">
      <Grid className="ac-content">
        <MainHeading>
          {type === "create" ? "Create" : "Reset"} Password
        </MainHeading>
        <Grid className="ac-form-div">
          <MuiTextfield
            placeholder={"Password"}
            match={match}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError({ ...error, password: "" });
            }}
            required
            autoFocus
            type={togglePasswordVisibility ? "text" : "password"}
            endIcon={
              togglePasswordVisibility ? (
                <VisibilityOff onClick={handlePasswordVisibility} />
              ) : (
                <Visibility onClick={handlePasswordVisibility} />
              )
            }
            error={Boolean(error?.password)}
            helperText={error?.password}
          />
          <MuiTextfield
            placeholder={"Confirm Password"}
            match={match}
            value={confirm_password}
            onChange={(e) => {
              setConfirm_password(e.target.value);
              setError({ ...error, confirm_password: "" });
            }}
            required
            type={togglePasswordVisibility ? "text" : "password"}
            endIcon={
              togglePasswordVisibility ? (
                <VisibilityOff onClick={handlePasswordVisibility} />
              ) : (
                <Visibility onClick={handlePasswordVisibility} />
              )
            }
            error={Boolean(error?.confirm_password)}
            helperText={error?.confirm_password}
          />
        </Grid>
      </Grid>
      <Grid className="ac-footer">
        <Button
          onClick={handleSubmit}
          loading={updatePassCodeData?.loading}
          profileBgBtn
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({ ProfileReducers: { updatePassCodeData } }) => ({
  updatePassCodeData,
});

const mapDispatchToProps = {
  updatePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
