import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles} from "@material-ui/core";
import { SmProfileFooter } from "../../components/Profile";
import clsx from "clsx";
import CreateOrEditPost from "../../components/Profile/Post/CreateOrEditPost";
import UserSuggestionList from "../../components/Profile/Users/UserSuggestionList";
import { getPostsList } from "../../redux/actions/post";
import SinglePost from "../../components/Profile/Post/SinglePost";
import PostFilter from "../../components/Profile/Post/PostFilter";
import { useIntersection } from "../../hooks/useIntersection";
import { PostSkeleton } from "../../components/Profile/Post/SocialSkeletons";
import queryString from "query-string";
import {useLocation} from 'react-router-dom'

const useStyle = makeStyles((theme) => ({
  postContainer: {
    "&>div": {
      marginBottom: 20,
    },
    [theme.breakpoints.down(769)]: {
      padding: 20,
    },
  },
}));

export const Post = (props) => {
  const classes = useStyle();
  const { activePage, match, history, getPostsList, postsList } = props;
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [editOrSharePostData, setEditOrSharePostData] = useState({
    post_id: "",
    type: "",
  });
  const [postFilter, setPostFilter] = useState({
    description: "",
  });
  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const setDefaultEditOrSharePostData = () =>
    setEditOrSharePostData({
      post_id: "",
      type: "",
    });
  const childComponentProps = {
    editOrSharePostData,
    setEditOrSharePostData,
    setDefaultEditOrSharePostData,
  };

  useEffect(() => {
    if(!params?.hashtag){
      getPostsList({ offset: 0 });
    }
  }, []);

  useEffect(() => {
    const { isIntersecting } = entry;
    if (isIntersecting && postsList?.offsetStatus) {
      getPostsList({ offset: postsList?.data?.length, ...postFilter });
    }
  }, [entry]);

  return (
    <Grid className={clsx("profile-root", classes.profileRoot)}>
      <Grid className={clsx("profile-page-content", classes.postContainer)}>
        <CreateOrEditPost {...childComponentProps} />
        <UserSuggestionList {...childComponentProps} />
        <PostFilter {...{ setPostFilter }} />
        <Grid item>
          {postsList.loading && !Boolean(postsList?.data?.length) ? (
            [...Array(10)].map((el, key) => {
              return <PostSkeleton key={key} />;
            })
          ) : (
            <>
              {postsList?.data?.map((item, key) => {
                return (
                  <SinglePost post={item} key={key} {...childComponentProps} />
                );
              })}
              {postsList?.offsetStatus &&
                [...Array(5)].map((el, key) => {
                  return (
                    <PostSkeleton
                      key={key}
                      setRef={key === 0 ? setTarget : null}
                    />
                  );
                })}
            </>
          )}
        </Grid>
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({ PostReducer: { postsList } }) => ({ postsList });

const mapDispatchToProps = {
  getPostsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
