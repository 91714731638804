import React, { useEffect } from "react";
import { productDetailLog } from "../../constants/TourConfig";
import { connect } from "react-redux";
import { resetTour } from "../../redux/actions/common";
import { updateUser, getUserDetails } from "../../redux/actions/profileActions";
import DefaultTour, { allRegisteredTour } from "./DefaultTour";

let updateUserApiFlag = false;

function ProductDetailTour(props) {
  const {
    showTour,
    resetTour,
    updateUser,
    userData,
    updateUserData,
    getUserDetails,
  } = props;
  const closeTour = () => {
    resetTour("");
    let formData = new FormData();
    formData.append("user_id", userData?.data?.id);
    formData.append("show_product_detail_tour", false);
    updateUser(formData, {
      hideNotification: true,
    });
    updateUserApiFlag = true;
  };

  useEffect(() => {
    if (updateUserData?.status === "1" && updateUserApiFlag) {
      updateUserApiFlag = false;
      // getUserDetails({ user_id: userData?.data?.id });
    }
  }, [updateUserData]);
  return (
    <DefaultTour
      steps={productDetailLog}
      isOpen={showTour === allRegisteredTour?.productDetail?.key}
      //   getCurrentStep={handleStepChange}
      // onBeforeClose={(...all) => {}}
      onRequestClose={closeTour}
    />
  );
}
const mapStateToProps = ({
  CommonReducers: { showTour },
  ProfileReducers: { userData, updateUserData },
}) => ({
  showTour,
  userData,
  updateUserData,
});
const mapDispatchToProps = {
  resetTour,
  updateUser,
  getUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailTour);
