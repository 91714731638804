import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "../../styles/common.scss";
import { IconButton, Box } from "@material-ui/core";
import {
  ArrowForwardIosRounded,
  ArrowBackIosRounded,
} from "@material-ui/icons";

export default function SwiperSlider({
  navigation = true,
  pagination = true,
  spaceBetween = 0,
  slidesPerView = 1,
  children = [],
  breakpoints = {},
  ...props
}) {
  let modules = [];
  if (Boolean(navigation)) modules.push(Navigation);
  if (Boolean(pagination)) modules.push(Pagination);
  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={"space-between"}
      className="swiperContainer"
    >
      <IconButton className="swiperBtnPrev" color="primary">
        <ArrowBackIosRounded />
      </IconButton>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        modules={modules}
        navigation={{
          nextEl: ".swiperBtnNext",
          prevEl: ".swiperBtnPrev",
          disabledClass: "swiperBtnDisabled",
          ...navigation,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
          ...pagination,
        }}
        breakpoints={{
          640: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          ...breakpoints,
        }}
        {...props}
      >
        {children?.map((item, key) => (
          <SwiperSlide key={key}>{item}</SwiperSlide>
        ))}
      </Swiper>
      <IconButton className="swiperBtnNext" color="primary">
        <ArrowForwardIosRounded />
      </IconButton>
    </Box>
  );
}
