import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContent,
  MuiButton,
} from "./DialogCommonComponents";
import clsx from "clsx";
import { TextField, makeStyles } from "@material-ui/core";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import { updateComment } from "../../redux/actions/post";

const useStyle = makeStyles((theme) => ({
  textFieldRoot: {
    width: "100%",
    "&>div": {
      background: "#F4F5F8",
      color: "#8A9294",
      borderRadius: 5,
      padding: "10px 15px",
      "&>textarea": {
        fontSize: 16,
        lineHeight: "23px",
      },
      "&>div": {
        "&>svg": {
          cursor: "pointer",
        },
      },
    },
  },
}));

function UpdateCommentOrReplyDialog({
  className = "",
  open,
  handleClose,
  updateComment,
  updatePostCommentData,
  data,
}) {
  const classes = useStyle();
  const [comment, setComment] = useState("");

  const handleComment = () => {
    if (comment?.trim()) {
      updateComment({
        comment,
        comment_id: data?.id,
        post_id: data?.post_id,
        parent_comment_id: data?.parent_comment_id,
      });
    }
  };

  useEffect(() => {
    if (
      !updatePostCommentData?.loading &&
      updatePostCommentData?.status === "1"
    ) {
      setComment("");
      handleClose();
    }
  }, [updatePostCommentData]);

  useEffect(() => {
    if (open) {
      setComment(data?.comment);
    }
  }, [open]);
  return (
    <MuiDialog
      className={clsx(className)}
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <MuiDialogTitle onClose={handleClose}>
        Edit {data?.parent_comment_id ? "Reply" : "Comment"}
      </MuiDialogTitle>
      <MuiDialogContent>
        <TextField
          className={classes.textFieldRoot}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={
            data?.parent_comment_id ? "Update Reply..." : "Update Comment..."
          }
          multiline
          minRows={5}
        />
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton secondaryBtn={true} onClick={handleClose}>
          Cancel
        </MuiButton>
        <MuiButton
          startIcon={
            updatePostCommentData?.loading ? <MuiCircularProgress /> : null
          }
          disabled={updatePostCommentData?.loading}
          onClick={handleComment}
        >
          Update
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

const mapStateToProps = ({ PostReducer: { updatePostCommentData } }) => ({
  updatePostCommentData,
});

const mapDispatchToProps = {
  updateComment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCommentOrReplyDialog);
