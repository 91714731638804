/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl, { Popup } from "!mapbox-gl";
import * as turf from "@turf/turf";
import Triangle from "../../constants/svg/triangle.svg";
import moment from "moment";
import { connect } from "react-redux";
import { dispatchUpdateMapDataIntegrantId } from "../../redux/actions/product";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_ACCESS_TOKEN;

function MapboxGL({
  handleShowPointDetail = () => {},
  hidePointDetails = () => {},
  setReplayLineAnimation,
  replayLineAnimation,
  updateOrganizationData = () => {},
  dispatchUpdateProductCurrentTab,
  productCurrentTab,
  mapData,
  dispatchUpdateMapDataIntegrantId,
  location,
}) {
  let centerCoords = mapData?.centerCoords;
  const mapContainer = useRef(null);
  const map = useRef(null);

  let pointsGeojson = {
    type: "FeatureCollection",
    features: [],
  };
  let routeGeojson = {
    type: "FeatureCollection",
    features: [],
  };
  let linesGeojson = {
    type: "FeatureCollection",
    features: [],
  };
  let trianglePointsGeojson = {
    type: "FeatureCollection",
    features: [],
  };
  let allCoordinatesString = [];
  let hidePointIds = [];
  let trianglePointsCoords = [];

  const getImage = (data) => {
    if (["transit", "batch"].includes(data?.type)) {
      return (
        data?.data?.metadata?.integrantTypeThumb ||
        data?.data?.metadata?.integrantType ||
        null
      );
    } else if (data?.type === "transfer") {
      if (data?.isFromLocation) {
        return (
          data?.data?.metadata?.fromOrganization?.org_thumb_url ||
          data?.data?.metadata?.fromOrganization?.logo_url ||
          null
        );
      } else {
        return (
          data?.data?.metadata?.toOrganization?.org_thumb_url ||
          data?.data?.metadata?.toOrganization?.logo_url ||
          null
        );
      }
    }
  };

  const checkThumbnailIsAvailable = (data) => {
    if (["transit", "batch"].includes(data?.type)) {
      return data?.data?.metadata?.integrantTypeThumb ? true : false;
    } else if (data?.type === "transfer") {
      if (data?.isFromLocation) {
        return data?.data?.metadata?.fromOrganization?.org_thumb_url
          ? true
          : false;
      } else {
        return data?.data?.metadata?.toOrganization?.org_thumb_url
          ? true
          : false;
      }
    }
  };

  const updateMapLayerSourceData = () => {
    pointsGeojson["features"] = [];
    linesGeojson["features"] = [];
    routeGeojson["features"] = [];
    trianglePointsGeojson["features"] = [];
    allCoordinatesString = [];
    hidePointIds = [];
    mapData?.data?.forEach((item, i, arr) => {
      if (item?.type === "exhibit") {
      } else if (!item?.length) {
        let obj = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: item?.origin,
          },
          properties: {
            title: item?.title[0]?.toUpperCase() + item?.title?.slice(1),
            type: item?.type,
            activeColorType: "defaultColor",
            id: item?.id,
            hover: false,
            data: item?.data,
            isDuplicate: false,
            image: getImage(item),
            imageName: item?.type + i,
            isThumbAvailable: Boolean(checkThumbnailIsAvailable(item)),
            organizationData: item?.organizationData,
            integrant_id: item?.integrant_id,
          },
        };
        allCoordinatesString?.push(item?.origin?.join(","));
        if (item?.type === "batch") {
          pointsGeojson?.features?.unshift(obj);
        } else {
          pointsGeojson?.features?.push(obj);
        }
      } else {
        let coords = [];
        let routesData = {
          type: "",
          integrant_id: "",
        };
        item?.forEach((subItem, subI, subArr) => {
          let obj = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: subItem?.origin,
            },
            properties: {
              title:
                subItem?.title[0]?.toUpperCase() + subItem?.title?.slice(1),
              type: subItem?.type,
              activeColorType: "defaultColor",
              id: subItem?.id,
              hover: false,
              data: subItem?.data,
              isDuplicate: false,
              image: getImage(subItem),
              isThumbAvailable: Boolean(
                Boolean(checkThumbnailIsAvailable(subItem))
              ),
              imageName: subItem?.type + i + subI,
              organizationData: subItem?.organizationData,
              integrant_id: subItem?.integrant_id,
            },
          };
          allCoordinatesString?.push(subItem?.origin?.join(","));
          pointsGeojson?.features?.push(obj);
          coords.push(subItem?.origin);
          if (subI === 0) {
            trianglePointsGeojson?.features?.push({
              ...obj,
              geometry: { ...obj?.geometry, coordinates: [] },
            });
            linesGeojson?.features?.push({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [subItem?.origin],
              },
              properties: {
                id: subItem?.id,
                title:
                  subItem?.title[0]?.toUpperCase() + subItem?.title?.slice(1),
                data: subItem?.data,
                type: subItem?.type,
                integrant_id: subItem?.integrant_id,
                showTriangle: false,
                showHoverTriangle: false,
              },
            });
            routesData["type"] = subItem?.type;
            routesData["integrant_id"] = subItem?.integrant_id;
          }
        });
        routeGeojson?.features?.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coords,
          },
          properties: routesData,
        });
      }
    });
    let batchLocations = pointsGeojson?.features
      ?.filter((item) => item?.properties?.type === "batch")
      ?.map((item) => item?.geometry?.coordinates?.join(","));
    pointsGeojson?.features?.forEach((el) => {
      let sameCoord = allCoordinatesString?.filter(
        (item) => item === el?.geometry?.coordinates?.join(",")
      );
      if (
        sameCoord?.length > 1 &&
        batchLocations?.includes(el?.geometry?.coordinates?.join(",")) &&
        ["transit", "transfer"].includes(el?.properties?.type)
      ) {
        hidePointIds.push(el?.properties?.id);
      }
    });
    // pointsGeojson?.features?.unshift(pointsGeojson?.features?.pop());
  };
  updateMapLayerSourceData();
  // console.log("pointsGeojson", pointsGeojson);
  // console.log("routeGeojson", routeGeojson);
  // console.log("linesGeojson", linesGeojson);
  // console.log("trianglePointsGeojson", trianglePointsGeojson);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    let mapObj = {
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10?optimize=true",
      zoom: 3,
    };
    if (centerCoords?.length > 0) {
      mapObj["center"] = centerCoords;
    }
    map.current = new mapboxgl.Map(mapObj);
    showUSmapAsdefault();
  });

  useEffect(() => {
    if (!map.current) return;
    map.current.on("load", () => {
      // Add points source to map
      if (!map.current.getSource("point-source")) {
        map.current.addSource("point-source", {
          type: "geojson",
          data: pointsGeojson,
        });
      }
      // Add triangle source to map
      if (!map.current.getSource("triangle-source")) {
        map.current.addSource("triangle-source", {
          type: "geojson",
          data: trianglePointsGeojson,
        });
      }
      // Add lines source to map
      if (!map.current.getSource("line-source")) {
        map.current.addSource("line-source", {
          type: "geojson",
          data: linesGeojson,
        });
      }
      // Add Lines layer to map
      if (!map.current.getLayer("line-layer")) {
        map.current.addLayer({
          id: "line-layer",
          type: "line",
          source: "line-source",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#735c7c",
            "line-width": 2,
            "line-opacity": 0.8,
          },
        });
      }
      // Add points layer to map
      const circleColorExpression = [
        "case",
        ["==", ["get", "activeColorType"], "disabledColor"],
        "#999",
        ["==", ["get", "activeColorType"], "activeColor"],
        "#fff",
        ["==", ["get", "type"], "batch"],
        "rgb(53, 162, 151)",
        ["==", ["get", "type"], "exhibit"],
        "rgb(201, 95, 61)",
        ["==", ["get", "type"], "transfer"],
        "rgb(176, 21, 96)",
        ["==", ["get", "type"], "transit"],
        "rgb(115, 92, 124)",
        "#999",
      ];
      const circleStrokeWidthExpression = [
        "case",
        ["!=", ["get", "image"], null],
        0,
        ["==", ["get", "hover"], true],
        8,
        ["==", ["get", "activeColorType"], "activeColor"],
        2,
        0,
      ];
      const circleStrokeColorExpression = [
        "case",
        ["==", ["get", "type"], "batch"],
        "rgb(53, 162, 151)",
        ["==", ["get", "type"], "exhibit"],
        "rgb(201, 95, 61)",
        ["==", ["get", "type"], "transfer"],
        "rgb(176, 21, 96)",
        ["==", ["get", "type"], "transit"],
        "rgb(115, 92, 124)",
        "#fff",
      ];
      const circleStrokeOpacityExpression = [
        "case",
        ["==", ["get", "hover"], true],
        0.3,
        1,
      ];
      const paintCircleProperty = {};
      paintCircleProperty["circle-color"] = circleColorExpression;
      paintCircleProperty["circle-radius"] = 10;
      paintCircleProperty["circle-stroke-width"] = circleStrokeWidthExpression;
      paintCircleProperty["circle-stroke-color"] = circleStrokeColorExpression;
      paintCircleProperty["circle-stroke-opacity"] =
        circleStrokeOpacityExpression;
      if (!map.current.getLayer("point-layer")) {
        map.current.addLayer({
          id: "point-layer",
          source: "point-source",
          type: "circle",
          filter: [
            "all",
            ["match", ["get", "id"], hidePointIds, false, true],
            ["==", ["get", "image"], null],
          ],
          paint: paintCircleProperty,
        });
      }
      for (let item of pointsGeojson?.features) {
        if (
          item?.properties?.image &&
          !hidePointIds.includes(item?.properties?.id)
        ) {
          map.current.loadImage(item?.properties?.image, (error, image) => {
            if (error) throw error;
            if (!map.current.hasImage(item?.properties?.imageName)) {
              map.current.addImage(item?.properties?.imageName, image);
            }
          });
        }
      }
      if (!map.current.getLayer("point-image-layer")) {
        map.current.addLayer({
          id: "point-image-layer",
          source: "point-source",
          type: "symbol",
          layout: {
            "icon-image": ["get", "imageName"],
            "icon-rotation-alignment": "map",
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-size": [
              "case",
              ["==", ["get", "isThumbAvailable"], true],
              0.8,
              0.05,
            ],
          },
        });
      }
      // Add point title layer to map
      if (!map.current.getLayer("text-point-layer")) {
        map.current.addLayer({
          id: "text-point-layer",
          source: "point-source",
          type: "symbol",
          filter: ["all", ["match", ["get", "id"], hidePointIds, false, true]],
          layout: {
            "text-field": ["get", "title"],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 1.9],
            "text-anchor": "top",
            "text-size": 12,
          },
          paint: {
            "text-color": "rgba(0,0,0,0.75)",
          },
        });
      }

      // Add triangle hover layer
      let hoverImg = new Image(35, 35);
      hoverImg.onload = () => {
        if (!map.current.hasImage("triangle-hover-img")) {
          map.current.addImage("triangle-hover-img", hoverImg, { sdf: true });
        }
      };
      hoverImg.src = Triangle;

      const hoverIconImageColorExpression = [
        "case",
        ["==", ["get", "type"], "batch"],
        "rgb(53, 162, 151)",
        ["==", ["get", "type"], "exhibit"],
        "rgb(201, 95, 61)",
        ["==", ["get", "type"], "transfer"],
        "rgb(176, 21, 96)",
        ["==", ["get", "type"], "transit"],
        "rgb(115, 92, 124)",
        "#000",
      ];
      // const hoverIconImageOpacityExpression = [
      //   "case",
      //   ["==", ["get", "hover"], true],
      //   0.3,
      //   0,
      // ];
      const hoverTriangleLayerPaintProperty = {};
      hoverTriangleLayerPaintProperty["icon-color"] =
        hoverIconImageColorExpression;
      hoverTriangleLayerPaintProperty["icon-opacity"] = 0.3;
      // hoverIconImageOpacityExpression;
      if (!map.current.getLayer("triangle-hover-layer")) {
        map.current.addLayer({
          id: "triangle-hover-layer",
          source: "line-source",
          type: "symbol",
          filter: ["==", ["get", "showHoverTriangle"], true],
          layout: {
            "icon-image": "triangle-hover-img",
            "icon-rotation-alignment": "map",
            "symbol-placement": "line-center",
            "icon-allow-overlap": true,
            "icon-rotate": -90,
          },
          paint: hoverTriangleLayerPaintProperty,
        });
      }

      // Add triangle layer
      let img = new Image(20, 20);
      img.onload = () => {
        if (!map.current.hasImage("triangle")) {
          map.current.addImage("triangle", img, { sdf: true });
        }
      };
      img.src = Triangle;
      const iconImageColorExpression = [
        "case",
        ["==", ["get", "type"], "batch"],
        "rgb(53, 162, 151)",
        ["==", ["get", "type"], "exhibit"],
        "rgb(201, 95, 61)",
        ["==", ["get", "type"], "transfer"],
        "rgb(176, 21, 96)",
        ["==", ["get", "type"], "transit"],
        "rgb(115, 92, 124)",
        "#000",
      ];
      const triangleLayerPaintProperty = {};
      triangleLayerPaintProperty["icon-color"] = iconImageColorExpression;
      if (!map.current.getLayer("triangle-layer")) {
        map.current.addLayer({
          id: "triangle-layer",
          source: "line-source",
          type: "symbol",
          filter: ["==", ["get", "showTriangle"], true],
          layout: {
            "symbol-placement": "line-center",
            "icon-image": "triangle",
            "icon-rotation-alignment": "map",
            "icon-allow-overlap": true,
            "icon-rotate": -90,
          },
          paint: triangleLayerPaintProperty,
        });
      }

      // Create a popup, but don't add it to the map yet.
      var popup = new Popup({
        closeButton: false,
        closeOnClick: false,
      });

      // Circle layer events
      let isMultiPointPopup = false;
      map.current.on(
        "mouseenter",
        ["point-layer", "point-image-layer"],
        (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          if (e.features?.length) {
            let obj = {
              type: "FeatureCollection",
              features: map.current
                .getSource("point-source")
                ?._data?.features?.map((item) => {
                  return {
                    ...item,
                    properties: {
                      ...item?.properties,
                      hover:
                        e.features[0]?.properties?.id === item?.properties?.id
                          ? true
                          : false,
                    },
                  };
                }),
            };
            map.current.getSource("point-source").setData(obj);
            if (window.innerWidth > 991) {
              showPopup(
                "point-layer",
                e.features[0]?.properties?.id,
                "",
                "point-source"
              );
            }
          }
        }
      );
      map.current.on("mouseleave", ["point-layer", "point-image-layer"], () => {
        map.current.getCanvas().style.cursor = "";
        let obj = {
          type: "FeatureCollection",
          features: map.current
            .getSource("point-source")
            ?._data?.features?.map((item) => {
              return {
                ...item,
                properties: {
                  ...item?.properties,
                  hover: false,
                },
              };
            }),
        };
        map.current.getSource("point-source").setData(obj);
        if (window.innerWidth > 991 && !isMultiPointPopup) {
          popup.remove();
        }
      });
      document
        .getElementsByClassName("close")[0]
        ?.addEventListener("click", () => {
          hidePointDetails();
          updateActiveColorType("default");
        });

      // Triangle layer event
      map.current.on(
        "mouseenter",
        ["triangle-layer", "triangle-hover-layer"],
        (e) => {
          const triangleCoords = [e?.lngLat?.lng, e?.lngLat?.lat];
          trianglePointsCoords.push(triangleCoords.join(","));
          trianglePointsGeojson.features = trianglePointsGeojson?.features?.map(
            (item) => {
              return item?.properties?.id === e.features[0]?.properties?.id
                ? {
                    ...item,
                    geometry: {
                      ...item?.geometry,
                      coordinates: triangleCoords,
                    },
                  }
                : item;
            }
          );
          map.current
            .getSource("triangle-source")
            .setData(trianglePointsGeojson);
          map.current.getCanvas().style.cursor = "pointer";
          if (e.features?.length) {
            let obj = {
              type: "FeatureCollection",
              features: map.current
                .getSource("line-source")
                ?._data?.features?.map((item) => {
                  return {
                    ...item,
                    properties: {
                      ...item?.properties,
                      showHoverTriangle:
                        e.features[0]?.properties?.id === item?.properties?.id
                          ? true
                          : false,
                    },
                  };
                }),
            };
            map.current.getSource("line-source").setData(obj);
            if (window.innerWidth > 991) {
              showPopup(
                "triangle-layer",
                e.features[0]?.properties?.id,
                "",
                "triangle-source"
              );
            }
          }
        }
      );
      map.current.on(
        "mouseleave",
        ["triangle-layer", "triangle-hover-layer"],
        () => {
          map.current.getCanvas().style.cursor = "";
          let obj = {
            type: "FeatureCollection",
            features: map.current
              .getSource("line-source")
              ?._data?.features?.map((item) => {
                return {
                  ...item,
                  properties: {
                    ...item?.properties,
                    showHoverTriangle: false,
                  },
                };
              }),
          };
          map.current.getSource("line-source").setData(obj);
          if (window.innerWidth > 991 && !isMultiPointPopup) {
            popup.remove();
          }
        }
      );

      // Common events for all layers
      map.current.on("click", function (e) {
        let circleLayerData = map.current.queryRenderedFeatures(e.point, {
          layers: ["point-layer", "point-image-layer"],
        });
        let triangleLayerData = map.current.queryRenderedFeatures(e.point, {
          layers: ["triangle-layer", "triangle-hover-layer"],
        });
        if (!circleLayerData.length && !triangleLayerData?.length) {
          hidePointDetails();
          updateActiveColorType("default");
          popup.remove();
          updateOrganizationData();
          dispatchUpdateProductCurrentTab("");
          isMultiPointPopup = false;
          return;
        }
        let feature = circleLayerData?.length
          ? circleLayerData[0]
          : triangleLayerData[0];
        showPopup(
          circleLayerData?.length ? "point-layer" : "triangle-layer",
          feature.properties.id,
          "click",
          circleLayerData?.length ? "point-source" : "triangle-source"
        );
      });
      if (linesGeojson?.features?.length !== 0) {
        if (mapData?.integrant_ids?.length > 0) {
          sortLinesAnimationIndex();
          showIngredientAnimation(0);
        } else {
          showAnimation(0);
        }
      } else {
        hidePointDetails("showProduct");
      }
      function showPopup(layer, id, type, source) {
        let data = [];
        let features = map.current.getSource(source)?._data?.features;
        let selectedPointData = features?.filter(
          (item) => item?.properties?.id === id
        )[0];
        for (let item of features) {
          if (
            ((layer === "point-layer" &&
              !hidePointIds?.includes(item?.properties?.id)) ||
              layer === "triangle-layer") &&
            selectedPointData?.geometry?.coordinates?.join(",") ===
              item?.geometry?.coordinates?.join(",")
          ) {
            data.push(item);
          }
        }
        if (data?.length > 1) {
          let el = document.getElementsByClassName("tooltipUl");
          if (!el[0]) {
            let html = `<div class="tooltipUl" id="tooltipUl"></div>`;
            popup
              .setLngLat(data[0]?.geometry?.coordinates)
              .setHTML(html)
              .addTo(map.current);
            isMultiPointPopup = true;
            for (let item of data) {
              const {
                title,
                data: { effectiveDate, exhibitsType },
                type,
              } = item?.properties;
              let li = document.createElement("div");
              li.classList.add("tooltipLi");
              li.onclick = () => {
                handleOnClick(layer, item);
                if (exhibitsType) {
                  dispatchUpdateProductCurrentTab(exhibitsType);
                }
                popup.remove();
              };
              li.innerHTML = `<span style="background:${getColorByType(
                type
              )}"></span>
              <div>
                <p>${title}</p>
                ${exhibitsType ? `<p>${exhibitsType}</p>` : ""}
                <p>${moment(effectiveDate).format("DD-MM-YYYY")}</p>
              </div>`;
              document.getElementById("tooltipUl").appendChild(li);
            }
          }
        } else if (data?.length === 1) {
          const {
            title,
            data: { effectiveDate, exhibitsType, description },
          } = data[0]?.properties;
          if (type === "click") {
            handleOnClick(layer, data[0]);
          }
          if (window.innerWidth > 991) {
            let html = `<div class="tooltipRootDiv">
              <p>${title}</p>
              ${exhibitsType ? `<p>${exhibitsType}</p>` : ""}
              ${
                ["transit", "transfer"].includes(data[0]?.properties?.type) &&
                trianglePointsCoords?.includes(
                  data[0]?.geometry?.coordinates?.join(",")
                )
                  ? `<p>${description}</p>`
                  : ""
              }
              <p>${moment(effectiveDate).format("DD-MM-YYYY")}</p>
            </div>`;
            popup
              .setLngLat(data[0]?.geometry?.coordinates)
              .setHTML(html)
              .addTo(map.current);
          }
          if (exhibitsType) {
            dispatchUpdateProductCurrentTab(exhibitsType);
          }
        }
      }
      function handleOnClick(layer, data) {
        handleShowPointDetail(data?.properties?.title, data?.properties?.data);
        if (layer === "point-layer") {
          updateActiveColorType("update", data?.properties?.id);
        }
        updateOrganizationData(data?.properties?.organizationData);
      }
    });
  }, [map]);
  function updateActiveColorType(type, id) {
    let exhibitsTypeData = pointsGeojson?.features
      ?.filter((item) => item?.properties?.id === id)
      ?.map((item) => item?.properties?.data?.exhibitsType)[0];
    if (!id && productCurrentTab !== "") {
      exhibitsTypeData = productCurrentTab;
    }
    pointsGeojson["features"] = pointsGeojson?.features?.map((item, i) => {
      return {
        ...item,
        properties: {
          ...item?.properties,
          activeColorType:
            type === "update"
              ? item?.properties?.id === id
                ? "activeColor"
                : exhibitsTypeData
                ? exhibitsTypeData === item?.properties?.data?.exhibitsType
                  ? "defaultColor"
                  : "disabledColor"
                : "disabledColor"
              : "defaultColor",
        },
      };
    });
    map.current.getSource("point-source")?.setData(pointsGeojson);
  }

  function showUSmapAsdefault() {
    map.current.fitBounds(
      [
        [-125, 25],
        [-66, 49],
      ],
      {
        padding: 20,
      }
    );
  }

  function handleZoomOut(coords) {
    if (!map.current.getBounds().contains(coords)) {
      const bounds = map.current.getBounds();
      bounds.extend(coords);
      map.current.fitBounds(bounds, {
        padding: 20,
      });
    }
  }
  function sortLinesAnimationIndex() {
    let linesData = [];
    for (let item of linesGeojson?.features) {
      if (mapData?.integrant_ids?.includes(item?.properties?.integrant_id)) {
        linesData?.unshift(item);
      } else {
        linesData?.push(item);
      }
    }
    linesGeojson["features"] = linesData?.reverse();
    let routesData = [];
    for (let item of routeGeojson?.features) {
      if (mapData?.integrant_ids?.includes(item?.properties?.integrant_id)) {
        routesData?.unshift(item);
      } else {
        routesData?.push(item);
      }
    }
    routeGeojson["features"] = routesData?.reverse();
    centerCoords = mapData?.data?.filter(
      (item) =>
        item?.type === "batch" &&
        item?.integrant_id === mapData?.integrant_ids[0]
    )[0]?.origin;
  }
  function showAnimation(index) {
    if (routeGeojson.features[index] != undefined) {
      // Calculate the distance in kilometers between route start/end point.
      const lineDistance = turf.length(routeGeojson.features[index]);
      const arc = [];
      // Number of steps to use in the arc and animation, more steps means
      // a smoother arc and animation, but too many steps will result in a
      // low frame rate
      const steps = 50000;
      // Draw an arc between the `origin` & `destination` of the two points
      for (let i = 50; i < lineDistance + 50; i += lineDistance / steps) {
        const segment = turf.along(routeGeojson.features[index], i);
        arc.push(segment.geometry.coordinates);
      }
      // Update the route with calculated arc coordinates
      handleZoomOut(routeGeojson.features[index].geometry.coordinates[0]);
      handleZoomOut(routeGeojson.features[index].geometry.coordinates[1]);
      routeGeojson.features[index].geometry.coordinates = arc;
      // Used to increment the value of the point measurement against the route.
      let counter = 0;
      function animate() {
        if (counter === 0) {
          if (window.innerWidth > 990) {
            handleShowPointDetail(
              linesGeojson?.features[index]?.properties?.title,
              linesGeojson?.features[index]?.properties?.data
            );
          }
        }
        const start =
          routeGeojson.features[index].geometry.coordinates[
            counter > steps ? arc.length - 1 : counter
          ];
        const end =
          routeGeojson.features[index].geometry.coordinates[
            counter >= steps ? arc.length - 1 : counter + 1
          ];
        if (!start || !end) {
          if (index < linesGeojson?.features?.length) {
            linesGeojson.features[index].properties.showTriangle = true;
            map.current.getSource("line-source").setData(linesGeojson);
            setTimeout(() => {
              showAnimation(index + 1);
            }, 2000);
          }
          if (index >= linesGeojson?.features?.length - 1) {
            setTimeout(() => {
              hidePointDetails("showProduct");
              map.current.setCenter(centerCoords);
              map.current.setZoom(3);
            }, 1000);
          }
          return;
        }
        linesGeojson.features[index].geometry.coordinates.push([
          start[0],
          end[1],
        ]);
        // Update the source with this new data
        map.current.getSource("line-source").setData(linesGeojson);
        if (counter === steps / 2) {
          // trianglePointsGeojson.features[index].properties.bearing =
          //   turf.bearing(turf.point(end), turf.point(start));
          // trianglePointsGeojson.features[index].geometry.coordinates = [
          //   start[0],
          //   end[1],
          // ]
          // linesGeojson.features[index].properties.showTriangle = true;
          // Update the source with this new data
          // map.current.getSource("line-source").setData(linesGeojson);
          // trianglePointsCoords.push([start[0], end[1]]?.join(","));
          // map.current
          //   .getSource("triangle-source")
          //   .setData(trianglePointsGeojson);
        }
        // Request the next frame of animation as long as the end has not been reached
        if (counter < steps) {
          requestAnimationFrame(animate);
        } else {
          if (index < linesGeojson?.features?.length) {
            linesGeojson.features[index].properties.showTriangle = true;
            map.current.getSource("line-source").setData(linesGeojson);
            showAnimation(index + 1);
          }
          if (index >= linesGeojson?.features?.length - 1) {
            setTimeout(() => {
              hidePointDetails("showProduct");
              map.current.setZoom(3);
              map.current.setCenter(centerCoords);
            }, 1000);
          }
        }
        counter = counter + steps / 50;
      }
      // Start the animation
      animate(counter);
    }
  }
  function showIngredientAnimation(index) {
    if (routeGeojson.features[index] != undefined) {
      // Calculate the distance in kilometers between route start/end point.
      const lineDistance = turf.length(routeGeojson.features[index]);
      const arc = [];
      // Number of steps to use in the arc and animation, more steps means
      // a smoother arc and animation, but too many steps will result in a
      // low frame rate
      const steps = 50000;
      // Draw an arc between the `origin` & `destination` of the two points
      for (let i = 50; i < lineDistance + 50; i += lineDistance / steps) {
        const segment = turf.along(routeGeojson.features[index], i);
        arc.push(segment.geometry.coordinates);
      }
      // Update the route with calculated arc coordinates
      handleZoomOut(routeGeojson.features[index].geometry.coordinates[0]);
      handleZoomOut(routeGeojson.features[index].geometry.coordinates[1]);
      routeGeojson.features[index].geometry.coordinates = arc;
      // Used to increment the value of the point measurement against the route.
      let counter = 0;
      function animate() {
        if (counter === 0) {
          if (window.innerWidth > 990) {
            handleShowPointDetail(
              linesGeojson?.features[index]?.properties?.title,
              linesGeojson?.features[index]?.properties?.data
            );
          }
        }
        const start =
          routeGeojson.features[index].geometry.coordinates[
            counter > steps ? arc.length - 1 : counter
          ];
        const end =
          routeGeojson.features[index].geometry.coordinates[
            counter >= steps ? arc.length - 1 : counter + 1
          ];
        if (!start || !end) {
          if (index < linesGeojson?.features?.length) {
            linesGeojson.features[index].properties.showTriangle = true;
            map.current.getSource("line-source").setData(linesGeojson);
            setTimeout(() => {
              showIngredientAnimation(index + 1);
            }, 2000);
          }
          if (index >= linesGeojson?.features?.length - 1) {
            setTimeout(() => {
              hidePointDetails("showProduct");
              map.current.setCenter(centerCoords);
              map.current.setZoom(3);
              setDefaultIntegrantIds();
            }, 1000);
          }
          return;
        }
        linesGeojson.features[index].geometry.coordinates.push([
          start[0],
          end[1],
        ]);
        // Update the source with this new data
        map.current.getSource("line-source").setData(linesGeojson);
        if (counter === steps / 2) {
          // trianglePointsGeojson.features[index].properties.bearing =
          //   turf.bearing(turf.point(end), turf.point(start));
          // trianglePointsGeojson.features[index].geometry.coordinates = [
          //   start[0],
          //   end[1],
          // ];
          // trianglePointsCoords.push([start[0], end[1]]?.join(","));
          // map.current
          //   .getSource("triangle-source")
          //   .setData(trianglePointsGeojson);
        }
        // Request the next frame of animation as long as the end has not been reached
        if (counter < steps) {
          requestAnimationFrame(animate);
        } else {
          if (index < linesGeojson?.features?.length) {
            linesGeojson.features[index].properties.showTriangle = true;
            map.current.getSource("line-source").setData(linesGeojson);
            showIngredientAnimation(index + 1);
          }
          if (index >= linesGeojson?.features?.length - 1) {
            setTimeout(() => {
              hidePointDetails("showProduct");
              map.current.setZoom(3);
              map.current.setCenter(centerCoords);
              setDefaultIntegrantIds();
            }, 1000);
          }
        }
        counter = counter + steps / 50;
      }
      function noAnimate() {
        for (let i = 0; i < steps; i = i + steps / 50) {
          const start =
            routeGeojson.features[index].geometry.coordinates[
              i > steps ? arc.length - 1 : i
            ];
          const end =
            routeGeojson.features[index].geometry.coordinates[
              i >= steps ? arc.length - 1 : i + 1
            ];
          if (start && end) {
            linesGeojson.features[index].geometry.coordinates.push([
              start[0],
              end[1],
            ]);
          }
          // Update the source with this new data
          if (i === steps / 2) {
            // trianglePointsGeojson.features[index].properties.bearing =
            //   turf.bearing(turf.point(end), turf.point(start));
            // trianglePointsGeojson.features[index].geometry.coordinates = [
            //   start[0],
            //   end[1],
            // ];
            // trianglePointsCoords.push([start[0], end[1]]?.join(","));
          }
        }
        map.current.getSource("line-source").setData(linesGeojson);
        // map.current.getSource("triangle-source").setData(trianglePointsGeojson);
        if (index < linesGeojson?.features?.length) {
          linesGeojson.features[index].properties.showTriangle = true;
          map.current.getSource("line-source").setData(linesGeojson);
          showIngredientAnimation(index + 1);
        }
        if (index >= linesGeojson?.features?.length - 1) {
          hidePointDetails("showProduct");
          map.current.setZoom(3);
          map.current.setCenter(centerCoords);
          setDefaultIntegrantIds();
        }
      }
      // Start the animation
      if (
        mapData?.integrant_ids?.includes(
          linesGeojson?.features[index]?.properties?.integrant_id
        )
      ) {
        animate(counter);
      } else {
        noAnimate(counter);
      }
    }
  }
  function handleResetLineAnimationGeojson() {
    updateMapLayerSourceData();
    map.current.getSource("line-source").setData(trianglePointsGeojson);
    map.current.getSource("triangle-source").setData(trianglePointsGeojson);
    map.current.setZoom(3);
    map.current.setCenter(centerCoords);
    setReplayLineAnimation(false);
  }
  function setDefaultIntegrantIds() {
    if (mapData?.integrant_ids?.length > 0) {
      dispatchUpdateMapDataIntegrantId({ ids: [], location: "" });
    }
  }
  useEffect(() => {
    if (replayLineAnimation) {
      handleResetLineAnimationGeojson();
      showAnimation(0);
    }
  }, [replayLineAnimation]);

  useEffect(() => {
    if (productCurrentTab !== "") {
      updateActiveColorType("update");
    }
  }, [productCurrentTab]);

  useEffect(() => {
    if (
      mapData?.integrant_ids?.length !== 0 &&
      mapData?.location === "infotab"
    ) {
      sortLinesAnimationIndex();
      showIngredientAnimation(0);
    }
  }, [mapData]);

  return (
    <div
      ref={mapContainer}
      className={
        location ? "drawerMapboxGLDiv" : `mapboxgl mapboxglManagedWidth`
      }
    ></div>
  );
}

const mapStateToProps = ({ productReducers: { mapData } }) => ({
  mapData,
});

const mapDispatchToProps = {
  dispatchUpdateMapDataIntegrantId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapboxGL);

const getColorByType = (type) => {
  switch (type) {
    case "batch":
      return "rgb(53, 162, 151)";
    case "exhibit":
      return "rgb(201, 95, 61)";
    case "transfer":
      return "rgb(176, 21, 96)";
    case "transit":
      return "rgb(115, 92, 124)";
    default:
      return "#000";
  }
};
