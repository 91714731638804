import React, { useEffect } from "react";
import LayerWrapper from "../../components/LayerWrapper";
import Routes from "../../routers";
import Loader from "../../components/Loader";
import { StylesProvider } from "@material-ui/core/styles";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import useViewport from "../../hooks/useViewport";

function App() {
  const { width, height } = useViewport();
  useEffect(() => {
    document?.documentElement?.style?.setProperty("--vh", `${height * 0.01}px`);
    let googleDivs = document.getElementsByClassName("goog-te-gadget");
    for (let el of googleDivs) {
      el.textContent = "";
    }
  }, [width, height]);
  return (
    <StylesProvider injectFirst>
      <LayerWrapper>
        <Loader />
        <Routes />
        <NotificationContainer />
      </LayerWrapper>
    </StylesProvider>
  );
}

export default App;
