import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  MuiSelect,
  MainHeading,
  SmProfileFooter,
} from "../../components/Profile";
import { dispatchGetAllIntegrantTypesList } from "../../redux/actions/product";
import utils from "../../utils/commonUtils";
import clsx from "clsx";
import {
  Grid,
  makeStyles,
  InputLabel,
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "../../components";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { postProductFeedback } from "../../redux/actions/profileActions";

const useStyle = makeStyles((theme) => ({
  reviewRating: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 10,
    "&>label": {
      marginRight: 10,
      marginBottom: 0,
    },
    "&>div": {
      width: "max-content",
      alignItems: "center",
      justifyContent: "flex-start",
      "&>svg": {
        cursor: "pointer",
      },
    },
  },
  reviewQuestion: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  textfield: {
    "&>div": {
      border: "1px solid #999",
    },
  },
  recyclePackaging: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "&>label": {
      marginRight: 10,
      marginBottom: 0,
    },
    "&>div": {
      "&>label": {
        margin: 0,
        "&>span": {
          "&:nth-child(1)": {
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
          "&:nth-child(2)": {
            fontSize: 18,
            lineHeight: "23px",
            color: theme.palette.text.primary,
            fontWeight: 400,
          },
        },
      },
    },
  },
  submitBtn: {
    width: "max-content",
  },
}));

const defaultReviewData = {
  rating: 0,
  message: "",
  product_buying_info: "",
  recycle_packaging: "",
};

let feedbackAPIFlag = false;

export const FeedbackPage = (props) => {
  const {
    userData,
    activePage,
    match,
    dispatchGetAllIntegrantTypesList,
    getAllintegrantTypesList,
    history,
    productFeedback,
    postProductFeedback,
  } = props;
  const classes = useStyle();
  const { user_id } = utils.getLocalStorageItems();
  const [integrant_type_id, setIntegrant_type_id] = useState("");
  const [integrant_id, setIntegrant_id] = useState("");
  const [reviewData, setReviewData] = useState(defaultReviewData);
  const [error, setError] = useState(false);
  const handleChange = (key, value) => {
    setReviewData((pre) => ({ ...pre, [key]: value }));
    setError(false);
  };
  const handleSubmit = () => {
    if (
      Object.entries(reviewData).filter((item) => !Boolean(item[1])).length ===
      0
    ) {
      const organization_id = getAllintegrantTypesList?.data?.filter(
        (item) => item?.id === integrant_type_id
      )[0]?.organization_id;
      postProductFeedback({
        ...reviewData,
        user_id: utils.getLocalStorageItems().user_id,
        integrant_id,
        integrant_type_id,
        organization_id,
      });
      feedbackAPIFlag = true;
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    setReviewData(defaultReviewData);
    setError(false);
    setIntegrant_type_id("");
    setIntegrant_id("");
  };

  useEffect(() => {
    if (productFeedback?.status === "1" && feedbackAPIFlag) {
      feedbackAPIFlag = false;
      handleClose();
      dispatchGetAllIntegrantTypesList({
        user_id,
        type: "consumer_profile_feedback_page",
      });
    }
  }, [productFeedback?.status]);

  const handleIntegrantTypeChange = (id) => {
    setIntegrant_type_id(id);
    setIntegrant_id("");
    setReviewData(defaultReviewData);
    setError(false);
  };
  const handleIntegrantChange = (id) => {
    setIntegrant_id(id);
  };

  useEffect(() => {
    dispatchGetAllIntegrantTypesList({
      user_id,
      type: "consumer_profile_feedback_page",
    });
  }, []);

  return (
    <Grid className="profile-root">
      <Grid className="profile-page-content profile-feedback-page">
        <MainHeading style={{ textAlign: "left", marginBottom: 20 }}>
          Provide Feedback
        </MainHeading>
        {(getAllintegrantTypesList?.data?.length > 0 ||
          getAllintegrantTypesList?.loading) && (
          <Grid className="product-section">
            <MuiSelect
              match={match}
              placeholder="Select product"
              menuItems={
                getAllintegrantTypesList?.loading
                  ? []
                  : getAllintegrantTypesList?.data?.map((item) => {
                      return {
                        value: item?.id,
                        label: (
                          <p className="product-list-li-offer-label">
                            {item?.title}
                            {item?.integrant_type_offer && <span>offer</span>}
                          </p>
                        ),
                      };
                    })
              }
              loading={getAllintegrantTypesList?.loading}
              onChange={(e) => handleIntegrantTypeChange(e.target.value)}
              value={integrant_type_id}
              className="notranslate"
            />
          </Grid>
        )}
        {getAllintegrantTypesList?.data?.length > 0 &&
          !getAllintegrantTypesList?.loading &&
          integrant_type_id && (
            <Grid className="product-section">
              <MuiSelect
                match={match}
                placeholder="Select batch"
                menuItems={getAllintegrantTypesList?.data
                  ?.filter((item) => item?.id === integrant_type_id)[0]
                  ?.integrantsType?.map((item) => {
                    return { value: item?.id, label: item?.external_id };
                  })}
                loading={getAllintegrantTypesList?.loading}
                onChange={(e) => handleIntegrantChange(e.target.value)}
                value={integrant_id}
              />
            </Grid>
          )}
        {integrant_type_id && integrant_id && (
          <>
            <Grid item className={clsx(classes.reviewRating)}>
              <InputLabel required error={!reviewData?.rating && error}>
                Rate this product
              </InputLabel>
              <Grid container>
                {[...Array(5)].map((el, i) => {
                  return reviewData.rating >= i + 1 ? (
                    <StarIcon
                      onClick={() => handleChange("rating", i + 1)}
                      style={{ color: "#ffe11b" }}
                      key={i}
                    />
                  ) : (
                    <StarOutlineIcon
                      key={i}
                      onClick={() => handleChange("rating", i + 1)}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid item className={classes.recyclePackaging}>
              <InputLabel
                required
                error={!reviewData.recycle_packaging && error}
              >
                Did you recycle the packaging?
              </InputLabel>
              <RadioGroup
                value={reviewData.recycle_packaging}
                onChange={(e) =>
                  handleChange("recycle_packaging", e.target.value)
                }
                row
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item className={clsx(classes.reviewQuestion)}>
              <InputLabel
                required
                error={!reviewData.product_buying_info && error}
              >
                Where did you buy this product?
              </InputLabel>
              <TextField
                className={classes.textfield}
                value={reviewData.product_buying_info}
                onChange={(e) =>
                  handleChange("product_buying_info", e.target.value)
                }
              />
            </Grid>
            <Grid item className={clsx(classes.reviewQuestion)}>
              <InputLabel required error={!reviewData.message && error}>
                Please provide feedback
              </InputLabel>
              <TextField
                multiline
                minRows={3}
                value={reviewData.message}
                className={classes.textfield}
                onChange={(e) => handleChange("message", e.target.value)}
              />
            </Grid>
            <Button
              primaryDarkBtn
              loading={productFeedback?.loading}
              onClick={handleSubmit}
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </>
        )}
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  productReducers: { getAllintegrantTypesList },
  ProfileReducers: { productFeedback },
}) => ({
  getAllintegrantTypesList,
  productFeedback,
});

const mapDispatchToProps = {
  dispatchGetAllIntegrantTypesList,
  postProductFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPage);
