import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Drawer,
  Grid,
  Typography,
  makeStyles,
  InputLabel,
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { postProductFeedback } from "../../../redux/actions/profileActions";
import utils from "../../../utils/commonUtils";
import { ProductFeedbackDialog } from "../../../components/Dialogs";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    width: 600,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 20,
    "&>p": {
      marginBottom: 15,
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  productInfo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 15,
    "&>img": {
      width: 80,
      height: 80,
      borderRadius: "50%",
      marginRight: 10,
    },
    "&>div": {
      flexDirection: "column",
      "&>div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "&>h6": {
          marginRight: 5,
        },
        "&>p": {},
      },
    },
  },
  reviewRating: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 10,
    "&>label": {
      marginRight: 10,
      marginBottom: 0,
    },
    "&>div": {
      width: "max-content",
      alignItems: "center",
      justifyContent: "flex-start",
      "&>svg": {
        cursor: "pointer",
      },
    },
  },
  reviewQuestion: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  textfield: {
    "&>div": {
      border: "1px solid #999",
    },
  },
  recyclePackaging: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "&>label": {
      marginRight: 10,
      marginBottom: 0,
    },
    "&>div": {
      "&>label": {
        margin: 0,
        "&>span": {
          "&:nth-child(1)": {
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
          "&:nth-child(2)": {
            fontSize: 18,
            lineHeight: "23px",
            color: theme.palette.text.primary,
            fontWeight: 400,
          },
        },
      },
    },
  },
}));

const defaultReviewData = {
  rating: 0,
  message: "",
  product_buying_info: "",
  recycle_packaging: "",
};

let feedbackAPIFlag = false;

export const Review = ({
  open = false,
  onClose = () => {},
  integrantInfo,
  productFeedback,
  postProductFeedback,
}) => {
  const history = useHistory();
  const classes = useStyle();
  const [reviewData, setReviewData] = useState(defaultReviewData);
  const [error, setError] = useState(false);
  const [openProductFeedbackDialog, setOpenProductFeedbackDialog] =
    useState(false);
  const handleChange = (key, value) => {
    setReviewData((pre) => ({ ...pre, [key]: value }));
    setError(false);
  };
  const handleSubmit = () => {
    if (
      Object.entries(reviewData).filter((item) => !Boolean(item[1])).length ===
      0
    ) {
      postProductFeedback({
        ...reviewData,
        user_id: utils.getLocalStorageItems().user_id,
        organization_id: integrantInfo.organization_id,
        integrant_id: integrantInfo.id,
        integrant_type_id: integrantInfo.integrant_type_id,
      });
      feedbackAPIFlag = true;
    } else {
      setError(true);
    }
  };

  const handleDrawerClose = () => {
    setReviewData(defaultReviewData);
    onClose();
  };

  const handleClose = (type) => (e) => {
    setOpenProductFeedbackDialog(false);
    handleDrawerClose();
    if (type === "create") {
      history.push("/create-profile");
    }
  };

  useEffect(() => {
    if (productFeedback?.status === "1" && feedbackAPIFlag) {
      feedbackAPIFlag = false;
      setOpenProductFeedbackDialog(true);
    }
  }, [productFeedback?.status]);

  useEffect(() => {
    if (open) {
      setReviewData(defaultReviewData);
    }
  }, [open]);
  return (
    <React.Fragment>
      <Drawer open={open} onClose={handleDrawerClose} anchor="right">
        <Grid className={classes.drawerRoot} container>
          <Grid
            item
            className={clsx(classes.closeArrow, "center")}
            onClick={handleDrawerClose}
          >
            <ArrowForwardIosIcon />
          </Grid>
          <Typography variant="h2" bold>
            Provide Feedback
          </Typography>
          <Typography variant="body1" light>
            Provide feedback to the brand and suppliers about this specific
            product and complete profile to receive rewards.
          </Typography>
          <Grid item className={clsx(classes.productInfo)}>
            <img
              src={integrantInfo?.integrantsType?.image_url}
              alt="Product Img"
            />
            <Grid container>
              <Grid item>
                <Typography variant="h6" bold>
                  Product Name:
                </Typography>
                <Typography variant="body2" light>
                  {integrantInfo?.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" bold>
                  Batch Number:
                </Typography>
                <Typography variant="body2" light>
                  {integrantInfo?.external_id}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.reviewRating)}>
            <InputLabel required error={!reviewData?.rating && error}>
              Rate this product
            </InputLabel>
            <Grid container>
              {[...Array(5)].map((el, i) => {
                return reviewData.rating >= i + 1 ? (
                  <StarIcon
                    onClick={() => handleChange("rating", i + 1)}
                    style={{ color: "#ffe11b" }}
                    key={i}
                  />
                ) : (
                  <StarOutlineIcon
                    key={i}
                    onClick={() => handleChange("rating", i + 1)}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item className={classes.recyclePackaging}>
            <InputLabel required error={!reviewData.recycle_packaging && error}>
              Did you recycle the packaging?
            </InputLabel>
            <RadioGroup
              value={reviewData.recycle_packaging}
              onChange={(e) =>
                handleChange("recycle_packaging", e.target.value)
              }
              row
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item className={clsx(classes.reviewQuestion)}>
            <InputLabel
              required
              error={!reviewData.product_buying_info && error}
            >
              Where did you buy this product?
            </InputLabel>
            <TextField
              className={classes.textfield}
              value={reviewData.product_buying_info}
              onChange={(e) =>
                handleChange("product_buying_info", e.target.value)
              }
            />
          </Grid>
          <Grid item className={clsx(classes.reviewQuestion)}>
            <InputLabel required error={!reviewData.message && error}>
              Please provide feedback
            </InputLabel>
            <TextField
              multiline
              minRows={3}
              value={reviewData.message}
              className={classes.textfield}
              onChange={(e) => handleChange("message", e.target.value)}
            />
          </Grid>
          <Button
            primaryDarkBtn
            loading={productFeedback?.loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Drawer>
      <ProductFeedbackDialog
        open={openProductFeedbackDialog}
        handleClose={handleClose()}
        handleCreate={handleClose("create")}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ ProfileReducers: { productFeedback } }) => ({
  productFeedback,
});

const mapDispatchToProps = {
  postProductFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
