import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { PrimaryIconFrame } from "../Common/IconFrames";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p:not(:nth-child(2))": {
      marginBottom: 15,
    },
    "&>p": {
      color: "#707070",
      "&>span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  heading: {
    display: "  grid",
    width: "100%",
    marginBottom: 75,
    textAlign: "center",
    justifyItems: "center",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
  },
  darkWord: {
    fontWeight: "700",
    color: "#000",
  },
  headingText: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const {
  images: { labelSection1Img },
} = appImages;

export default function LabelSection1() {
  const classes = useStyle();
  const content = [
    <>Are the same ingredients listed on the label as on the NIH listing?</>,
    <>Are the active ingredients the same?</>,
    <>Are the percentages the same?</>,
    <>Does the lab COA also agree?</>,
  ];
  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Box className={classes.heading}>
          <PrimaryHeading>
            Compare the ingredients on the product label to
          </PrimaryHeading>
          <Typography className={classes.headingText} variant="body2">
            what the brand has claimed to the NIH (Nat'l Institute of Health)
            that the product contains and with the lab's certificate of analysis
            (COA)
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={labelSection1Img} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Grid container spacing={3}>
              {content?.map((item, key) => {
                return (
                  <Grid item style={{ width: "100%" }} key={key}>
                    <Box className={classes.contentBox}>
                      <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                      <Typography variant="body2">{item}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
