import React, { useState } from "react";
import PropTypes from "prop-types";
import { AppImages, AppConstant } from "../../constants";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
const {
  icon: { download, iconView, blockchainIcon },
} = AppImages;

const { tabsText } = AppConstant;

function ProductTabs({ tabs, integrantInfo, productBGColor }) {
  const [currentTab, setTabs] = useState(0);
  const urlParams = useParams();
  //const { external_id } = urlParams
  const { external_id } = integrantInfo;
  return (
    <div className="product-tabs">
      <div className="tab-header">
        <TabHead
          tabs={tabs}
          currentTab={currentTab}
          setTabs={setTabs}
          productBGColor={productBGColor}
        />
      </div>
      <div className="tab-panel">
        <TabPanel
          tabs={tabs}
          currentTab={currentTab}
          externalId={external_id}
          urlParams={urlParams}
          productBGColor={productBGColor}
        />
      </div>
    </div>
  );
}
ProductTabs.propTypes = {
  tabs: PropTypes.instanceOf(Array),
};

ProductTabs.defaultProps = {
  tabs: [],
};

export default ProductTabs;

/** ***Tab****** */

function tabColor(index) {
  switch (index) {
    case 0:
      return "#df8901";
    case 1:
      return "#0000ff";
    case 2:
      return "#ff0000";
    default:
      return "#000000";
  }
}

const TabHead = ({ tabs, currentTab, setTabs, productBGColor }) =>
  tabs.map((item, currentMenu) => (
    <button
      type="button"
      className={`tab-button ${currentTab === currentMenu && "active"}`}
      /*style={{ background: tabColor(currentMenu) }}*/
      style={{
        background: productBGColor ? productBGColor : tabColor(currentMenu),
        color:
          currentTab !== currentMenu
            ? productBGColor
              ? productBGColor
              : tabColor(currentMenu)
            : "#fff",
      }}
      onClick={() => setTabs(currentMenu)}
      key={item.title}
    >
      {item.title}
    </button>
  ));

const TabPanel = ({
  tabs,
  currentTab,
  externalId,
  urlParams,
  productBGColor,
}) =>
  tabs.map((item, index) => {
    if (currentTab === index) {
      const { title, exhibitsType } = item;
      return (
        <div key={title}>
          <p
            className="text-center tab-title"
            style={{ color: productBGColor ? productBGColor : tabColor(index) }}
          >
            {tabsText[title]} {externalId}{" "}
            {title === "Quality" ? tabsText.Quality01 : "product?"}
          </p>
          <div
            className="tab-list"
            style={{
              background: productBGColor
                ? productBGColor
                : "linear-gradient(to left bottom, yellow, orange, darkorange)",
            }}
          >
            <div className="tab-list-white">
              {exhibitsType ? (
                exhibitsType.map((list) => {
                  const { id, url } = list;
                  const pdfUrl =
                    "/p/" +
                    urlParams.short_code +
                    "/b/" +
                    urlParams.external_id +
                    "/d/" +
                    id;
                  const blockchainUrl =
                    "/p/" +
                    urlParams.short_code +
                    "/b/" +
                    urlParams.external_id +
                    "/" +
                    id +
                    "/blockchain-proof";
                  return (
                    <div
                      className="tab-list-item d-flex justify-content-between align-items-center"
                      key={id}
                    >
                      <div className="tab-list-itemtext">{list.title}</div>
                      <span className="tab-list-icon">
                        <Link to={pdfUrl} title="View Document">
                          <img
                            src={iconView}
                            alt=""
                            height="20"
                            className="icon-right"
                          />
                        </Link>
                        <Link
                          to={blockchainUrl}
                          title="Exhibit Blockchain Proof"
                        >
                          <img
                            src={blockchainIcon}
                            alt=""
                            height="20"
                            className="icon-right"
                          />
                        </Link>
                        <a
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                          title="Download"
                        >
                          <img src={download} alt="" height="20" />
                        </a>
                      </span>
                    </div>
                  );
                })
              ) : (
                <>No Item</>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  });
