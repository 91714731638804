import {
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  GET_POSTS_LIST_START,
  GET_POSTS_LIST_SUCCESS,
  POST_LIKE_DISLIKE_START,
  POST_LIKE_DISLIKE_SUCCESS,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  GET_POST_COMMENT_LIST_START,
  GET_POST_COMMENT_LIST_SUCCESS,
  UPDATE_POST_START,
  UPDATE_POST_SUCCESS,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  GET_POST_DETAIL_START,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_LIKED_USERS_START,
  GET_POST_LIKED_USERS_SUCCESS,
  DELETE_POST_COMMENT_START,
  DELETE_POST_COMMENT_SUCCESS,
  UPDATE_POST_COMMENT_START,
  UPDATE_POST_COMMENT_SUCCESS,
  SHARE_POST_START,
  SHARE_POST_SUCCESS,
  GET_LINK_PREVIEW_START,
  GET_LINK_PRIVIEW_SUCCESS,
} from "../actionsType/post";
import { postAPIs } from "../../services/index";
import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";
import { catchHandler } from ".";

export const getPostHashtags = async (params) => {
  const response = await postAPIs.getPostHashtags(params);
  return response?.data?.data || [];
};

export const createPost = (params) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_POST_START,
    });
    const postData = await postAPIs.createPost(params);
    dispatch({
      type: CREATE_POST_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Create post",
    });
  } catch (error) {
    catchHandler(dispatch, error, CREATE_POST_SUCCESS);
  }
};

export const sharePost = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SHARE_POST_START,
    });
    const postData = await postAPIs.sharePost(params);
    dispatch({
      type: SHARE_POST_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Share post",
    });
  } catch (error) {
    catchHandler(dispatch, error, SHARE_POST_SUCCESS);
  }
};

export const updatePost = (params) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_POST_START,
    });
    const postData = await postAPIs.updatePost(params);
    dispatch({
      type: UPDATE_POST_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Update post",
    });
  } catch (error) {
    catchHandler(dispatch, error, UPDATE_POST_SUCCESS);
  }
};

export const deletePost = (params) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_POST_START,
    });
    const postData = await postAPIs.deletePost(params);
    dispatch({
      type: DELETE_POST_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Delete post",
    });
  } catch (error) {
    catchHandler(dispatch, error, DELETE_POST_SUCCESS);
  }
};

export const getPostDetail = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_DETAIL_START,
    });
    const postData = await postAPIs.getPostDetail(params);
    dispatch({
      type: GET_POST_DETAIL_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Get post detail",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_POST_DETAIL_SUCCESS);
  }
};

export const getPostsList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POSTS_LIST_START,
      payload: params,
    });
    const postsData = await postAPIs.getPostsList({ ...params, limit: 10 });
    dispatch({
      type: GET_POSTS_LIST_SUCCESS,
      payload: postsData?.data,
    });
    if (postsData?.data?.status !== "1") {
      NotificationManager.error(postsData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Get posts",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_POSTS_LIST_SUCCESS);
  }
};

export const postLikeDislike = (params) => async (dispatch) => {
  try {
    dispatch({
      type: POST_LIKE_DISLIKE_START,
    });
    const likeDislikeData = await postAPIs.postLikeDislike(params);
    dispatch({
      type: POST_LIKE_DISLIKE_SUCCESS,
      payload: likeDislikeData?.data,
    });
    if (likeDislikeData?.data?.status !== "1") {
      NotificationManager.error(likeDislikeData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Like dislike post",
    });
  } catch (error) {
    catchHandler(dispatch, error, POST_LIKE_DISLIKE_SUCCESS);
  }
};

export const getPostLikedUsersList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_LIKED_USERS_START,
    });
    const usersList = await postAPIs.getPostLikedUsersList(params);
    dispatch({
      type: GET_POST_LIKED_USERS_SUCCESS,
      payload: usersList?.data,
    });
    if (usersList?.data?.status !== "1") {
      NotificationManager.error(usersList?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Get post liked users list",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_POST_LIKED_USERS_SUCCESS);
  }
};

export const postComment = (params) => async (dispatch) => {
  try {
    dispatch({
      type: POST_COMMENT_START,
    });
    const commentData = await postAPIs.postComment(params);
    dispatch({
      type: POST_COMMENT_SUCCESS,
      payload: commentData?.data,
    });
    if (commentData?.data?.status !== "1") {
      NotificationManager.error(commentData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Add comments and replay in post",
    });
  } catch (error) {
    catchHandler(dispatch, error, POST_COMMENT_SUCCESS);
  }
};

export const updateComment = (params) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_POST_COMMENT_START,
    });
    const commentData = await postAPIs.updateComment(params);
    dispatch({
      type: UPDATE_POST_COMMENT_SUCCESS,
      payload: commentData?.data,
    });
    if (commentData?.data?.status !== "1") {
      NotificationManager.error(commentData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Update post comment",
    });
  } catch (error) {
    catchHandler(dispatch, error, UPDATE_POST_COMMENT_SUCCESS);
  }
};

export const deleteComment = (params) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_POST_COMMENT_START,
    });
    const commentData = await postAPIs.deleteComment(params);
    dispatch({
      type: DELETE_POST_COMMENT_SUCCESS,
      payload: commentData?.data,
    });
    if (commentData?.data?.status !== "1") {
      NotificationManager.error(commentData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Delete post comment",
    });
  } catch (error) {
    catchHandler(dispatch, error, DELETE_POST_COMMENT_SUCCESS);
  }
};

export const getPostCommentList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_COMMENT_LIST_START,
    });
    const commentListData = await postAPIs.getPostCommentList(params);
    dispatch({
      type: GET_POST_COMMENT_LIST_SUCCESS,
      payload: commentListData?.data,
    });
    if (commentListData?.data?.status !== "1") {
      NotificationManager.error(commentListData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Get post comment list.",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_POST_COMMENT_LIST_SUCCESS);
  }
};

export const getLinkPreview = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LINK_PREVIEW_START,
    });
    const postData = await postAPIs.getLinkPreview(params);
    dispatch({
      type: GET_LINK_PRIVIEW_SUCCESS,
      payload: postData?.data,
    });
    if (postData?.data?.status !== "1") {
      NotificationManager.error(postData?.data?.message, "", 5000);
    }
    ReactGA.event({
      category: "Post",
      action: "Get link priview",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_LINK_PRIVIEW_SUCCESS);
  }
};
