import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";

const {
  images: { consumerProfileImage },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    "&>img": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      maxWidth: "100%",
      width: "100%",
    },
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function ConsumerProfile() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>Consumer Profile</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            Consumers create and manage their own profile and demographic
            information. They also manage which companies they connect to, which
            products they provide feedback on, how they interact on the social
            page with other consumers and with practitioners, how they report
            recycling efforts and which surveys they may respond to for
            compensation.
          </Typography>
          <Box className={classes.sectionImage}>
            <img src={consumerProfileImage} alt="Image" />
          </Box>
          <Box className={classes.btns}>
            <Button href="/create-profile">Learn More</Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
