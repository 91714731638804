import React from "react";
import { Grid, makeStyles, Typography } from "../";
import { AppImages } from "../../constants";

const {
  homePageImg: {
    featuredCompanies03,
    featuredCompanies04,
    featuredCompanies05,
    featuredCompanies06,
    featuredCompanies07,
    featuredCompanies08,
  },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  certifiedPartnersContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "70px 0 35px",
    margin: "0 auto",
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  certifiedPartnersHeadings: {
    marginBottom: 50,
    "&>h2": {
      textAlign: "center",
      marginBottom: 15,
      "&>span": {
        color: theme.palette.primary.main,
      },
    },
    "&>p": {
      textAlign: "center",
      fontStyle: "italic",
    },
  },
  certifiedPartnersListDiv: {
    "&>div": {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      "&:not(:last-child)": {
        marginBottom: 25,
      },
    },
  },
  certifiedPartnersTypes: {
    width: "100%",
    maxWidth: 370,
    paddingRight: 35,
    "&>h3": {
      color: theme.palette.primary.main,
      marginBottom: 15,
    },
    "&>p": {
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  certifiedPartnersLogos: {
    width: "100%",
    maxWidth: "calc(100% - 370px)",
    display: "flex",
    alignItems: "start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "&>div": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 10,
      border: "1px solid #44444480",
      padding: 10,
      margin: "0 25px 25px 0",
      height: 100,
      width: 240,
      "&>img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    certifiedPartnersContainer: {
      padding: "50px 0 25px",
    },
    certifiedPartnersHeadings: {
      marginBottom: 30,
    },
    certifiedPartnersListDiv: {
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    certifiedPartnersTypes: {
      marginBottom: 20,
      maxWidth: "unset",
      "&>*": {
        textAlign: "center",
      },
    },
    certifiedPartnersLogos: {
      maxWidth: "unset",
    },
  },
  [theme.breakpoints.down("xs")]: {
    certifiedPartnersContainer: {
      padding: "40px 0 20px",
    },
    certifiedPartnersHeadings: {
      marginBottom: 20,
    },
    certifiedPartnersLogos: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "&>div": {
        margin: "0 0 25px 0",
      },
    },
  },
  [theme.breakpoints.down(400)]: {
    certifiedPartnersContainer: {
      padding: "30px 0 15px",
    },
  },
}));

export default function CertifiedPartners() {
  const classes = useStyle();
  return (
    <Grid container className={classes.certifiedPartnersContainer}>
      <div className="bodyMaxWidth">
        <Grid item className={classes.certifiedPartnersHeadings}>
          <Typography variant="h2" bold>
            <span>HealthLOQ</span> Certified Partners
          </Typography>
          <Typography variant="body1" light>
            Transparent Companies you can Trust
          </Typography>
        </Grid>
        <Grid item className={classes.certifiedPartnersListDiv}>
          <Grid container>
            <Grid item className={classes.certifiedPartnersTypes}>
              <Typography variant="h3" bold>
                Silver Tiers
              </Typography>
              <Typography variant="body2" light>
                Evidence provided directly to the blockchain by an outside,
                third party
              </Typography>
            </Grid>
            <Grid item className={classes.certifiedPartnersLogos}>
              <div>
                <img src={featuredCompanies03} alt="" />
              </div>
              <div>
                <img src={featuredCompanies04} alt="" />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.certifiedPartnersTypes}>
              <Typography variant="h3" bold>
                Bronze Tiers
              </Typography>
              <Typography variant="body2" light>
                Evidence provided to the blockchain of quality and/or
                sustainability for every batch
              </Typography>
            </Grid>
            <Grid item className={classes.certifiedPartnersLogos}>
              <div>
                <img src={featuredCompanies05} alt="" />
              </div>
              <div>
                <img src={featuredCompanies06} alt="" />
              </div>
              <div>
                <img src={featuredCompanies07} alt="" />
              </div>
              <div>
                <img src={featuredCompanies08} alt="" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
