import clsx from "clsx";
import React, { useState } from "react";
import { Grid, Drawer, makeStyles, Box, Tooltip } from "../../";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MapboxGL from "../../Common/MapboxGL";
import { connect } from "react-redux";
import { ReactComponent as ReplayIcon } from "../../../constants/svg/icon-replay.svg";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      height: "100%",
    },
  },
  originHeader: {
    height: 69,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    "&>svg": {
      color: theme.palette.common.white,
      width: 30,
      height: 30,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
  mapRoot: {
    height: "calc(100% - 69px)",
  },
  replyIcon: {
    cursor: "pointer",
    padding: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>svg": {
      width: 25,
      height: 25,
      fill: theme.palette.common.white,
    },
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

export function Origin({ open = false, onClose = () => {}, mapData }) {
  const classes = useStyle();
  const [replayAnimationToggler, setReplayAnimationToggler] = useState(false);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      className={classes.drawerRoot}
    >
      <Box className={classes.originHeader}>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <KeyboardArrowDownIcon />
        </Grid>
        <Tooltip title="Replay">
          <Box
            onClick={() => setReplayAnimationToggler(true)}
            className={classes.replyIcon}
          >
            <ReplayIcon />
          </Box>
        </Tooltip>
      </Box>
      <Box className={classes.mapRoot}>
        <MapboxGL
          startReplyAnimation={replayAnimationToggler}
          stopReplyAnimation={() => setReplayAnimationToggler(false)}
        />
      </Box>
    </Drawer>
  );
}

const mapStateToProps = ({ productReducers: { mapData } }) => ({ mapData });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Origin);
