import React from "react";
import "./style.scss";
import { Grid, Tabs, Tab } from "@material-ui/core";

export default function SmFooterTabs({
  activeSmFooterTab,
  setActiveSmFooterTab,
}) {
  return (
    <Grid className="sm-footer-tabs-root">
      <Tabs
        value={activeSmFooterTab}
        onChange={(e, i) => setActiveSmFooterTab(i)}
      >
        <Tab label="Info" />
        <Tab label="Journey" />
        <Tab label="Map" />
      </Tabs>
    </Grid>
  );
}
