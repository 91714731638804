import React, { useEffect, useState } from "react";
import { TextField, Box, makeStyles } from "@material-ui/core";
import useDebounce from "../../../hooks/useDebounce";
import { connect } from "react-redux";
import { getPostsList } from "../../../redux/actions/post";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  postFilterRoot: {
    width: "100%",
    margin: "10px 0",
  },
  postFilterTextfield: {
    width: "100%",
    "&>div": {
      border: "1px solid #E5E5E5",
      "&>input": {
        padding: "8px 10px",
      },
    },
  },
}));

export function PostFilter({ getPostsList, postsList, setPostFilter }) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const [isFilter, setIsFilter] = useState(false);
  const [description, setDescription] = useState("");
  const descDebounceValue = useDebounce(description);
  const handleEnter = () => {
    getPostsList({ offset: 0, description });
  };
  useEffect(() => {
    if (isFilter) {
      getPostsList({ offset: 0, description: descDebounceValue });
      setIsFilter(false);
    }
  }, [descDebounceValue]);

  useEffect(() => {
    if (params?.hashtag) {
      getPostsList({ offset: 0, description: `#${params?.hashtag}` });
      setDescription(`#${params?.hashtag}`);
      history.replace({
        search: "",
      });
    }
  }, [params.hashtag]);
  return (
    <Box className={classes.postFilterRoot}>
      <TextField
        placeholder="Search..."
        variant="standard"
        InputProps={{ disableUnderline: true }}
        className={classes.postFilterTextfield}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
          setIsFilter(true);
          setPostFilter((pre) => ({ ...pre, description: e.target.value }));
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) handleEnter();
        }}
      />
    </Box>
  );
}

const mapStateToProps = ({ PostReducer: { postsList } }) => ({ postsList });

const mapDispatchToProps = {
  getPostsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostFilter);
