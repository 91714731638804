/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"

// import * as folderIcon from '../../styles/test.svg'

export const FolderIcon = () => (
  <svg 
  stroke="currentColor" 
  fill="none" 
  strokeWidth="2" 
  viewBox="0 0 24 24" 
  strokeLinecap="round" 
  strokeLinejoin="round" 
  height="1em" 
  width="1em" 
  xmlns="http://www.w3.org/2000/svg" 
  >
    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z">
    </path>
  </svg>
);

const AttachmentsIntegrantTypeList = ({ list, index, itemId }) => {
  const urlParams = useParams()

  /*
  const {
 title, attachments, id, updated_on, url
} = list
  
  // console.log(subItem);
  return (
      <div key={id} className={`doc-list ${!index && "active"}`}>
          <div className="doc-title">
              <b>{moment(updated_on).format("l")}</b> - {url ? <a href={url} target="_blank" rel="noreferrer" className="link-doc-title">{title}</a> : title}
          </div>
          {attachments.map((subItem) => {
            console.log(subItem);
        if (subItem.id) {
          // const {
          //   integrantsType: { image_url }
          // } = subItem
          const { short_code, external_id } = urlParams
          
          return (
              <div>
                  <Link to={`/p/${short_code}/b/${external_id}/${subItem.id}`} className="d-flex link-sub-attachments ml0 align-items-center">
                      <div className="icon-attachments">{subItem.integrantType ? <img src={subItem.integrantType} alt="" /> : subItem.integrantsTitle && subItem.integrantsTitle.charAt(0)}</div>
                      {subItem.integrantsTitle}
                  </Link>
              </div>
          )
        }
        return null
        // return (
        //     <a href={subItem.url} target="_blank" download rel="noreferrer">
        //         <div className="icon-folder">
        //             <i className="material-icons md-18">folder</i>
        //         </div>
        //         {`${subItem.title} ${subIndex + 1}`}
        //     </a>
        // )
      })}
      </div>
  )
  */
  const pdfUrl = '/p/' + urlParams.short_code + '/b/' + urlParams.external_id + '/d/' + itemId + '/' + list.id


 if (list.type === "Quality" || list.type === "Sustainability" || list.type === "Diversity") {
  return (
    <div key={list.id} className={`doc-list ${!index && "active"}`}>
      <div className="doc-title">
          <b>{moment(list.date).format("l")} - {list.type}</b> - {list.url ? 
          <Link to={pdfUrl} title="View Document">
            {list.title}
          </Link>
           : list.title}
      </div>
    </div>
  )
 } else if(list.type === "Transfer"){
  
   return (
    <div key={list.id} className={`doc-list ${!index && "active"}`}>
      <div className="doc-title">
          <b>{moment(list.date).format("l")} - {list.type}</b> - {list.url ? <Link to={pdfUrl} title="View Document" className="link-doc-title">{list.title}</Link> : list.title}
      </div>
      
      <div className="transfer-cont">
        <div className="column">
          <b>From Organization: - </b> {list.fromOrganization.name}<br/>
          {list.fromLocation && <span><div className="location-icon"></div> {list.fromLocation.line_1+","+list.fromLocation.line_2}<br />
          {list.fromLocation.city+","+list.fromLocation.zip+","+list.fromLocation.state+","+list.fromLocation.country}</span> }
        </div>
        <div className="column">
          <b>To Organization: - </b> {list.toOrganization.name}<br/>
          {list.toLocation && <span><div className="location-icon"></div> {list.toLocation.line_1+","+list.toLocation.line_2}<br />
          {list.toLocation.city+","+list.toLocation.zip+","+list.toLocation.state+","+list.toLocation.country}</span> }
          </div>
      </div>
            
      {
        list.exhibits.map((el,idx) => {
          return <div className="list-exhibit" key={idx}>
            <FolderIcon className="icon-folder"/>
            <a href={el.url} target="_blank" rel="noreferrer" className="link-doc-title">{el.title}</a>
          </div>
        })
      }
      
    </div>
   )
 } else if(list.type === "Transit"){
  
  return (
   <div key={list.id} className={`doc-list ${!index && "active"}`}>
     <div className="doc-title">
         <b>{moment(list.date).format("l")} - {list.type}</b> - {list.url ? <Link to={pdfUrl} title="View Document" className="link-doc-title">{list.title}</Link> : list.title}
     </div>
     <div className="transfer-cont">
        <div className="column">
          {list.fromLocation && <span><div className="location-icon"></div> {list.fromLocation.line_1+","+list.fromLocation.line_2}<br />
          {list.fromLocation.city+","+list.fromLocation.zip+","+list.fromLocation.state+","+list.fromLocation.country}</span> }
        </div>
        <div className="column">
          {list.toLocation && <span><div className="location-icon"></div> {list.toLocation.line_1+","+list.toLocation.line_2}<br />
          {list.toLocation.city+","+list.toLocation.zip+","+list.toLocation.state+","+list.toLocation.country}</span> }
          </div>
      </div>
     {
       list.exhibits.map((el,idx) => {
         return <div className="list-exhibit" key={idx}>
           <FolderIcon className="icon-folder"/>
           <a href={el.url} target="_blank" rel="noreferrer" className="link-doc-title">{el.title}</a>
         </div>
       })
     }
     
   </div>
  )
}
}
AttachmentsIntegrantTypeList.propTypes = {
  list: PropTypes.object,
  index: PropTypes.number
}
AttachmentsIntegrantTypeList.defaultProps = {
  list: [],
  index: 0
}

export default AttachmentsIntegrantTypeList
