import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import LabSection from "../../components/Labs/LabSection";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  images: { labsHeaders },
} = appImages;

export default function Labs() {
  return (
    <PageWrapper pageName="labs and certifying">
      <HeaderSection
        headerImgSrc={labsHeaders}
        headerText="Protect your work product and attestations"
        headerTextPosition="right"
        headerImgPosition="left"
      />
      <LabSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
