import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Link,
  CircularProgress,
  Box,
  Autocomplete,
} from "../";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {
  dispatchIntegrantsList,
  dispatchIntegrantsListClean,
} from "../../redux/actions";
import "../Header/style.scss";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.common.white,
    "&>div": {
      display: "flex",
      flexDirection: "column",
      marginTop: 100,
    },
  },
  aboutTrustDiv: {
    marginBottom: 20,
    "&>hr": {
      backgroundColor: "#28A74540",
      width: 250,
      height: "2px !important",
    },
    "&>h4": {
      margin: "0px 10px",
      textAlign: "center",
    },
  },
  healthloqTagLine: {
    marginBottom: 50,
    "&>h1": {
      textAlign: "center",
      fontSize: 70,
      lineHeight: "80px",
      "&>span": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 60,
        lineHeight: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 48,
        lineHeight: "60px",
      },
    },
  },
  searchDiv: {
    marginBottom: 40,
    margin: "0 auto",
    width: "100%",
    "&>div": {
      width: "100%",
      maxWidth: 770,
    },
  },
  searchTextfield: {
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#EEEEEE",
    "&>div": {
      width: "100%",
      height: 80,
      padding: "0 50px",
      "&>input": {
        fontSize: 22,
        lineHeight: "29px",
        fontWeight: theme.typography.fontWeightLight,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        "&::placeholder": {
          color: "#44444480",
        },
      },
      "&>div": {
        "&>svg": {
          color: "#28A74580",
          width: 30,
          height: 30,
          cursor: "pointer",
        },
      },
    },
  },
  searchResultDiv: {
    position: "absolute",
    paddingTop: 5,
    maxHeight: 300,
    zIndex: 3,
    width: "100%",
    "&>div": {
      width: "100%",
      overflowY: "auto",
      height: "100%",
      borderRadius: 10,
      backgroundColor: theme.palette.common.white,
      boxShadow: "#32325d1a 0px 7px 14px 0px, #00000012 0px 3px 6px 0px",
    },
  },
  searchResultLoading: {
    "&>div": {
      marginLeft: 10,
    },
  },
  searchResultZeroStateMsg: {
    "&>a": {
      color: theme.palette.primary.main,
    },
  },
  orgInfoDiv: {
    width: "100%",
    cursor: "pointer",
    padding: "7px 10px",
    "&>a": {
      width: "100%",
      "&>h6": {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  productInfoDiv: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "7px 10px",
    paddingLeft: 30,
    "&>img": {
      width: 40,
      height: 40,
      borderRadius: "50%",
      marginRight: 10,
    },
    "&>a": {
      width: "100%",
      fontSize: 16,
      lineHeight: "20px",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  searchResultNotFirstLi: {
    borderTop: "1px solid #eeeeee",
  },
  descriptionDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 220,
    padding: "0 30px",
    width: "100%",
    "&>p": {
      width: "100%",
      textAlign: "center",
      "&>a": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
      "&:not(:last-child)": {
        marginBottom: 20,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 125,
      },
    },
    healthloqTagLine: {
      marginBottom: 30,
    },
    searchDiv: {
      marginBottom: 30,
    },
    descriptionDiv: {
      padding: 0,
      marginBottom: 180,
    },
    searchContainer: {
      "&>div": {
        marginTop: 80,
      },
    },
    searchTextfield: {
      "&>div": {
        height: 60,
        padding: "0 30px",
        "&>input": {
          fontSize: 18,
          lineHeight: "23px",
        },
        "&>div": {
          "&>svg": {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  [theme.breakpoints.down("xs")]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 65,
      },
    },
    searchContainer: {
      "&>div": {
        marginTop: 60,
      },
    },
    descriptionDiv: {
      marginBottom: 140,
    },
    searchTextfield: {
      "&>div": {
        height: 50,
        padding: "0 20px",
        "&>input": {
          fontSize: 16,
          lineHeight: "20px",
        },
      },
    },
  },
  [theme.breakpoints.down(400)]: {
    aboutTrustDiv: {
      "&>hr": {
        width: 30,
      },
    },
    searchContainer: {
      "&>div": {
        marginTop: 40,
      },
    },
    descriptionDiv: {
      marginBottom: 100,
    },
  },
}));

export const SearchSection = (props) => {
  const classes = useStyle();
  const {
    integrantsList,
    integrantsListIsLoading,
    dispatchIntegrantsList,
    dispatchIntegrantsListClean,
  } = props;
  const [isListActive, setListActive] = useState(false);

  const productSearch = (value) => {
    if (value && value.length > 2) {
      setListActive(true);
      dispatchIntegrantsList(value);
    }
    if (!value) {
      setListActive(false);
    }
    dispatchIntegrantsListClean();
  };
  const productOnBlur = () => {
    if (!isListActive) {
      dispatchIntegrantsListClean();
    }
  };

  useEffect(() => {
    dispatchIntegrantsListClean();
  }, [dispatchIntegrantsListClean]);
  return (
    <Grid container className={classes.searchContainer}>
      <div className="bodyMaxWidth">
        <Grid item className={clsx(classes.healthloqTagLine, "center")}>
          <Typography variant="h1" bold>
            What's inside the product <span>MATTERS</span>
          </Typography>
        </Grid>
        <Grid item className={clsx(classes.searchDiv, "center")}>
          <Autocomplete
            open={isListActive}
            onClose={() => setListActive(false)}
            options={integrantsList}
            onBlur={productOnBlur}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <Grid container flexDirection="column">
                <Grid key={option.id} className={clsx(classes.orgInfoDiv)}>
                  <Link
                    to={`/products/${option.id}`}
                    className={"ellipsis"}
                    isLink={false}
                  >
                    <Typography variant="h6" bold>
                      {option.name}
                    </Typography>
                  </Link>
                </Grid>
                {option?.integrants?.map((el, key) => {
                  return (
                    <Grid
                      key={el.id}
                      className={clsx(
                        classes.productInfoDiv,
                        key && classes.searchResultNotFirstLi
                      )}
                    >
                      <img
                        src={el?.integrantsType?.thumbnail_url}
                        alt="Product Img"
                      />
                      <Link
                        isLink={false}
                        to={`/p/${option?.short_code}/b/${el?.external_id_slug}`}
                        className="ellipsis notranslate"
                      >
                        {`${el?.title} (${el?.external_id})`}
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            filterOptions={(option) => {
              return option;
            }}
            onInputChange={(event, newValue) => {
              productSearch(newValue);
            }}
            noOptionsText={
              <Typography
                variant="body2"
                light
                className={classes.searchResultZeroStateMsg}
              >
                Not seeing what you are searching for?&nbsp;
                <Link to={"/invite"}>Click here</Link> to invite an organization
              </Typography>
            }
            loading={integrantsListIsLoading}
            loadingText={
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                className={classes.searchResultLoading}
              >
                <Typography variant="body2" light>
                  Please Wait, Loading...
                </Typography>
                <CircularProgress />
              </Grid>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search by company, product, or batch number"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlinedIcon fontSize="large" />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                className={clsx(classes.searchTextfield, params?.className)}
              />
            )}
          />
        </Grid>
        <Grid item className={clsx(classes.descriptionDiv, "center")}>
          <Typography variant="body1" light>
            56% of herbal and dietary supplements are&nbsp;
            <a
              href="https://www.healio.com/news/hepatology/20171022/most-dietary-supplements-mislabeled-may-cause-liver-injury"
              target={"_blank"}
              rel="noreferrer"
            >
              mislabeled
            </a>
          </Typography>
          <Typography variant="body1" light>
            {/* Each year 56 million people die of foodborne illnesses and 624
            million&nbsp;
            <a
              href={"http://pubmed.ncbi.nlm.nih.gov"}
              target="_blank"
              rel="noreferrer"
            >
              people get sick
            </a> */}
            An estimated 600 million - almost 1 in 10 people in the world - fall
            ill after eating contaminated food and 420,000 die every year,&nbsp;
            <a
              href={
                "https://www.who.int/news-room/fact-sheets/detail/food-safety"
              }
              target="_blank"
              rel="noreferrer"
            >
              resulting in the loss of 33 million healthy life years (DALYs).
            </a>
          </Typography>
          <Typography variant="body1" light>
            Can you trust what you are putting in your body is what they say it
            is? We need&nbsp;
            <u>
              <i>verifiable</i>
            </u>
            &nbsp;information.
          </Typography>
          <Typography variant="body1" light>
            HealthLOQ is your accountability partner that connects you to the
            legit companies who provide evidence to support what they say.
          </Typography>
          <Typography variant="body1" light>
            <Link to={"/p/better_brand/b/BLN-001"} variant="body1">
              Click here
            </Link>
            &nbsp; to view an example product.
          </Typography>
        </Grid>
      </div>
    </Grid>
  );
};

const mapStateToProps = ({
  productReducers: { integrantsList, integrantsListIsLoading },
}) => ({
  integrantsList,
  integrantsListIsLoading,
});

const mapDispatchToProps = {
  dispatchIntegrantsList,
  dispatchIntegrantsListClean,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
