import React from "react";
import SEO from "../SEO";
import { makeStyles } from "@material-ui/core";
import Footer from "../Footer";
import { AppConstant } from "../../constants";
import Header from "../Header";

const useStyle = makeStyles((theme) => ({
  bodyRoot: {
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    "&>div:last-child": {
      width: "100%",
      height: "auto",
      minHeight: "calc(100% - 100px)",
      marginTop: 100,
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function PageWrapper({
  hideFooter = false,
  children,
  pageName = "",
}) {
  const classes = useStyle();
  return (
    <>
      <SEO
        title={AppConstant[pageName]?.pageTitle}
        description={AppConstant[pageName]?.pageDes}
      />
      <div className={classes.bodyRoot}>
        <Header />
        <div>
          {children}
          {!hideFooter && <Footer />}
        </div>
      </div>
    </>
  );
}
