import clsx from "clsx";
import React from "react";
import { Grid, Typography, makeStyles } from "../";
import { Box } from "@material-ui/core";
import PrimaryVideoPlayer from "../Common/VideoPlayers/PrimaryVideoPlayer";

const useStyle = makeStyles((theme) => ({
  productExtraInfo: {
    margin: "50px auto 20px !important",
    "&>div": {
      marginBottom: 20,
      "&>*": {
        textAlign: "left",
      },
    },
  },
  facetRoot: {},
  facetsUl: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ccc",
    borderRadius: 10,
    textTransform: "uppercase",
    letterSpacing: "1px",
    "&>div": {
      display: "flex",
      // alignItems: "center",
      flexDirection: "column",
      padding: "10px 15px",
      flexWrap: "wrap",
      "&:nth-child(even)": {
        backgroundColor: "#f9f9f9",
      },
      "&>h6": {
        marginRight: 10,
      },
      "&>p": {
        "& p": {
          margin: 0,
        },
      },
      "&:last-child": {
        borderRadius: `0 0 10px 10px`,
      },
    },
  },
  videoPlayer: {
    width: "100%",
    maxWidth: 1070,
    margin: "0px auto 20px",
  },
}));

export default function ProductExtraDetails({
  integrantsDetail: { integrantInfo },
}) {
  let facets = JSON.parse(integrantInfo?.facets);
  facets = facets
    ?.map((item) => ({ ...item, group_id: item?.group_id || "0" }))
    ?.sort((a, b) => +a?.group_id - +b?.group_id);
  let lastIndex = null;
  const classes = useStyle();
  return (
    <Grid container>
      <div className={clsx("bodyMaxWidth", classes.productExtraInfo)}>
        {Boolean(integrantInfo?.integrantsType?.youtube_video_url) ? (
          <Box
            className={classes.videoPlayer}
            display={"flex"}
            justifyContent={"center"}
          >
            <PrimaryVideoPlayer
              url={integrantInfo?.integrantsType?.youtube_video_url}
              onError={(e) => console.log(e)}
            />
          </Box>
        ) : (
          Boolean(integrantInfo?.integrantsType?.bunny_video_url) &&
          integrantInfo?.integrantsType?.is_uploaded_bunny_video && (
            <Box
              className={classes.videoPlayer}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <PrimaryVideoPlayer
                url={integrantInfo?.integrantsType?.bunny_video_url}
                onError={(e) => console.log(e)}
                light={integrantInfo?.integrantsType?.bunny_video_thumbnail_url}
              />
              <Typography variant="body2" bold style={{ marginTop: 10 }}>
                {integrantInfo?.integrantsType?.bunny_video_title}
              </Typography>
            </Box>
          )
        )}
        <Box>
          <Typography variant="h3" bold>
            Description
          </Typography>
          <Typography variant="body2" light>
            {integrantInfo?.description}
          </Typography>
        </Box>
        {integrantInfo?.integrantsType?.directions && (
          <Box>
            <Typography variant="h3" bold>
              Directions
            </Typography>
            <Typography variant="body2" light>
              {integrantInfo?.integrantsType?.directions}
            </Typography>
          </Box>
        )}
        {integrantInfo?.integrantsType?.warnings && (
          <Box>
            <Typography variant="h3" bold>
              Warning
            </Typography>
            <Typography variant="body2" light>
              {integrantInfo?.integrantsType?.warnings}
            </Typography>
          </Box>
        )}
        {facets?.length > 0 && (
          <Box className={classes.facetRoot}>
            {facets?.map((item, index, arr) => {
              if (lastIndex >= arr.length || index < lastIndex) {
                return <></>;
              }
              let nextIndex = index;
              for (let a in facets) {
                a = parseInt(a);
                if (a >= nextIndex) {
                  let nextI = facets?.[a]?.group_id
                    ? facets?.[a]?.group_id
                    : "";
                  let curI = item?.group_id ? item?.group_id : "";
                  if (nextI === curI) {
                    nextIndex = a + 1;
                  } else {
                    break;
                  }
                }
              }
              if (nextIndex === index) {
                nextIndex++;
              }
              let groupArr = facets?.slice(index, nextIndex);
              lastIndex += groupArr?.length;
              return (
                <>
                  <Grid
                    container
                    className={classes.facetsUl}
                    key={"trait" + index}
                  >
                    {groupArr?.map((item, i) => {
                      return (
                        <Grid key={i}>
                          <Typography variant="h6" bold>
                            {item?.title}:
                          </Typography>
                          <Typography
                            variant="body2"
                            light
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              );
            })}
          </Box>
        )}
      </div>
    </Grid>
  );
}
