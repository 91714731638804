import React from "react";
import { Grid } from "@material-ui/core";
import { Paragraph } from "./";
import CheckIcon from "@material-ui/icons/Check";

export default function CategoryBox({
  item,
  handleChange,
  isSelected = false,
}) {
  return (
    <Grid className="category-box center" onClick={handleChange}>
      <img src={item?.category_image} alt={item?.title} />
      <Paragraph>{item?.title}</Paragraph>
      {isSelected && (
        <Grid className="selected-cat-icon center">
          <CheckIcon />
        </Grid>
      )}
      {isSelected && (
        <Grid className="selected-cat-icon center">
          <CheckIcon />
        </Grid>
      )}
    </Grid>
  );
}
