import React from "react";
import { connect } from "react-redux";
import { makeStyles, Box, Typography, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import PersonIcon from "@material-ui/icons/Person";
import { FollowUnfollowUser, UserAvatar } from "../../Common";
import { Link } from "../../";

const useStyle = makeStyles((theme) => ({
  userSuggestionBoxContainer: {
    padding: 10,
    border: `1px solid ${theme.palette.primary.main}`,
    width: "160px",
    minHeight: "132px",
    borderRadius: 10,
  },
  userAvatar: {
    marginBottom: 10,
  },
  userSuggestionBoxText: {
    width: "100%",
  },
  userName: {
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    textTransform: "capitalize",
    width: "100%",
    maxWidth: "max-content",
    textAlign: "center",
    lineBreak: "anywhere",
  },
  userNameRow: {
    width: "100%",
    "&>a": {
      "&>svg": {
        width: 15,
        height: 15,
        color: theme.palette.primary.main,
      },
    },
    "&>*:not(:last-child)": {
      marginRight: 5,
    },
  },
  userConnnectionCount: {
    textAlign: "center",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    color: "#999",
    width: "100%",
  },
  userDetailLink: {
    cursor: "pointer",
    "&>svg": {
      width: "0.9em",
      height: "0.9em",
      color: theme.palette.primary.main,
    },
    "&>p": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const UserSuggestionBox = (props) => {
  const { user } = props;
  const classes = useStyle();
  return (
    <Box
      className={classes.userSuggestionBoxContainer}
      display="flex"
      flexDirection="column"
      alignItems={"center"}
    >
      <Link
        to={`/user-detail/${user?.id}`}
        className={classes.userDetailLink}
        target="_blank"
      >
        <UserAvatar
          gender={user?.gender}
          src={user?.profile_picture}
          size={50}
          useCustomStyle={true}
          className={classes.userAvatar}
        />
      </Link>
      <Box
        className={classes.userSuggestionBoxText}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.userNameRow}
        >
          <Link
            to={`/user-detail/${user?.id}`}
            className={clsx(classes.userDetailLink, "ellipsis")}
            target="_blank"
          >
            <Tooltip title={user?.name} arrow>
              <Typography
                variant="body1"
                className={clsx(classes.userName, "ellipsis")}
              >
                {user?.name}
              </Typography>
            </Tooltip>
          </Link>
          {(user?.practitioner === true || user?.practitioner === "Yes") && (
            <Link
              to={`/user-detail/${user?.id}`}
              className={classes.userDetailLink}
              target="_blank"
            >
              <Tooltip title={"Practitioner"} arrow>
                <PersonIcon />
              </Tooltip>
            </Link>
          )}
          <FollowUnfollowUser user={user} />
        </Box>
        <Typography
          variant="body2"
          className={clsx(classes.userConnnectionCount, "ellipsis")}
        >
          {user?.user_follow_count}&nbsp;
          {user?.user_follow_count > 1 ? "Connections" : "Connection"}
        </Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserSuggestionBox);
