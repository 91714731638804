import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import CalendlyPage from "../Common/CalendlyPage";

const {
  images: { docAuthenticatorDashboard },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    "&>img": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      maxWidth: "100%",
      width: "100%",
    },
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function BulkDocumentProtection() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>Bulk Document Protection</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            The system works seamlessly with current document management systems
            and repositories and simply takes a digital fingerprint of the
            document. No movement of the document or change to partner agreement
            or security parameters.
          </Typography>
          <Box className={classes.sectionImage}>
            <img src={docAuthenticatorDashboard} alt="Image" />
          </Box>
          <Box className={classes.btns}>
            <CalendlyPage>
              <Button>Request a Demo</Button>
            </CalendlyPage>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
