import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./style.scss";
import { dispatchBlogNewsList } from "../../redux/actions/blogNews";
import { connect } from "react-redux";
import moment from "moment";
import { Body } from "../../components/Common";
import utils from "../../utils/commonUtils";

const category = ["all"];

const BlogNews = ({ dispatchBlogNewsList, blogNewsList }) => {
  const params = useParams();
  useEffect(() => {}, [blogNewsList]);
  useEffect(() => {
    dispatchBlogNewsList();
  }, []);
  return (
    <Body
      seoTitle="Newsroom - HealthLOQ"
      seoDescription="Stay up to date with the most recent insights, trends, articles and news from HealthLOQ chapters around the world"
    >
      <div className="page-blog-news">
        <div className="header-blog-news">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-12">
                <p className="hl-title">What's New in HealthLOQ</p>
                <p className="hl-des">
                  Stay up to date with the most recent insights, trends,
                  articles and news from HealthLOQ Chapters around the world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-blog-news">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-12">
                <ul className="menu-category">
                  <li>Filter by category</li>
                  {category.map((link, i) => {
                    return (
                      <li>
                        <Link
                          to={"/blog-news/" + link}
                          className={
                            "text-capitalize " +
                            (params.type === link ? "active" : "")
                          }
                        >
                          {link}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {blogNewsList?.map((item, index) => {
                  const { type } = item?.attributes;
                  if (!category.includes(type)) {
                    category.push(type);
                  }
                  if (params.type !== "all") {
                    if (type === params.type) {
                      return (
                        <ItemBlogNews
                          {...item?.attributes}
                          id={item?.id}
                          slug={item?.slug}
                          key={index}
                        />
                      );
                    }
                    return null;
                  } else {
                    return (
                      <ItemBlogNews
                        {...item?.attributes}
                        id={item?.id}
                        slug={item?.slug}
                        key={index}
                      />
                    );
                  }
                })}
                {blogNewsList?.length === 0 && (
                  <p style={{ margin: "20px 0px" }}>No Data Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};

const mapStateToProps = ({ blogNewsReducers: { blogNewsList } }) => ({
  blogNewsList,
});

const mapDispatchToProps = {
  dispatchBlogNewsList: dispatchBlogNewsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogNews);

const ItemBlogNews = ({
  image,
  type,
  readMoreUrl,
  createdby,
  source,
  publishedAt,
  tag,
  subTitle,
  title,
  slug,
}) => {
  let link;
  let btn;
  const btnClass = "btn btn-success";
  if (readMoreUrl) {
    link = (
      <a href={readMoreUrl} target="_blank">
        {title}
      </a>
    );
    btn = (
      <a href={readMoreUrl} target="_blank" className={btnClass}>
        Read More
      </a>
    );
  } else {
    link = <Link to={"/blog-news/d/" + slug}>{title}</Link>;
    btn = (
      <Link to={"/blog-news/d/" + slug} className={btnClass}>
        Read More
      </Link>
    );
  }
  return (
    <div className="list-news">
      <div className="row align-items-center">
        {image?.data && (
          <div className="col-md-4">
            <img
              src={`https://newsroom.healthloq.com${image?.data?.attributes?.url}`}
              alt=""
              className="img-fluid"
            />
          </div>
        )}
        <div className="col-md">
          <div>
            <p className="ibn-title">{link}</p>
            <ul className="ibn-list">
              <li>{moment(publishedAt).format("MM/DD/YYYY")}</li>
              <li>
                By <strong>{createdby}</strong>
              </li>
              {source && (
                <li>
                  Source{" "}
                  <strong>
                    {utils.isUrl(source) ? (
                      <a
                        target={"_blank"}
                        href={utils.getValidUrl(source)}
                        rel="noreferrer"
                      >
                        {source}
                      </a>
                    ) : (
                      source
                    )}
                  </strong>
                </li>
              )}
            </ul>
          </div>
          {subTitle && <p className="ibn-subtitle">{subTitle}</p>}
          {btn}
          <ul className="ibn-footer">
            <li className="text-capitalize">{type}</li>
            {tag?.tags?.length !== 0 &&
              tag?.tags?.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};
