import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  primaryHeadingRoot: {
    lineHeight: "140%",
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: "0.2px",
    textTransform: "uppercase",
    fontStyle: "normal",
    display: "flex",
    textAlign: "center",
  },
}));

export default function PrimaryHeading(props) {
  const classes = useStyle();
  return (
    <Typography
      variant="h1"
      className={classes.primaryHeadingRoot}
      {...props}
    />
  );
}
