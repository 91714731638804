import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import {
  ConnectToConsumerSection,
  ConsumerProfile,
} from "../../components/ConsumerConnection";
import ConsumerConnections from "../../components/ConsumerConnection/ConsumerConnections";
import ConsumerFeedback from "../../components/ConsumerConnection/ConsumerFeedback";
import HealthLoqSection from "../../components/ConsumerConnection/HealthloqSection";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { consumerConnections },
} = appImages;

export default function ConsumerConnection() {
  return (
    <PageWrapper pageName="consumer connection">
      <HeaderSection
        headerImgSrc={consumerConnections}
        headerText="Connect to your customers with permissioned, rich data consent and promote loyalty and advocacy"
      />
      <ConnectToConsumerSection />
      <ConsumerProfile />
      <ConsumerFeedback />
      <ConsumerConnections />
      <HealthLoqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
