import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Button, MuiTextfield } from "../../components/Profile";
import { forgotPassword } from "../../redux/actions/profileActions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

export function ForgotPassword(props) {
  const { match, forgotPassCodeData, forgotPassword, history } = props;
  const emailRegex = new RegExp(
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,6})+$/
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = () => {
    if (!email) {
      setError(true);
    } else if (!emailRegex.test(email)) {
      setError(true);
      NotificationManager.error("Please enter valid email.", "", 5000);
    } else {
      forgotPassword({ email });
    }
  };
  useEffect(() => {
    if (forgotPassCodeData.status === "1") {
      history.push("/profile-login");
    }
  }, [forgotPassCodeData]);
  return (
    <Grid className="ac-root">
      <Grid className="ac-content">
        <Grid className="ac-form-div">
          <MuiTextfield
            type="email"
            placeholder={"Email"}
            match={match}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
            required
            error={error}
          />
        </Grid>
      </Grid>
      <Grid className="ac-footer">
        <Button
          onClick={handleSubmit}
          loading={forgotPassCodeData?.loading}
          profileBgBtn
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({ ProfileReducers: { forgotPassCodeData } }) => ({
  forgotPassCodeData,
});

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
