/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "react-router-dom"

const Callouts = (props) => {
  const {
    item: { url }
  } = props
  if (url) {
    return (
        <Link to={url} className="link-integration">
            <Item {...props} />
        </Link>
    )
  }
  return <Item {...props} />
}

const Item = ({ type, item }) => {
  const { title, imgUrl, description } = item
  return (
      <div className={`item-${type}`} key={title}>
          {imgUrl && <div className={`img-${type}`} ><img src={imgUrl} alt={title} /></div>}
          {title && <p className={`title-${type}`}>{title}</p>}
          {description && <p className={`description-${type}`}>{description}</p>}
      </div>
  )
}

export default Callouts
