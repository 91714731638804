import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { PrimaryIconFrame } from "../Common/IconFrames";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    "&>p:not(:nth-child(2))": {
      marginBottom: 15,
    },
    "&>p": {
      color: "#707070",
      "&>span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  heading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  topHeading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    "&>img": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    background: theme.palette.common.white,
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
  },
  darkWord: {
    fontWeight: "700",
    color: "#000",
  },
}));

const {
  images: { bulkDocVerification },
} = appImages;

export default function HowItWorkSection1() {
  const classes = useStyle();
  const content = [
    <>
      <span className={classes.darkWord}>Verification</span> - A retailer
      verifies that a document they are holding from a supplier is legitimate
      and has not been tampered or falsified.
    </>,
    <>
      <span className={classes.darkWord}>Validation</span> - A certifying body
      validates an ISO or GMP certification for a 3rd party or internal lab.
    </>,
  ];
  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Box className={classes.topHeading}>
            <PrimaryHeading>Bulk Document Verification</PrimaryHeading>
          </Box>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={bulkDocVerification} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>How it works</PrimaryHeading>
            </Box>
            <Grid container spacing={3}>
              {content?.map((item, key) => {
                return (
                  <Grid item key={key}>
                    <Box className={classes.contentBox}>
                      <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                      <Typography variant="body2">{item}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
