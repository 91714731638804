import React from "react";
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  menuItem: {
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      background: `${theme.palette.primary.main}50`,
    },
  },
}));

export default function MuiMenu({
  open,
  anchorRef,
  handleClose,
  menuItems,
  placement = "bottom-end",
}) {
  const classes = useStyle();

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      handleClose();
    }
  }
  return (
    <Popper
      open={open}
      anchorEl={anchorRef}
      role={undefined}
      transition
      disablePortal
      placement={placement}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {menuItems?.map((item, key) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        item?.onClick();
                      }}
                      key={key}
                      className={classes.menuItem}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
