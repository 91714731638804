import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import CheckIcon from "@material-ui/icons/Check";

export default function OrganizationBox({ data, onClick, isSelected = false }) {
  return (
    <Tooltip
      arrow
      placement="top-center"
      title={
        !data?.hasSurvey
          ? data?.name + " organization don't contain any survey."
          : ""
      }
    >
      <Grid>
        <Grid
          className={clsx(
            "organization-box",
            "center",
            isSelected && "selected-org",
            !data?.hasSurvey && "disabled-org"
          )}
          onClick={onClick}
        >
          {data?.hasSurvey && data?.offerCount !== 0 && (
            <Grid className={"organization-box-offer"}>
              {data?.offerCount} {data?.offerCount === 1 ? "offer" : "offers"}
            </Grid>
          )}
          <img src={data?.logo_url} alt="Organization-logo" />
          <p>{data?.name}</p>
          {isSelected && (
            <Grid className="center selected-org-icon">
              <CheckIcon />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Tooltip>
  );
}
