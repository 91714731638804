import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppImages, AppConstant } from "../../constants";

const { homeVideo, homeVideoPoster } = AppImages;

const {
  homePage: { textTell, register, responsibleCompanies },
} = AppConstant;

const Banner = () => {
  const [isVideoPosterisLoaded, setIsVideoPosterisLoaded] = useState(false);
  useEffect(() => {
    const el = document.getElementById("homepage-video");
    if (el) {
      let img = new Image();
      img.src = el.getAttribute("poster");
      img.onload = () => {
        setIsVideoPosterisLoaded(true);
      };
    }
  }, []);

  return (
    <div>
      <div className="home-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg d-flex align-items-center">
              <div className="box-home-banner">
                <p className="title">
                  {textTell}{" "}
                  <span className="text-healthLoqc">
                    {responsibleCompanies}
                  </span>
                </p>
                <div className="box-btn">
                  <Link className="btn btn-success" to="/register">
                    {register}
                  </Link>
                </div>
                <p className="box-sub-text">
                  Anyone can <i>say</i> whatever they want about quality and
                  sustainability, but HealthLOQ Certified partners
                  actually&nbsp;
                  <i>do</i> something to prove their claims.&nbsp;
                  <Link to="/p/better_brand/b/BLN-001">Click here</Link> to view
                  the example product.
                </p>
              </div>
            </div>
            <div className="col-lg d-flex align-items-center">
              <div className="featured-video">
                <video
                  poster={homeVideoPoster}
                  src={homeVideo}
                  preload="none"
                  controls={isVideoPosterisLoaded}
                  id="homepage-video"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
