import clsx from "clsx";
import React from "react";
import MobileProductDetail from "../MobileProductDetail";
import ProductJourney from "../ProductJourney";
import "./style.scss";

export default function ManageProductLeftside({
  activeSmFooterTab,
  exhibitsList,
  integrantInfo,
  exhibitsInfo,
  blockchainProof,
  sourceLinks,
  productUrlDetail,
  setActiveSmFooterTab,
}) {
  return (
    <div
      className={clsx(
        "sm-product-leftside-root",
        [0, 1].includes(activeSmFooterTab) && "sm-product-leftside-show"
      )}
    >
      {activeSmFooterTab === 1 && (
        <ProductJourney exhibitsList={exhibitsList} />
      )}
      {activeSmFooterTab === 0 && (
        <MobileProductDetail
          integrantInfo={integrantInfo}
          exhibitsInfo={exhibitsInfo}
          blockchainProof={blockchainProof}
          sourceLinks={sourceLinks}
          productUrlDetail={productUrlDetail}
          setActiveSmFooterTab={setActiveSmFooterTab}
          location={"infotab"}
        />
      )}
    </div>
  );
}
