import React from "react";

export default function Label({
  htmlFor = "",
  className = "",
  children,
  required = false,
  has_error = false,
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={`labelRoot ${className} ${has_error ? "label_error" : ""}`}
    >
      {children}
      {required && <span className="requiredLabelSpan">*</span>}
    </label>
  );
}
