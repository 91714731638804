/* eslint-disable camelcase */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReactComponent as BlockchainProofIcon } from "../../constants/svg/blockchain-proof.svg";
import { ReactComponent as HomeIcon } from "../../constants/svg/icon-home.svg";
import { ReactComponent as FolderIcon } from "../../constants/svg/icon-folder.svg";
import { ReactComponent as BuyIcon } from "../../constants/svg/icon-buy.svg";
import { ReactComponent as BatchIcon } from "../../constants/svg/icon-batch.svg";
import { ReactComponent as StarReviewIcon } from "../../constants/svg/icon-star-review.svg";
import { ReactComponent as UserProfileIcon } from "../../constants/svg/icon-user-profile.svg";
import { useTheme, useMediaQuery } from "@material-ui/core";
import utils from "../../utils/commonUtils";

const getIcon = (text) => {
  switch (text) {
    case "Product":
      return <HomeIcon className="fill-icon" />;
    case "Origin":
      return <FolderIcon className="stroke-icon" />;
    case "Buy":
      return <BuyIcon className="stroke-icon" />;
    case "Other Batches":
      return <BatchIcon className="fill-icon" />;
    case "Blockchain Proof":
      return <BlockchainProofIcon className="fill-icon" />;
    case "Review":
      return <StarReviewIcon className="fill-icon" />;
    case "Profile":
      return <UserProfileIcon className="fill-icon" />;
    default:
      return "";
  }
};

const ProductRightNavigation = ({
  productUrlDetail,
  buyUrl,
  batchesList,
  blockchainProof,
  urlParams,
  mapData,
  integrantInfo,
}) => {
  const { short_code, external_id } = productUrlDetail;
  const [activeLink, setActiveLink] = useState(0);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(769));
  const {
    id,
    organization_id,
    integrant_type_id,
    title,
    integrantsType,
    organizations,
  } = integrantInfo;
  const rightNav = [
    {
      text: "Product",
      link: `/p/${short_code}/b/${external_id}`,
    },
    {
      text: "Review",
      link: `/product-feedback/${window.btoa(organization_id)}/${window.btoa(
        id
      )}`,
    },
  ];

  if (buyUrl !== "") {
    rightNav.push({ text: "Buy", link: buyUrl });
  }

  if (mapData?.data?.length) {
    rightNav.push({
      text: "Origin",
      link: `/p/${short_code}/b/${external_id}/origin`,
    });
  }

  if (batchesList && batchesList.length) {
    rightNav.push({
      text: "Other Batches",
      link: `/p/${short_code}/b/${external_id}/ob/batches`,
    });
  }

  if (
    Object.keys(blockchainProof).length !== 0 &&
    blockchainProof.result &&
    !match
  ) {
    rightNav.push({
      text: "Blockchain Proof",
      link: `/p/${short_code}/b/${external_id}/${
        typeof urlParams.document === "undefined"
          ? "integrant"
          : typeof urlParams.documentID === "undefined"
          ? urlParams.document
          : urlParams.documentID
      }/blockchain-proof`,
    });
  }
  rightNav.push({
    text: "Profile",
    link: utils.getLocalStorageItems().is_signup
      ? "/profile-login"
      : "/create-profile",
  });

  const handleClick = (i) => {
    setActiveLink(i);
    if ([1, rightNav.length - 1].includes(i)) {
      utils.addItemsIntoSessionStorage({
        organization_id,
        selectedIntegrantTypeId: integrant_type_id,
        integrant_id: id,
      });
      utils.addItemsIntoSessionStorage(
        {
          productDetails: JSON.stringify({
            title,
            image: integrantsType?.image_url,
            external_id: integrantInfo?.external_id,
            external_id_slug: integrantInfo?.external_id_slug,
            short_code: organizations?.short_code,
          }),
        },
        false
      );
    }
  };

  return (
    <div className="product-right-navigation">
      {rightNav.map((item, i) => {
        const { text, link } = item;
        if (text === "Buy" && buyUrl) {
          return (
            <a
              className="link-rightnav"
              href={link}
              target="_blank"
              rel="noreferrer"
              key={text}
              onClick={() => handleClick(i)}
            >
              <div>
                {getIcon(text)}
                <span>{text}</span>
              </div>
            </a>
          );
        }
        return (
          <Link
            className={"link-rightnav " + (activeLink === i ? "active" : "")}
            to={link}
            key={text}
            onClick={() => handleClick(i)}
          >
            <div>
              {getIcon(text)}
              <span>{text}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

ProductRightNavigation.propTypes = {
  productUrlDetail: PropTypes.instanceOf(Object),
  buyUrl: PropTypes.string,
  sourceLinks: PropTypes.instanceOf(Array),
};

ProductRightNavigation.defaultProps = {
  productUrlDetail: {},
  buyUrl: "",
  sourceLinks: [],
};

const mapStateToProps = ({ productReducers: { mapData } }) => ({
  mapData,
});

export default connect(mapStateToProps, null)(ProductRightNavigation);
