import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { PublicPrivateSwitch } from "../Profile";

// style
const useStyle = makeStyles((theme) => ({
  textFieldLable: {
    fontWeight: "500",
    color: "#000",
    fontSize: "18px",
    marginBottom: 8,
  },
}));
export default function HealthLoqTextField({
  className,
  value = null,
  placeholder = null,
  onChange = () => {},
  label = null,
  required = false,
  fullWidth = false,
  multiline,
  variant = "outlined",
  match,
  showLockIcon,
  lockIconState,
  switchId,
  handleLockIconClick,
  small,
  darkLabel,
  ...props
}) {
  // import style
  const classes = useStyle();
  return (
    <Grid>
      {label && (
        <InputLabel
          className={darkLabel && classes.textFieldLable}
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        {...props}
        className={clsx(classes.textField, className)}
        value={value}
        variant={variant}
        fullWidth
        multiline
        placeholder={placeholder}
        onChange={onChange}
        InputProps={{
          endAdornment: showLockIcon && (
            <InputAdornment position="end">
              <PublicPrivateSwitch
                checked={lockIconState}
                id={switchId}
                onChange={handleLockIconClick}
              />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}
