import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import { PrimaryIconFrame } from "../Common/IconFrames";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "#F7F7F7",
  },
  healthloqHeading: {
    margin: "70px 0 70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentBox: {
    height: "100%",
    maxHeight: "max-content",
    minHeight: 120,
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    background: theme.palette.common.white,
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
    "&>p": {
      color: theme.palette.text.secondary,
      "&>a": {
        textDecoration: "underline",
      },
    },
  },
  bottomActionButtons: {
    display: "flex",
    justifyContent: "center",
    margin: "65px 0 85px",
  },
}));

export default function Healthloq() {
  const classes = useStyle();
  let content = [
    <>
      56% of herbal and dietary supplements are&nbsp;
      <Link href="https://www.healio.com/news/hepatology/20171022/most-dietary-supplements-mislabeled-may-cause-liver-injury">
        mislabeled
      </Link>
    </>,
    <>
      An estimated 600 million - almost 1 in 10 people in the world - fall ill
      after eating contaminated food and 420,000 die every year,&nbsp;
      <Link href="https://www.who.int/news-room/fact-sheets/detail/food-safety">
        resulting in the loss of 33 million healthy life years (DALYs)
      </Link>
      .
    </>,
    <>
      FDA finds thousands of&nbsp;
      <Link href="https://journalofethics.ama-assn.org/article/my-patient-taking-unsafe-dietary-supplement/2022-05">
        tainted products
      </Link>
      &nbsp;marked as dietary supplements
    </>,
    <>
      Fraudulent "
      <Link href="https://www.nutraingredients-usa.com/Article/2014/05/13/Dry-labbing-is-still-out-there-and-there-is-still-a-market-for-it-say-analytical-experts">
        Dry Labs
      </Link>
      " are a problem
    </>,
    <>
      Majority of immune supplements bought at online retailers are found to
      have&nbsp;
      <Link href="https://www.nutraingredients-usa.com/Article/2022/08/11/Majority-of-immune-supplements-bought-on-Amazon-found-to-have-labels-that-don-t-match-contents">
        labels that don't match contents
      </Link>
    </>,
    <>
      Independent testing reveals&nbsp;
      <Link href="https://www.naturalproductsinsider.com/supplements/independent-testing-program-reveals-subpar-quality-of-dietary-supplements-sold-on-amazon">
        problems with labels
      </Link>
    </>,
    <>
      Cheater brands are&nbsp;
      <Link href="https://www.naturalproductsinsider.com/supplement-regulations/amazon-s-gmp-policies-not-perfect-but-praised-by-supplement-stakeholders">
        falsifying documents
      </Link>
    </>,
  ];
  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Box className={classes.healthloqHeading}>
          <PrimaryHeading>Healthloq</PrimaryHeading>
        </Box>
        <Grid container spacing={3}>
          {content?.map((item, key) => {
            return (
              <Grid item key={key} xs={12} sm={12} md={6}>
                <Box className={classes.contentBox}>
                  <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                  <Typography variant="body2">{item}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Box className={classes.bottomActionButtons}>
          <CalendlyPage>
            <Button>Request a Demo</Button>
          </CalendlyPage>
        </Box>
      </Box>
    </SectionWithTreePlan>
  );
}
