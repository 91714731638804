import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
} from "./DialogCommonComponents";
import clsx from "clsx";
import MuiCircularProgress from "../Common/MuiCircularProgress";
import {
  makeStyles,
  Box,
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import moment from "moment";
import { getPostLikedUsersList } from "../../redux/actions/post";
import { FollowUnfollowUser, UserAvatar } from "../Common";
import { Link } from "../";

const useStyle = makeStyles((theme) => ({
  ul: {},
  li: {
    padding: "10px 0",
  },
  username: {
    fontSize: "14px",
    fontWeight: "500",
    color: " #333333",
    lineHeight: 1.2,
    "&>svg": {
      width: 15,
      height: 15,
      marginLeft: 5,
      color: theme.palette.primary.main,
    },
  },
  AvatarPostTime: {
    fontSize: "12px",
    fontWeight: "500",
    color: " #999999",
    lineHeight: 1.2,
  },
  likeCount: {
    fontSize: "12px",
    fontWeight: "500",
    color: " #999999",
    lineHeight: 1.2,
    marginLeft: 5,
  },
  userDetailLink: {
    cursor: "pointer",
    "&>svg": {
      width: "0.9em",
      height: "0.9em",
      color: theme.palette.primary.main,
    },
    "&>p": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

function PostLikedUsersListDialog(props) {
  const {
    postLikedUsersList,
    className = "",
    open,
    handleClose,
    getPostLikedUsersList,
    post_id,
  } = props;
  const classes = useStyle();
  useEffect(() => {
    if (open) {
      getPostLikedUsersList({ post_id });
    }
  }, [open]);
  return (
    <MuiDialog
      className={clsx(className)}
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <MuiDialogTitle onClose={handleClose}>
        Likes
        {Boolean(postLikedUsersList?.data?.length) && (
          <span className={classes.likeCount}>
            {`(${postLikedUsersList?.data?.length} ${
              postLikedUsersList?.data?.length < 2 ? "Like" : "Likes"
            })`}
          </span>
        )}
      </MuiDialogTitle>
      <MuiDialogContent>
        {postLikedUsersList?.loading &&
        (postLikedUsersList?.data?.length === 0 ||
          (postLikedUsersList?.data?.length > 0 &&
            postLikedUsersList?.data[0]?.post_id !== post_id)) ? (
          <Box className="center">
            <MuiCircularProgress />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection="column" className={classes.ul}>
            {postLikedUsersList?.data?.map((item, key, arr) => {
              return (
                <React.Fragment key={key}>
                  <Box
                    className={classes.li}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Box
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"flex-start"}
                    >
                      <Link
                        to={`/user-detail/${item?.like_user?.id}`}
                        className={classes.userDetailLink}
                        target="_blank"
                      >
                        <UserAvatar src={item?.like_user?.profile_picture} />
                      </Link>
                      <Box display={"flex"} flexDirection="column">
                        <Link
                          to={`/user-detail/${item?.like_user?.id}`}
                          className={classes.userDetailLink}
                          target="_blank"
                        >
                          <Typography className={clsx(classes.username)}>
                            {item?.like_user?.name}
                            {(item?.like_user?.practitioner === true ||
                              item?.like_user?.practitioner === "Yes") && (
                              <Tooltip title={"Practitioner"} arrow>
                                <PersonIcon />
                              </Tooltip>
                            )}
                          </Typography>
                        </Link>
                        <Typography className={classes.AvatarPostTime}>
                          {moment(item?.created_on).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <FollowUnfollowUser user={item?.like_user} />
                    </Box>
                  </Box>
                  {arr.length - 1 !== key && <Divider />}
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </MuiDialogContent>
    </MuiDialog>
  );
}

const mapStateToProps = ({ PostReducer: { postLikedUsersList } }) => ({
  postLikedUsersList,
});

const mapDispatchToProps = {
  getPostLikedUsersList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostLikedUsersListDialog);
