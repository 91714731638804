import React, { useState } from "react";

const CheckAuthenticity = () => {
  const [batchNo, setbatchNo] = useState("");
  const [isBatchErrow, setBatchErrow] = useState(false);
  const handlerBatch = () => {
    setBatchErrow(true);
  };
  return (
    <div className="check-authenticity">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <p className="title ca-title">
                <span className="ca-title01">Check Authenticity</span>
                <span className="ca-title02">
                  Through Blockchain Technology
                </span>
              </p>
              <p className="text-ca01">
                Enter the batch number of an item you own or are attempting to
                buy to confirm its authenticity.
              </p>
              <div className="form-check-authenticity">
                <input
                  placeholder="Enter Batch Number"
                  value={batchNo}
                  onChange={(val) => {
                    setbatchNo(val.target.value);
                    setBatchErrow(false);
                  }}
                />
                <div
                  className={
                    "btn btn-success " + (batchNo === "" ? "disabled" : "")
                  }
                  onClick={() => handlerBatch()}
                >
                  Check Authenticity
                </div>
              </div>
              {isBatchErrow && (
                <div className="errow-ca">
                  Unfortunately, the batch number you entered was not returned.
                  <br />
                  Please try again and ensure you have entered the batch number
                  correctly.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckAuthenticity;
