import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { MainHeading, Paragraph } from "../../components/Profile";
import utils from "../../utils/commonUtils";
import { ReactComponent as LockIcon } from "../../constants/svg/icon-lock.svg";

export const Offers = (props) => {
  const { userData, history, profileProgress } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  return (
    <Grid className="offers-root">
      <MainHeading>Offers</MainHeading>
      <Grid className="offers-circles">
        <Grid>
          <Grid className="center">
            {utils.getShortUserName(userData?.data?.name)}
          </Grid>
        </Grid>
        <Grid>
          <Grid className="center">$25</Grid>
          <Paragraph>Benefits to date</Paragraph>
        </Grid>
      </Grid>
      <Grid className="offers-tabs">
        <Paragraph
          onClick={() => setActiveTabIndex(0)}
          className={activeTabIndex === 0 ? "active" : ""}
        >
          Your Offers
        </Paragraph>
        <Paragraph
          onClick={() => setActiveTabIndex(1)}
          className={activeTabIndex === 1 ? "active" : ""}
        >
          Completed Offers
        </Paragraph>
      </Grid>
      <Grid className="offers-ul">
        {[...Array(5)].map(() => {
          return (
            <Grid className="offers-li" onClick={() => history.push("/survey")}>
              <Grid>
                <Paragraph>Colgate Questionnaire</Paragraph>
                <Paragraph>Earn $15</Paragraph>
              </Grid>
              {profileProgress === 100 && (
                <Grid
                  className="center"
                  title="Enrich your profile to unlock these offer"
                >
                  <LockIcon />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid className="offers-unlock-msg">
        <LockIcon />
        <Paragraph>Enrich your profile to unlock these offers</Paragraph>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ ProfileReducers: {} }) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
