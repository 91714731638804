import { Section1, Section2 } from "../../components/CircularEconomy";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { circularEconomy },
} = appImages;

export default function CircularEconomy() {
  return (
    <PageWrapper pageName="label validation">
      <HeaderSection
        headerImgSrc={circularEconomy}
        headerText="No more carbon credits No more false claims and goals Just reporting the facts"
      />
      <Section1 />
      <Section2 />
      <AsSeenInSection />
    </PageWrapper>
  );
}
