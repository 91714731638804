import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  makeStyles,
  InputLabel,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { MuiTextfield } from "../FormComponents";
import { CloseRounded, CloudUploadRounded } from "@material-ui/icons";
import {
  updateUser,
  getUserDetails,
} from "../../../redux/actions/profileActions";

const useStyle = makeStyles((theme) => ({
  socialProfileFormContainer: {
    width: "100%",
    margin: "20px 0 0",
  },
  socialProfileForm: {
    display: "flex",
    flexDirection: "column",
  },
  spTextfield: {
    marginBottom: 15,
    "&>label": {
      margin: "0 0 5px",
    },
    "&>div": {
      marginBottom: 0,
      "&>div": {
        marginTop: 0,
      },
    },
  },
  fileRootDiv: {
    marginBottom: 10,
    "&>div": {
      "&>label": {
        cursor: "pointer",
      },
      "&>svg": {
        cursor: "pointer",
      },
    },
  },
  cancelButton: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    padding: "8px 10px",
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 400,
    marginRight: 10,
    "&:hover": {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  submitButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    borderRadius: 5,
    padding: "8px 10px",
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 400,
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.MuiButton-root.Mui-disabled": {
      background: "#999",
      color: "#fff",
      border: "1px solid #999",
    },
  },
}));

let updateUserFlag = false;

export const SocialProfileForm = (props) => {
  const {
    userData,
    getUserDetails,
    updateUser,
    updateUserData,
    setToggleSocialProfileForm,
  } = props;
  const classes = useStyle();
  const [practitioner_bio, setPractitioner_bio] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState(null);
  const [pracCredentials, setPracCredentials] = useState(null);
  const [pracPictuure, setPracPictuure] = useState(null);
  const [pracDeletedFilesData, setPracDeletedFilesData] = useState({
    credentials: "",
    pictuure: "",
    profile_picture: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", userData?.data?.id);
    formData.append("practitioner_bio", practitioner_bio);
    userProfilePicture &&
      typeof userProfilePicture !== "string" &&
      formData.append("profile_picture", userProfilePicture);
    pracCredentials &&
      typeof pracCredentials !== "string" &&
      formData.append("practitioner_credentials", pracCredentials);
    pracPictuure &&
      typeof pracPictuure !== "string" &&
      formData.append("practitioner_pictuure", pracPictuure);
    (pracDeletedFilesData.credentials ||
      pracDeletedFilesData.pictuure ||
      pracDeletedFilesData.profile_picture) &&
      formData.append(
        "pracDeletedFilesData",
        JSON.stringify(pracDeletedFilesData)
      );
    updateUser(formData);
    updateUserFlag = true;
  };
  const handleClose = () => {
    setPracCredentials(null);
    setUserProfilePicture(null);
    setPracPictuure(null);
    setPractitioner_bio("");
    setPracDeletedFilesData({
      credentials: "",
      pictuure: "",
      profile_picture: "",
    });
    setToggleSocialProfileForm(false);
  };

  useEffect(() => {
    if (updateUserData?.status === "1" && updateUserFlag) {
      updateUserFlag = false;
      getUserDetails();
      handleClose();
    }
  }, [updateUserData]);

  useEffect(() => {
    if (userData?.status === "1") {
      setPracCredentials(null);
      setPracPictuure(null);
      setPracDeletedFilesData({
        credentials: "",
        pictuure: "",
        profile_picture: "",
      });
      setUserProfilePicture(null);
      setPractitioner_bio(userData?.data?.practitioner_bio);
    }
  }, [userData]);

  return (
    <Box className={classes.socialProfileFormContainer}>
      <form onSubmit={handleSubmit} className={classes.socialProfileForm}>
        <Grid container alignItems="center" className={classes.fileRootDiv}>
          <Grid item>
            <label htmlFor="user-profile-file">
              <CloudUploadRounded style={{ marginRight: 5 }} />
              {userProfilePicture
                ? userProfilePicture?.name
                : "Upload Profile Picture"}
            </label>
            {userProfilePicture ||
            (userData?.data?.profile_picture &&
              !pracDeletedFilesData.profile_picture) ? (
              <CloseRounded
                onClick={() => {
                  if (userProfilePicture) setUserProfilePicture(null);
                  else
                    setPracDeletedFilesData((pre) => ({
                      ...pre,
                      profile_picture: userData?.data?.profile_picture,
                    }));
                }}
                style={{ margin: "0px 5px" }}
              />
            ) : null}
          </Grid>
          <Grid item>
            {(userProfilePicture &&
              userProfilePicture?.type?.split("/")[0] === "image") ||
            (userData?.data?.profile_picture &&
              !userProfilePicture?.profile_picture &&
              userData?.data?.profile_picture?.split(".").pop() !== "pdf") ? (
              <img
                width={60}
                height={60}
                src={
                  Boolean(userProfilePicture)
                    ? URL.createObjectURL(userProfilePicture)
                    : userData?.data?.profile_picture
                }
                alt="profile_picture"
              />
            ) : null}
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          flexDirection="column"
          className={classes.spTextfield}
        >
          <InputLabel htmlFor="profile-bio">Profile Bio</InputLabel>
          <MuiTextfield
            value={practitioner_bio}
            onChange={(e) => setPractitioner_bio(e.target.value)}
            id="profile-bio"
            multiline
            minRows={5}
            maxRows={5}
            autoFocus
          />
        </Box>
        {userData?.data?.practitioner === "Yes" && (
          <>
            <Grid container alignItems="center" className={classes.fileRootDiv}>
              <Grid item>
                <label htmlFor="practitioner-credentials-file">
                  <CloudUploadRounded style={{ marginRight: 5 }} />
                  {pracCredentials
                    ? pracCredentials?.name
                    : "Upload Practitioner Credentials"}
                </label>
                {pracCredentials ||
                (userData?.data?.practitioner_credentials &&
                  !pracDeletedFilesData.credentials) ? (
                  <CloseRounded
                    onClick={() => {
                      if (pracCredentials) setPracCredentials(null);
                      else
                        setPracDeletedFilesData((pre) => ({
                          ...pre,
                          credentials: userData?.data?.practitioner_credentials,
                        }));
                    }}
                    style={{ margin: "0px 5px" }}
                  />
                ) : null}
              </Grid>
              <Grid item>
                {(pracCredentials &&
                  pracCredentials?.type?.split("/")[0] === "image") ||
                (userData?.data?.practitioner_credentials &&
                  !pracDeletedFilesData.credentials &&
                  userData?.data?.practitioner_credentials?.split(".").pop() !==
                    "pdf") ? (
                  <img
                    width={60}
                    height={60}
                    src={
                      Boolean(pracCredentials)
                        ? URL.createObjectURL(pracCredentials)
                        : userData?.data?.practitioner_credentials
                    }
                    alt="practitioner_credentials"
                  />
                ) : null}
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" className={classes.fileRootDiv}>
              <Grid item>
                <label htmlFor="practitioner-pictuure-file">
                  <CloudUploadRounded style={{ marginRight: 5 }} />
                  {pracPictuure
                    ? pracPictuure?.name
                    : "Upload Practitioner Picture"}
                </label>
                {pracPictuure ||
                (userData?.data?.practitioner_pictuure &&
                  !pracDeletedFilesData.pictuure) ? (
                  <CloseRounded
                    onClick={() => {
                      if (pracPictuure) setPracPictuure(null);
                      else
                        setPracDeletedFilesData((pre) => ({
                          ...pre,
                          pictuure: userData?.data?.practitioner_pictuure,
                        }));
                    }}
                    style={{ margin: "0px 5px" }}
                  />
                ) : null}
              </Grid>
              <Grid item>
                {pracPictuure ||
                (userData?.data?.practitioner_pictuure &&
                  !pracDeletedFilesData.pictuure) ? (
                  <img
                    width={60}
                    height={60}
                    src={
                      Boolean(pracPictuure)
                        ? URL.createObjectURL(pracPictuure)
                        : userData?.data?.practitioner_pictuure
                    }
                    alt="practitioner_picture"
                  />
                ) : null}
              </Grid>
            </Grid> */}
          </>
        )}
        <Box display={"flex"} alignItems="center" justifyContent={"flex-end"}>
          <Button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            startIcon={
              updateUserData?.loading && <CircularProgress size={20} />
            }
            disabled={
              updateUserData?.loading ||
              (!practitioner_bio &&
                !userProfilePicture &&
                !pracCredentials &&
                !pracPictuure)
            }
            className={classes.submitButton}
          >
            Update
          </Button>
        </Box>
        <input
          type={"file"}
          accept="image/*"
          id="user-profile-file"
          className="display-none"
          onChange={(e) => {
            e?.target?.files?.length > 0 &&
              setUserProfilePicture(e?.target?.files[0]);
          }}
          onClick={(e) => (e.target.value = null)}
        />
        <input
          type={"file"}
          accept="image/*,application/pdf"
          id="practitioner-credentials-file"
          className="display-none"
          onChange={(e) => {
            e?.target?.files?.length > 0 &&
              setPracCredentials(e?.target?.files[0]);
          }}
          onClick={(e) => (e.target.value = null)}
        />
        <input
          onClick={(e) => (e.target.value = null)}
          type={"file"}
          accept="image/*"
          id="practitioner-pictuure-file"
          className="display-none"
          onChange={(e) =>
            e?.target?.files?.length > 0 && setPracPictuure(e?.target?.files[0])
          }
        />
      </form>
    </Box>
  );
};

const mapStateToProps = ({
  ProfileReducers: { userData, updateUserData },
}) => ({ userData, updateUserData });

const mapDispatchToProps = {
  updateUser,
  getUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialProfileForm);
