import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Typography, Tooltip } from "..";
import clsx from "clsx";
import Ingredients from "./ProductDetailDrawers/Ingredients";
import Review from "./ProductDetailDrawers/Review";
import Register from "./ProductDetailDrawers/Register";
import Testing from "./ProductDetailDrawers/Testing";
import Timeline from "./ProductDetailDrawers/Timeline";
import Origin from "./ProductDetailDrawers/Origin";
import OtherBatches from "./ProductDetailDrawers/OtherBatches";
import {
  ReviewIcon,
  BatchIcon,
  OriginIcon,
  BlockchainProofIcon,
  CartIcon,
  TimelineIcon,
  TestingIcon,
  RecycleIcon,
  RegisterIcon,
  IngredientsIcon,
} from "../../constants/svgIcons";
import utils from "../../utils/commonUtils";
import { Link, useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  mockupContainer: {
    minHeight: 450,
    "&>div": {
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
      position: "relative",
    },
  },
  imageBackgroundDiv: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "650px 650px 0 0",
    maxWidth: 650,
    width: "100%",
    height: 325,
  },
  productAnimationRootDiv: {
    width: "100%",
    zIndex: 1,
    margin: "0 auto",
    "&>div": {
      "&:last-child": {
        position: "relative",
        width: 380,
        height: 380,
        marginBottom: 50,
        "&>img": {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
    },
  },
}));

export default function ProductDetailDesktop({
  integrantsBatchesList,
  integrantsDetail: { integrantInfo },
  integrantsDetail,
  dispatchBlockchainProof,
  exhibitsList,
  showProductDetailTour,
  recycle_val
}) {
  let links = [
    {
      title: "origin",
      question: "Where did this product and it's ingredients come from?",
      point: 10,
      icon: <OriginIcon />,
    },
    {
      title: "testing",
      question: "How do I know this product is safe?",
      point: 11,
      icon: <TestingIcon />,
    },
    {
      title: "ingredients",
      question: "What is in this product?",
      point: 12,
      icon: <IngredientsIcon />,
    },
    {
      title: "timeline",
      question: "Timeline",
      point: 13,
      icon: <TimelineIcon />,
    },
    {
      title: "other batches",
      question: "other batches",
      point: 14,
      icon: <BatchIcon />,
    },
    {
      title: "proof",
      question: "Verify to check the document on the blockchain.",
      point: 22,
      isDialog: true,
      icon: <BlockchainProofIcon />,
    },
    {
      title: "Register",
      question: "Register, connect/share, respond and invite",
      point: 23,
      icon: <RegisterIcon />,
    },
    {
      title: "Recycle",
      question: "Record how you disposed of the product and packaging",
      point: 24,
      icon: <RecycleIcon />,
      tour: "recycle",
    },
    {
      title: "Review",
      question: "leave an anonymous review of the product",
      point: 1,
      icon: <ReviewIcon />,
      tour: "review",
    },
    {
      title: "Buy",
      question: "Where can I buy this product again?",
      point: 2,
      link: integrantInfo?.buy_again_url,
      newTab: true,
      icon: <CartIcon />,
    },
  ];
  const classes = useStyle();
  const [isProductImageLoaded, setIsProductImageLoaded] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");

  const onProductImageLoad = () => {
    setIsProductImageLoaded(true);
    setTimeout(() => {
      links.forEach((el, i) => {
        const q1 = document.getElementById(`pd-question-${i + 1}`);
        q1.classList.add(`pd-question-${i + 1}`);
        document
          .getElementById(`point-${el?.point}`)
          .classList.add("point-width");
      });
      showProductDetailTour();
    }, 2000);
  };

  const [currentDrawer, setCurrentDrawer] = useState(0);

  const history = useHistory();
  useEffect(() => {
    if(recycle_val){
      setCurrentDrawer(8);
      history.replace(window.location.pathname);
    }
  }, [recycle_val])

  const handleClose = (type) => {
    if (type === "openOrigin") {
      setCurrentDrawer(1);
    } else if (type === "otherBatches") {
      setCurrentDrawer(5);
    } else {
      if (currentDrawer === 5) setSelectedBatch("");
      setCurrentDrawer(0);
    }
  };

  const handleIntegrantBlockchainProof = () => {
    dispatchBlockchainProof({
      id: integrantInfo?.id,
      type: "integrant",
      showCustomLoader: true,
    });
  };

  return (
    <Grid container className={classes.mockupContainer}>
      <div className="bodyMaxWidth">
        <div className={clsx(classes.productAnimationRootDiv, "center")}>
          <div>
            {isProductImageLoaded && (
              <>
                {links.map((el, i) => {
                  return (
                    <Tooltip key={i} arrow title={el.question}>
                      <div
                        id={`pd-question-${i + 1}`}
                        style={{
                          display: "flex",
                          animation: `zoomInMockup5Question${
                            i + 1
                          } 2s ease-in-out`,
                        }}
                        product-detail-tour={el?.tour || ""}
                        className="product-detail-questions-mockup5"
                        onClick={() => {
                          if (!el?.link && !el?.isDialog) {
                            setCurrentDrawer(i + 1);
                          }
                          if (el?.isDialog) {
                            handleIntegrantBlockchainProof();
                          }
                        }}
                        data-toggle={el?.isDialog ? "modal" : ""}
                        data-target={
                          el?.isDialog ? "#IntegrantBlockchainProofDialog" : ""
                        }
                      >
                        {el.link ? (
                          <a
                            href={utils.getValidUrl(el.link)}
                            target={el?.newTab ? "_blank" : "_self"}
                            rel="noreferrer"
                          >
                            <Typography variant="body2" medium>
                              {el?.icon}
                              <span className="ellipsis">{el.title}</span>
                            </Typography>
                          </a>
                        ) : (
                          <Typography variant="body2" medium>
                            {el?.icon}
                            <span className="ellipsis">{el.title}</span>
                          </Typography>
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
                <div className="product-detail-mockup5-points-border" />
                {[...Array(24)].map((el, i) => {
                  return (
                    <div
                      key={i}
                      className={clsx(
                        "product-detail-mockup5-points",
                        `point-${i + 1}`
                      )}
                      id={`point-${i + 1}`}
                    ></div>
                  );
                })}
              </>
            )}
            <img
              src={integrantInfo?.integrantsType?.thumbnail_url}
              alt={integrantInfo?.title}
              onLoad={onProductImageLoad}
            />
          </div>
        </div>
        <div className={classes.imageBackgroundDiv}></div>
      </div>
      <Origin
        open={currentDrawer === 1}
        {...{ ...integrantsDetail, exhibitsList }}
        onClose={handleClose}
      />
      <Testing
        open={currentDrawer === 2}
        {...{
          ...integrantsDetail,
          integrantsBatchesList,
          setSelectedBatch,
        }}
        onClose={handleClose}
      />
      <Ingredients
        open={currentDrawer === 3}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Timeline
        open={currentDrawer === 4}
        onClose={handleClose}
        {...{ exhibitsList, ...integrantsDetail }}
      />
      <OtherBatches
        open={currentDrawer === 5}
        onClose={handleClose}
        {...{
          integrantsBatchesList,
          ...integrantsDetail,
          selectedBatch,
        }}
      />
      <Register
        open={currentDrawer === 7}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Review
        open={currentDrawer === 8}
        onClose={handleClose}
        {...integrantsDetail}
      />
      <Review
        open={currentDrawer === 9}
        onClose={handleClose}
        {...integrantsDetail}
      />
    </Grid>
  );
}
