import { connect } from "socket.io-client";
import utils from "../utils/commonUtils";
import { apiURL } from "../services/constants";

export const socket = connect(apiURL, {
  auth: {
    token: utils.getLocalStorageItems().auth_token,
  },
  transports: ["websocket"],
});
