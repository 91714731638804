import React from "react";
import { AppImages } from "../../constants";
import "../Team/style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Body } from "../../components/Common";

const {
  images: {
    founder1,
    founder2,
    founder3,
    advisor1,
    advisor2,
    advisor3,
    advisor4,
    advisor5,
    advisor6,
    oAdteam1,
    oAdteam2,
    oAdteam3,
  },
  icon: { linkedinIcon },
} = AppImages;

let objData = {
  data: [
    {
      url: founder1,
      name: "Derek Lurth - CEO",
      linkedinURL: "https://www.linkedin.com/in/derek-lurth/",
      heading: "Founders",
    },
    {
      url: founder2,
      name: "Ken Staker - CTO",
      linkedinURL: "https://www.linkedin.com/in/ken-staker/",
      heading: "Founders",
    },
    {
      url: founder3,
      name: "Todd Taylor",
      linkedinURL: "https://www.linkedin.com/in/taylorops/",
      heading: "Founders",
    },
    {
      url: advisor1,
      name: "Thomas Sullivan Jr",
      linkedinURL: "https://www.linkedin.com/in/tommy-sullivan-6b008a38/",
      heading: "Advisors",
    },
    {
      url: advisor2,
      name: "Kenn Israel",
      linkedinURL: "https://www.linkedin.com/in/kenn-israel/",
      heading: "Advisors",
    },
    {
      url: advisor3,
      name: "Craig Miles",
      linkedinURL: "https://www.linkedin.com/in/craig-miles-163652189/",
      heading: "Advisors",
    },
    {
      url: advisor4,
      name: "Dr. Robert Post",
      linkedinURL: "https://www.linkedin.com/in/drrobpost/",
      heading: "Advisors",
    },
    {
      url: advisor5,
      name: "Rakesh Amin",
      linkedinURL: "https://www.linkedin.com/in/rakeshamin/",
      heading: "Advisors",
    },
    {
      url: advisor6,
      name: "Dr. Norman Relkin",
      linkedinURL: "https://www.linkedin.com/in/norman-relkin-975609b5/",
      heading: "Advisors",
    },
    {
      url: oAdteam1,
      name: "Sanjay Panchal",
      linkedinURL: "https://www.linkedin.com/in/spanchal1",
      heading: "Operations And Development Team",
    },
    {
      url: oAdteam2,
      name: "Ronak Patel",
      linkedinURL: "https://www.linkedin.com/in/ronak-patel-995857168/",
      heading: "Operations And Development Team",
    },
    {
      url: oAdteam3,
      name: "McKay Tucker",
      linkedinURL: "https://www.linkedin.com/in/johnmckaytucker/",
      heading: "Operations And Development Team",
    },
  ],
};

const Affiliate = () => {
  return (
    <Body seoTitle="Partners - HealthLOQ" seoDescription="Partners - HealthLOQ">
      <div className="page-affiliate">
        <div className="meet-the-team-content">
          <div className="mttHeader center">
            <h1>Meet The Team</h1>
          </div>
          <div className="container" style={{ maxWidth: "1200px" }}>
            <div className="row no-gutters">
              <div className="col-12">
                <div className="mttCardRow meet-the-team-slider">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={0}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    navigation={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                      640: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      1010: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {objData?.data?.map((item, key) => {
                      return (
                        <SwiperSlide className="swiper-slide-mttCard">
                          <div
                            className="mttCard"
                            key={key}
                            style={{ width: "auto" }}
                          >
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <img src={item?.url} alt={item?.name} />
                                  </div>
                                </div>
                                <div
                                  className="center"
                                  style={{ flexDirection: "column" }}
                                >
                                  <h3 className="center mttLink notranslate">
                                    {item?.name}
                                    <span>
                                      <a
                                        href={item?.linkedinURL}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="center"
                                      >
                                        <img
                                          src={linkedinIcon}
                                          alt="Link Icon"
                                        />
                                      </a>
                                    </span>
                                  </h3>
                                  <span>{item?.heading}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};
export default Affiliate;
