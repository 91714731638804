import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import CalendlyPage from "../Common/CalendlyPage";

const {
  images: { counterfeitPreventionImage },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&>p:not(:nth-child(2))": {
      marginBottom: 15,
    },
    "&>p": {
      color: "#707070",
      "&>span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  heading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    "&>img": {
      maxWidth: "100%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    },
  },
  btns: {
    display: "flex",
    "& button": {
      padding: "18px 50px",
    },
  },
  secondaryBtn: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    marginRight: 15,
    "&:hover": {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
  },
}));

export default function Section3() {
  const classes = useStyle();
  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={counterfeitPreventionImage} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>How it works</PrimaryHeading>
            </Box>
            <Typography variant="body2">
              <span>HealthLOQ</span> certifies network relationships between
              organizations so that verifying entities can see multiple levels
              of verifications simultaneously.
            </Typography>
            <Typography variant="body2">
              For example, a client may wish to see if a COA for a product is
              authentic and then see if the lab that issued the COA is ISO
              certified.
            </Typography>
            <Typography variant="body2">
              They may also want to see if the ISO certifying entity is endorsed
              following ISO guidelines. <span>HealthLOQ</span>'s validation
              capability can link all levels of verification on a single
              inquiry.
            </Typography>
            <Typography variant="body2">
              This image shows the validation certificate of the COA itself and
              then the box below shows the ANSI certification held by Canyon
              Labs verifying their lab GMP and ISO certification
            </Typography>
            <Box className={classes.btns}>
              {/* <Button className={classes.secondaryBtn}>Learn More</Button> */}
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
