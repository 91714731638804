import {
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  SOCKET_CREATE_POST,
  GET_POSTS_LIST_START,
  GET_POSTS_LIST_SUCCESS,
  POST_LIKE_DISLIKE_START,
  POST_LIKE_DISLIKE_SUCCESS,
  SOCKET_LIKE_DISLIKE,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  GET_POST_COMMENT_LIST_START,
  GET_POST_COMMENT_LIST_SUCCESS,
  UPDATE_POST_START,
  UPDATE_POST_SUCCESS,
  SOCKET_UPDATE_POST,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  SOCKET_DELETE_POST,
  GET_POST_DETAIL_START,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_LIKED_USERS_START,
  GET_POST_LIKED_USERS_SUCCESS,
  DELETE_POST_COMMENT_START,
  DELETE_POST_COMMENT_SUCCESS,
  SOCKET_DELETE_POST_COMMENT,
  UPDATE_POST_COMMENT_START,
  UPDATE_POST_COMMENT_SUCCESS,
  SHARE_POST_START,
  SHARE_POST_SUCCESS,
  SOCKET_SHARE_POST,
  SOCKET_POST_COMMENT,
  SOCKET_UPDATE_POST_COMMENT,
  SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST,
  GET_LINK_PREVIEW_START,
  GET_LINK_PRIVIEW_SUCCESS,
  UPDATE_PROFILE_POST,
} from "../actionsType/post";

const initialState = {
  createPostData: {
    loading: false,
    message: "",
    status: "",
  },
  postsList: {
    loading: false,
    status: "",
    message: "",
    data: [],
    offsetStatus: false,
  },
  postLikeDislikeData: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
  postCommentData: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
  postCommentList: {
    loading: false,
    status: "",
    message: "",
    data: [],
  },
  updatePostData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  deletePostData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  postDetail: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  linkPriview: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  postLikedUsersList: {
    loading: false,
    message: "",
    status: "",
    data: [],
  },
  deletePostCommentData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  updatePostCommentData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  sharePostData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  profilePost: [],
};

const PostReducer = (previousState = initialState, action, state) => {
  switch (action.type) {
    case UPDATE_PROFILE_POST: {
      return {
        ...previousState,
        profilePost: action?.payload || [],
      };
    }
    case SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST: {
      const posts = { ...previousState.postsList };
      let profilePost = previousState.profilePost;
      const { follow_user_id, user_id } = action.payload;
      const loginUserId = state.ProfileReducers.userData?.data?.id;
      if (loginUserId !== follow_user_id && loginUserId !== user_id) {
        return {
          ...previousState,
        };
      }
      const verifyUser = (id) => id === user_id || id === follow_user_id;
      if (verifyUser(loginUserId)) {
        const getPosts = (arr) =>
          arr?.map((post) => {
            return {
              ...post,
              share_post: post?.share_post
                ? {
                    ...post?.share_post,
                    user: {
                      ...post?.share_post?.user,
                      is_follow: verifyUser(post?.share_post?.user?.id)
                        ? !post?.share_post?.user?.is_follow
                        : post?.share_post?.user?.is_follow,
                    },
                  }
                : post?.share_post,
              user: {
                ...post?.user,
                is_follow: verifyUser(post?.user?.id)
                  ? !post?.user?.is_follow
                  : post?.user?.is_follow,
              },
              comments: post?.comments?.map((comment) => {
                return {
                  ...comment,
                  user: {
                    ...comment?.user,
                    is_follow: verifyUser(comment?.user?.id)
                      ? !comment?.user?.is_follow
                      : comment?.user?.is_follow,
                  },
                  child_comment: comment?.child_comment?.map((reply) => {
                    return {
                      ...reply,
                      user: {
                        ...reply?.user,
                        is_follow: verifyUser(reply?.user?.id)
                          ? !reply?.user?.is_follow
                          : reply?.user?.is_follow,
                      },
                    };
                  }),
                };
              }),
            };
          });
        posts["data"] = getPosts(posts?.data);
        profilePost = getPosts(profilePost);
      }
      const postLikedUsersList = { ...previousState.postLikedUsersList };
      postLikedUsersList["data"] = postLikedUsersList?.data?.map((user) => {
        return verifyUser(user?.like_user?.id)
          ? {
              ...user,
              like_user: {
                ...user?.like_user,
                is_follow: !user?.like_user?.is_follow,
              },
            }
          : user;
      });
      return {
        ...previousState,
        postsList: posts,
        profilePost,
        postLikedUsersList: postLikedUsersList,
      };
    }
    case SOCKET_UPDATE_POST_COMMENT: {
      const posts = { ...previousState.postsList };
      const { comment, post_id, comment_id, parent_comment_id } =
        action.payload;
      let profilePost = previousState.profilePost;
      const getPosts = (arr) =>
        arr?.map((item) => {
          return item?.id === post_id
            ? {
                ...item,
                comments: item?.comments?.map((i) => {
                  if (parent_comment_id) {
                    return i?.id === parent_comment_id
                      ? {
                          ...i,
                          child_comment: i?.child_comment?.map((reply) => {
                            return reply?.id === comment_id
                              ? { ...reply, comment }
                              : reply;
                          }),
                        }
                      : i;
                  }
                  return i?.id === comment_id
                    ? {
                        ...i,
                        comment,
                      }
                    : i;
                }),
              }
            : item;
        });
      posts["data"] = getPosts(posts?.data);
      profilePost = getPosts(profilePost);
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_POST_COMMENT: {
      const posts = { ...previousState.postsList };
      const { data, type } = action.payload;
      let profilePost = previousState.profilePost;
      const getPosts = (arr) =>
        arr?.map((post) => {
          return post.id === data?.post_id
            ? {
                ...post,
                comment_count:
                  type === "Comment"
                    ? post?.comment_count + 1
                    : post.comment_count,
                comments:
                  type === "Comment"
                    ? [data, ...post?.comments]
                    : post?.comments?.map((comment) => {
                        return comment?.id === data?.parent_comment_id
                          ? {
                              ...comment,
                              child_comment: [data, ...comment?.child_comment],
                            }
                          : comment;
                      }),
              }
            : post;
        });
      posts["data"] = getPosts(posts?.data);
      profilePost = getPosts(profilePost);
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_CREATE_POST: {
      const posts = { ...previousState.postsList };
      posts["data"] = [action.payload?.data, ...posts?.data];
      let profilePost = previousState.profilePost;
      if (profilePost[0]?.user_id === action?.payload?.data?.user_id) {
        profilePost = [action.payload?.data, ...profilePost];
      }
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_UPDATE_POST: {
      const posts = { ...previousState.postsList };
      posts["data"] = posts?.data?.map((post) => {
        return post.id === action?.payload?.data?.id
          ? action.payload?.data
          : post;
      });
      let profilePost = previousState.profilePost;
      profilePost = profilePost?.map((post) => {
        return post.id === action?.payload?.data?.id
          ? action.payload?.data
          : post;
      });
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_SHARE_POST: {
      const posts = { ...previousState.postsList };
      posts["data"] = [action.payload?.data, ...posts?.data];
      let profilePost = previousState.profilePost;
      if (profilePost[0]?.user_id === action?.payload?.data?.user_id) {
        profilePost = [action.payload?.data, ...profilePost];
      }
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_DELETE_POST_COMMENT: {
      const posts = { ...previousState.postsList };
      const { post_id, comment_id, parent_comment_id } = action.payload;
      let profilePost = previousState.profilePost;
      const getPosts = (arr) =>
        arr?.map((item) => {
          return item?.id === post_id
            ? {
                ...item,
                comment_count: !parent_comment_id
                  ? item?.comment_count - 1
                  : item?.comment_count,
                comments: !parent_comment_id
                  ? item?.comments?.filter(
                      (comment) => comment?.id !== comment_id
                    )
                  : item?.comments?.map((comment) => {
                      return comment?.id === parent_comment_id
                        ? {
                            ...comment,
                            child_comment: comment?.child_comment?.filter(
                              (reply) => reply?.id !== comment_id
                            ),
                          }
                        : comment;
                    }),
              }
            : item;
        });
      posts["data"] = getPosts(posts?.data);
      profilePost = getPosts(profilePost);
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_DELETE_POST: {
      const posts = { ...previousState.postsList };
      const { post_id } = action.payload;
      let profilePost = previousState.profilePost;
      posts["data"] = posts?.data?.filter((post) => {
        return post.id !== post_id;
      });
      posts["data"] = posts?.data?.map((post) => {
        return post?.share_post?.id === post_id
          ? {
              ...post,
              share_post: null,
            }
          : post;
      });
      profilePost = profilePost?.filter((post) => {
        return post.id !== post_id;
      });
      profilePost = profilePost?.map((post) => {
        return post?.share_post?.id === post_id
          ? {
              ...post,
              share_post: null,
            }
          : post;
      });
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SOCKET_LIKE_DISLIKE: {
      const posts = { ...previousState.postsList };
      const loginUserId = state.ProfileReducers.userData?.data?.id;
      const { post_id, type, user_id } = action.payload;
      let profilePost = previousState.profilePost;
      const getPosts = (arr) =>
        arr?.map((post) => {
          return post.id === post_id
            ? {
                ...post,
                like_count:
                  type === "like" ? post?.like_count + 1 : post?.like_count - 1,
                is_like:
                  loginUserId === user_id ? !post?.is_like : post?.is_like,
              }
            : post;
        });
      posts["data"] = getPosts(posts?.data);
      profilePost = getPosts(profilePost);
      return {
        ...previousState,
        postsList: posts,
        profilePost,
      };
    }
    case SHARE_POST_START: {
      return {
        ...previousState,
        sharePostData: {
          ...previousState.sharePostData,
          loading: true,
        },
      };
    }
    case SHARE_POST_SUCCESS: {
      return {
        ...previousState,
        sharePostData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case UPDATE_POST_COMMENT_START: {
      return {
        ...previousState,
        updatePostCommentData: {
          ...previousState.updatePostCommentData,
          loading: true,
        },
      };
    }
    case UPDATE_POST_COMMENT_SUCCESS: {
      return {
        ...previousState,
        updatePostCommentData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case DELETE_POST_COMMENT_START: {
      return {
        ...previousState,
        deletePostCommentData: {
          ...previousState.deletePostCommentData,
          loading: true,
        },
      };
    }
    case DELETE_POST_COMMENT_SUCCESS: {
      return {
        ...previousState,
        deletePostCommentData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_POST_LIKED_USERS_START: {
      return {
        ...previousState,
        postLikedUsersList: {
          ...previousState.postLikedUsersList,
          loading: true,
        },
      };
    }
    case GET_POST_LIKED_USERS_SUCCESS: {
      return {
        ...previousState,
        postLikedUsersList: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_POST_DETAIL_START: {
      return {
        ...previousState,
        postDetail: {
          ...previousState.postDetail,
          loading: true,
        },
      };
    }
    case GET_POST_DETAIL_SUCCESS: {
      return {
        ...previousState,
        postDetail: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_LINK_PREVIEW_START: {
      return {
        ...previousState,
        linkPriview: {
          ...previousState.linkPriview,
          loading: true,
        },
      };
    }
    case GET_LINK_PRIVIEW_SUCCESS: {
      return {
        ...previousState,
        linkPriview: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case DELETE_POST_START: {
      return {
        ...previousState,
        deletePostData: {
          ...previousState.deletePostData,
          loading: true,
        },
      };
    }
    case DELETE_POST_SUCCESS: {
      return {
        ...previousState,
        deletePostData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case UPDATE_POST_START: {
      return {
        ...previousState,
        updatePostData: {
          ...previousState.updatePostData,
          loading: true,
        },
      };
    }
    case UPDATE_POST_SUCCESS: {
      return {
        ...previousState,
        updatePostData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_POST_COMMENT_LIST_START: {
      return {
        ...previousState,
        postCommentList: {
          ...previousState.postCommentList,
          loading: true,
        },
      };
    }
    case GET_POST_COMMENT_LIST_SUCCESS: {
      let posts = { ...previousState.postsList };
      let post_id = action.payload?.data?.length
        ? action.payload?.data[0]?.post_id
        : null;
      let profilePost = previousState.profilePost;
      if (post_id) {
        posts["data"] = posts?.data?.map((item) => {
          return item?.id === post_id
            ? {
                ...item,
                comments: action.payload?.data,
              }
            : item;
        });
        profilePost = profilePost?.map((item) => {
          return item?.id === post_id
            ? {
                ...item,
                comments: action.payload?.data,
              }
            : item;
        });
      }
      return {
        ...previousState,
        postCommentList: {
          loading: false,
          ...action.payload,
        },
        postsList: posts,
        profilePost,
      };
    }
    case POST_COMMENT_START: {
      return {
        ...previousState,
        postCommentData: {
          ...previousState.postCommentData,
          loading: true,
        },
      };
    }
    case POST_COMMENT_SUCCESS: {
      return {
        ...previousState,
        postCommentData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case POST_LIKE_DISLIKE_START: {
      return {
        ...previousState,
        postLikeDislikeData: {
          ...previousState.postLikeDislikeData,
          loading: true,
        },
      };
    }
    case POST_LIKE_DISLIKE_SUCCESS: {
      return {
        ...previousState,
        postLikeDislikeData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_POSTS_LIST_START: {
      return {
        ...previousState,
        postsList: {
          ...previousState.postsList,
          loading: true,
          offsetStatus:
            action?.payload?.offset === 0
              ? false
              : previousState?.postsList?.offsetStatus,
          data:
            action?.payload?.offset === 0 ? [] : previousState?.postsList?.data,
        },
      };
    }
    case GET_POSTS_LIST_SUCCESS: {
      return {
        ...previousState,
        postsList: {
          loading: false,
          ...action.payload,
          data: previousState.postsList.data.concat(action?.payload?.data),
        },
      };
    }
    case CREATE_POST_START: {
      return {
        ...previousState,
        createPostData: {
          ...previousState.createPostData,
          loading: true,
        },
      };
    }
    case CREATE_POST_SUCCESS: {
      return {
        ...previousState,
        createPostData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    default:
      return previousState || initialState;
  }
};
export default PostReducer;
