import React from "react";
import { Grid } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import moment from "moment";
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import utils from "../../utils/commonUtils";
import Fancybox from "../Common/Fancybox";
var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

export default function ProductJourney({ exhibitsList, className = "" }) {
  const handleDocumentViewClick = (id) => {
    let el = document.getElementById(id);
    let extension = el.getAttribute("data-extention");
    let url = el.getAttribute("data-gas_cert_url");
    if (extension === "doc") {
      window.location.href = url;
    } else if (extension === "pdf") {
      window.jQuery("." + id).fancybox({
        width: 900, // or whatever
        height: 620,
        type: "iframe",
      });
      window.jQuery("." + id).attr("href", url);
      window.jQuery("." + id).click();
    } else {
      window.jQuery("." + id).fancybox({
        autoScale: true,
      });
      window.jQuery("." + id).attr("href", url);
      window.jQuery("." + id).click();
    }
  };
  const getFancyBoxData = (item) => {
    return {
      url: item?.metadata?.url,
      extension: utils.getExtension(item?.metadata?.url),
    };
  };
  const getLocationStr = (location, type) => {
    if (location && typeof location === "object") {
      const { line_1, line_2, city, country, state, zip } = location;
      return (
        <>
          <h5 className="pj-label">
            {type === "batch"
              ? "Location"
              : type === "from"
              ? "From Location"
              : type === "to"
              ? "To Location"
              : type === "exhibit"
              ? "Location"
              : type === "transfer"
              ? "Location"
              : ""}
          </h5>
          <p className="pj-location">
            {line_1} {line_2} {city} {state} {country} {zip && "-"} {zip}
          </p>
        </>
      );
    }
    return "";
  };
  return (
    <Grid className={clsx("pj-root", className)}>
      {exhibitsList
        ?.flatMap((item) => item?.history)
        ?.sort(
          (a, b) =>
            new Date(b?.effectiveDate).getTime() -
            new Date(a?.effectiveDate).getTime()
        )
        ?.map((item, key) => {
          if (!item?.type || item?.type === "ingredient_added") {
            return (
              <Fade bottom>
                <Grid className={clsx("pj-box")} key={key}>
                  <Grid className="center">
                    <CreateNewFolderOutlinedIcon />
                  </Grid>
                  <Grid></Grid>
                  <Grid>
                    <p className="pj-date">
                      {moment(item?.effectiveDate).format("DD MMMM YYYY")}
                    </p>
                    <Grid className="pj-product-section">
                      <img src={item?.integrantType} alt="Integrant Icon" />
                      <Grid>
                        <h5 className="pj-label">Product</h5>
                        <p>{item?.text}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            );
          } else if (item?.type === "batch") {
            return (
              <Fade bottom>
                <Grid className={clsx("pj-box")} key={key}>
                  <Grid className="center">
                    <LocationOnOutlinedIcon />
                  </Grid>
                  <Grid></Grid>
                  <Grid>
                    <p className="pj-date">
                      {moment(item?.effectiveDate).format("DD MMMM YYYY")}
                    </p>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.integrantType}
                        alt="Integrant Icon"
                      />
                      <Grid>
                        <h5 className="pj-label">Product</h5>
                        <p>{item?.text}</p>
                      </Grid>
                    </Grid>
                    {item?.description && (
                      <>
                        <h5 className="pj-label">Description</h5>
                        <p className="pj-product-description">
                          {item?.description}
                        </p>
                      </>
                    )}
                    {getLocationStr(item?.metadata?.location, item?.type)}
                  </Grid>
                </Grid>
              </Fade>
            );
          } else if (item?.type === "transit") {
            return (
              <Fade bottom>
                <Grid className={clsx("pj-box")} key={key}>
                  <Grid className="center">
                    <SwapHorizIcon />
                  </Grid>
                  <Grid></Grid>
                  <Grid>
                    <p className="pj-date">
                      {moment(item?.effectiveDate).format("DD MMMM YYYY")}
                    </p>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.integrantType}
                        alt="Integrant Icon"
                      />
                      <Grid>
                        <h5 className="pj-label">Product Transit</h5>
                        <p>{item?.text}</p>
                      </Grid>
                    </Grid>
                    {item?.description && (
                      <>
                        <h5 className="pj-label">Description</h5>
                        <p className="pj-product-description">
                          {item?.description}
                        </p>
                      </>
                    )}
                    {getLocationStr(item?.metadata?.fromLocation, "from")}
                    {getLocationStr(item?.metadata?.toLocation, "to")}
                  </Grid>
                </Grid>
              </Fade>
            );
          } else if (item?.type === "transfer") {
            return (
              <Fade bottom>
                <Grid className={clsx("pj-box")} key={key}>
                  <Grid className="center">
                    <SwapHorizIcon />
                  </Grid>
                  <Grid></Grid>
                  <Grid>
                    <p className="pj-date">
                      {moment(item?.effectiveDate).format("DD MMMM YYYY")}
                    </p>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.integrantType}
                        alt="Integrant Icon"
                      />
                      <Grid>
                        <h5 className="pj-label">Product Transfer</h5>
                        <p>{item?.text}</p>
                      </Grid>
                    </Grid>
                    {item?.description && (
                      <>
                        <h5 className="pj-label">Description</h5>
                        <p className="pj-product-description">
                          {item?.description}
                        </p>
                      </>
                    )}
                    <h5 className="pj-label mb10">From Organization</h5>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.fromOrganization?.logo_url}
                        alt="Integrant Icon"
                        className="pj-circle-image"
                      />
                      <Grid>
                        <p>{item?.metadata?.fromOrganization?.name}</p>
                      </Grid>
                    </Grid>
                    {getLocationStr(item?.metadata?.fromLocation, "transfer")}
                    <h5 className="pj-label mb10 mt20">To Organization</h5>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.toOrganization?.logo_url}
                        alt="Integrant Icon"
                        className="pj-circle-image"
                      />
                      <Grid>
                        <p>{item?.metadata?.toOrganization?.name}</p>
                      </Grid>
                    </Grid>
                    {getLocationStr(item?.metadata?.toLocation, "transfer")}
                  </Grid>
                </Grid>
              </Fade>
            );
          } else if (item?.type === "exhibit") {
            const fancyboxData = getFancyBoxData(item);
            return (
              <Fade bottom>
                <Grid className={clsx("pj-box")} key={key}>
                  <Grid
                    className={clsx(
                      "center",
                      "cursorPointer",
                      "pj-attachment-icon"
                    )}
                    data-extention={fancyboxData.extension}
                    data-gas_cert_url={fancyboxData.url}
                    id={"attachment-icon-" + item?.id}
                    onClick={() => {
                      handleDocumentViewClick("attachment-icon-" + item?.id);
                    }}
                  >
                    <AttachmentOutlinedIcon />
                  </Grid>
                  <Grid></Grid>
                  <Grid>
                    <p className="pj-date">
                      {moment(item?.effectiveDate).format("DD MMMM YYYY")}
                    </p>
                    <Grid className="pj-product-section">
                      <img
                        src={item?.metadata?.integrant_type}
                        alt="Integrant Icon"
                        className={clsx("pj-attachment-image", "cursorPointer")}
                        data-extention={fancyboxData.extension}
                        data-gas_cert_url={fancyboxData.url}
                        id={"attachment-image-" + item?.id}
                        onClick={() => {
                          handleDocumentViewClick(
                            "attachment-image-" + item?.id
                          );
                        }}
                      />
                      <Grid>
                        <h5
                          className={clsx(
                            "pj-attachment-heading",
                            "cursorPointer"
                          )}
                          data-extention={fancyboxData.extension}
                          data-gas_cert_url={fancyboxData.url}
                          id={"attachment-heading-" + item?.id}
                          onClick={() => {
                            handleDocumentViewClick(
                              "attachment-heading-" + item?.id
                            );
                          }}
                        >
                          Attachment
                        </h5>
                        <p>
                          {item?.text} - {item?.exhibitsType}
                        </p>
                      </Grid>
                    </Grid>
                    {item?.description && (
                      <>
                        <h5 className="pj-label">Description</h5>
                        <p className="pj-product-description">
                          {item?.description}
                        </p>
                      </>
                    )}
                    {getLocationStr(item?.metadata?.location, item?.type)}
                  </Grid>
                </Grid>
                <Fancybox className={"attachment-icon-" + item?.id} />
                <Fancybox className={"attachment-image-" + item?.id} />
                <Fancybox className={"attachment-heading-" + item?.id} />
              </Fade>
            );
          } else {
            return "";
          }
        })}
    </Grid>
  );
}
