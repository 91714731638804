import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  Box,
  Button,
  Tooltip,
  Typography,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  createPost,
  getPostDetail,
  updatePost,
  sharePost,
  getLinkPreview,
  getPostHashtags,
} from "../../../redux/actions/post";
import { Link } from "../../";
import { getProductMentions } from "../../../redux/actions/productActions";
import { CircularProgress } from "../..";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import clsx from "clsx";
import moment from "moment";
import { FollowUnfollowUser, UserAvatar } from "../../Common";
import PersonIcon from "@material-ui/icons/Person";
import { MentionsInput, Mention } from "react-mentions";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import LinkPreview from "./LinkPreview";
import jwt from "jsonwebtoken";
import apiConstants from "../../../services/constants";
import InviteOrganization from "../../Dialogs/InviteOrganization";
import { InviteUser } from "../../Dialogs";
import SocialProfileForm from "./SocialProfileForm";
import { NotificationManager } from "react-notifications";
const {
  PRODUCER_APP_URL: { subscription },
} = apiConstants;
const nl2br = require("nl2br");

const useStyle = makeStyles((theme) => ({
  dialogTitle: {
    border: "1px solid #E5E5E5",
  },
  coePostContainer: {
    border: "1px solid #E5E5E5",
    background: "#fff",
    padding: 15,
    "&>div": {
      width: "100%",
    },
  },
  dialogContent: {
    "&>div": {
      border: "none",
    },
  },
  rightSide: {
    width: "calc(100% - 55px)",
  },
  postTextfield: {
    cursor: "pointer",
    width: "100%",
    "&>div": {
      "&>textarea": {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        lineHeight: "23px",
        fontWeight: 400,
      },
    },
  },
  submitButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    borderRadius: 5,
    padding: "8px 10px",
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 400,
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: "0.8",
    },
  },
  footer: {
    width: "100%",
  },
  footerLeftSide: {
    "&>label": {
      margin: 0,
      "&>svg": {
        width: 25,
        height: 25,
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    "&>*:not(:last-child)": {
      marginRight: 10,
    },
  },
  footerRightSide: {
    "&>button": {
      "&:not(:last-child)": {
        marginRight: 10,
      },
    },
  },
  cancelButton: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    padding: "8px 10px",
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    lineHeight: "23px",
    fontWeight: 400,
    "&:hover": {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  mediaPreviewContainer: {
    overflow: "auto",
    width: "100%",
    margin: "10px 0",
  },
  mediaPreviewBox: {
    height: 128,
    overflow: "hidden",
    position: "relative",
    minWidth: 128,
    marginRight: 10,
    borderRadius: 10,
    "&>span": {
      top: 5,
      right: 5,
      height: "auto",
      padding: 4,
      zIndex: 1,
      position: "absolute",
      minWidth: "auto",
      cursor: "pointer",
      borderRadius: "50%",
      background: theme.palette.primary.main,
      "&>svg": {
        color: theme.palette.common.white,
        width: 15,
        height: 15,
      },
    },
    "&>img": {
      width: 128,
      height: "inherit",
      objectFit: "cover",
    },
    "&>div": {
      width: 128,
      height: "inherit",
      "&>video": {
        objectFit: "cover",
        width: "100%",
        height: "100%",
      },
    },
  },
  addMediaButton: {
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "10px 20px",
    height: 128,
    cursor: "pointer",
    "&>svg": {
      cursor: "pointer",
      width: 30,
      height: 30,
      color: theme.palette.primary.main,
    },
  },
  AvatarName: {
    fontSize: "14px",
    fontWeight: "500",
    color: " #333333",
    lineHeight: 1.2,
  },
  postContent: {
    marginBottom: 20,
  },
  AvatarPostTime: {
    fontSize: "12px",
    fontWeight: "500",
    color: " #999999",
    lineHeight: 1.2,
  },
  userNameRow: {
    "&>a>svg": {
      width: 15,
      height: 15,
      color: theme.palette.primary.main,
    },
    "&>*:not(:last-child)": {
      marginRight: 5,
    },
  },
  AvatarRoot: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  textFieldWithMention: {
    "&>div": {
      "&:nth-child(1)": {
        height: 43,
        border: `1px solid #E5E5E5`,
        "&>div": {
          padding: "9px 10px",
        },
        "&>textarea": {
          padding: "9px 10px",
          fontFamily: theme.typography.fontFamily,
          fontSize: 16,
          lineHeight: "23px",
          fontWeight: 400,
        },
        "&:focus": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
      "&:nth-child(2)": {
        borderRadius: 5,
        zIndex: "2 !important",
        marginTop: "30px !important",
        maxHeight: 200,
        overflowY: "auto",
        border: "1px solid #E5E5E5",
        left: "0 !important",
        width: "100%",
      },
    },
  },
  actionTextfieldWithMention: {
    "&>div": {
      "&:nth-child(1)": {
        maxHeight: 150,
        height: "auto",
        minHeight: 43,
      },
      "&:nth-child(2)": {},
    },
  },
  suggestionBox: {
    width: "100%",
    padding: "10px 15px 10px 10px",
    borderBottom: `1px solid #E5E5E5`,
    "&>div": {
      "&:nth-child(1)": {
        marginRight: 10,
      },
      "&:nth-child(2)": {
        "&>h6": {
          display: "flex",
          alignItems: "center",
          marginBottom: 5,
          "&>span": {
            marginLeft: 5,
            borderRadius: 20,
            width: "max-content",
            padding: "3px 5px",
            fontSize: 12,
            lineHeight: "14px",
            fontWeight: 400,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
        "&>p": {
          width: "100%",
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          "-webkit-line-clamp": 2,
          fontSize: 16,
          lineHeight: "20px",
        },
      },
    },
    [theme.breakpoints.down(600)]: {
      padding: 5,
      "&>div": {
        "&:nth-child(1)": {
          marginRight: 5,
        },
        "&:nth-child(2)": {
          "&>h6": {
            fontSize: 16,
            lineHeight: "20px",
          },
          "&>p": {
            fontSize: 14,
            lineHeight: "18px",
          },
        },
      },
    },
  },
  formHelperText: {
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  textContent: {
    fontWeight: 400,
    letterSpacing: 0,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
  postHashtagLi: {
    padding: "5px 10px",
    borderBottom: `1px solid #E5E5E5`,
    width: "100%",
    "&>p": {
      width: "100%",
      "&>span": {
        marginLeft: 10,
        fontSize: 14,
        lineHeight: "18px",
        color: "#999",
        fontWeight: 400,
      },
    },
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}`,
      "&>p": {
        color: theme.palette.primary.main,
      },
    },
  },
  userDetailLink: {
    cursor: "pointer",
    "&>svg": {
      width: "0.9em",
      height: "0.9em",
      color: theme.palette.primary.main,
    },
    "&>p": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  postMentionZeroStateMsg: {
    marginBottom: 0,
  },
}));

const searchProductMentionAPIDebounced = AwesomeDebouncePromise(
  (search) => getProductMentions({ searchText: search }),
  500
);

const getPostHashtagsDebounced = AwesomeDebouncePromise(
  (search) => getPostHashtags({ searchText: search }),
  500
);

const detectHashtagFromStr = (str) => {
  let result = [],
    i = 0,
    j;
  if (str?.length > 0) {
    while (i < str.length) {
      if (str[i] === "#") {
        const hashtagMarkupPrefix = str.slice(i, i + 7);
        if (hashtagMarkupPrefix === "#[#!@!#") {
          let k = i + 7;
          while (k < str?.length) {
            if (str[k] === "]") {
              const hashtagMarkupSufix = str.slice(k - 5, k + 1);
              if (hashtagMarkupSufix === "#!@!#]") {
                i = k + 1;
                break;
              }
            }
            k++;
          }
          continue;
        }
      }
      if (str[i] === "#" && str[i + 1]?.match(/[a-z]/i)) {
        j = i + 2;
        while (j < str.length + 1) {
          if (
            !str[j]?.match(/[a-z]/i) &&
            !result.includes(str.slice(i, j)?.trim())
          ) {
            result.push(str.slice(i, j)?.trim());
            break;
          }
          j++;
        }
        i = j;
      } else {
        i++;
      }
    }
  }
  return result;
};

export const CreateOrEditPost = (props) => {
  const {
    userData,
    createPost,
    createPostData,
    getPostDetail,
    postDetail,
    updatePost,
    updatePostData,
    sharePost,
    sharePostData,
    getLinkPreview,
    linkPriview,
    setDefaultEditOrSharePostData,
    editOrSharePostData,
  } = props;
  const history = useHistory();
  const classes = useStyle();
  const { action } = useParams();
  const [createPostView, setCreatePostView] = useState("");
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState([]);
  const [errors, setErrors] = useState({ description: false });
  const [sharePostDetails, setSharePostDetails] = useState(null);
  const [postLinkPreview, setPostLinkPreview] = useState("");
  const [firstLinkPreview, setFirstLinkPreview] = useState("");
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [mentionSearchText, setMentionSearchText] = useState("");
  const [toggleSocialProfileForm, setToggleSocialProfileForm] = useState(false);

  const verifyData = () => {
    let isValid = true;
    let obj = { ...errors };
    if (description?.trim() === "" && !Boolean(media.length)) {
      obj["description"] = true;
      isValid = false;
    }
    setErrors(obj);
    return isValid;
  };
  const handleSubmit = () => {
    if (verifyData()) {
      const link_content = {
        title: postLinkPreview?.title,
        description: postLinkPreview?.description,
        link: postLinkPreview?.link,
        image: postLinkPreview?.image,
      };
      const formData = new FormData();
      let str = description;
      const manualHashtags = detectHashtagFromStr(str);
      manualHashtags?.forEach((item) => {
        str = str
          .replace(item, ` #[#!@!#0@#${item?.slice(1)}#!@!#] `)
          .replace(/\s+/g, " ")
          .trim();
      });
      formData.append("description", str);
      if (postLinkPreview) {
        formData.append("link_content", JSON.stringify(link_content));
      }
      if (media.length > 0) {
        media
          ?.filter((item) => !item?.id)
          .forEach((file, index) => {
            formData.append(`files`, file);
          });
      }
      if (createPostView !== "create")
        formData.append("post_id", editOrSharePostData?.post_id);
      if (deletedFiles?.length)
        formData.append("deletedFiles", JSON.stringify(deletedFiles));
      if (createPostView === "create") {
        createPost(formData);
      } else if (createPostView === "edit") {
        updatePost(formData);
      } else if (createPostView === "share") {
        sharePost(formData);
      }
    }
  };
  const handleProductMention = async (search, callback) => {
    setMentionSearchText(search);
    if (search.trim().length > 0) {
      let data = await searchProductMentionAPIDebounced(search);
      if (data.length) {
        callback(
          data
            ?.map((item) => ({
              ...item,
              display: item?.title,
              id: `${item?.id}___${item?.type}`,
            }))
            .concat([
              { id: "0", display: search, type: "user" },
              { id: "0", display: search, type: "organization" },
            ])
        );
      } else {
        callback([
          { id: "0", display: search, type: "user" },
          { id: "0", display: search, type: "organization" },
        ]);
      }
      return;
    }
  };
  const handleProductHashtag = async (search, callback) => {
    let data = search?.trim()
      ? await getPostHashtagsDebounced(search)
      : await getPostHashtags({ searchText: search });
    if (data.length) {
      const searchHashtagAvailable = Boolean(
        data?.filter((item) => item.hashtag === search).length
      );
      callback(
        data
          ?.map((item) => {
            return {
              ...item,
              display: item?.hashtag,
            };
          })
          .concat(
            !searchHashtagAvailable && search?.trim()
              ? [
                  {
                    display: search,
                    id: "0",
                    optionLabel: `Create "#${search}"`,
                  },
                ]
              : []
          )
      );
    } else {
      callback(
        search?.trim()
          ? [{ display: search, id: "0", optionLabel: `Create "#${search}"` }]
          : []
      );
    }
    return;
  };
  const handleClose = () => {
    setDescription("");
    setPostLinkPreview("");
    setFirstLinkPreview("");
    setMedia([]);
    setSharePostDetails(null);
    setDeletedFiles([]);
    setCreatePostView("");
    setDefaultEditOrSharePostData();
  };
  useEffect(() => {
    if (!createPostData?.loading && createPostData?.status === "1") {
      handleClose();
    }
  }, [createPostData]);
  useEffect(() => {
    if (!updatePostData?.loading && updatePostData?.status === "1") {
      handleClose();
    }
  }, [updatePostData]);
  useEffect(() => {
    if (!sharePostData?.loading && sharePostData?.status === "1") {
      handleClose();
    }
  }, [sharePostData]);
  useEffect(() => {
    if (
      postDetail?.status === "1" &&
      postDetail?.data?.id === editOrSharePostData?.post_id &&
      createPostView === "edit"
    ) {
      setDescription(postDetail?.data?.description);
      setMedia(postDetail?.data?.post_medias);
    }
    if (
      postDetail?.status === "1" &&
      postDetail?.data?.id === editOrSharePostData?.post_id
    ) {
      if (createPostView === "share") {
        setSharePostDetails(postDetail?.data);
      } else if (createPostView === "edit") {
        setSharePostDetails(postDetail?.data?.share_post);
      }
    }
  }, [postDetail]);
  useEffect(() => {
    if (
      (createPostView === "edit" || createPostView === "share") &&
      editOrSharePostData?.post_id
    ) {
      getPostDetail({
        post_id: editOrSharePostData?.post_id,
        type: createPostView,
      });
    }
  }, [editOrSharePostData?.post_id, createPostView]);

  useEffect(() => {
    var expression =
      /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
    var regex = new RegExp(expression);
    var getAllUrl = [];
    if (regex.exec(description) != null) {
      var urls,
        output = "";
      var url =
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
      while ((urls = url.exec(description)) !== null) {
        output += urls[0] + ", ";
      }
      getAllUrl = output.substring(0, output.length - 2);
      let firstUrl = getAllUrl.split(",");
      if (firstUrl.length > 0) {
        if (firstLinkPreview != firstUrl["0"]) {
          // if (postImageArr.length === 0) {
          getLinkPreview({ link: firstUrl["0"] });
          setFirstLinkPreview(firstUrl["0"]);
          // }
        }
      }
    } else {
      setPostLinkPreview("");
      setFirstLinkPreview("");
    }
  }, [description]);

  const removeLinkPreview = () => {
    setPostLinkPreview("");
    setFirstLinkPreview("");
  };

  useEffect(() => {
    if (linkPriview?.status == "1") {
      setPostLinkPreview(linkPriview?.data);
    }
  }, [linkPriview]);

  useEffect(() => {
    if (editOrSharePostData?.type) {
      setCreatePostView(editOrSharePostData?.type);
    }
  }, [editOrSharePostData?.type]);

  const [openOrganizationInviteDialog, setOpenOrganizationInviteDialog] =
    useState(false);
  let jwt_token_url = "";
  if (userData?.data?.invite_qr_url) {
    jwt_token_url = userData?.data?.invite_qr_url;
  } else {
    let jwt_token = jwt.sign(
      { user_id: userData?.data?.id },
      process.env.REACT_APP_JWT_SECRET
    );
    jwt.sign(
      { user_id: userData?.data?.id },
      process.env.REACT_APP_JWT_SECRET,
      function (err, token) {
        jwt_token = token;
      }
    );
    jwt_token_url = `${subscription}/${jwt_token}`;
  }

  const [openUserInviteDialog, setOpenUserInviteDialog] = useState(false);

  const getForm = () => {
    return (
      <Box
        className={classes.coePostContainer}
        display="flex"
        flexDirection={"column"}
      >
        <Box
          display="flex"
          alignItems={createPostView ? "flex-start" : "center"}
        >
          <Tooltip title="Update Your Profile" arrow>
            <Box className={"createPost-user-profile user-profile-edit-label"}>
              <UserAvatar
                src={userData?.data?.profile_picture}
                onClick={() => setToggleSocialProfileForm((pre) => !pre)}
              />
              <span>
                <EditRoundedIcon />
              </span>
            </Box>
          </Tooltip>
          <Box
            display={"flex"}
            flexDirection="column"
            className={classes.rightSide}
          >
            <MentionsInput
              value={description}
              placeholder="Say Something ..."
              onClick={() => {
                setToggleSocialProfileForm(false);
                setCreatePostView((pre) => (pre === "" ? "create" : pre));
              }}
              autoFocus={true}
              onChange={(e) => {
                setCreatePostView((pre) => (pre === "" ? "create" : pre));
                setDescription(e.target.value);
                setErrors((pre) => ({ ...pre, description: false }));
              }}
              className={clsx(
                classes.textFieldWithMention,
                createPostView && classes.actionTextfieldWithMention
              )}
            >
              <Mention
                markup="@[@!#!@__id__#@__display__@!#!@]"
                displayTransform={(id, display) => {
                  return `@${display} `;
                }}
                trigger="@"
                data={handleProductMention}
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems="flex-start"
                      className={classes.suggestionBox}
                    >
                      {suggestion?.id === "0" ? (
                        <>
                          {suggestion?.type === "organization" && (
                            <p className={classes.postMentionZeroStateMsg}>
                              Don't see the organization you're looking for?{" "}
                              <span
                                onClick={() =>
                                  setOpenOrganizationInviteDialog(true)
                                }
                                style={{ cursor: "pointer", color: "#28a745" }}
                              >
                                Click here
                              </span>{" "}
                              to invite them to HealthLOQ.
                            </p>
                          )}
                          {suggestion?.type === "user" && (
                            <p className={classes.postMentionZeroStateMsg}>
                              Don't see the user you're looking for?{" "}
                              <span
                                onClick={() => setOpenUserInviteDialog(true)}
                                style={{ cursor: "pointer", color: "#28a745" }}
                              >
                                Click here
                              </span>{" "}
                              to invite them to HealthLOQ.
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <UserAvatar
                            src={suggestion?.imageUrl}
                            size={50}
                            useCustomStyle
                          />
                          <Box display={"flex"} flexDirection="column">
                            <Typography
                              variant="h6"
                              className={
                                suggestion?.type === "product"
                                  ? "notranslate"
                                  : ""
                              }
                            >
                              {suggestion?.title}{" "}
                              {suggestion?.type === "organization" && (
                                <span>Organization</span>
                              )}
                              {suggestion?.type === "user" && <span>User</span>}
                            </Typography>
                            <Tooltip arrow title={suggestion?.description}>
                              <Typography variant="body2">
                                {suggestion?.description}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </>
                      )}
                    </Box>
                  );
                }}
              />
              <Mention
                markup="#[#!@!#__id__@#__display__#!@!#]"
                displayTransform={(id, display) => {
                  return `#${display} `;
                }}
                trigger="#"
                data={handleProductHashtag}
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => {
                  return (
                    <Box
                      display={"flex"}
                      alignItems="flex-start"
                      className={classes.postHashtagLi}
                    >
                      <Typography variant="body2">
                        {suggestion?.id !== "0"
                          ? `#${suggestion?.display}`
                          : suggestion.optionLabel}
                        {suggestion.post_count > 0 && (
                          <span>{`(${suggestion.post_count} post${
                            suggestion.post_count === 1 ? "" : "s"
                          })`}</span>
                        )}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </MentionsInput>

            {createPostView && (
              <>
                <FormHelperText className={classes.formHelperText}>
                  To tag any product from Healthloq in your post, please use @
                  with product name.
                </FormHelperText>
                <LinkPreview
                  data={postLinkPreview}
                  handleClose={removeLinkPreview}
                />
                <Box display={"flex"} className={classes.mediaPreviewContainer}>
                  {Boolean(media.length) && !postDetail?.loading && (
                    <>
                      {media?.map((item, key) => {
                        let fileType = item?.id
                          ? item?.type
                          : item?.type?.split("/")[0];
                        let fileUrl = item?.id
                          ? item?.url
                          : URL.createObjectURL(item);
                        return (
                          <Box className={classes.mediaPreviewBox} key={key}>
                            {fileType === "image" ? (
                              <img src={fileUrl} alt="post-img" />
                            ) : (
                              <div>
                                <video src={fileUrl} controls />
                              </div>
                            )}
                            <span
                              className="center"
                              onClick={() =>
                                setMedia((pre) => {
                                  if (item?.id) {
                                    setDeletedFiles((pre) => [...pre, item]);
                                  }
                                  return pre.filter(
                                    (file, index) => key !== index
                                  );
                                })
                              }
                            >
                              <CloseRoundedIcon />
                            </span>
                          </Box>
                        );
                      })}
                      <Tooltip title="Photos" arrow>
                        <label htmlFor="post-media-icon">
                          <Box
                            className={clsx(classes.addMediaButton, "center")}
                          >
                            <AddBoxOutlinedIcon />
                          </Box>
                        </label>
                      </Tooltip>
                    </>
                  )}
                </Box>
                {["edit", "share"].includes(createPostView) &&
                  (sharePostDetails || postDetail?.loading) && (
                    <Box className={classes.sharePostDetailContainer}>
                      {postDetail?.loading ? (
                        <Box className="center">
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <Box className="feedRoot lrComponent feedMainRoot">
                          <Box className="ldrabRoot feedHeaderRoot">
                            <Box className={classes.AvatarRoot}>
                              <Link
                                to={`/user-detail/${sharePostDetails?.user?.id}`}
                                className={classes.userDetailLink}
                                target="_blank"
                              >
                                <UserAvatar
                                  src={sharePostDetails?.user?.profile_picture}
                                />
                              </Link>
                              <Box>
                                <Box
                                  className={classes.userNameRow}
                                  display="flex"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <Link
                                    to={`/user-detail/${sharePostDetails?.user?.id}`}
                                    className={clsx(
                                      classes.userDetailLink,
                                      "ellipsis"
                                    )}
                                    target="_blank"
                                  >
                                    <Typography
                                      className={clsx(classes.AvatarName)}
                                    >
                                      {sharePostDetails?.user?.name}
                                    </Typography>
                                  </Link>
                                  {(sharePostDetails?.user?.practitioner ===
                                    true ||
                                    sharePostDetails?.user?.practitioner ===
                                      "Yes") && (
                                    <Link
                                      to={`/user-detail/${sharePostDetails?.user?.id}`}
                                      className={classes.userDetailLink}
                                      target="_blank"
                                    >
                                      <Tooltip title={"Practitioner"} arrow>
                                        <PersonIcon />
                                      </Tooltip>
                                    </Link>
                                  )}
                                  <FollowUnfollowUser
                                    user={sharePostDetails?.user}
                                  />
                                </Box>
                                <Typography className={classes.AvatarPostTime}>
                                  {moment(
                                    sharePostDetails?.created_on
                                  ).fromNow()}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className={classes.postMoreActions}></Box>
                          </Box>
                          <Box
                            className={clsx(
                              "ldrabRoot feedContentRoot",
                              classes.postContent
                            )}
                          >
                            <Box>
                              <div className={classes.textContent}>
                                {sharePostDetails?.description?.length <=
                                600 ? (
                                  ReactHtmlParser(
                                    nl2br(sharePostDetails?.description)
                                  )
                                ) : (
                                  <ShowMoreText
                                    lines={3}
                                    more="Show more"
                                    less="Show less"
                                    anchorClass={"showMoreBtns"}
                                    expanded={false}
                                  >
                                    {ReactHtmlParser(
                                      nl2br(sharePostDetails?.description)
                                    )}
                                  </ShowMoreText>
                                )}
                              </div>
                              <LinkPreview
                                data={JSON.parse(
                                  sharePostDetails?.link_content
                                )}
                              />
                              {Boolean(
                                sharePostDetails?.post_medias?.length
                              ) && (
                                <Box
                                  display={"flex"}
                                  className={classes.mediaPreviewContainer}
                                >
                                  {sharePostDetails?.post_medias?.map(
                                    (item, key) => {
                                      return (
                                        <Box
                                          className={classes.mediaPreviewBox}
                                          key={key}
                                        >
                                          {item?.type === "image" ? (
                                            <img
                                              src={item?.url}
                                              alt="post-img"
                                            />
                                          ) : (
                                            <div>
                                              <video src={item?.url} controls />
                                            </div>
                                          )}
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  className={classes.footer}
                >
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="flex-start"
                    className={classes.footerLeftSide}
                  >
                    <Tooltip title="Photos" arrow>
                      <label htmlFor="post-media-icon">
                        <ImageIcon />
                      </label>
                    </Tooltip>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems="center"
                    className={classes.footerRightSide}
                  >
                    <Button
                      className={classes.cancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.submitButton}
                      onClick={handleSubmit}
                      startIcon={
                        createPostData?.loading ||
                        sharePostData?.loading ||
                        updatePostData?.loading ? (
                          <CircularProgress color="#fff" />
                        ) : null
                      }
                      disabled={
                        createPostData?.loading ||
                        sharePostData?.loading ||
                        updatePostData?.loading
                      }
                    >
                      {createPostView === "create"
                        ? "Post"
                        : createPostView === "edit"
                        ? "Update"
                        : "Share"}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {toggleSocialProfileForm && (
          <SocialProfileForm {...{ setToggleSocialProfileForm }} />
        )}
        <input
          className="display-none"
          accept="image/*"
          id="post-media-icon"
          type={"file"}
          multiple
          onChange={(e) => {
            if (e.target?.files?.length) {
              if (media?.length + e.target?.files?.length > 20) {
                NotificationManager.error(
                  "You can't upload more then 20 images in single post.",
                  "",
                  5000
                );
                return;
              }
              const otherFileTypeCount = Array.from(e.target?.files)?.filter(
                (file) => file?.type?.split("/")[0] !== "image"
              )?.length;
              if (otherFileTypeCount) {
                NotificationManager.error(
                  "The file type you have uploaded is not valid. Only image file type is valid for the post.",
                  "",
                  5000
                );
                return;
              }
              setMedia((pre) => [...pre, ...e.target.files]);
            }
          }}
          onClick={(e) => (e.target.value = null)}
        />
        <InviteOrganization
          jwt_token_url={jwt_token_url}
          open={openOrganizationInviteDialog}
          handleClose={() => setOpenOrganizationInviteDialog(false)}
          trendingOrganizationData={null}
          checkBoxSelected={[]}
          trendingOrganizationList={null}
        />
        <InviteUser
          mentionSearchText={mentionSearchText}
          open={openUserInviteDialog}
          handleClose={() => setOpenUserInviteDialog(false)}
        />
      </Box>
    );
  };

  return createPostView === "" || createPostView === "create" ? (
    getForm()
  ) : (
    <Dialog
      open={Boolean(editOrSharePostData?.type)}
      onClose={() => {
        setDefaultEditOrSharePostData();
        setCreatePostView("");
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {createPostView === "edit" ? "Update Post" : "Share Post"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {getForm()}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({
  ProfileReducers: { userData },
  PostReducer: {
    createPostData,
    postDetail,
    updatePostData,
    sharePostData,
    linkPriview,
  },
}) => ({
  userData,
  createPostData,
  postDetail,
  updatePostData,
  sharePostData,
  linkPriview,
});

const mapDispatchToProps = {
  createPost,
  getPostDetail,
  updatePost,
  sharePost,
  getLinkPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditPost);
