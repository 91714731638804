import {
  Avatar,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import appImages from "../../../constants/AppImage";
import {
  CommentIcon,
  FollowIcon,
  LikeIcon,
  SendIcon,
  ShareIcon,
} from "../../../constants/svgIcons";
import clsx from "clsx";
import { useState } from "react";

// style
const useStyle = makeStyles((theme) => ({
  connectionPostContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
    padding: 20,
  },
  connectionHeader: {
    display: "flex",
    gap: 10,
  },
  connectionPostName: {
    color: "#000",
    fontWeight: 700,
  },
  buttonContainer: {
    display: "flex",
    marginTop: "20px 0px",
    gap: 10,
    "&>button": {
      padding: "4px 14px",
      background: theme.palette.grey[300],
      color: theme.palette.common.black,
      "&:hover": {
        color: theme.palette.common.black,
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  connectionTextField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 12,
      "&>input": {
        fontWeight: 600,
        color: "#0F172A",
        fontSize: 16,
        marginBottom: 5,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E8F0",
    },
  },
  connectionDivider: {
    height: "2px !important",
  },
  post: {
    margin: "15px 0px",
  },
  addIcon: {
    cursor: "pointer",
  },
}));

// import photoes
const {
  images: { profileIcon },
} = appImages;

export default function ConnectionPost({ name, timeStamp, postContent }) {
  // import style
  const classes = useStyle();

  //  local state
  const [openCommentBox, setOpenCommentBox] = useState(false);
  return (
    <Box className={classes.connectionPostContainer}>
      {/* ====== CONNECTION POST HEADER ======  */}
      <Box className={classes.connectionHeader}>
        <Avatar src={profileIcon} />
        <Box>
          <Typography variant="body2" className={classes.connectionPostName}>
            {name}
          </Typography>
          <Typography variant="p">{timeStamp}</Typography>
        </Box>
        <FollowIcon className={classes.addIcon} />
      </Box>

      <Typography className={classes.post} variant="body2">
        {postContent}
      </Typography>

      <Divider className={classes.connectionDivider} />

      <Box className={clsx(classes.buttonContainer, classes.post)}>
        <Button variant="outlined" startIcon={<LikeIcon />}>
          Like
        </Button>
        <Button variant="outlined" startIcon={<ShareIcon />}>
          Share
        </Button>
        <Button
          onClick={() => setOpenCommentBox(!openCommentBox)}
          variant="outlined"
          startIcon={<CommentIcon />}
        >
          Comment
        </Button>
      </Box>

      {openCommentBox && (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Say something"
          className={classes.connectionTextField}
          InputProps={{
            endAdornment: <SendIcon />,
          }}
        />
      )}
    </Box>
  );
}
