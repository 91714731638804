import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import appImages from "../../constants/AppImage";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    margin: "auto",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    background: theme.palette.common.white,
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
  },
  darkWord: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
}));

const {
  images: { connectToConsumer },
} = appImages;

export default function Section1() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={connectToConsumer} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.contentBox}>
              <Typography variant="body2">
                <span className={classes.darkWord}>HealthLOQ</span> provides
                holistic product origin information to your customers. Then it
                allows consumers to connect with your organization in a
                rich-data, permissioned way. This combination of product origin
                and content information along with the consumer connection
                allows for steps toward reporting on true circular economy
                results. The consumer can report recycling of packaging, reuse
                of product or packaging, and provide targeted feedback on their
                batch of product.
              </Typography>
            </Box>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
