import React from "react";
import { BodyContact } from "../../components/ContactUs";
import { Body } from "../../components/Common";

const ContactUs = () => (
  <Body className="page-contact" hideFooter={true}>
    <BodyContact />
  </Body>
);

export default ContactUs;
