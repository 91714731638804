import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContent,
  MuiButton,
} from "./DialogCommonComponents";
import { deleteUser } from "../../redux/actions/profileActions";
import clsx from "clsx";
import { Paragraph } from "../Profile";
import { Grid, Avatar, Tooltip } from "@material-ui/core";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import utils from "../../utils/commonUtils";

function UserDeleteDialog({
  className = "",
  open,
  handleClose,
  deleteUserData,
  deleteUser,
}) {
  const handleDelete = () => {
    deleteUser();
  };
  useEffect(() => {
    if (deleteUserData?.status === "1") {
      utils.removeItemsFromLocalStorage([
        "auth_token",
        "otpVerified",
        "is_signup",
        "user_id",
        "latitude",
        "longitude",
      ]);
      window.sessionStorage.clear();
      handleClose();
      window.location.href = "/";
    }
  }, [deleteUserData]);
  return (
    <MuiDialog
      className={clsx(className)}
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
    >
      <MuiDialogTitle onClose={handleClose}>
        Delete Consumer Profile
      </MuiDialogTitle>
      <MuiDialogContent>
        <Paragraph>
          Are you sure you want to delete your HealthLOQ profile? This will
          remove all your data from our platform and this action cannot be
          undone.
        </Paragraph>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleClose}>Cancel</MuiButton>
        <MuiButton
          onClick={handleDelete}
          endIcon={
            deleteUserData?.loading ? (
              <MuiCircularProgress className="delete-progress" />
            ) : (
              ""
            )
          }
        >
          Delete
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}

const mapStateToProps = ({ ProfileReducers: { deleteUserData } }) => ({
  deleteUserData,
});

const mapDispatchToProps = {
  deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDeleteDialog);
