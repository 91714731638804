import { SHOW_TOUR, UPDATE_TOUR_ACTIVE_STEP } from "../actionsType/common";

const initialState = {
  showTour: "",
  tourActiveStep: null,
};

const CommonReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case SHOW_TOUR: {
      return {
        ...previousState,
        showTour: action.payload,
      };
    }
    case UPDATE_TOUR_ACTIVE_STEP: {
      return {
        ...previousState,
        tourActiveStep: action.payload,
      };
    }

    default:
      return previousState || initialState;
  }
};
export default CommonReducers;
