/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  dispatchIntegrantsDetail,
  dispatchexhibitsList,
  dispatchIntegrantsDetailClean,
  dispatchBlockchainProof,
  dispatchIngredientsIds,
} from "../../redux/actions";
import PopupModal from "../../components/PopupModal";
import WelcomeModal from "../../components/WelcomeModal";
import ProductDetail from "./ProductDetail";
import ProductDetailWithMap from "./ProductDetailWithMap";
import BatchesList from "./BatchesList";
import BlockchainProof from "./BlockchainProof";
import Document from "./Document";
import { ProductRightNavigation } from "../../components/Products";
import Loader from "../../components/Loader";
import "./style.scss";
import clsx from "clsx";
import queryString from "query-string";
import { Body } from "../../components/Common";
import { useTheme, useMediaQuery } from "@material-ui/core";

const Products = ({
  getblockchainProof,
  getIntegrantsDetail,
  integrantsDetail,
  cleanIntegrantsDetail,
  getexhibitsList,
  exhibitsList,
  integrantsBatchesList,
  blockchainProof,
  getIngredientsIds,
  ingredientsIds,
  loaderReducer,
}) => {
  const match = useMediaQuery(useTheme().breakpoints.down(769));
  /** Intro Modal* */
  const [isVisible, setModalVisible] = useState(
    localStorage.getItem("healthloq_intro") !== "false"
  );
  function modalHandler(val) {
    localStorage.setItem("healthloq_intro", val);
    setModalVisible(val);
  }
  /** End Intro Modal* */
  const urlParams = useParams();
  const params = queryString.parse(window.location.search);
  const { short_code, external_id, attachments } = urlParams;
  const { integrantInfo, sourceLinks } = integrantsDetail;
  useEffect(() => {
    let obj = { short_code, external_id_slug: external_id };
    if (params.preview && window.atob(params.preview) === "true") {
      obj["preview"] = true;
    }
    getIntegrantsDetail(obj);
    return () => {
      cleanIntegrantsDetail();
    };
  }, [getIntegrantsDetail, cleanIntegrantsDetail, external_id, attachments]);

  useEffect(() => {
    if (Object.keys(integrantsDetail).length !== 0 && integrantInfo) {
      const {
        integrantInfo: { id },
      } = integrantsDetail;
      getblockchainProof({ id, type: "integrant" });
      getIngredientsIds({ integrant_id: id });
    }
  }, [integrantsDetail]);

  useEffect(() => {
    if (typeof ingredientsIds === "object" && ingredientsIds?.length > 0) {
      getexhibitsList(ingredientsIds);
    }
  }, [ingredientsIds]);

  if (!loaderReducer && exhibitsList?.length === 0) {
    return <Loader />;
  }

  if (Object.keys(integrantsDetail).length === 0 || !Boolean(integrantInfo)) {
    return <></>;
  }
  return (
    <Body
      seoTitle={integrantInfo?.title}
      seoDescription={integrantInfo?.description}
      hideFooter={Boolean(attachments) || match}
    >
      <div className="page-products">
        <div className={isVisible ? "welcome-wrapper" : ""}>
          <PopupModal
            isVisible={isVisible}
            onClose={modalHandler}
            bgBlur="product-welcome"
          >
            <WelcomeModal />
          </PopupModal>
        </div>
        <div className={"page-products-content"}>
          <div
            className={clsx(
              "product-container"
              // attachments && "product-origin-container"
            )}
          >
            <ProductSection
              {...integrantsDetail}
              exhibitsList={exhibitsList}
              batchesList={integrantsBatchesList}
              blockchainProof={blockchainProof}
              productUrlDetail={{ short_code, external_id }}
              buyUrl={integrantInfo?.buy_again_url}
            />
            {!attachments && (
              <ProductRightNavigation
                productUrlDetail={{ short_code, external_id }}
                buyUrl={integrantInfo?.buy_again_url}
                batchesList={integrantsBatchesList}
                blockchainProof={blockchainProof}
                urlParams={urlParams}
                integrantInfo={integrantInfo}
              />
            )}
          </div>
        </div>
      </div>
    </Body>
  );
};

const mapStateToProps = ({
  productReducers: {
    integrantsDetail,
    exhibitsList,
    isErrorInAPI,
    integrantsBatchesList,
    blockchainProof,
    ingredientsIds,
  },
  loaderReducer,
}) => ({
  integrantsBatchesList,
  integrantsDetail,
  isErrorInAPI,
  exhibitsList,
  blockchainProof,
  ingredientsIds,
  loaderReducer,
});

const mapDispatchToProps = {
  getIntegrantsDetail: dispatchIntegrantsDetail,
  getexhibitsList: dispatchexhibitsList,
  getIngredientsIds: dispatchIngredientsIds,
  cleanIntegrantsDetail: dispatchIntegrantsDetailClean,
  getblockchainProof: dispatchBlockchainProof,
};

Products.propTypes = {
  getIntegrantsDetail: PropTypes.func,
  cleanIntegrantsDetail: PropTypes.func,
  integrantsDetail: PropTypes.shape({
    integrantInfo: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buy_again_url: PropTypes.string,
    }),
    sourceLinks: PropTypes.instanceOf(Array),
  }),
  exhibitsList: PropTypes.instanceOf(Object),
  ingredientsIds: PropTypes.instanceOf(Object),
};

Products.defaultProps = {
  getIntegrantsDetail: () => {},
  cleanIntegrantsDetail: () => {},
  integrantsDetail: {
    integrantInfo: {
      title: "",
      description: "",
      buy_again_url: "",
    },
    sourceLinks: [],
  },
  exhibitsList: [],
  ingredientsIds: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

/** ******Page********** */
const ProductSection = ({
  integrantInfo,
  exhibitsInfo,
  sourceLinks,
  exhibitsList,
  batchesList,
  blockchainProof,
  productUrlDetail,
  buyUrl,
}) => {
  const urlParams = useParams();
  const { attachments, batches, isBlockchainProof, document, documentID } =
    urlParams;
  if (batches) {
    return (
      <BatchesList batchesList={batchesList} integrantInfo={integrantInfo} />
    );
  } else if (isBlockchainProof === "blockchain-proof") {
    return <BlockchainProof blockchainProof={blockchainProof} />;
  } else if (document) {
    return (
      <Document
        list={typeof documentID === "undefined" ? exhibitsInfo : exhibitsList}
      />
    );
  }
  if (attachments && sourceLinks) {
    return (
      <ProductDetailWithMap
        sourceLinks={sourceLinks}
        exhibitsList={exhibitsList}
        integrantInfo={integrantInfo}
        exhibitsInfo={exhibitsInfo}
        productUrlDetail={productUrlDetail}
        buyUrl={buyUrl}
        blockchainProof={blockchainProof}
        urlParams={urlParams}
      />
    );
  }
  return (
    <ProductDetail
      integrantInfo={integrantInfo}
      exhibitsInfo={exhibitsInfo}
      sourceLinks={sourceLinks}
      blockchainProof={blockchainProof}
      productUrlDetail={productUrlDetail}
    />
  );
};

ProductSection.propTypes = {
  sourceLinks: PropTypes.instanceOf(Array),
  integrantInfo: PropTypes.shape({}),
  exhibitsInfo: PropTypes.instanceOf(Array),
};

ProductSection.defaultProps = {
  sourceLinks: [],
  integrantInfo: {},
  exhibitsInfo: [],
};
