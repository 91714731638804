import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";

export * from "./product";

export const catchHandler = (dispatch, error, type, options) => {
  ReactGA.exception({
    error,
    description: "An error ocurred",
    fatal: true,
  });
  dispatch({
    type,
    payload: {
      status: "0",
      message: error.message,
      data: null,
    },
  });
  if (!options.hide_notification) {
    NotificationManager.error(
      "Something went wrong! Please try again later.",
      "",
      5000
    );
  }
};
