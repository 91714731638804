import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  Box,
} from "@material-ui/core";
import {
  Button,
  MainHeading,
  PublicPrivateSwitch,
  SmProfileFooter,
} from "../../components/Profile";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  toggleProducerConnections,
  getOrganizationList,
} from "../../redux/actions/organization";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  summaryMainHeading: {
    fontSize: "34px",
    lineHeight: "40px",
    fontWeight: 700,
    color: "#000",
    marginBottom: 10,
    [theme.breakpoints.down(768)]: {
      marginTop: 20,
    },
  },
  tableContainer: {
    marginTop: 20,
    marginBottom: 30,
    maxHeight: 660,
  },
  tableHead: {
    background: "#FBFBFB",
    "&>tr": {
      "&>th": {
        fontWeight: 700,
      },
    },
  },
  table: {
    [theme.breakpoints.down("sm")]: {
      // minWidth: "max-content",
    },
  },
  tableRow: {
    "&>td": {
      padding: "5px 5px 5px 10px",
      "&>img": {
        width: 50,
        height: 50,
      },
      [theme.breakpoints.down(768)]: {
        padding: 0,
        fontSize: 16,
        lineHeight: "20px",
      },
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
        lineHeight: "18px",
        "&>img": {
          width: 35,
          height: 35,
        },
      },
    },
    height: 60,
  },
  profileRoot: {
    justifyContent: "flex-start !important",
  },
  searchTextFieldDiv: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down(768)]: {
      "&>div": {
        width: "100%",
      },
    },
  },
  organizationNameLink: {
    color: "inherit",
  },
}));

let toggleProducerConnectionsFlag = false;

export const Connections = (props) => {
  const {
    userData,
    activePage,
    match,
    history,
    toggleProducerConnections,
    toggleProducerConnectionsData,
    organizationList,
    getOrganizationList,
  } = props;
  const classes = useStyle();
  const [loadingId, setLoadingId] = useState("");
  const [searchText, setSearchText] = useState("");
  const isMasterToggleConnected =
    organizationList?.data?.length ===
    organizationList?.data?.filter(
      (item) => item?.producer_connections?.length > 0
    )?.length;

  const handleGetOrganizationsList = (type) => {
    let params = {
      user_id: userData?.data?.id,
      type: "connections",
    };
    if (searchText?.trim() !== "" && type !== "clear")
      params["search_text"] = searchText;
    getOrganizationList(params);
  };

  const handleToggleSwitchChange = (id) => (e) => {
    if (loadingId) return;
    let newOrganizationIds = [];
    let deleteOrganizationIds = [];
    if (id) {
      setLoadingId(id);
      newOrganizationIds = e.target.checked ? [id] : [];
      deleteOrganizationIds = e.target.checked ? [] : [id];
    } else {
      if (e.target.checked) {
        newOrganizationIds = organizationList?.data
          ?.filter((item) => item?.producer_connections?.length === 0)
          ?.map((item) => item?.id);
      } else {
        deleteOrganizationIds = organizationList?.data
          ?.filter((item) => item?.producer_connections?.length > 0)
          ?.map((item) => item?.id);
      }
      setLoadingId("all");
    }
    toggleProducerConnections({
      user_id: userData?.data?.id,
      newOrganizationIds,
      deleteOrganizationIds,
    });
    toggleProducerConnectionsFlag = true;
  };

  useEffect(() => {
    if (
      toggleProducerConnectionsData?.status === "1" &&
      toggleProducerConnectionsFlag
    ) {
      toggleProducerConnectionsFlag = false;
      handleGetOrganizationsList();
    }
  }, [toggleProducerConnectionsData?.status]);

  useEffect(() => {
    if (!organizationList?.loading) setLoadingId("");
  }, [organizationList]);

  useEffect(() => {
    if (userData?.data?.id) handleGetOrganizationsList();
  }, [userData?.data?.id]);

  return (
    <Grid className={clsx("profile-root", classes.profileRoot)}>
      <Grid className="profile-page-content">
        <Grid item className="trending-organization-container">
          <div className="trending-organization-header">
            <Typography variant="h1" className={classes.summaryMainHeading}>
              Connect
            </Typography>
            <p>
              Connect with companies you like, provide further product input and
              receive incentives from them.
            </p>
          </div>
          <div className={classes.searchTextFieldDiv}>
            <TextField
              placeholder="Search"
              value={searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleGetOrganizationsList}
                  >
                    <SearchIcon style={{ cursor: "pointer" }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value === "") handleGetOrganizationsList("clear");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleGetOrganizationsList();
              }}
            />
          </div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center" width="20%">
                    Logo
                  </TableCell>
                  <TableCell align="left" width="55%">
                    Name
                  </TableCell>
                  <TableCell align="center" width="25%">
                    <Box className="center">
                      {loadingId === "all" && <MuiCircularProgress />}
                      <PublicPrivateSwitch
                        title={
                          isMasterToggleConnected
                            ? "All Disconnect"
                            : "All Connect"
                        }
                        checked={isMasterToggleConnected}
                        id={"master-connection-toggle"}
                        onChange={handleToggleSwitchChange("")}
                        onText="All Connected "
                        offText="All Disconnected"
                        isMasterConnectionToggle
                        style={{ marginLeft: loadingId === "all" ? 10 : 0 }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.loading ||
                (organizationList?.loading && !loadingId) ? (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan={3} align="center">
                      <MuiCircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {organizationList?.data?.map((item, key) => {
                      return (
                        <TableRow key={key} className={classes.tableRow}>
                          <TableCell align="center" width="20%">
                            {item?.logo_url ? (
                              <Link to={`/organization-detail/${item.id}`}>
                                <img
                                  style={{ objectFit: "contain" }}
                                  width="50px"
                                  height="50px"
                                  src={item?.logo_url}
                                  alt=""
                                />
                              </Link>
                            ) : (
                              "---"
                            )}
                          </TableCell>
                          <TableCell align="left" width="55%">
                            <Link
                              to={`/organization-detail/${item.id}`}
                              className={classes.organizationNameLink}
                            >
                              {item?.name}
                            </Link>
                            {item?.offerCount > 0 && (
                              <span className="org_offer_badge">
                                {item?.offerCount}
                                &nbsp;
                                {item?.offerCount === 1 ? "offer" : "offers"}
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center" width="25%">
                            <PublicPrivateSwitch
                              title={
                                item?.producer_connections?.length !== 0 ||
                                loadingId === item?.id
                                  ? "Disconnect"
                                  : "Connect"
                              }
                              checked={
                                item?.producer_connections?.length !== 0 ||
                                loadingId === item?.id
                              }
                              id={item?.id}
                              onChange={handleToggleSwitchChange(item?.id)}
                              onText="Connected"
                              offText="Disconnected"
                              isConnectionToggle
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {organizationList?.data?.length === 0 && (
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3} align="center">
                          Data not found!
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  OrganizationReducers: { organizationList, toggleProducerConnectionsData },
  ProfileReducers: {},
}) => ({ toggleProducerConnectionsData, organizationList });

const mapDispatchToProps = {
  toggleProducerConnections,
  getOrganizationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Connections);
