import {
  SOCKET_NEW_USER_NOTIFICATION,
  PROFILE_LOGIN_FAIL,
  PROFILE_LOGIN_START,
  PROFILE_LOGIN_SUCCESS,
  PROFILE_SIGNUP_FAIL,
  PROFILE_SIGNUP_START,
  PROFILE_SIGNUP_SUCCESS,
  PRODUCT_FEEDBACK_FAIL,
  PRODUCT_FEEDBACK_START,
  PRODUCT_FEEDBACK_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_USER_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_START,
  UPDATE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  GET_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_QUESTIONS_START,
  GET_SURVEY_QUESTIONS_SUCCESS,
  SUBMIT_SURVEY_ANSWERS_FAIL,
  SUBMIT_SURVEY_ANSWERS_START,
  SUBMIT_SURVEY_ANSWERS_SUCCESS,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PROFILE_PICTURE_START,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  GET_USER_SURVEY_HISTORY_START,
  GET_USER_SURVEY_HISTORY_SUCCESS,
  GET_INVITE_ORGANIZATION_HISTORY_START,
  GET_INVITE_ORGANIZATION_HISTORY_SUCCESS,
  INVITE_ORGANIZATION_FAIL,
  INVITE_ORGANIZATION_START,
  INVITE_ORGANIZATION_SUCCESS,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  RESEND_OTP_START,
  RESEND_OTP_SUCCESS,
  GET_USERS_LIST_START,
  GET_USERS_LIST_SUCCESS,
  FOLLOW_UNFOLLOW_USER_START,
  FOLLOW_UNFOLLOW_USER_SUCCESS,
  SOCKET_FOLLOW_UNFOLLOW_USER,
  GET_USER_CONNECTIONS_START,
  GET_USER_CONNECTIONS_SUCCESS,
  INVITE_USER_START,
  INVITE_USER_SUCCESS,
  GET_USER_NOTIFICATIONS_START,
  GET_USER_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_USER_NOTIFICATIONS_START,
  MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS,
} from "../actionsType/profileActionType";

const initialState = {
  userSurveyHistory: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  userInviteOrganizationHistory: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  profileSignup: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  profileLogin: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  productFeedback: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  categoriesList: {
    loading: false,
    message: "",
    status: "",
    data: [],
  },
  userData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  allUserData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  updateUserData: {
    loading: false,
    message: "",
    status: "",
  },
  deleteUserData: {
    loading: false,
    message: "",
    status: "",
  },
  surveyQuestions: {
    loading: false,
    message: "",
    status: "",
    data: null,
    offer: null,
  },
  surveyAnswersData: {
    loading: false,
    message: "",
    status: "",
  },
  updatePassCodeData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  forgotPassCodeData: {
    loading: false,
    message: "",
    status: "",
  },
  updateProfilePictureData: {
    loading: false,
    message: "",
    status: "",
  },
  inviteOrganizationData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  verifyOTPData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  logoutData: {
    loading: false,
    message: "",
    status: "",
  },
  resendOTPData: {
    loading: false,
    message: "",
    status: "",
  },
  usersList: {
    loading: false,
    message: "",
    status: "",
    data: [],
  },
  followUnfollowUserData: {
    loading: false,
    message: "",
    status: "",
    data: null,
  },
  userConnections: {
    loading: false,
    message: "",
    status: "",
    data: [],
  },
  inviteUserData: {
    loading: false,
    message: "",
    status: "",
  },
  userNotificationData: {
    loading: false,
    status: "",
    message: "",
    data: {
      count: 0,
      rows: [],
    },
  },
  markAsReadUserNotifications: {
    loading: false,
    status: "",
    message: "",
    data: null,
  },
};

const ProfileReducers = (previousState = initialState, action, state) => {
  switch (action.type) {
    case SOCKET_NEW_USER_NOTIFICATION: {
      if (
        state?.ProfileReducers?.userData?.data?.id !==
        action?.payload?.receiver_id
      ) {
        return {
          ...previousState,
        };
      }
      return {
        ...previousState,
        userData: {
          ...previousState?.userData,
          data: {
            ...previousState?.userData?.data,
            userUnreadNotificationCount:
              previousState?.userData?.data?.userUnreadNotificationCount + 1,
          },
        },
        userNotificationData: {
          ...previousState?.userNotificationData,
          data: {
            count: previousState?.userNotificationData?.data?.count + 1,
            rows: [
              action.payload,
              ...previousState?.userNotificationData?.data?.rows,
            ],
          },
        },
      };
    }
    case MARK_AS_READ_USER_NOTIFICATIONS_START: {
      return {
        ...previousState,
        markAsReadUserNotifications: {
          ...previousState.markAsReadUserNotifications,
          loading: true,
        },
      };
    }
    case MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...previousState,
        markAsReadUserNotifications: {
          loading: false,
          ...action.payload,
        },
        userNotificationData: {
          ...previousState?.userNotificationData,
          data: {
            ...previousState?.userNotificationData?.data,
            rows: previousState?.userNotificationData?.data?.rows?.map(
              (item) => {
                return {
                  ...item,
                  is_read: action.payload?.data?.includes(item?.id)
                    ? true
                    : item?.is_read,
                };
              }
            ),
          },
        },
        userData: {
          ...previousState?.userData,
          data: {
            ...previousState?.userData?.data,
            userUnreadNotificationCount:
              previousState?.userData?.data?.userUnreadNotificationCount -
              action?.payload?.data?.length,
          },
        },
      };
    }
    case GET_USER_NOTIFICATIONS_START: {
      return {
        ...previousState,
        userNotificationData: {
          ...previousState.userNotificationData,
          loading: true,
          data:
            action?.payload?.offset === 0
              ? {
                  count: 0,
                  rows: [],
                }
              : previousState?.userNotificationData?.data,
        },
      };
    }
    case GET_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...previousState,
        userNotificationData: {
          loading: false,
          ...action.payload,
          data:
            action?.payload?.offset === 0
              ? action?.payload?.data
              : {
                  count: previousState?.userNotificationData?.data?.count,
                  rows: previousState?.userNotificationData?.data?.rows?.concat(
                    action?.payload?.data?.rows
                  ),
                },
        },
      };
    }
    case INVITE_USER_START: {
      return {
        ...previousState,
        inviteUserData: {
          ...previousState.inviteUserData,
          loading: true,
        },
      };
    }
    case INVITE_USER_SUCCESS: {
      return {
        ...previousState,
        inviteUserData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_USER_CONNECTIONS_START: {
      return {
        ...previousState,
        userConnections: {
          ...previousState.userConnections,
          loading: true,
        },
      };
    }
    case GET_USER_CONNECTIONS_SUCCESS: {
      return {
        ...previousState,
        userConnections: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case SOCKET_FOLLOW_UNFOLLOW_USER: {
      const { type, follow_user_id, user_id, data } = action.payload;
      const usersList = { ...previousState.usersList };
      const userConnections = { ...previousState.userConnections };
      const loginUserId = state.ProfileReducers.userData?.data?.id;
      if (loginUserId !== follow_user_id && loginUserId !== user_id) {
        return {
          ...previousState,
        };
      }
      const otherUserId = user_id === loginUserId ? follow_user_id : user_id;
      if (type === "follow") {
        userConnections["data"] = [...userConnections?.data, data];
        usersList["data"] = usersList?.data?.filter(
          (user) => user.id !== otherUserId
        );
      } else {
        userConnections["data"] = userConnections?.data?.filter(
          (user) => user?.id !== otherUserId
        );
        usersList["data"] = [...usersList?.data, data];
      }

      const allUserData = { ...previousState?.allUserData };
      // const { follow_user_id, user_id } = action.payload;
      // const loginUserId = state.ProfileReducers.userData?.data?.id;
      const verifyUser = (id) => id === user_id || id === follow_user_id;
      if (
        verifyUser(loginUserId) &&
        allUserData?.data &&
        allUserData?.data?.posts
      ) {
        allUserData["data"]["posts"] = allUserData?.data?.posts?.map((post) => {
          return {
            ...post,
            share_post: post?.share_post
              ? {
                  ...post?.share_post,
                  user: {
                    ...post?.share_post?.user,
                    is_follow: verifyUser(post?.share_post?.user?.id)
                      ? !post?.share_post?.user?.is_follow
                      : post?.share_post?.user?.is_follow,
                  },
                }
              : post?.share_post,
            user: {
              ...post?.user,
              is_follow: verifyUser(post?.user?.id)
                ? !post?.user?.is_follow
                : post?.user?.is_follow,
            },
            comments: post?.comments?.map((comment) => {
              return {
                ...comment,
                user: {
                  ...comment?.user,
                  is_follow: verifyUser(comment?.user?.id)
                    ? !comment?.user?.is_follow
                    : comment?.user?.is_follow,
                },
                child_comment: comment?.child_comment?.map((reply) => {
                  return {
                    ...reply,
                    user: {
                      ...reply?.user,
                      is_follow: verifyUser(reply?.user?.id)
                        ? !reply?.user?.is_follow
                        : reply?.user?.is_follow,
                    },
                  };
                }),
              };
            }),
          };
        });
      }

      return {
        ...previousState,
        usersList,
        userConnections,
        allUserData: allUserData,
      };
    }
    case FOLLOW_UNFOLLOW_USER_START: {
      return {
        ...previousState,
        followUnfollowUserData: {
          ...previousState.followUnfollowUserData,
          loading: true,
        },
      };
    }
    case FOLLOW_UNFOLLOW_USER_SUCCESS: {
      return {
        ...previousState,
        followUnfollowUserData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_USERS_LIST_START: {
      return {
        ...previousState,
        usersList: {
          ...previousState.usersList,
          loading: true,
          status: "",
        },
      };
    }
    case GET_USERS_LIST_SUCCESS: {
      return {
        ...previousState,
        usersList: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case RESEND_OTP_START: {
      return {
        ...previousState,
        resendOTPData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case RESEND_OTP_SUCCESS: {
      return {
        ...previousState,
        resendOTPData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case LOGOUT_START: {
      return {
        ...previousState,
        logoutData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...previousState,
        logoutData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case VERIFY_OTP_START: {
      return {
        ...previousState,
        verifyOTPData: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...previousState,
        verifyOTPData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case GET_USER_SURVEY_HISTORY_START: {
      return {
        ...previousState,
        userSurveyHistory: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case GET_USER_SURVEY_HISTORY_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        userSurveyHistory: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case GET_INVITE_ORGANIZATION_HISTORY_START: {
      return {
        ...previousState,
        userInviteOrganizationHistory: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case GET_INVITE_ORGANIZATION_HISTORY_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        userInviteOrganizationHistory: {
          loading: false,
          status,
          message,
          data: data,
        },
      };
    }
    case UPDATE_PROFILE_PICTURE_START: {
      return {
        ...previousState,
        updateProfilePictureData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case UPDATE_PROFILE_PICTURE_SUCCESS: {
      const { status, message, profile_picture } = action.payload;
      const user = previousState?.userData?.data;
      if (profile_picture && user) {
        user["profile_picture"] = profile_picture;
      }
      return {
        ...previousState,
        updateProfilePictureData: {
          loading: false,
          status,
          message,
        },
        userData: {
          ...previousState.userData,
          data: user,
        },
      };
    }
    case FORGOT_PASSWORD_START: {
      return {
        ...previousState,
        forgotPassCodeData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        forgotPassCodeData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case PROFILE_SIGNUP_START: {
      return {
        ...previousState,
        profileSignup: {
          loading: true,
          message: "",
          status: "",
          data: action?.payload || null,
        },
      };
    }
    case PROFILE_SIGNUP_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        profileSignup: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case UPDATE_PASSWORD_START: {
      return {
        ...previousState,
        updatePassCodeData: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        updatePassCodeData: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case PROFILE_SIGNUP_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        profileSignup: {
          loading: false,
          status,
          message,
          data: null,
        },
      };
    }
    case PROFILE_LOGIN_START: {
      return {
        ...previousState,
        profileLogin: {
          loading: true,
          message: "",
          status: "",
          data: action?.payload || null,
        },
      };
    }
    case PROFILE_LOGIN_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        profileLogin: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case PROFILE_LOGIN_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        profileLogin: {
          loading: false,
          status,
          message,
          data: null,
        },
      };
    }
    case PRODUCT_FEEDBACK_START: {
      return {
        ...previousState,
        productFeedback: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case PRODUCT_FEEDBACK_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        productFeedback: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case PRODUCT_FEEDBACK_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        productFeedback: {
          loading: false,
          status,
          message,
          data: null,
        },
      };
    }
    case GET_CATEGORIES_START: {
      return {
        ...previousState,
        categoriesList: {
          ...previousState.categoriesList,
          loading: true,
        },
      };
    }
    case GET_CATEGORIES_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        categoriesList: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case GET_CATEGORIES_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        categoriesList: {
          loading: false,
          status,
          message,
          data: [],
        },
      };
    }
    case GET_USER_START: {
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          loading: true,
        },
      };
    }
    case GET_USER_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        userData: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case GET_USER_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        userData: {
          loading: false,
          status,
          message,
          data: null,
        },
      };
    }
    case GET_ALL_USER_START: {
      return {
        ...previousState,
        allUserData: {
          ...previousState.allUserData,
          loading: true,
        },
      };
    }
    case GET_ALL_USER_SUCCESS: {
      return {
        ...previousState,
        allUserData: {
          loading: false,
          ...action.payload,
        },
      };
    }
    case UPDATE_USER_START: {
      return {
        ...previousState,
        updateUserData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case UPDATE_USER_SUCCESS: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        updateUserData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case UPDATE_USER_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        updateUserData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case DELETE_USER_START: {
      return {
        ...previousState,
        deleteUserData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case DELETE_USER_SUCCESS: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        deleteUserData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case DELETE_USER_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        deleteUserData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case GET_SURVEY_QUESTIONS_START: {
      return {
        ...previousState,
        surveyQuestions: {
          loading: true,
          message: "",
          status: "",
          data: null,
          offer: null,
        },
      };
    }
    case GET_SURVEY_QUESTIONS_SUCCESS: {
      const { status, message, data, offerData } = action.payload;
      return {
        ...previousState,
        surveyQuestions: {
          loading: false,
          status,
          message,
          data,
          offer: offerData,
        },
      };
    }
    case GET_SURVEY_QUESTIONS_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        surveyQuestions: {
          loading: false,
          status,
          message,
          data: null,
          offer: null,
        },
      };
    }
    case SUBMIT_SURVEY_ANSWERS_START: {
      return {
        ...previousState,
        surveyAnswersData: {
          loading: true,
          message: "",
          status: "",
        },
      };
    }
    case SUBMIT_SURVEY_ANSWERS_SUCCESS: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        surveyAnswersData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case SUBMIT_SURVEY_ANSWERS_FAIL: {
      const { status, message } = action.payload;
      return {
        ...previousState,
        surveyAnswersData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case INVITE_ORGANIZATION_START: {
      return {
        ...previousState,
        inviteOrganizationData: {
          loading: true,
          message: "",
          status: "",
          data: null,
        },
      };
    }
    case INVITE_ORGANIZATION_SUCCESS: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        inviteOrganizationData: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    case INVITE_ORGANIZATION_FAIL: {
      const { status, message, data } = action.payload;
      return {
        ...previousState,
        inviteOrganizationData: {
          loading: false,
          status,
          message,
          data,
        },
      };
    }
    default:
      return previousState || initialState;
  }
};
export default ProfileReducers;
