import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  makeStyles,
  Divider,
  IconButton,
  Tooltip,
  Popover,
  Typography,
  Badge,
} from "@material-ui/core";
import UserSuggestionBox from "./UserSuggestionBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import {
  getUsersList,
  getUserConnections,
  getUserNotifications,
  markAsReadUserNotifications,
} from "../../../redux/actions/profileActions";
import MuiTabs from "../../Common/MuiTabs";
import { UserSuggestionSkeleton } from "../Post/SocialSkeletons";
import NotificationsIcon from "@material-ui/icons/Notifications";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "../..";
import { UserAvatar } from "../../Common";
import moment from "moment";
import { useIntersection } from "../../../hooks/useIntersection";

const useStyle = makeStyles((theme) => ({
  suggestionListContainer: {
    width: "100%",
    "&>hr": {
      width: "100%",
      marginBottom: 15,
    },
    "&>div:last-child": {
      minHeight: 125,
    },
  },
  swiperContainer: {
    width: "100%",
    paddingBottom: "15px !important",
    "&>div": {
      "&:nth-child(1)": {
        padding: "0 5px",
        "&>div": {
          display: "flex",
          justifyContent: "center",
          height: "max-content",
        },
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      color: theme.palette.primary.main,
      background: `${theme.palette.primary.main}80`,
      "&:hover": {
        background: `#00000070`,
        color: theme.palette.common.black,
      },
      "&::after, &::before": {
        fontSize: "15px",
      },
    },
    "& .swiper-pagination-horizontal": {
      bottom: "0px !important",
      height: "unset !important",
      marginBottom: "unset !important",
    },
  },
  notificationBadge: {
    right: 10,
  },
  notificationIcon: {
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  notificationUl: {
    minWidth: 500,
    height: "max-content",
    maxHeight: 400,
    overflowY: "auto",
    width: "100%",
    "&>*:not(:last-child)": {
      borderBottom: `1px solid rgba(0,0,0,0.1)`,
    },
    "&>h5": {
      padding: "10px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  notificationLi: {
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&>div": {
      "&>p": {
        "&:last-child": {
          fontSize: 12,
          lineHeight: "16px",
          color: "#999",
        },
      },
    },
    "&>p": {
      width: "100%",
      textAlign: "center",
    },
  },
  activeNotificationLi: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const UserSuggestionList = (props) => {
  const {
    getUsersList,
    usersList,
    getUserConnections,
    userConnections,
    getUserNotifications,
    userNotificationData,
    userData,
    markAsReadUserNotifications,
  } = props;
  const [callMarkAsReadApiFlag, setCallMarkAsReadApiFlag] = useState(false);
  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const classes = useStyle();
  const [activeTab, setActiveTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTabsChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const { isIntersecting } = entry;
    if (
      isIntersecting &&
      userNotificationData?.data?.count -
        userNotificationData?.data?.rows?.length >
        0
    ) {
      getUserNotifications({
        limit: 20,
        offset: userNotificationData?.data?.rows?.length,
      });
      setCallMarkAsReadApiFlag((pre) => true);
    }
  }, [entry]);

  useEffect(() => {
    if (
      userNotificationData?.status === "1" &&
      !userNotificationData?.loading &&
      userNotificationData?.data?.rows?.length &&
      callMarkAsReadApiFlag
    ) {
      const notificationIds = userNotificationData?.data?.rows
        ?.filter((item) => !item?.is_read)
        ?.map((item) => item?.id);
      if (notificationIds?.length) {
        markAsReadUserNotifications({ notificationIds });
      }
      setCallMarkAsReadApiFlag((pre) => !pre);
    }
  }, [userNotificationData, callMarkAsReadApiFlag]);

  useEffect(() => {
    getUsersList();
    getUserConnections();
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      className={classes.suggestionListContainer}
    >
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <MuiTabs
          labels={[
            "Add to Your Network",
            `My Network ${
              userConnections?.data?.length > 0
                ? `(${userConnections?.data?.length})`
                : ""
            }`,
          ]}
          onChange={handleTabsChange}
          value={activeTab}
          variant="scrollable"
          scrollButtons={"auto"}
        />
        <Tooltip arrow title="Notifications">
          <Badge
            badgeContent={userData?.data?.userUnreadNotificationCount}
            color="primary"
            classes={{ badge: classes.notificationBadge }}
          >
            <IconButton
              onClick={(e) => {
                setAnchorEl((pre) => (pre ? null : e.currentTarget));
                if (!anchorEl) {
                  getUserNotifications({ limit: 20, offset: 0 });
                  setCallMarkAsReadApiFlag(true);
                }
              }}
              className={classes.notificationIcon}
            >
              <NotificationsIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
      <Divider />
      {activeTab === 0 &&
        (usersList.loading || usersList?.data?.length ? (
          <Swiper
            slidesPerView={5}
            spaceBetween={15}
            modules={[Autoplay, Navigation, Pagination]}
            navigation
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            pagination={{ clickable: true, dynamicBullets: true }}
            className={classes.swiperContainer}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {usersList.loading && !Boolean(usersList?.data?.length)
              ? [...Array(10)].map((el, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <UserSuggestionSkeleton />
                    </SwiperSlide>
                  );
                })
              : usersList?.data?.map((item, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <UserSuggestionBox user={item} />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        ) : (
          <Box display={"flex"} alignItems="center" justifyContent={"center"}>
            <Typography variant="body1">
              You already connect with all connections.
            </Typography>
          </Box>
        ))}

      {activeTab === 1 &&
        (userConnections?.loading && !Boolean(userConnections?.data?.length) ? (
          <Box display={"flex"}>
            {[...Array(10)].map((el, key) => {
              return <UserSuggestionSkeleton key={key} />;
            })}
          </Box>
        ) : userConnections?.data?.length ? (
          <Swiper
            slidesPerView={5}
            spaceBetween={15}
            modules={[Autoplay, Navigation, Pagination]}
            navigation
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true, dynamicBullets: true }}
            className={classes.swiperContainer}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {userConnections?.data?.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <UserSuggestionBox user={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <Box display={"flex"} alignItems="center" justifyContent={"center"}>
            <Typography variant="body1">
              You Don't Have Any Connections Yet.
            </Typography>
          </Box>
        ))}

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          className={classes.notificationUl}
        >
          <Typography variant="h5">Notifications</Typography>
          {userNotificationData?.loading &&
            !userNotificationData?.data?.count &&
            [...Array(5)].map((el, i) => {
              return (
                <Box key={i} className={clsx(classes.notificationLi)}>
                  <Box sx={{ marginRight: 15 }}>
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width={43}
                      height={43}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    sx={{ width: "calc(100% - 58px)" }}
                  >
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={"100%"}
                      height={20}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={"50%"}
                      height={15}
                    />
                  </Box>
                </Box>
              );
            })}
          {userNotificationData?.data?.rows?.map((item, key) => {
            return (
              <Box
                key={key}
                className={clsx(
                  classes.notificationLi,
                  !item?.is_read && classes.activeNotificationLi
                )}
              >
                <Link
                  to={`/user-detail/${item?.senderUser?.id}`}
                  target="_blank"
                >
                  <UserAvatar
                    title={item?.senderUser?.name}
                    src={item?.senderUser?.profile_picture}
                  />
                </Link>
                <Box display={"flex"} flexDirection="column">
                  <Typography variant="h6">{item?.message}</Typography>
                  <Typography variant="body2">
                    {moment(item?.created_on).format("MM/DD/YYYY hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          {userNotificationData?.data?.count -
            userNotificationData?.data?.rows?.length >
            0 && (
            <Box className={clsx(classes.notificationLi)} ref={setTarget}>
              <Box sx={{ marginRight: 15 }}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={43}
                  height={43}
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection="column"
                sx={{ width: "calc(100% - 58px)" }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={"100%"}
                  height={20}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={"50%"}
                  height={15}
                />
              </Box>
            </Box>
          )}
          {userNotificationData?.status === "1" &&
            !userNotificationData?.loading &&
            userNotificationData?.data?.count === 0 && (
              <Box className={clsx(classes.notificationLi)}>
                <Typography variant="body1">Data not available</Typography>
              </Box>
            )}
        </Box>
      </Popover>
    </Box>
  );
};

const mapStateToProps = ({
  ProfileReducers: {
    usersList,
    userConnections,
    userNotificationData,
    userData,
  },
}) => ({ usersList, userConnections, userNotificationData, userData });

const mapDispatchToProps = {
  getUsersList,
  getUserConnections,
  getUserNotifications,
  markAsReadUserNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSuggestionList);
