import clsx from "clsx";
import React from "react";
import { Typography, Grid, Drawer, makeStyles, Button, Link } from "../../";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyle = makeStyles((theme) => ({
  drawerRoot: {
    "&>div:nth-child(3)": {
      width: 600,
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: 20,
        "&>h2,&>p": {
          marginBottom: 20,
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  },
  closeArrow: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#d9d9d9",
    marginBottom: 15,
    "&>svg": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      boxShadow: "0px 0px 15px 0px #0000001a",
      backgroundColor: theme.palette.common.white,
      "&>svg": {
        color: "#d9d9d9",
      },
    },
  },
}));

export default function Register({ open = false, onClose = () => {} }) {
  const classes = useStyle();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      className={classes.drawerRoot}
    >
      <Grid container>
        <Grid
          item
          className={clsx(classes.closeArrow, "center")}
          onClick={onClose}
        >
          <ArrowForwardIosIcon />
        </Grid>
        <Typography variant="h2" bold>
          Register, connect/share, respond and invite
        </Typography>
        <Typography variant="body1" light>
          Register as a consumer, connect to companies you like - share
          information about yourself in exchange for offers, respond to surveys
          about your experience with the product, invite other companies to
          share info on the healthLOQ platform
        </Typography>
        <Link to={"/create-profile"} isLink={false}>
          <Button primaryDarkBtn>Register</Button>
        </Link>
      </Grid>
    </Drawer>
  );
}
