import appImages from "../../constants/AppImage";
import LeftImageSection from "../Common/Section/LeftImageSection";
import RightImageSection from "../Common/Section/RightImageSection";

const {
  images: { supplierHowItWorkSection1 },
} = appImages;

export default function LabSection() {
  const content = [
    {
      href: "/document-verification",
      content: (
        <>
          Certify COAs and allow retailers and consumers to verify those
          certifications
        </>
      ),
    },
    {
      href: "/document-protection",
      content: (
        <>
          Show that their lab meets ISO and GMP criteria and show that the
          certifying organization can attest to that certification
        </>
      ),
    },
    {
      href: "/document-verification",
      content: (
        <>
          Be able to provide a certificate verification capability at scale and
          in an automated way - no more "stare and compare"
        </>
      ),
    },
    {
      href: "/product-origin",
      content: (
        <>Do all the above whilst maintaining document integrity and security</>
      ),
    },
  ];
  return (
    <>
      <LeftImageSection
        img={supplierHowItWorkSection1}
        content={content}
        heading="Labs must be able to certify their attestations automatically and at scale"
      />
      <RightImageSection
        position="left"
        content="The document protection process is effective for any type of document in any industry and allows organizations to digitally sign proofs of the documents they produce, protecting the reputation of their work product. The privacy of the document content is preserved since only the hash of the produced document is published publicly. There is no need to expose sensitive or proprietary information outside the security perimeter of the organization that creates the original. No document export is required. The document protection process only requires a single API call to publish a hash once a document is generated in the normal business process. HealthLOQ provides a simple open-source tool to execute the document protection function that can be implemented in just a few hours."
      />
    </>
  );
}
