import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "..";
import { AppImages } from "../../constants";

const {
  homeVideo,
  homeVideoPoster,
  homePageImg: { whyHealthLoq },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  whyHealthloqContainer: {
    backgroundColor: theme.palette.secondary.main,
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  videoDiv: {
    marginTop: "-120px",
    display: "flex",
    justifyContent: "center",
    "&>div": {
      width: "100%",
      maxWidth: 1070,
      height: "auto",
      maxHeight: 600,
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 0px 60px 0px #0000001A`,
      "&>video": {
        width: "100%",
        height: "100%",
      },
    },
  },
  whyHealthLoqDiv: {
    margin: "90px auto 100px",
    "&>div": {
      alignItems: "center",
      justifyContent: "space-around",
    },
  },
  whyHealthLoqImageDiv: {
    width: "100%",
    maxWidth: 570,
    height: "auto",
    maxHeight: 570,
    "&>img": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down(1210)]: {
      maxWidth: 470,
      maxHeight: 470,
    },
    [theme.breakpoints.down(980)]: {
      maxWidth: 450,
      maxHeight: 450,
    },
  },
  whyHealthLoqContent: {
    paddingLeft: 30,
    width: "100%",
    maxWidth: "50%",
    "&>h2": {
      marginBottom: 25,
      "&>span": {
        color: theme.palette.primary.main,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    whyHealthLoqDiv: {
      margin: "70px auto 80px",
    },
    whyHealthLoqImageDiv: {
      marginBottom: 30,
    },
    whyHealthLoqContent: {
      maxWidth: "unset",
      paddingLeft: 0,
      "&>*": {
        textAlign: "center",
      },
      "&>h2": {
        marginBottom: 20,
      },
    },
    videoDiv: {
      marginTop: "-90px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    videoDiv: {
      marginTop: "-70px",
    },
    whyHealthLoqImageDiv: {
      marginBottom: 20,
    },
    whyHealthLoqContent: {
      "&>h2": {
        marginBottom: 15,
      },
    },
    whyHealthLoqDiv: {
      margin: "50px auto 60px",
    },
  },
  [theme.breakpoints.down(400)]: {
    videoDiv: {
      marginTop: "-50px",
    },
    whyHealthLoqDiv: {
      margin: "30px auto 40px",
    },
  },
}));

export default function WhatIsHealthLOQ() {
  const classes = useStyle();
  const [isVideoPosterisLoaded, setIsVideoPosterisLoaded] = useState(false);
  useEffect(() => {
    const el = document.getElementById("homepage-video");
    if (el) {
      let img = new Image();
      img.src = el.getAttribute("poster");
      img.onload = () => {
        setIsVideoPosterisLoaded(true);
      };
    }
  }, []);
  return (
    <Grid container className={classes.whyHealthloqContainer}>
      <div className="bodyMaxWidth">
        <Grid item className={classes.videoDiv}>
          <div>
            <video
              poster={homeVideoPoster}
              src={homeVideo}
              preload="none"
              controls={isVideoPosterisLoaded}
              id="homepage-video"
            />
          </div>
        </Grid>
        <Grid item className={classes.whyHealthLoqDiv}>
          <Grid container>
            <Grid item className={classes.whyHealthLoqImageDiv}>
              <img src={whyHealthLoq} alt="Why HealthLOQ Img" />
            </Grid>
            <Grid item className={classes.whyHealthLoqContent}>
              <Typography variant="h2" bold>
                The <span>WHY</span> before you <span>BUY</span>
              </Typography>
              <Typography variant="body1" light>
                HealthLOQ is a third party trust and transparency platform that
                uses the latest digital technologies to create a tamper-proof
                audit trail of information about a product's origin,
                ingredients, testing and path to you - the consumer.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
