/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import {
  ProductBanner,
  ProductTabs,
  ProductCallouts,
  MobileProductDetail,
} from "../../components/Products";
import { AppConstant, AppImages } from "../../constants";

const {
  productSection: {
    wantToKnow,
    titleDescription,
    titleDirections,
    titleWarning,
  },
} = AppConstant;

const { imageNoItem } = AppImages;

const ProductDetail = ({
  integrantInfo,
  exhibitsInfo,
  sourceLinks,
  blockchainProof,
  productUrlDetail,
}) => {
  const {
    id,
    external_id_slug,
    title,
    description,
    integrantsType,
    organizations: { tag_line, short_code, bg_image, bg_color },
    facets,
  } = integrantInfo;

  const productCallouts01 = [];

  /** *Integration List** */

  if (typeof sourceLinks === "object") {
    const integrationList = [];
    sourceLinks.forEach((item) => {
      const updateObj = {
        title: "N/A",
        imgUrl: imageNoItem,
        url: `/p/${short_code}/b/${external_id_slug}/origin`,
      };
      if (item.childLink) {
        if (item.childLink.title) {
          updateObj.title = item.childLink.title;
        }
        if (
          item.childLink.integrantsType &&
          item.childLink.integrantsType.image_url
        ) {
          updateObj.imgUrl = item.childLink.integrantsType.image_url;
        }
      }
      integrationList.push(updateObj);
    });
    const facetsList = {
      type: "integration",
      title: wantToKnow,
      list: integrationList,
    };
    if (integrationList && integrationList.length) {
      productCallouts01.push(facetsList);
    }
  }

  /** *Description** */
  const descriptionArr = [];
  if (integrantsType) {
    const { directions, warnings } = integrantsType;
    if (description) {
      descriptionArr.push({ title: titleDescription, description });
    }
    if (directions) {
      descriptionArr.push({ title: titleDirections, description: directions });
    }
    if (warnings) {
      descriptionArr.push({ title: titleWarning, description: warnings });
    }
  }
  const descriptionObj = {
    type: "description",
    title: "",
    list: descriptionArr,
  };
  productCallouts01.push(descriptionObj);

  let beautyFacetsObj = [];

  if (typeof facets === "object") {
    beautyFacetsObj = facets;
  } else {
    if (facets === "[object Object]") {
      beautyFacetsObj = [];
    } else {
      const uf = JSON.parse(JSON.stringify(facets));
      if (uf && uf.length !== 0) {
        beautyFacetsObj = uf && JSON.parse(uf);
      }
    }
  }
  /** *Table** */
  if (typeof beautyFacetsObj === "object") {
    const facetsList = {
      type: "about",
      title: "",
      list: beautyFacetsObj,
    };
    productCallouts01.push(facetsList);
  }
  return (
    <div className="product-detail-root">
      <div className="product-main-description">
        <ProductBanner
          notificationLine={tag_line}
          productImg={integrantsType}
          productBGImg={bg_image}
          productBGColor={bg_color}
        />
        <div className="product-body">
          <p className="product-title text-center">{title}</p>
          <ProductTabs
            tabs={exhibitsInfo}
            integrantInfo={integrantInfo}
            productBGColor={bg_color}
          />
          {productCallouts01.map((item) => (
            <ProductCallouts
              data={item}
              key={item.type}
              sourceLinks={sourceLinks}
            />
          ))}
        </div>
      </div>
      <MobileProductDetail
        integrantInfo={integrantInfo}
        exhibitsInfo={exhibitsInfo}
        blockchainProof={blockchainProof}
        sourceLinks={sourceLinks}
        productUrlDetail={productUrlDetail}
      />
    </div>
  );
};

ProductDetail.propTypes = {
  integrantInfo: PropTypes.shape({
    title: PropTypes.string,
    facets: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    integrantsType: PropTypes.shape({
      title: PropTypes.string,
      directions: PropTypes.string,
      warnings: PropTypes.string,
    }),
    organization: PropTypes.shape({
      name: PropTypes.string,
      tag_line: PropTypes.string,
    }),
  }),
  exhibitsInfo: PropTypes.instanceOf(Array),
  sourceLinks: PropTypes.instanceOf(Array),
  blockchainProof: PropTypes.instanceOf(Object),
  productUrlDetail: PropTypes.instanceOf(Object),
};

ProductDetail.defaultProps = {
  integrantInfo: {
    title: "",
    facets: "",
    id: "",
    description: "",
    integrantsType: {
      title: "",
      directions: "",
      warnings: "",
    },
    organization: {
      name: "",
      tag_line: "",
    },
  },
  exhibitsInfo: [],
  sourceLinks: [],
  blockchainProof: {},
  productUrlDetail: {},
};

export default ProductDetail;
