import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUserSurveyHistory,
  getInviteOrganizationHistory,
} from "../../redux/actions/profileActions";
import { Grid } from "@material-ui/core";
import {
  MuiAccordion,
  InviteOrganizationHistoryAccordion,
  SmProfileFooter,
} from "../../components/Profile";
import MuiCircularProgress from "../../components/Common/MuiCircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import moment from "moment";

export const SurveyHistory = ({
  getUserSurveyHistory,
  userSurveyHistory,
  getInviteOrganizationHistory,
  userInviteOrganizationHistory,
  history,
  match,
  activePage,
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState([]);
  const handleAccordionClick = (id) => {
    if (expandedAccordion?.includes(id)) {
      setExpandedAccordion((pre) => pre?.filter((item) => item !== id));
    } else {
      setExpandedAccordion((pre) => [...pre, id]);
    }
  };
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    if (value == 0) {
      getUserSurveyHistory();
    } else if (value == 1) {
      getInviteOrganizationHistory();
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }
  return (
    <Grid className="survey-history">
      <AppBar position="static" className={"tabs"}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="survey-history-tabs"
          scrollButtons="auto"
        >
          <Tab selected={value === 0} label="Surveys Completed" />
          <Tab selected={value === 1} label="Invitations Sent" />
        </Tabs>
      </AppBar>
      <div className="survey-history-content">
        {value == 0 ? (
          userSurveyHistory?.loading ? (
            <Grid container alignItems="center" justifyContent="center">
              <MuiCircularProgress />
            </Grid>
          ) : userSurveyHistory?.data?.length ? (
            userSurveyHistory?.data?.map((item, i) => {
              return (
                <MuiAccordion
                  onClick={() =>
                    handleAccordionClick(moment(item?.created_on).valueOf())
                  }
                  key={i}
                  data={item}
                  expanded={expandedAccordion?.includes(
                    moment(item?.created_on).valueOf()
                  )}
                />
              );
            })
          ) : (
            <Grid container alignItems="center" justifyContent="center">
              Data not available.
            </Grid>
          )
        ) : (
          ""
        )}
        {value == 1 ? (
          userInviteOrganizationHistory?.loading ? (
            <Grid container alignItems="center" justifyContent="center">
              <MuiCircularProgress />
            </Grid>
          ) : userInviteOrganizationHistory?.data &&
            userInviteOrganizationHistory?.data.length > 0 ? (
            Object.entries(userInviteOrganizationHistory?.data)?.map(
              ([key, value], i) => {
                return (
                  <InviteOrganizationHistoryAccordion
                    onClick={() => handleAccordionClick(key)}
                    key={i}
                    data={value}
                    expanded={expandedAccordion?.includes(key)}
                  />
                );
              }
            )
          ) : (
            <Grid container alignItems="center" justifyContent="center">
              Data not available.
            </Grid>
          )
        ) : (
          ""
        )}
      </div>
      {!match && <SmProfileFooter history={history} activePage={activePage} />}
    </Grid>
  );
};

const mapStateToProps = ({
  ProfileReducers: { userSurveyHistory, userInviteOrganizationHistory },
}) => ({
  userSurveyHistory,
  userInviteOrganizationHistory,
});

const mapDispatchToProps = {
  getUserSurveyHistory,
  getInviteOrganizationHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyHistory);
