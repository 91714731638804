import { Box, Grid, makeStyles, Link, Button } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import appImages from "../../constants/AppImage";
import { createBrowserHistory } from "history";

const { grma_page } = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "100vh",
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  sectionImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
  },
}));

export default function HealthLoqSection() {
  const classes = useStyle();
  const history = createBrowserHistory();
  const handleExploreClick = () => {
    window.open(
      "https://grmalliance.org/product-integrity-program/#overview",
      "_blank"
    );
  };

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid className={classes.content} container spacing={3}>
          <Box style={{ marginBottom: "10px" }}>
            <PrimaryHeading>HealthLOQ</PrimaryHeading>
          </Box>
          <Box style={{ marginBottom: "50px" }}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              rel="noopener noreferrer"
            >
              HealthLOQ is part of the enabling technology behind the GRMA
              Product Integrity Program
            </Link>
          </Box>
          <Box className={classes.sectionImage}>
            <Button target="_blank" onClick={handleExploreClick}>
              Expolore
            </Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
