import React from "react"
import "./style.scss"
import { AppImages, AppConstant } from "../../constants"

const {
  welcomePopup: {
    introMessage, title, quality, sustainability, diversity
 }
} = AppConstant

const {
  icon: { qualityIcon, sustainabilityIcon, diversityIcon }
} = AppImages

const WelcomeModal = () => (
    <>
        <div className="popup-welcome">
            <div className="popup-welcome-title">{title}</div>
            <div className="popup-welcome-content">
                <div className="d-flex">
                    <div className="item-welcome">
                        <img className="intro-img" src={qualityIcon} alt={quality} width="115" />
                        <p className="intro-quality txt-intro">{quality}</p>
                    </div>
                    <div className="item-welcome">
                        <img className="intro-img" src={sustainabilityIcon} alt={sustainability} width="115" />
                        <p className="intro-sustainability txt-intro">{sustainability}</p>
                    </div>
                    <div className="item-welcome">
                        <img className="intro-img" src={diversityIcon} alt={diversity} width="115" />
                        <p className="intro-diversity txt-intro">{diversity}</p>
                    </div>
                </div>
                <p className="intro-message">{introMessage}</p>
            </div>
        </div>
    </>
)

export default WelcomeModal
