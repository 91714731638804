import React from "react";
import { PageWrapper } from "../../components/Wrappers";
import AsSeenInSection from "../../components/Common/AsSeenInSection";
import { RetailerSection } from "../../components/Retailers";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import appImages from "../../constants/AppImage";

const {
  images: { retailersHeader },
} = appImages;

export default function Retailers() {
  return (
    <PageWrapper pageName="solutions">
      <HeaderSection
        headerImgSrc={retailersHeader}
        headerText="Compliance Dashboard to help retailers verify supplier products, documents, and credentials"
        headerTextPosition="right"
        headerImgPosition="center"
      />
      <RetailerSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
