import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import appImages from "../../constants/AppImage";
import CalendlyPage from "../Common/CalendlyPage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    margin: "auto",
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    background: theme.palette.common.white,
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
  },
}));

const {
  images: { connectToConsumer },
} = appImages;

export default function ConnectToConsumerSection() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={connectToConsumer} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.contentBox}>
              <Typography variant="body2">
                We enable consumer connections using a fully-permissioned,
                rich-data consent and social platform. Consumers are in complete
                control of their profile data and can both extend and retract
                access to their information at any time. The platform allows
                consumers to
              </Typography>
              <ul>
                <li>Give demographic information about themselves</li>
                <li>Give product feedback</li>
                <li>Receive incentives and product promotions</li>
                <li>Interact with other consumers and health practitioners.</li>
              </ul>
            </Box>
            <Box className={classes.btns}>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
