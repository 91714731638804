import {
  GET_ORGANIZATION_LIST_START,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_TRENDING_ORGANIZATION_START,
  GET_TRENDING_ORGANIZATION_SUCCESS,
  TOGGLE_PRODUCER_CONNECTIONS_START,
  TOGGLE_PRODUCER_CONNECTIONS_SUCCESS,
} from "../actionsType/organization";
import { productAPI, trendingOrganizationsAPI } from "../../services/index";
import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";
import { catchHandler } from ".";

export const getOrganizationList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_LIST_START,
    });
    const organizationList = await productAPI.getOrganizationList(params);
    if (organizationList?.data?.length > 0) {
      dispatch({
        type: GET_ORGANIZATION_LIST_SUCCESS,
        payload: {
          data: organizationList?.data,
          status: "1",
          message: "Organization list fetched successfully.",
        },
      });
    } else {
      dispatch({
        type: GET_ORGANIZATION_LIST_SUCCESS,
        payload: {
          data: null,
          status: "0",
          message: "Something went wrong! Organization list not listed.",
        },
      });
    }
    ReactGA.event({
      category: "Organization",
      action: "Get organization list",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_ORGANIZATION_LIST_SUCCESS);
  }
};

export const getTrendingOrganizationsList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRENDING_ORGANIZATION_START,
    });
    const trendingOrganizationList =
      await trendingOrganizationsAPI.getTrendingOrganizationsAPIList(params);
    if (trendingOrganizationList?.data?.data?.length > 0) {
      dispatch({
        type: GET_TRENDING_ORGANIZATION_SUCCESS,
        payload: {
          data: trendingOrganizationList?.data,
          status: "1",
          message: "Trending organization list fetched successfully.",
        },
      });
    } else {
      dispatch({
        type: GET_TRENDING_ORGANIZATION_SUCCESS,
        payload: {
          data: null,
          status: "0",
          message:
            "Something went wrong! Trending organization list not listed.",
        },
      });
    }
    ReactGA.event({
      category: "Organization",
      action: "Get trending list",
    });
  } catch (error) {
    catchHandler(dispatch, error, GET_TRENDING_ORGANIZATION_SUCCESS);
  }
};

export const toggleProducerConnections = (params) => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_PRODUCER_CONNECTIONS_START,
    });
    const response = await productAPI.toggleProducerConnections(params);
    dispatch({
      type: TOGGLE_PRODUCER_CONNECTIONS_SUCCESS,
      payload: response?.data,
    });
    ReactGA.event({
      category: "Organization",
      action: "Create producer connection",
    });
  } catch (error) {
    catchHandler(dispatch, error, TOGGLE_PRODUCER_CONNECTIONS_SUCCESS);
  }
};
