import React from "react";
import { ProductBanner } from "../../components/Products";
import { Link } from "react-router-dom";

const BatchesList = ({
  batchesList,
  integrantInfo,
  integrantInfo: {
    title,
    integrantsType,
    organizations: { tag_line },
  },
}) => {
  return (
    <div className="product-main-description product-batchs-root">
      <ProductBanner notificationLine={tag_line} productImg={integrantsType} />
      <div className="batch-container">
        <div className="batch-content">
          <div className="title-batch-content">{title} - Other Batches</div>
          {batchesList && batchesList.length ? (
            batchesList.map((item, index) => {
              return (
                <BatchesItem
                  {...item}
                  integrantInfo={integrantInfo}
                  key={index}
                />
              );
            })
          ) : (
            <div className="text-center">No Batche(s) Found</div>
          )}
        </div>
      </div>
    </div>
  );
};

const BatchesItem = ({
  external_id,
  external_id_slug,
  is_published,
  title,
  integrantInfo: {
    organizations: { short_code },
  },
}) => {
  if (is_published) {
    return (
      <div className="batches-item">
        <div className="d-flex justify-content-between align-items-start">
          <div className="title-batch">
            {title}
            <i>Batch No.: {external_id}</i>
          </div>
          <Link
            to={"/p/" + short_code + "/b/" + external_id_slug}
            className="button-batch"
            title="View"
          >
            View
          </Link>
        </div>
      </div>
    );
  }
  return null;
};

export default BatchesList;
