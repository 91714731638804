import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { Button, MuiTextfield } from "../../components/Profile";
import { verifyOTP, resendOTP } from "../../redux/actions/profileActions";
import { connect } from "react-redux";
import utils from "../../utils/commonUtils";
import { Link } from "react-router-dom";

export function VerifyOTP(props) {
  const { match, history, verifyOTP, verifyOTPData, resendOTP, resendOTPData } =
    props;
  const [otp, setOtp] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const handleSubmit = () => {
    if (!otp?.trim()) {
      setError("OTP is required.");
      return;
    }
    if (otp.length !== 6) {
      setError("OTP length should be 6 digit.");
      return;
    }
    verifyOTP({ otp, rememberMe });
  };
  useEffect(() => {
    if (verifyOTPData?.status === "1") {
      setOtp(null);
      setError("");
      setRememberMe(false);
      const { survey_token, is_register } = utils.getLocalStorageItems();
      if (survey_token) {
        utils.removeItemsFromLocalStorage("survey_token");
        history.push(`/survey/${survey_token}`);
      } else {
        // const is_register = utils.getLocalStorageItems().is_register;
        if (is_register == "true") {
          utils.removeItemsFromLocalStorage(["is_register"]);
          history.push("/profile-signup");
        } else {
          history.push("/connections");
        }
        // history.push("/connections");
      }
    }
  }, [verifyOTPData]);
  return (
    <Grid className="ac-root">
      <Grid className="ac-content">
        <Grid className="ac-form-div">
          <MuiTextfield
            type="text"
            placeholder={"Enter 6 digit OTP"}
            match={match}
            value={otp}
            onChange={(e) => {
              const otpVal = e.target.value;
              if (
                otpVal === "" ||
                (otpVal.length < 7 && /^\d+$/.test(otpVal))
              ) {
                setOtp(e.target.value);
                setError("");
              }
            }}
            required
            error={Boolean(error)}
            helperText={error}
          />
        </Grid>
        <Grid className="ac-rememberme-div">
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
                style={{ background: "none" }}
              />
            }
            label="Remember Me for 7 days."
          />
        </Grid>
      </Grid>
      <Grid className="ac-footer">
        <Button
          onClick={handleSubmit}
          loading={verifyOTPData?.loading}
          profileBgBtn
        >
          Verify
        </Button>
        <Button
          onClick={() => resendOTP()}
          loading={resendOTPData?.loading}
          profileBgBtn
        >
          Resend OTP
        </Button>
        <p className="psl-footer-link">
          <Link
            to={"/profile-login"}
            onClick={() => utils.removeItemsFromLocalStorage(["auth_token"])}
          >
            Back to Login
          </Link>
        </p>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({
  ProfileReducers: { verifyOTPData, resendOTPData },
}) => ({
  verifyOTPData,
  resendOTPData,
});

const mapDispatchToProps = {
  verifyOTP,
  resendOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);
