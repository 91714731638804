import React from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Link,
  IconButton,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  linkPreviewBox: {
    margin: "10px 0",
    position: "relative",
  },
  linkEl: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkPreviewImage: {
    width: "100%",
    "&>img": {
      width: "100%",
      height: "100%",
    },
  },
  linkPreviewContent: {
    marginLeft: (props) => (props?.data?.image ? 10 : 0),
    "&>h6": {
      fontWeight: 600,
    },
    "&>p": {
      fontWeight: 400,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: (props) => (props?.data?.image ? 10 : 0),
      marginLeft: 0,
    },
  },
  linkPreviewContainer: {
    backgroundColor: "rgba(40 ,167 ,69 , 0.15)",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 10,
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 8,
  },
}));

export default function LinkPreview({ data, handleClose }) {
  const classes = useStyle({ data });
  if (!data) return null;
  return (
    <Box className={classes.linkPreviewBox}>
      {Boolean(handleClose) && (
        <IconButton
          className={classes.closeIcon}
          onClick={handleClose}
          color="primary"
        >
          <CloseRounded />
        </IconButton>
      )}
      <Link
        href={data?.link}
        target="_blank"
        underline="none"
        className={classes.linkEl}
      >
        <Grid
          container
          alignItems="center"
          className={classes.linkPreviewContainer}
        >
          {Boolean(data?.image) && (
            <Grid item xs={12} sm={3}>
              <Box className={classes.linkPreviewImage}>
                <img src={data?.image} alt="link-preview-img" />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={Boolean(data?.image) ? 9 : 12}>
            <Box
              display={"flex"}
              flexDirection="column"
              className={classes.linkPreviewContent}
            >
              <Typography variant="h6">{data?.title}</Typography>
              <Typography variant="body2">{data?.description}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
}
