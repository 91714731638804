import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { followUnfollowUser } from "../../redux/actions/profileActions";
import { FollowIcon, UnfollowIcon } from "../../constants/svgIcons";
import { makeStyles, Box, CircularProgress, Tooltip } from "@material-ui/core";
import { AlertDialog } from "../Dialogs";

const useStyle = makeStyles((theme) => ({
  followUnfollowContainer: {
    "&>svg": {
      width: 20,
      height: 20,
      cursor: "pointer",
    },
  },
}));

export const FollowUnfollowUser = ({
  followUnfollowUser,
  followUnfollowUserData,
  user,
  userData,
}) => {
  const classes = useStyle();
  const [loadingId, setLoadingId] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const toggleFollowUnfollow = () => {
    setLoadingId(user?.id);
    followUnfollowUser({ follow_user_id: user?.id });
  };
  const handleClose = (value) => {
    setOpenConfirmationDialog(false);
    if (value) {
      toggleFollowUnfollow();
    }
  };
  useEffect(() => {
    if (
      !followUnfollowUserData?.loading &&
      followUnfollowUserData?.status === "1"
    ) {
      setLoadingId("");
    }
  }, [followUnfollowUserData]);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent="center"
      className={classes.followUnfollowContainer}
    >
      {user?.id !== userData?.data?.id &&
        (followUnfollowUserData?.loading && loadingId === user?.id ? (
          <CircularProgress size={20} />
        ) : user?.is_follow ? (
          <Tooltip title={`Remove ${user?.name} from your connection`} arrow>
            <UnfollowIcon onClick={() => setOpenConfirmationDialog(true)} />
          </Tooltip>
        ) : (
          <Tooltip title={`Connect with ${user?.name}`} arrow>
            <FollowIcon onClick={toggleFollowUnfollow} />
          </Tooltip>
        ))}
      <AlertDialog
        open={openConfirmationDialog}
        handleClose={handleClose}
        heading="Remove Connection"
        content={`Are you sure you want to remove ${user?.name} from your connections?`}
        cancelBtnText="Cancel"
        actionBtnText="Remove"
      />
    </Box>
  );
};

const mapStateToProps = ({
  ProfileReducers: { followUnfollowUserData, userData },
}) => ({
  followUnfollowUserData,
  userData,
});

const mapDispatchToProps = {
  followUnfollowUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUnfollowUser);
