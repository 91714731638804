import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MapboxGL, MapboxLeftSide } from "../../components/Products";
import { connect } from "react-redux";
import { dispatchUpdateProductCurrentTab } from "../../redux/actions";
import SmFooterTabs from "../../components/Products/OriginComponents/SmFooterTabs";
import OriginHeader from "../../components/Products/OriginComponents/OriginHeader";
import ManageProductLeftside from "../../components/Products/OriginComponents/ManageProductLeftside";
import { useTheme, useMediaQuery } from "@material-ui/core";

const ProductDetailWithMap = ({
  exhibitsList,
  integrantInfo,
  exhibitsInfo,
  sourceLinks,
  productUrlDetail,
  buyUrl,
  blockchainProof,
  urlParams,
  dispatchUpdateProductCurrentTab,
  productCurrentTab,
}) => {
  const [pointDetails, setPointDetails] = useState(null);
  const [showLeftSide, setShowLeftSide] = useState(false);
  const [replayLineAnimation, setReplayLineAnimation] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    organization_name: "",
    organization_id: "",
    organization_logo_url: null,
  });
  const { short_code, external_id } = productUrlDetail;
  const rightNav = {
    productUrl: `/p/${short_code}/b/${external_id}`,
  };
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(769));

  useEffect(() => {
    if (exhibitsList?.length !== 0) {
      const { organization_id, organization_name, organization_logo_url } =
        exhibitsList[0];
      setOrganizationData({
        organization_name,
        organization_id,
        organization_logo_url,
      });
    }
  }, [exhibitsList]);

  const updateOrganizationData = (data) => {
    if (data) {
      setOrganizationData(data);
    } else {
      if (exhibitsList?.length !== 0) {
        const { organization_id, organization_name, organization_logo_url } =
          exhibitsList[0];
        setOrganizationData({
          organization_name,
          organization_id,
          organization_logo_url,
        });
      }
    }
  };

  if (buyUrl !== "") {
    rightNav["buyUrl"] = buyUrl;
  }

  if (Object.keys(blockchainProof).length !== 0 && blockchainProof.result) {
    rightNav["blockchainUrl"] = `/p/${short_code}/b/${external_id}/${
      typeof urlParams.document === "undefined"
        ? "integrant"
        : typeof urlParams.documentID === "undefined"
        ? urlParams.document
        : urlParams.documentID
    }/blockchain-proof`;
  }

  const handleShowPointDetail = (title, data) => {
    setPointDetails({ title, data });

    let div = document.getElementsByClassName("sidebar-options");
    div[0].classList.add("open");
  };
  const hidePointDetails = (type) => {
    if (type === "showProduct" && !showLeftSide) {
      setShowLeftSide(true);
    }
    let div = document.getElementsByClassName("sidebar-options");
    div[0].classList.remove("open");
    setPointDetails(null);
  };

  const [activeSmFooterTab, setActiveSmFooterTab] = useState(2);

  if (exhibitsList?.length !== 0) {
    return (
      <div className="product-attachments">
        <OriginHeader
          rightNav={rightNav}
          integrantInfo={integrantInfo}
          organizationData={organizationData}
          setReplayLineAnimation={setReplayLineAnimation}
          activeSmFooterTab={activeSmFooterTab}
          match={match}
        />
        <div className="map-container detail-page-wrapper">
          <ManageProductLeftside
            activeSmFooterTab={activeSmFooterTab}
            exhibitsList={exhibitsList}
            integrantInfo={integrantInfo}
            exhibitsInfo={exhibitsInfo}
            blockchainProof={blockchainProof}
            sourceLinks={sourceLinks}
            productUrlDetail={productUrlDetail}
            setActiveSmFooterTab={setActiveSmFooterTab}
          />
          <MapboxLeftSide
            exhibitsList={exhibitsList}
            integrantInfo={integrantInfo}
            exhibitsInfo={exhibitsInfo}
            sourceLinks={sourceLinks}
            pointDetails={pointDetails}
            showLeftSide={showLeftSide}
            blockchainProof={blockchainProof}
            pIndex={0}
            hidePointDetails={hidePointDetails}
            productCurrentTab={productCurrentTab}
            dispatchUpdateProductCurrentTab={dispatchUpdateProductCurrentTab}
          />
          <MapboxGL
            productCurrentTab={productCurrentTab}
            handleShowPointDetail={handleShowPointDetail}
            hidePointDetails={hidePointDetails}
            replayLineAnimation={replayLineAnimation}
            setReplayLineAnimation={setReplayLineAnimation}
            updateOrganizationData={updateOrganizationData}
            dispatchUpdateProductCurrentTab={dispatchUpdateProductCurrentTab}
          />
          <SmFooterTabs
            activeSmFooterTab={activeSmFooterTab}
            setActiveSmFooterTab={setActiveSmFooterTab}
          />
        </div>
      </div>
    );
  }

  return <div className="product-attachments _p10">No Data Found</div>;
};

const mapStateToProps = ({ productReducers: { productCurrentTab } }) => ({
  productCurrentTab,
});

const mapDispatchToProps = {
  dispatchUpdateProductCurrentTab,
};

ProductDetailWithMap.propTypes = {
  exhibitsList: PropTypes.instanceOf(Object),
};
ProductDetailWithMap.defaultProps = {
  exhibitsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailWithMap);
