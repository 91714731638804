import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BuyIcon } from "../../../constants/svg/icon-buy.svg";
import { ReactComponent as HomeIcon } from "../../../constants/svg/icon-home.svg";
import { ReactComponent as ReplayIcon } from "../../../constants/svg/icon-replay.svg";
import { ReactComponent as BlockchainProofIcon } from "../../../constants/svg/blockchain-proof.svg";
import { dispatchBlockchainProof } from "../../../redux/actions";
import { connect } from "react-redux";
import IntegrantBlockchainProofDialog from "../../Dialogs/IntegrantBlockchainProofDialog";
import "./style.scss";

function OriginHeader({
  organizationData,
  rightNav,
  integrantInfo,
  dispatchBlockchainProof,
  setReplayLineAnimation,
  activeSmFooterTab,
  match,
}) {
  const handleIntegrantBlockchainProof = () => {
    dispatchBlockchainProof({
      id: integrantInfo?.id,
      type: "integrant",
      showCustomLoader: true,
    });
  };
  return (
    <div className="title-attachments">
      <div className="d-flex align-items-center">
        <div className="icon-attachments">
          <img src={organizationData?.organization_logo_url} alt="" />
        </div>
        <Link
          to={"/products/" + organizationData?.organization_id}
          className="text-capitalize"
          target="_blank"
        >
          {organizationData?.organization_name}
        </Link>
      </div>
      <div className="center">
        {(!match || (match && activeSmFooterTab === 2)) && (
          <button
            type="button"
            onClick={() => setReplayLineAnimation(true)}
            className="icon-location"
            title="Replay"
          >
            <ReplayIcon />
          </button>
        )}
        <Link to={rightNav?.productUrl} style={{ marginLeft: 20 }}>
          <button type="button" className="icon-location">
            <HomeIcon />
          </button>
        </Link>
      </div>
      <div className="bottom-btns">
        {rightNav?.buyUrl && (
          <a
            target={"_blank"}
            href={rightNav?.buyUrl}
            rel="noreferrer"
            className="btn button primary-btn buy-btn"
          >
            <BuyIcon />
            <span>Buy</span>
          </a>
        )}
        {rightNav?.blockchainUrl && (
          <button
            className="btn button primary-btn  blockchain-btn"
            onClick={handleIntegrantBlockchainProof}
            data-toggle="modal"
            data-target="#IntegrantBlockchainProofDialog"
          >
            <BlockchainProofIcon />
            <span>Blockchain Proof</span>
          </button>
        )}
      </div>
      <IntegrantBlockchainProofDialog integrant_id={integrantInfo?.id} />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  dispatchBlockchainProof,
};

export default connect(mapStateToProps, mapDispatchToProps)(OriginHeader);
