import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppImages } from "../../constants";
import {
  dispatchVerifyDocument,
  dispatchBlockchainProof,
} from "../../redux/actions";
import { CircularProgress, IntegrantBlockchainProof, Label } from "../Common";
import LoadingTextWithCircularProgress from "../Common/LoadingTextWithCircularProgress";

const {
  icon: { uploadIcon, correctIcon, wrongIcon },
} = AppImages;

let verfiyBlockchainProofFlag = false;
let verifyDocumentAPIFlag = false;

function DocumentToBeVerifiedDialog({
  organizationList,
  organizationListLoading,
  verifyDocument,
  verifyDocumentData,
  getBlockchainProof,
  blockchainProof_loading,
  blockchainProof,
}) {
  const [producerValue, setProducerValue] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [blockchainVerifyMsg, setBlockchainVerifyMsg] = useState({
    type: "",
    msg: "",
  });
  const [blockchainData, setBlockchainData] = useState(null);

  const handleProducerOnChange = (e) => {
    if (e.target.value) {
      setProducerValue(e.target.value);
      if (blockchainVerifyMsg?.type === "producer_error") {
        setBlockchainVerifyMsg({ type: "", msg: "" });
      }
    }
  };

  const handleVerify = () => {
    if (!uploadedFile) {
      setBlockchainVerifyMsg({
        type: "upload_file_error",
        msg: "Please upload file for verify.",
      });
    } else if (!producerValue) {
      setBlockchainVerifyMsg({
        type: "producer_error",
        msg: "Please select producer for verify blockchain proof.",
      });
    } else {
      const formData = new FormData();
      formData.append("id", null);
      formData.append("organization_id", producerValue);
      formData.append("type", "integrant");
      formData.append("files[]", uploadedFile);
      verifyDocument(formData);
      verifyDocumentAPIFlag = true;
    }
  };

  const handleClose = () => {
    setBlockchainVerifyMsg({ type: "", msg: "" });
    setUploadedFile(null);
    setProducerValue("");
    setBlockchainData(null);
  };

  useEffect(() => {
    if (
      verifyDocumentAPIFlag &&
      !verifyDocumentData?.loading &&
      verifyDocumentData?.data
    ) {
      if (verifyDocumentData?.data?.isVerifyDocument) {
        setBlockchainVerifyMsg({
          type: "success",
          msg: "Verified match on the blockchain.",
        });
        getBlockchainProof({
          id: verifyDocumentData?.data?.integrantId,
          type: "integrant",
          showCustomLoader: true,
        });
        verfiyBlockchainProofFlag = true;
      } else {
        setBlockchainVerifyMsg({
          type: "error",
          msg: "Not a verified match on the blockchain.",
        });
        setBlockchainData(null);
      }
      setTimeout(() => setBlockchainVerifyMsg({ type: "", msg: "" }), 8000);
      verifyDocumentAPIFlag = false;
    }
  }, [verifyDocumentData]);

  useEffect(() => {
    if (
      verfiyBlockchainProofFlag &&
      Object.keys(blockchainProof).length !== 0
    ) {
      verfiyBlockchainProofFlag = false;
      setBlockchainData(blockchainProof);
    }
  }, [blockchainProof]);

  return (
    <div
      className={`modal fade bd-example-modal-lg`}
      id="headerProductBlockchainProofModel"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="headerProductBlockchainProofModelLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="headerProductBlockchainProofModelLabel"
            >
              Verify Blockchain Proof
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="headerBlockchainVerifyProofBox mb20">
              <div className="select-box-div">
                <Label required children={"Select Producer"} />
                <div className="flex-start">
                  <select
                    className="select-box"
                    value={producerValue}
                    onChange={handleProducerOnChange}
                  >
                    <option>Select Producer</option>
                    {organizationList?.map((item, key) => {
                      return (
                        <option key={key} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  {organizationListLoading && (
                    <CircularProgress
                      className="loaderDiv"
                      borderColor="#53da00"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="headerBlockchainVerifyProofBox mb20 upload-file-div">
              <input
                type="file"
                id="headerPBPVInputFile"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setUploadedFile(e.target.files[0]);
                    setBlockchainVerifyMsg({ type: "", msg: "" });
                  }
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
                style={{ display: "none" }}
              />
              <label htmlFor="headerPBPVInputFile">
                <div className="upload-btn">
                  <img src={uploadIcon} alt="Upload Icon" />
                  Upload
                </div>
              </label>
              {uploadedFile && (
                <p className="uploadedImageName">{uploadedFile?.name}</p>
              )}
            </div>
            {blockchainVerifyMsg?.msg && (
              <div className="headerBlockchainVerifyProofBox mb20">
                {["error", "success"].includes(blockchainVerifyMsg?.type) && (
                  <img
                    src={
                      blockchainVerifyMsg?.type === "error"
                        ? wrongIcon
                        : correctIcon
                    }
                    alt={`${blockchainVerifyMsg?.type} Icon`}
                    className="msgIcon"
                  />
                )}
                <p
                  className={
                    blockchainVerifyMsg?.type === "success"
                      ? "blockchainVerifySuccessMsg"
                      : "blockchainVerifyErrorMsg"
                  }
                >
                  {blockchainVerifyMsg?.msg}
                </p>
              </div>
            )}
            <div className="headerBlockchainVerifyProofBox mb20">
              <button className="upload-btn" onClick={handleVerify}>
                Verify
                {verifyDocumentData?.loading && <CircularProgress />}
              </button>
            </div>
            {blockchainProof_loading ? (
              <LoadingTextWithCircularProgress text="Please wait while we are fetching the product detail..." />
            ) : (
              blockchainData && (
                <IntegrantBlockchainProof
                  className="mb20"
                  blockchainData={blockchainData}
                />
              )
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary model-close-button center"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  productReducers: {
    organizationList,
    organizationListLoading,
    verifyDocumentData,
    blockchainProof_loading,
    blockchainProof,
  },
}) => ({
  organizationList,
  organizationListLoading,
  verifyDocumentData,
  blockchainProof_loading,
  blockchainProof,
});

const mapDispatchToProps = {
  verifyDocument: dispatchVerifyDocument,
  getBlockchainProof: dispatchBlockchainProof,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentToBeVerifiedDialog);
