import React, { useEffect } from "react";
import { profileLog } from "../../constants/TourConfig";
import { connect } from "react-redux";
import { resetTour, updateTourActiveStep } from "../../redux/actions/common";
import { updateUser, getUserDetails } from "../../redux/actions/profileActions";
import DefaultTour, { allRegisteredTour } from "./DefaultTour";
import { useHistory } from "react-router-dom";

let updateUserApiFlag = false;

function ProfileTour(props) {
  const {
    showTour,
    resetTour,
    updateUser,
    userData,
    updateUserData,
    getUserDetails,
    updateTourActiveStep,
  } = props;
  const history = useHistory();
  const closeTour = () => {
    resetTour("");
    updateTourActiveStep(null);
    let formData = new FormData();
    formData.append("user_id", userData?.data?.id);
    formData.append("show_profile_tour", false);
    updateUser(formData, {
      hideNotification: true,
    });
    updateUserApiFlag = true;
  };
  const handleStepChange = (step) => {
    step += 1;
    updateTourActiveStep(step);
    if (step === 1) history.push("/profile");
    if (step === 2) history.push("/connections");
    if (step === 3) history.push("/feedback");
    if (step === 4) history.push("/profile-details");
    if ([5, 6, 7].includes(step)) history.push("/invite");
    if (step === 8) history.push("/survey-history");
  };

  useEffect(() => {
    if (updateUserData?.status === "1" && updateUserApiFlag) {
      updateUserApiFlag = false;
      getUserDetails();
    }
  }, [updateUserData]);
  return (
    <DefaultTour
      steps={profileLog}
      isOpen={showTour === allRegisteredTour?.profile?.key}
      getCurrentStep={handleStepChange}
      // onBeforeClose={(...all) => {}}
      onRequestClose={closeTour}
    />
  );
}
const mapStateToProps = ({
  CommonReducers: { showTour },
  ProfileReducers: { userData, updateUserData },
}) => ({
  showTour,
  userData,
  updateUserData,
});
const mapDispatchToProps = {
  resetTour,
  updateUser,
  getUserDetails,
  updateTourActiveStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTour);
