import { createTheme } from "../components";

export const theme = () => {
  let themeObj = createTheme({
    palette: {
      primary: {
        main: "#28A745",
      },
      secondary: {
        main: "#EAF7ED",
      },
      text: {
        primary: "#444444",
        secondary: "#707070",
      },
      error: {
        main: "#D6513F",
      },
      borderColor: "rgba(68, 68, 68, 0.5)",
      disableColor: "#eee",
    },
  });
  themeObj.typography = {
    pxToRem: (size) => `${size / 16}rem`,
    fontFamily: "DM Sans, sans-serif",
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    button: {
      fontSize: 16,
    },
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 48,
      lineHeight: "62px",
      [themeObj.breakpoints.down("sm")]: {
        fontSize: 32,
        lineHeight: "42px",
      },
      [themeObj.breakpoints.down("xs")]: {
        fontSize: 26,
        lineHeight: "30px",
      },
    },
    h3: {
      fontSize: 32,
      lineHeight: "42px",
      [themeObj.breakpoints.down("sm")]: {
        fontSize: 26,
        lineHeight: "30px",
      },
      [themeObj.breakpoints.down("xs")]: {
        fontSize: 18,
        lineHeight: "23px",
      },
    },
    h4: {
      fontSize: 30,
      lineHeight: "39px",
      [themeObj.breakpoints.down("sm")]: {
        fontSize: 26,
        lineHeight: "30px",
      },
      [themeObj.breakpoints.down("xs")]: {
        fontSize: 18,
        lineHeight: "23px",
      },
    },
    h5: {
      fontSize: 26,
      lineHeight: "30px",
      [themeObj.breakpoints.down("sm")]: {
        fontSize: 18,
        lineHeight: "23px",
      },
    },
    h6: {
      fontSize: 18,
      lineHeight: "23px",
      [themeObj.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
  };
  themeObj.overrides = {
    MuiTypography: {
      root: {
        color: themeObj.palette.text.primary,
        fontFamily: themeObj.typography.fontFamily,
      },
    },
    MuiButton: {
      root: {
        color: themeObj.palette.common.white,
        background: themeObj.palette.primary.main,
        fontWeight: themeObj.typography.fontWeightBold,
        lineHeight: "140%",
        letterSpacing: "0.2px",
        textTransform: "uppercase",
        borderRadius: "34px",
        border: `1px solid ${themeObj.palette.primary.main}`,
        "&:hover": {
          background: themeObj.palette.common.white,
          color: themeObj.palette.primary.main,
        },
      },
      disabled: {
        background: themeObj.palette.disableColor,
        border: `1px solid ${themeObj.palette.disableColor}`,
      },
      text: {
        padding: "10px 20px",
      },
    },
    MuiIconButton: {
      root: {
        color: themeObj.palette.common.white,
        background: themeObj.palette.primary.main,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: themeObj.typography.fontWeightLight,
        fontFamily: themeObj.typography.fontFamily,
        color: themeObj.palette.text.primary,
      },
      asterisk: {
        color: themeObj.palette.error.main,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 18,
        lineHeight: "23px",
        fontWeight: themeObj.typography.fontWeightLight,
        fontFamily: themeObj.typography.fontFamily,
        color: themeObj.palette.text.primary,
      },
    },
    MuiSelect: {
      root: {
        fontSize: 18,
        lineHeight: "23px",
        fontWeight: themeObj.typography.fontWeightLight,
        fontFamily: themeObj.typography.fontFamily,
        color: themeObj.palette.text.primary,
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: 1301,
      },
    },
    MuiTextField: {
      root: {
        "&>div": {
          "&>input,&>textarea": {
            fontFamily: themeObj.typography.fontFamily,
            fontSize: 18,
            lineHeight: "23px",
            color: themeObj.palette.text.primary,
            fontWeight: themeObj.typography.fontWeightLight,
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: themeObj.palette.text.primary,
        "&:hover": {
          color: themeObj.palette.primary.main,
        },
      },
    },
    MuiLink: {
      root: {
        color: themeObj.palette.text.primary,
        "&:hover": {
          color: themeObj.palette.primary.main,
        },
      },
    },
  };
  return themeObj;
};
