import { Button, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  secondaryBtn: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    marginRight: 15,
    "&:hover": {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
  },
}));
export default function SecondaryButton({
  btnLable = "Learn More",
  handleOnClick = () => {},
}) {
  const classes = useStyle();
  return (
    <Button onClick={handleOnClick} className={classes.secondaryBtn}>
      {btnLable}
    </Button>
  );
}
