import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getDocumentHashBlockchainProof,
  setInitialBlockchainProofStates,
  getExhibitBlockchainProof,
} from "../../redux/actions";
import { CircularProgress, Typography, Box } from "..";
import { IntegrantBlockchainProof } from "../Common";
import NewIntegrantBlockChinProof from "../Common/NewIntegrantBlockChinProof";

function BlockchainProofDialog({
  exhibitId,
  setExhibitId,
  exhibitBlockchainProof,
  documentHashBlockchainProof,
  getDocumentHashBlockchainProof,
  setInitialBlockchainProofStates,
  getExhibitBlockchainProof,
}) {
  const fetchData = async (id, type) => {
    if (type === "exhibit") {
      getExhibitBlockchainProof({
        id,
        type,
      });
    }
    if (type === "document_hash") {
      getDocumentHashBlockchainProof({
        id,
        type,
      });
    }
  };
  useEffect(() => {
    setInitialBlockchainProofStates();
  }, []);
  useEffect(() => {
    if (exhibitId) {
      setInitialBlockchainProofStates();
      fetchData(exhibitId, "exhibit");
      setExhibitId("");
    }
  }, [exhibitId]);
  useEffect(() => {
    if (
      !exhibitBlockchainProof?.isLoading &&
      exhibitBlockchainProof?.documentHashId
    ) {
      fetchData(exhibitBlockchainProof?.documentHashId, "document_hash");
    }
  }, [exhibitBlockchainProof]);
  return (
    <div
      className={`modal fade bd-example-modal-xl blockchainProofModel`}
      id="productBlockchainProofDialog"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="productBlockchainProofModelLabel"
      aria-hidden="true"
      style={{ zIndex: 1100 }}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="productBlockchainProofModelLabel">
              Document blockchain proof
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Box className="blockchain-proof-ul">
              {exhibitBlockchainProof?.isLoading && (
                <Typography variant="body2">
                  Please wait while we are fetching the document detail...
                  <CircularProgress size={20} />
                </Typography>
              )}
              {!exhibitBlockchainProof?.isLoading &&
                typeof exhibitBlockchainProof?.result === "boolean" && (
                  <NewIntegrantBlockChinProof
                    blockchainData={exhibitBlockchainProof}
                  />
                )}
              {documentHashBlockchainProof?.isLoading &&
                !exhibitBlockchainProof?.isLoading && (
                  <Typography variant="body1">
                    Please wait while we are fetching the document detail...
                    <CircularProgress size={20} />
                  </Typography>
                )}
              {!documentHashBlockchainProof?.isLoading &&
                typeof documentHashBlockchainProof?.result === "boolean" && (
                  <NewIntegrantBlockChinProof
                    blockchainData={documentHashBlockchainProof}
                  />
                )}
            </Box>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary model-close-button center"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  productReducers: { exhibitBlockchainProof, documentHashBlockchainProof },
}) => ({
  exhibitBlockchainProof,
  documentHashBlockchainProof,
});
const mapDispatchToProps = {
  getDocumentHashBlockchainProof,
  setInitialBlockchainProofStates,
  getExhibitBlockchainProof,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockchainProofDialog);
