export const PRODUCT_FEEDBACK_START = "PRODUCT_FEEDBACK_START";
export const PRODUCT_FEEDBACK_SUCCESS = "PRODUCT_FEEDBACK_SUCCESS";
export const PRODUCT_FEEDBACK_FAIL = "PRODUCT_FEEDBACK_FAIL";
export const PROFILE_SIGNUP_START = "PROFILE_SIGNUP_START";
export const PROFILE_SIGNUP_SUCCESS = "PROFILE_SIGNUP_SUCCESS";
export const PROFILE_SIGNUP_FAIL = "PROFILE_SIGNUP_FAIL";
export const PROFILE_LOGIN_START = "PROFILE_LOGIN_START";
export const PROFILE_LOGIN_SUCCESS = "PROFILE_LOGIN_SUCCESS";
export const PROFILE_LOGIN_FAIL = "PROFILE_LOGIN_FAIL";
export const GET_CATEGORIES_START = "GET_CATEGORIES_START";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_ALL_USER_START = "GET_ALL_USER_START";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const GET_SURVEY_QUESTIONS_START = "GET_SURVEY_QUESTIONS_START";
export const GET_SURVEY_QUESTIONS_SUCCESS = "GET_SURVEY_QUESTIONS_SUCCESS";
export const GET_SURVEY_QUESTIONS_FAIL = "GET_SURVEY_QUESTIONS_FAIL";
export const SUBMIT_SURVEY_ANSWERS_START = "SUBMIT_SURVEY_ANSWERS_START";
export const SUBMIT_SURVEY_ANSWERS_SUCCESS = "SUBMIT_SURVEY_ANSWERS_SUCCESS";
export const SUBMIT_SURVEY_ANSWERS_FAIL = "SUBMIT_SURVEY_ANSWERS_FAIL";
export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const UPDATE_PROFILE_PICTURE_START = "UPDATE_PROFILE_PICTURE_START";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";
export const GET_USER_SURVEY_HISTORY_START = "GET_USER_SURVEY_HISTORY_START";
export const GET_USER_SURVEY_HISTORY_SUCCESS =
  "GET_USER_SURVEY_HISTORY_SUCCESS";
export const INVITE_ORGANIZATION_START = "INVITE_ORGANIZATION_START";
export const INVITE_ORGANIZATION_SUCCESS = "INVITE_ORGANIZATION_SUCCESS";
export const INVITE_ORGANIZATION_FAIL = "INVITE_ORGANIZATION_FAIL";
export const GET_INVITE_ORGANIZATION_HISTORY_START =
  "GET_INVITE_ORGANIZATION_HISTORY_START";
export const GET_INVITE_ORGANIZATION_HISTORY_SUCCESS =
  "GET_INVITE_ORGANIZATION_HISTORY_SUCCESS";
export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESEND_OTP_START = "RESEND_OTP_START";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const FOLLOW_UNFOLLOW_USER_START = "FOLLOW_UNFOLLOW_USER_START";
export const FOLLOW_UNFOLLOW_USER_SUCCESS = "FOLLOW_UNFOLLOW_USER_SUCCESS";
export const SOCKET_FOLLOW_UNFOLLOW_USER = "SOCKET_FOLLOW_UNFOLLOW_USER";
export const GET_USER_CONNECTIONS_START = "GET_USER_CONNECTIONS_START";
export const GET_USER_CONNECTIONS_SUCCESS = "GET_USER_CONNECTIONS_SUCCESS";
export const INVITE_USER_START = "INVITE_USER_START";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const GET_USER_NOTIFICATIONS_START = "GET_USER_NOTIFICATIONS_START";
export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
export const MARK_AS_READ_USER_NOTIFICATIONS_START =
  "MARK_AS_READ_USER_NOTIFICATIONS_START";
export const MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS =
  "MARK_AS_READ_USER_NOTIFICATIONS_SUCCESS";
export const SOCKET_NEW_USER_NOTIFICATION = "SOCKET_NEW_USER_NOTIFICATION";
