import React from "react";
import { Grid, makeStyles, Typography } from "../";
import { AppImages } from "../../constants";

const {
  asSeenInImg: {
    asSeenInImg01,
    asSeenInImg02,
    asSeenInImg03,
    asSeenInImg04,
    asSeenInImg07,
  },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  asSeenInContainer: {
    backgroundColor: theme.palette.secondary.main,
    margin: "0 auto",
    padding: "70px 0 40px",
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  asSeenInHeading: {
    marginBottom: 35,
    "&>h2": {
      textAlign: "center",
      "&>span": {
        color: theme.palette.primary.main,
      },
    },
  },
  asSeenInLogos: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "wrap",
    "&>div": {
      marginBottom: 30,
      borderRadius: 5,
      backgroundColor: theme.palette.common.white,
      border: "1px solid #44444480",
      padding: 10,
      width: 270,
      height: 100,
      "&>img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      "&:not(:last-child)": {
        marginRight: 30,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    asSeenInContainer: {
      padding: "50px 0 20px",
    },
    asSeenInHeading: {
      marginBottom: 20,
    },
    asSeenInLogos: {
      justifyContent: "center",
      "&>div": {
        "&:last-child": {
          marginRight: 30,
        },
      },
    },
  },
  [theme.breakpoints.down("xs")]: {
    asSeenInContainer: {
      padding: "40px 0 10px",
    },
    asSeenInLogos: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "&>div": {
        marginRight: "0px !important",
      },
    },
  },
  [theme.breakpoints.down(400)]: {
    asSeenInContainer: {
      padding: "30px 0 0px",
    },
  },
}));

export default function AsSeenIn() {
  const classes = useStyle();
  return (
    <Grid container className={classes.asSeenInContainer}>
      <div className="bodyMaxWidth">
        <Grid item className={classes.asSeenInHeading}>
          <Typography variant="h2" bold>
            As <span>Seen In</span>
          </Typography>
        </Grid>
        <Grid item className={classes.asSeenInLogos}>
          <div>
            <img src={asSeenInImg01} alt="" />
          </div>
          <div>
            <img src={asSeenInImg02} alt="" />
          </div>
          <div>
            <img src={asSeenInImg03} alt="" />
          </div>
          <div>
            <img src={asSeenInImg04} alt="" />
          </div>
          <div>
            <img src={asSeenInImg07} alt="" />
          </div>
        </Grid>
      </div>
    </Grid>
  );
}
