import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import { SectionWithTreePlan } from "../Common/SectionWrappers";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function HealthLoqSection() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>HealthLOQ</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            HealthLOQ allows an individual or organization to verify product
            documentation (e.g. certificate of analysis or "COA"), including the
            label. With HealthLOQ's verification capability, you can either
            manually or automatically verify the legitimacy of a document. You
            can even verify the legitimate of the organization that issued the
            GMP or ISO certification to the lab or manufacturer. And with our
            "Product Origin" solution, you can tie these verified document to
            specific products. and that the certifying organization itself is in
            fact been accredited by a known standards body. Manual, single
            document verification can happen with the click of a button. A
            retailer can automate the document verification process with a very
            simple download of the HealthLOQ verification dashboard which can be
            implemented in just a few hours. Retailers don't have to manually
            validate - it happens automatically!
          </Typography>
          <Box className={classes.btns}>
            <Button href="/contact-us">Contact Us</Button>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
