import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import "./style.scss"

const Loader = ({ loaderVisible }) => {
  if (loaderVisible) {
    return <div className="transparent-bg"><div className="loader" /></div>
  }
  return <></>
}

Loader.propTypes = {
  loaderVisible: PropTypes.bool
}

Loader.defaultProps = {
  loaderVisible: false
}

const mapStateToProps = (state) => ({
  loaderVisible: state.loaderReducer
})

Loader.displayName = "Loader"
export default connect(mapStateToProps)(Loader)
