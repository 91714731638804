import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import appImages from "../../constants/AppImage";
import { PrimaryIconFrame } from "../Common/IconFrames";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&>p:not(:nth-child(2))": {
      marginBottom: 15,
    },
    "&>p": {
      color: "#707070",
      "&>span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& a": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& a": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  contentBox: {
    width: "100%",
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    "&>div": {
      marginRight: 15,
      "&>div": {
        fontSize: 30,
      },
    },
  },
  darkWord: {
    fontWeight: "700",
    color: "#000",
  },
}));

const {
  images: { labelSection1Img },
} = appImages;

export default function ProductSection1() {
  const classes = useStyle();
  const content = [
    <>How do consumers know what ingredients are in products? </>,
    <>Where are the ingredients from? </>,
    <>How can claims made by a brand be verified? </>,
    <>
      How can a consumer get access to independent lab testing results for both
      products and ingredients?
    </>,
  ];
  return (
    <SectionWithTreePlan position="right" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={labelSection1Img} alt="Image" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Grid container spacing={3}>
              {content?.map((item, key) => {
                return (
                  <Grid item style={{ width: "100%" }} key={key}>
                    <Box className={classes.contentBox}>
                      <PrimaryIconFrame>{key + 1}</PrimaryIconFrame>
                      <Typography variant="body2">{item}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box className={classes.btns}>
              <Button href="/products?product_type=all">
                Verify Product Origin
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
