import clsx from "clsx";
import React from "react";
import { Grid, Typography, makeStyles, Link } from "../";

const useStyle = makeStyles((theme) => ({
  productBasicInfo: {
    "&>*": {
      textAlign: "center",
    },
    "&>h2": {
      margin: "30px 0 0",
      fontSize: 42,
      lineHeight: "50px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down(900)]: {
        whiteSpace: "unset",
        textOverflow: "unset",
      },
      [theme.breakpoints.down(600)]: {
        margin: "20px 0 10px",
        fontSize: 38,
        lineHeight: "45px",
      },
    },
    "&>p": {
      "&:nth-child(2)": {
        marginBottom: 15,
        letterSpacing: "2px",
        position: "relative",
        "&::before": {
          position: "absolute",
          content: "''",
          top: "50%",
          left: "50%",
          transform: "translate(-50% , -50%)",
          maxWidth: "50%",
          width: "100%",
          height: 1,
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.down("xs")]: {
            maxWidth: "80%",
          },
        },
        "&>span": {
          position: "relative",
          padding: "0 15px",
          backgroundColor: theme.palette.common.white,
          "&>a": {
            letterSpacing: "2px",
            textTransform: "capitalize",
            textDecoration: "underline",
          },
        },
      },
      "&:nth-child(3)": {
        marginBottom: 20,
        fontSize: 16,
        lineHeight: "24px",
        height: 48,
        [theme.breakpoints.down(900)]: {
          display: "none",
        },
      },
    },
  },
}));

export default function ProductBasicDetail({
  integrantsDetail: { integrantInfo },
}) {
  const classes = useStyle();
  return (
    <Grid container>
      <div className={clsx(classes.productBasicInfo, "bodyMaxWidth")}>
        <Typography variant="h2" className={"notranslate"} bold>
          {integrantInfo?.title}
        </Typography>
        <Typography variant="body2" light>
          <span>
            Produced by&nbsp;
            <Link to={`/organization-detail/${integrantInfo?.organization_id}`}>
              {integrantInfo?.organizations?.name}
            </Link>
          </span>
        </Typography>
      </div>
    </Grid>
  );
}
