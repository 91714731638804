import React from "react";
import { AppImages } from "../../constants";

const {
  homePageImg: {
    featuredCompanies03,
    featuredCompanies04,
    featuredCompanies05,
    featuredCompanies06,
    featuredCompanies07,
    featuredCompanies08,
  },
} = AppImages;

const Logos = () => (
  <div className="container-certified-partners">
    <p className="title text-center">
      HealthLOQ
      <span className="text-healthLoqc"> Certified Partners</span>
      <span className="text-transparent-companies">
        Transparent Companies you can Trust
      </span>
    </p>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="certified-partners text-center">
            <p className="box-certified-partners-title">Silver Tier</p>
            <p className="box-certified-partners-des">
              Evidence provided directly to the blockchain by an outside, third
              party
            </p>
            <div className="bggImgDiv">
              <img src={featuredCompanies04} alt="" className="img-fluid" />
              <img src={featuredCompanies05} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="certified-partners text-center">
            <p className="box-certified-partners-title">Bronze Tier</p>
            <p className="box-certified-partners-des">
              Evidence provided to the blockchain of quality and/or
              sustainability for every batch
            </p>
            <div className="bggImgDiv">
              <img
                src={featuredCompanies03}
                alt=""
                className="img-fluid"
                style={{ filter: "brightness(0) invert(0.5)" }}
              />
              <img src={featuredCompanies06} alt="" className="img-fluid" />
            </div>
            <div className="bggImgDiv">
              <img src={featuredCompanies07} alt="" className="img-fluid" />
              <img src={featuredCompanies08} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Logos;
