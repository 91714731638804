import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tooltip,
  IconButton,
  Skeleton,
} from "../";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { connect } from "react-redux";
import utils from "../../utils/commonUtils";
import Fancybox from "../Common/Fancybox";
import clsx from "clsx";
import { dispatchBlockchainProof } from "../../redux/actions/product";
import { BlockchainProofIcon } from "../../constants/svgIcons";
import IntegrantBlockchainProofDialog from "../Dialogs/IntegrantBlockchainProofDialog";

const useStyle = makeStyles((theme) => ({
  orgLogoImg: {
    "&>div": {
      margin: "0 auto",
      width: 200,
      height: 200,
      borderRadius: 20,
      boxShadow: "0 0 10px rgba(0,0,0,0.15)",
      "&>img": {
        borderRadius: 20,
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      "&>span": {
        borderRadius: 20,
      },
    },
  },
  orgContent: {
    marginBottom: 60,
    "&>h2": {
      marginBottom: 25,
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "&>div": {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 5,
      padding: "5px 10px",
      marginBottom: 20,
      "&>p": {
        "&>span": {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    "&>p": {},
    "&>span": {
      "&:nth-child(2)": {
        margin: "10px 0 15px",
      },
    },
  },
  tableHead: {
    "&>tr": {
      "&>th": {
        backgroundColor: "#D9D9D9",
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  orgDocuments: {
    marginBottom: 50,
  },
  table: {
    [theme.breakpoints.down("sm")]: {
      width: "max-content",
      "& td": {
        maxWidth: 500,
      },
    },
  },
  effectiveDate: {
    "&>span": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  tableActionBtns: {
    "&>*:not(:last-child)": {},
    "&>button": {
      "& svg": {
        width: 24,
        height: 24,
        fill: theme.palette.primary.main,
      },
    },
  },
}));

export function OrganizationBasicDetail({
  organizationDetail,
  dispatchBlockchainProof,
  blockchainProof_loading,
  blockchainProof,
}) {
  const [organizationExhibitId, setOrganizationExhibitId] = useState("");
  const classes = useStyle();
  const handleVerifyDocumentOnBlockchain = (id) => (e) => {
    setOrganizationExhibitId(id);
    dispatchBlockchainProof({
      id,
      type: "organization_exhibit",
      showCustomLoader: true,
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} className={classes.orgLogoImg}>
        <Box display="flex" alignItems={"center"} justifyContent="center">
          {organizationDetail?.loading ? (
            <Skeleton
              variant="rect"
              animation="wave"
              width={200}
              height={200}
            />
          ) : (
            <img
              src={organizationDetail?.data?.logo_url}
              alt="organization-logo"
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={9} className={classes.orgContent}>
        {organizationDetail?.loading ? (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              width={"50%"}
              height={70}
            />
            <Skeleton
              variant="text"
              animation="wave"
              width={"80%"}
              height={40}
            />
            {[...Array(5)].map((el, key) => {
              return (
                <Skeleton
                  key={key}
                  variant="text"
                  animation="wave"
                  width={"100%"}
                  height={20}
                />
              );
            })}
          </>
        ) : (
          <>
            <Typography variant="h2">
              {organizationDetail?.data?.name}
            </Typography>
            <Box>
              <Typography variant="body2">
                <span>Tagline : </span>
                {organizationDetail?.data?.tag_line}
              </Typography>
            </Box>
            <Typography variant="body2">
              {organizationDetail?.data?.description}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12} className={classes.orgDocuments}>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            className={clsx(!organizationDetail?.loading && classes.table)}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationDetail?.data?.organizationExhibits?.map(
                (item, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{item?.title}</TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.effectiveDate}
                        >
                          <span>Effective Date:&nbsp;</span>
                          {moment(item?.effective_date).format("MM/DD/YYYY")}
                        </Typography>
                        {item?.description}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"center"}
                          className={classes.tableActionBtns}
                        >
                          <Tooltip arrow title="View Document">
                            <IconButton
                              color="primary"
                              data-extention={utils.getExtension(item?.url)}
                              data-gas_cert_url={item?.url}
                              id={"organization-exhibit-document-" + item?.id}
                              onClick={() => {
                                utils.handleDocumentViewClick(
                                  "organization-exhibit-document-" + item?.id
                                );
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Fancybox
                            className={
                              "organization-exhibit-document-" + item?.id
                            }
                          />
                          <Tooltip
                            arrow
                            title="Verify to check the document on the blockchain."
                          >
                            <IconButton
                              color="primary"
                              onClick={handleVerifyDocumentOnBlockchain(
                                item?.id
                              )}
                              data-toggle={"modal"}
                              data-target={"#IntegrantBlockchainProofDialog"}
                            >
                              <BlockchainProofIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
              {organizationDetail?.data?.organizationExhibits?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Data not found!
                  </TableCell>
                </TableRow>
              )}
              {organizationDetail?.loading && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Box
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      Loading...
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <IntegrantBlockchainProofDialog
        integrant_id={organizationExhibitId}
        type="organization_exhibit"
      />
    </Grid>
  );
}

const mapStateToProps = ({
  productReducers: {
    organizationDetail,
    blockchainProof_loading,
    blockchainProof,
  },
}) => ({
  organizationDetail,
  blockchainProof_loading,
  blockchainProof,
});

const mapDispatchToProps = {
  dispatchBlockchainProof,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationBasicDetail);
