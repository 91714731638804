import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

export function MuiDialog({
  children,
  className = "",
  onClose,
  open,
  fullScreen,
  fullWidth,
  maxWidth,
  ...props
}) {
  return (
    <Dialog
      className={clsx("dialog-root", className)}
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Dialog>
  );
}

export function MuiDialogTitle({
  className = "",
  children,
  onClose,
  ...props
}) {
  return (
    <Grid className={clsx("dialog-title", className)}>
      <DialogTitle {...props}>{children}</DialogTitle>
      <CloseRoundedIcon onClick={onClose} />
    </Grid>
  );
}

export function MuiDialogContent({ className = "", children, ...props }) {
  return (
    <DialogContent className={clsx("dialog-content", className)} {...props}>
      {children}
    </DialogContent>
  );
}

export function MuiDialogActions({ className = "", children, ...props }) {
  return (
    <DialogActions className={clsx("dialog-actions", className)} {...props}>
      {children}
    </DialogActions>
  );
}

export function MuiButton({
  className = "",
  children,
  onClick,
  secondaryBtn = false,
  ...props
}) {
  return (
    <Button
      className={clsx(
        "dialog-actions-buttons",
        secondaryBtn && "dialog-secondary-action-button",
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
