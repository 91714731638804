import React from "react"
import { Helmet } from "react-helmet-async"
import PropTypes from "prop-types"

const SEO = ({ title, description }) => (
    <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="HandheldFriendly" content="true" />
    </Helmet>
)

export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

SEO.defaultProps = {
  title: "",
  description: ""
}
