import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import CalendlyPage from "../Common/CalendlyPage";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

const {
  images: { docFolderForBulkVerification, bulkFilesIcon },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
  },
  content: {
    "&>p": {
      textAlign: "center",
    },
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  sectionImages: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px 0",
    padding: "0 10px",
    "&>img": {
      maxWidth: "calc(50% - 55px)",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
      "&:first-child": {
        width: "40%",
      },
    },
    "&>svg": {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "&>svg": {
        margin: "20px 0",
        transform: "rotate(90deg)",
      },
      "&>img": {
        maxWidth: "100%",
        "&:first-child": {
          width: "unset !important",
          minWidth: "50%",
        },
      },
    },
    [theme.breakpoints.down(768)]: {
      "&>img": {
        "&:first-child": {
          minWidth: "60%",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&>img": {
        "&:first-child": {
          minWidth: "70%",
        },
      },
    },
  },
  btns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

export default function BulkVerification() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3} className={classes.content}>
          <Box className={classes.heading}>
            <PrimaryHeading>Bulk Verification</PrimaryHeading>
          </Box>
          <Typography variant="body2">
            The system uses automated document verification technology to
            highlight any changes to documents from the original. So, for
            example, if the date on a COA is changed, the system will highlight
            the document and that change as invalid.
          </Typography>
          <Box className={classes.sectionImages}>
            <img src={bulkFilesIcon} alt="Image" />
            <ArrowForwardRoundedIcon />
            <img src={docFolderForBulkVerification} alt="Image" />
          </Box>
          <Box className={classes.btns}>
            <CalendlyPage>
              <Button>Request a Demo</Button>
            </CalendlyPage>
          </Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
