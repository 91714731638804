import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import allReducers from "../reducers"
// import { tracking } from "./AnalyticsData"

const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose

const middleware = [thunk]

const { NODE_ENV } = process.env

const store = () => {
    const isDevMode = NODE_ENV !== "production"
    if (isDevMode) {
        return createStore(
            allReducers,
            composeEnhancers(applyMiddleware(...middleware))
        )
    }
    return createStore(allReducers, applyMiddleware(...middleware))
}

export default store()
