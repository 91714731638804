import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { socket } from "../../configs/socket";
import {
  SOCKET_LIKE_DISLIKE,
  SOCKET_DELETE_POST,
  SOCKET_DELETE_POST_COMMENT,
  SOCKET_CREATE_POST,
  SOCKET_UPDATE_POST,
  SOCKET_SHARE_POST,
  SOCKET_POST_COMMENT,
  SOCKET_UPDATE_POST_COMMENT,
  SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST,
} from "../../redux/actionsType/post";
import {
  SOCKET_FOLLOW_UNFOLLOW_USER,
  SOCKET_NEW_USER_NOTIFICATION,
} from "../../redux/actionsType/profileActionType";

export default function Socket() {
  const dispatch = useDispatch();

  const handleDispatch = (type) => (payload) => {
    for (let item of typeof type === "object" ? type : [type]) {
      dispatch({
        type: item,
        payload,
      });
    }
  };
  useEffect(() => {
    socket.on("postCreate", handleDispatch(SOCKET_CREATE_POST));
    socket.on("postUpdate", handleDispatch(SOCKET_UPDATE_POST));
    socket.on("postShare", handleDispatch(SOCKET_SHARE_POST));
    socket.on("postDelete", handleDispatch(SOCKET_DELETE_POST));
    socket.on("likeDislike", handleDispatch(SOCKET_LIKE_DISLIKE));
    socket.on("createComment", handleDispatch(SOCKET_POST_COMMENT));
    socket.on("commentUpdate", handleDispatch(SOCKET_UPDATE_POST_COMMENT));
    socket.on("commentDelete", handleDispatch(SOCKET_DELETE_POST_COMMENT));
    socket.on(
      "userFollowORUnfollow",
      handleDispatch([
        SOCKET_FOLLOW_UNFOLLOW_USER,
        SOCKET_FOLLOW_UNFOLLOW_USER_IN_POST,
      ])
    );
    socket.on(
      "newUserNotification",
      handleDispatch(SOCKET_NEW_USER_NOTIFICATION)
    );
  }, []);
  return null;
}
