import React, { useEffect, useState, useRef } from "react";
import RealTimeAgo from "timeago-react";
import { connect } from "react-redux";
import * as timeago from "timeago.js";
import {
  Grid,
  makeStyles,
  Typography,
  Tooltip,
  Box,
  TextField,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import ShareIcon from "@material-ui/icons/ShareOutlined";
import clsx from "clsx";
import {
  postLikeDislike,
  postComment,
  getPostCommentList,
  deletePost,
  deleteComment,
} from "../../../redux/actions/post";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import PersonIcon from "@material-ui/icons/Person";
import SendIcon from "@material-ui/icons/Send";
import { CircularProgress, Link } from "../../";
import {
  AlertDialog,
  PostLikedUsersDialogList,
  UpdateCommentOrReplyDialog,
} from "../../Dialogs";
import { FollowUnfollowUser, MuiMenu, UserAvatar } from "../../Common";
import { PractitionerIcon, ReplyIcon } from "../../../constants/svgIcons";
import PostMediaSlider from "./PostMediaSlider";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import LinkPreview from "./LinkPreview";
import { fromNowTimeFormat } from "../../../utils/dateAndTimeUtils.js";
const nl2br = require("nl2br");
timeago.register("pt_BR", fromNowTimeFormat);

const useStyle = makeStyles((theme) => ({
  AvatarRoot: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  AvatarName: {
    fontSize: "14px",
    fontWeight: "500",
    color: " #333333",
    lineHeight: 1.2,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    width: "100%",
  },
  AvatarPostTime: {
    fontSize: "12px",
    fontWeight: "500",
    color: " #999999",
    lineHeight: 1.2,
  },
  postMoreActions: {
    "&>svg": {
      color: "#D9D9D9",
      cursor: "pointer",
    },
  },
  postActionWrapper: {
    "&>div": {
      marginRight: 20,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: 5,
        height: 15,
        width: 15,
      },
    },
    "& p": {
      fontSize: 12,
      lineHeight: 1.25,
    },
  },
  postContent: {
    marginBottom: 20,
  },
  username: {
    "&>svg": {
      "&:nth-child(1)": {
        width: 15,
        height: 15,
        color: theme.palette.primary.main,
        marginLeft: 5,
      },
    },
  },
  likesCommentsCount: {
    marginTop: 10,
    "&>p": {
      margin: 0,
      fontSize: 12,
      lineHeight: 1.25,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  },
  commentTextfield: {
    margin: "10px 0",
    "&>div": {
      width: "100%",
      "&>div": {
        background: "#F4F5F8",
        color: "#8A9294",
        borderRadius: 5,
        padding: "10px 15px",
        "&>textarea": {
          fontSize: 16,
          lineHeight: "23px",
        },
        "&>div": {
          "&>svg": {
            cursor: "pointer",
          },
        },
      },
    },
  },
  commentContainer: {
    maxHeight: 400,
    overflow: "auto",
    padding: "0 5px",
    "&>hr": {
      width: "100%",
      margin: "10px 0",
    },
  },
  replayIconAndCountDiv: {
    marginBottom: 15,
    "&>*": {
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
    "&>svg": {
      width: 20,
      height: 20,
    },
    "&>p": {
      margin: 0,
      fontSize: 12,
      lineHeight: 1.25,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  commentMoreIcon: {
    marginLeft: 5,
    minWidth: 24,
  },
  userNameRow: {
    "&>a>svg": {
      width: 15,
      height: 15,
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
    "&>*:not(:last-child)": {
      marginRight: 5,
    },
  },
  sharePostDetailContainer: {},
  userAvatar: {
    [theme.breakpoints.down(600)]: {
      marginRight: 10,
      "&>div": {
        width: "25px !important",
        height: "25px !important",
      },
    },
  },
  previewLinkPostMinCls: {
    margin: "auto",
    display: "flex",
  },
  textContent: {
    fontWeight: 400,
    letterSpacing: 0,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
  userDetailLink: {
    cursor: "pointer",
    "&>svg": {
      width: "0.9em",
      height: "0.9em",
      color: theme.palette.primary.main,
    },
    "&>p": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const SinglePost = (props) => {
  const classes = useStyle();
  const {
    userData,
    postLikeDislike,
    post,
    postComment,
    postCommentData,
    getPostCommentList,
    deletePost,
    deleteComment,
    setEditOrSharePostData,
    postLocation,
  } = props;
  const menuIconRefs = useRef({});
  const [openMuiMenu, setOpenMuiMenu] = useState(false);
  const [showCommentTextfield, setShowCommentTextfield] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showCommentsList, setShowCommentsList] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [commentId, setCommentId] = useState("");
  const [replyText, setReplyText] = useState("");
  const [showRepliesList, setShowRepliesList] = useState([]);
  const [menuIconType, setMenuIconType] = useState("");
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [openPostLikedUsersListDialog, setOpenPostLikedUsersListDialog] =
    useState(false);
  const [deleteCommentAndReplyId, setDeleteCommentAndReplyId] = useState({});
  const [openUpdateCommentOrReplyDialog, setOpenUpdateCommentOrReplyDialog] =
    useState(false);
  const [updateCommentOrReplyData, setUpdateCommentOrReplyData] = useState({});

  const handleCloseMuiMenu = () => {
    setOpenMuiMenu(false);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmationDialog(true);
    setUpdateCommentOrReplyData({});
  };

  const handleShare = () => {
    setEditOrSharePostData({ post_id: post?.id, type: "share" });
  };

  const handleEdit = () => {
    if (menuIconType !== "post") {
      setOpenUpdateCommentOrReplyDialog(true);
    } else {
      setEditOrSharePostData({ post_id: post?.id, type: "edit" });
    }
  };

  const handleCloseUpdateCommentOrReplyDialog = () => {
    setOpenUpdateCommentOrReplyDialog(false);
    setUpdateCommentOrReplyData({});
    setMenuIconType("");
    setDeleteCommentAndReplyId({});
  };

  const handleCloseDeleteConfirmationDialog = (value) => {
    setOpenDeleteConfirmationDialog(false);
    if (value) {
      if (menuIconType === "post") {
        deletePost({ post_id: post.id });
      } else {
        deleteComment({ ...deleteCommentAndReplyId, post_id: post?.id });
      }
    }
    setMenuIconType("");
    setDeleteCommentAndReplyId({});
  };

  const handleComment = () => {
    if (commentText?.trim()) {
      postComment({
        post_id: post.id,
        user_id: userData?.data?.id,
        comment: commentText,
        parent_comment_id: null,
        receiver_id: post?.user_id,
      });
      setLoadingType("comment");
    }
  };

  const handleReply = (receiver_id) => {
    if (replyText?.trim()) {
      postComment({
        post_id: post.id,
        user_id: userData?.data?.id,
        comment: replyText,
        parent_comment_id: commentId,
        notificationData: {
          commentUserId: receiver_id,
          postUserId: post?.user_id,
        },
      });
      setLoadingType("reply");
    }
  };

  const handleClose = () => {
    setCommentText("");
    setLoadingType("");
    setReplyText("");
  };

  useEffect(() => {
    if (
      !postCommentData?.loading &&
      postCommentData?.status === "1" &&
      postCommentData?.data?.post_id === post?.id
    ) {
      handleClose();
      if (showCommentsList) {
        getPostCommentList({ post_id: post.id });
      } else {
        setShowCommentsList(true);
      }
      if (
        postCommentData?.data?.parent_comment_id &&
        !showRepliesList.includes(postCommentData?.data?.parent_comment_id)
      ) {
        setShowRepliesList((pre) => [
          ...pre,
          postCommentData?.data?.parent_comment_id,
        ]);
      }
    }
  }, [postCommentData]);

  useEffect(() => {
    if (showCommentsList) {
      getPostCommentList({ post_id: post.id });
    }
  }, [showCommentsList]);

  return (
    <Box className="feedRoot lrComponent feedMainRoot">
      <Box className="ldrabRoot feedHeaderRoot">
        <Box className={classes.AvatarRoot}>
          <Link
            to={`/user-detail/${post?.user?.id}`}
            className={classes.userDetailLink}
            target="_blank"
          >
            <UserAvatar src={post?.user?.profile_picture} />
          </Link>
          <Box>
            <Box
              className={classes.userNameRow}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Link
                to={`/user-detail/${post?.user?.id}`}
                className={classes.userDetailLink}
                target="_blank"
              >
                <Typography className={clsx(classes.AvatarName)}>
                  {post?.user?.name}
                </Typography>
              </Link>
              {(post?.user?.practitioner === true ||
                post?.user?.practitioner === "Yes") && (
                <Link
                  to={`/user-detail/${post?.user?.id}`}
                  className={classes.userDetailLink}
                  target="_blank"
                >
                  <Tooltip title={"Practitioner"} arrow>
                    <PersonIcon />
                  </Tooltip>
                </Link>
              )}
              <FollowUnfollowUser user={post?.user} />
            </Box>
            <Typography className={classes.AvatarPostTime}>
              <RealTimeAgo datetime={post?.created_on} locale="pt_BR" />
            </Typography>
          </Box>
        </Box>
        <Box className={classes.postMoreActions}>
          {post?.user_id === userData?.data?.id &&
            postLocation !== "userProfile" && (
              <MoreHorizIcon
                onClick={(e) => {
                  setOpenMuiMenu(true);
                  setMenuIconType("post");
                }}
                ref={(el) =>
                  (menuIconRefs.current = {
                    ...menuIconRefs.current,
                    post: el,
                  })
                }
              />
            )}
        </Box>
      </Box>
      <Box className={clsx("ldrabRoot feedContentRoot", classes.postContent)}>
        <Box>
          <div className={classes.textContent}>
            {post?.description?.length <= 600 ? (
              ReactHtmlParser(nl2br(post?.description))
            ) : (
              <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                anchorClass={"showMoreBtns"}
                expanded={false}
              >
                {ReactHtmlParser(nl2br(post?.description))}
              </ShowMoreText>
            )}
          </div>
          <LinkPreview data={JSON.parse(post?.link_content)} />
        </Box>
        <PostMediaSlider mediaList={post?.post_medias} />
      </Box>
      {Boolean(post?.share_post) && (
        <Box className={classes.sharePostDetailContainer}>
          <Box className="feedRoot lrComponent feedMainRoot">
            <Box className="ldrabRoot feedHeaderRoot">
              <Box className={classes.AvatarRoot}>
                <Link
                  to={`/user-detail/${post?.share_post?.user?.id}`}
                  className={classes.userDetailLink}
                  target="_blank"
                >
                  <UserAvatar src={post?.share_post?.user?.profile_picture} />
                </Link>
                <Box>
                  <Box
                    className={classes.userNameRow}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Link
                      to={`/user-detail/${post?.share_post?.user?.id}`}
                      className={classes.userDetailLink}
                      target="_blank"
                    >
                      <Typography className={clsx(classes.AvatarName)}>
                        {post?.share_post?.user?.name}
                      </Typography>
                    </Link>
                    {(post?.share_post?.user?.practitioner === true ||
                      post?.share_post?.user?.practitioner === "Yes") && (
                      <Link
                        to={`/user-detail/${post?.share_post?.user?.id}`}
                        className={classes.userDetailLink}
                        target="_blank"
                      >
                        <Tooltip title={"Practitioner"} arrow>
                          <PersonIcon />
                        </Tooltip>
                      </Link>
                    )}
                    <FollowUnfollowUser user={post?.share_post?.user} />
                  </Box>
                  <Typography className={classes.AvatarPostTime}>
                    <RealTimeAgo
                      datetime={post?.share_post?.created_on}
                      locale="pt_BR"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.postMoreActions}></Box>
            </Box>
            <Box
              className={clsx("ldrabRoot feedContentRoot", classes.postContent)}
            >
              <Box>
                <div className={classes.textContent}>
                  {post?.share_post?.description?.length <= 600 ? (
                    ReactHtmlParser(nl2br(post?.share_post?.description))
                  ) : (
                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      anchorClass={"showMoreBtns"}
                      expanded={false}
                    >
                      {ReactHtmlParser(nl2br(post?.share_post?.description))}
                    </ShowMoreText>
                  )}
                </div>
                <LinkPreview
                  data={JSON.parse(post?.share_post?.link_content)}
                />
              </Box>
              <PostMediaSlider mediaList={post?.share_post?.post_medias} />
            </Box>
          </Box>
        </Box>
      )}

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.postActionWrapper}
      >
        <Grid
          onClick={() =>
            postLikeDislike({ post_id: post?.id, post_user_id: post?.user_id })
          }
        >
          {post?.is_like ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}

          <Typography>Like</Typography>
        </Grid>
        <Grid onClick={() => setShowCommentTextfield((pre) => !pre)}>
          <CommentIcon />
          <Typography>Comment</Typography>
        </Grid>
        {postLocation !== "userProfile" && (
          <Grid onClick={handleShare}>
            <ShareIcon />
            <Typography>Share</Typography>
          </Grid>
        )}
      </Grid>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
        className={classes.likesCommentsCount}
      >
        <p onClick={() => setOpenPostLikedUsersListDialog(true)}>
          {Boolean(post?.like_count)
            ? `${post?.like_count} ${post?.like_count === 1 ? "Like" : "Likes"}`
            : ""}
        </p>
        <p onClick={() => setShowCommentsList((pre) => !pre)}>
          {Boolean(post?.comment_count)
            ? `${post?.comment_count} ${
                post?.comment_count === 1 ? "Comment" : "Comments"
              }`
            : ""}
        </p>
      </Box>

      {showCommentTextfield && (
        <Box className={classes.commentTextfield}>
          <TextField
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {postCommentData?.loading && loadingType === "comment" ? (
                    <CircularProgress />
                  ) : (
                    <SendIcon onClick={handleComment} />
                  )}
                </InputAdornment>
              ),
            }}
            placeholder="Add Comment..."
            multiline
            minRows={1}
            maxRows={5}
          />
        </Box>
      )}
      {showCommentsList && (
        <Box
          display={"flex"}
          flexDirection="column"
          className={classes.commentContainer}
        >
          <Divider />
          {post?.comments?.map((comment, commentKey) => {
            return (
              <Grid
                key={commentKey}
                container
                alignItems="flex-start"
                className={clsx("commentReplyRootDiv feedHeaderRoot")}
              >
                <Link
                  to={`/user-detail/${comment?.user?.id}`}
                  className={classes.userDetailLink}
                  target="_blank"
                >
                  <UserAvatar
                    src={comment?.user?.profile_picture}
                    className={classes.userAvatar}
                  />
                </Link>
                <Grid className="ffcarRightSideDiv">
                  <Grid className="ffcarContentDiv">
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box
                        className={classes.userNameRow}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Link
                          to={`/user-detail/${comment?.user?.id}`}
                          className={classes.userDetailLink}
                          target="_blank"
                        >
                          <Typography
                            className={clsx("messageContent")}
                            medium
                            ellipsis
                          >
                            {comment?.user?.name}
                          </Typography>
                        </Link>
                        {(comment?.user?.practitioner === true ||
                          comment?.user?.practitioner === "Yes") && (
                          <Link
                            to={`/user-detail/${comment?.user?.id}`}
                            className={classes.userDetailLink}
                            target="_blank"
                          >
                            <Tooltip title={"Practitioner"} arrow>
                              <PersonIcon />
                            </Tooltip>
                          </Link>
                        )}
                        <FollowUnfollowUser user={comment?.user} />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        <Typography className={classes.AvatarPostTime}>
                          <RealTimeAgo
                            datetime={comment?.created_on}
                            locale="pt_BR"
                          />
                        </Typography>
                        <Box
                          className={clsx(
                            classes.postMoreActions,
                            classes.commentMoreIcon
                          )}
                        >
                          {comment.user_id === userData?.data?.id && (
                            <MoreHorizIcon
                              onClick={(e) => {
                                setOpenMuiMenu(true);
                                setDeleteCommentAndReplyId({
                                  comment_id: comment?.id,
                                });
                                setMenuIconType("comment");
                                setUpdateCommentOrReplyData(comment);
                              }}
                              ref={(el) =>
                                (menuIconRefs.current = {
                                  ...menuIconRefs.current,
                                  [comment?.id]: el,
                                })
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <div className={classes.textContent}>
                      {comment?.comment?.length <= 600 ? (
                        ReactHtmlParser(nl2br(comment?.comment))
                      ) : (
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          anchorClass={"showMoreBtns"}
                          expanded={false}
                        >
                          {ReactHtmlParser(nl2br(comment?.comment))}
                        </ShowMoreText>
                      )}
                    </div>
                  </Grid>
                  <Box
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    className={classes.replayIconAndCountDiv}
                  >
                    <ReplyIcon
                      onClick={() => {
                        setReplyText("");
                        setCommentId((pre) =>
                          pre === comment?.id ? "" : comment?.id
                        );
                      }}
                    />
                    <p
                      onClick={() =>
                        setShowRepliesList((pre) => [...pre, comment?.id])
                      }
                    >
                      {Boolean(comment?.child_comment?.length)
                        ? `${comment?.child_comment?.length} ${
                            comment?.child_comment?.length === 1
                              ? "Reply"
                              : "Replies"
                          }`
                        : ""}
                    </p>
                  </Box>
                  {commentId === comment?.id && (
                    <Box className={classes.commentTextfield}>
                      <TextField
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              {postCommentData?.loading &&
                              loadingType === "reply" ? (
                                <CircularProgress />
                              ) : (
                                <SendIcon
                                  onClick={() => handleReply(comment?.user_id)}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Add Reply..."
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                    </Box>
                  )}
                  {showRepliesList?.includes(comment?.id) &&
                    comment?.child_comment?.map((reply, replyKey) => {
                      return (
                        <Grid
                          key={replyKey}
                          container
                          alignItems="flex-start"
                          className="commentReplyRootDiv"
                        >
                          <Link
                            to={`/user-detail/${reply?.user?.id}`}
                            className={classes.userDetailLink}
                            target="_blank"
                          >
                            <UserAvatar
                              src={reply?.user?.profile_picture}
                              className={classes.userAvatar}
                            />
                          </Link>
                          <Grid className="ffcarRightSideDiv">
                            <Grid className="ffcarContentDiv">
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="flex-start"
                              >
                                <Box
                                  className={classes.userNameRow}
                                  display="flex"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <Link
                                    to={`/user-detail/${reply?.user?.id}`}
                                    className={classes.userDetailLink}
                                    target="_blank"
                                  >
                                    <Typography
                                      className={clsx("messageContent")}
                                      medium
                                      ellipsis
                                    >
                                      {reply?.user?.name}
                                    </Typography>
                                  </Link>
                                  {(reply?.user?.practitioner === true ||
                                    reply?.user?.practitioner === "Yes") && (
                                    <Link
                                      to={`/user-detail/${reply?.user?.id}`}
                                      className={classes.userDetailLink}
                                      target="_blank"
                                    >
                                      <Tooltip title={"Practitioner"} arrow>
                                        <PersonIcon />
                                      </Tooltip>
                                    </Link>
                                  )}
                                  <FollowUnfollowUser user={reply?.user} />
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"center"}
                                >
                                  <Typography
                                    className={classes.AvatarPostTime}
                                  >
                                    <RealTimeAgo
                                      datetime={reply?.created_on}
                                      locale="pt_BR"
                                    />
                                  </Typography>
                                  <Box
                                    className={clsx(
                                      classes.postMoreActions,
                                      classes.commentMoreIcon
                                    )}
                                  >
                                    {reply.user_id === userData?.data?.id && (
                                      <MoreHorizIcon
                                        onClick={(e) => {
                                          setOpenMuiMenu(true);
                                          setDeleteCommentAndReplyId({
                                            comment_id: reply?.id,
                                            parent_comment_id: comment?.id,
                                          });
                                          setMenuIconType("reply");
                                          setUpdateCommentOrReplyData(reply);
                                        }}
                                        ref={(el) =>
                                          (menuIconRefs.current = {
                                            ...menuIconRefs.current,
                                            [reply?.id]: el,
                                          })
                                        }
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                              <div className={classes.textContent}>
                                {reply?.comment?.length <= 600 ? (
                                  ReactHtmlParser(nl2br(reply?.comment))
                                ) : (
                                  <ShowMoreText
                                    lines={3}
                                    more="Show more"
                                    less="Show less"
                                    anchorClass={"showMoreBtns"}
                                    expanded={false}
                                  >
                                    {ReactHtmlParser(nl2br(reply?.comment))}
                                  </ShowMoreText>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}
      <MuiMenu
        open={openMuiMenu}
        anchorRef={
          menuIconRefs.current[
            menuIconType === "post"
              ? "post"
              : deleteCommentAndReplyId?.comment_id
          ]
        }
        handleClose={handleCloseMuiMenu}
        menuItems={[
          { label: "Edit", onClick: handleEdit },
          { label: "Delete", onClick: handleDelete },
        ]}
      />
      <UpdateCommentOrReplyDialog
        handleClose={handleCloseUpdateCommentOrReplyDialog}
        open={openUpdateCommentOrReplyDialog}
        data={updateCommentOrReplyData}
      />
      <AlertDialog
        open={openDeleteConfirmationDialog}
        handleClose={handleCloseDeleteConfirmationDialog}
        heading={
          menuIconType === "post"
            ? "Delete Post"
            : menuIconType === "comment"
            ? "Delete Comment"
            : menuIconType === "reply"
            ? "Delete Reply"
            : ""
        }
        content={
          menuIconType === "post"
            ? "Are you sure you want to delete this post permanently?"
            : menuIconType === "comment"
            ? "Are you sure you want to delete this comment permanently?"
            : menuIconType === "reply"
            ? "Are you sure you want to delete this reply permanently?"
            : ""
        }
        cancelBtnText="Cancel"
        actionBtnText="Delete"
      />
      <PostLikedUsersDialogList
        open={openPostLikedUsersListDialog}
        handleClose={() => setOpenPostLikedUsersListDialog(false)}
        post_id={post.id}
      />
    </Box>
  );
};

const mapStateToProps = ({
  PostReducer: {
    postLikeDislikeData,
    postCommentData,
    postCommentList,
    deletePostData,
    deletePostCommentData,
  },
  ProfileReducers: { userData },
}) => ({
  postLikeDislikeData,
  postCommentData,
  userData,
  postCommentList,
  deletePostData,
  deletePostCommentData,
});

const mapDispatchToProps = {
  postLikeDislike,
  postComment,
  getPostCommentList,
  deletePost,
  deleteComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePost);
