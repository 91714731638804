import {
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  makeStyles,
  Popover,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  simpleSelectOption: {},
  simpleSelectActiveOption: {
    color: theme.palette.primary.main,
  },
  ulRoot: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  popoverRoot: {
    "&>div:nth-child(3)": {
      padding: 10,
      "&>li": {
        padding: "10px 20px",
      },
    },
  },
  activeUl: {
    boxShadow: `inset 0 calc(1rem * -0.31) 0 -1px ${theme.palette.primary.main}`,
  },
  listItemTextRoot: {
    "&>span": {
      maxWidth: "150px",
      textAlign: "center",
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
    },
  },
}));

export default function SimpleSelect({
  options = [],
  value = "",
  onChange = () => {},
  className = "",
  label = "",
  ...props
}) {
  const classes = useStyle();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, data) => {
    setSelectedIndex(data.label);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (value) {
      const findLabel = options.find(
        (data) => data.value === window.location.pathname
      );
      setSelectedIndex(findLabel ? findLabel.label : options[0].label);
    }
  }, [window.location.pathname, value]);

  return (
    <>
      <List
        className={clsx(classes.ulRoot, Boolean(anchorEl) && classes.activeUl)}
        onClick={handleClickListItem}
      >
        <ListItem button>
          <Tooltip arrow title={label}>
            <ListItemText
              primary={label}
              className={classes.listItemTextRoot}
            />
          </Tooltip>
        </ListItem>
      </List>
      <Popover
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popoverRoot}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            disabled={selectedIndex === item.label || item?.disabled}
            selected={selectedIndex === item.label}
            onClick={(event) => {
              if (item?.customUIType) return;
              if (item.label == "Whitepapers") {
                window.open(
                  "/assets/whitepaper/HealthLOQ-Whitepaper.pdf",
                  "_blank"
                );
              } else {
                handleMenuItemClick(event, item);
                onChange(item.value);
              }
            }}
          >
            {item?.customUIType === "button" ? (
              <Button
                onClick={() => {
                  window.open(item?.value, "_blank");
                  handleClose();
                }}
              >
                {item?.label}
              </Button>
            ) : (
              item?.label
            )}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
