import {
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";
import { Rating } from "@material-ui/lab";
import HealthLoqTextField from "../Common/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";

// STYLE
const useStyle = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: "42px 30px",
  },
  darkWord: {
    fontWeight: "700",
    color: "#000",
    marginBottom: 0,
  },
  feedBackText: {
    margin: "auto 0",
    display: "grid",
    gap: 19,
  },
  feedBackRating: {
    display: "flex",
  },
  feedBackProductDispalyContainer: {
    marginTop: 19,
  },
  feedBackContainer: {
    display: "grid",
    gap: 19,
  },
  feedBackRadioButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backGround: "yellow",
    "&>label": {
      marginRight: 10,
      marginBottom: 0,
      fontWeight: "500",
      color: "#000",
      fontSize: "18px",
    },
    "&>div": {
      "&>label": {
        margin: 0,
        "&>span": {
          "&:nth-child(1)": {
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
  feedBackSubmitButton: {
    display: "flex",
    justifyItem: "start",
  },
  button: {
    padding: "18px 50px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "&>button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
}));

// IMAGES
const {
  images: { feedBackImg },
} = appImages;

export default function ConsumerFeedBackDetails() {
  // IMPORT STYLE
  const classes = useStyle();

  // VALIDATION SCHEMA
  const feedBackValidation = Yup.object().shape({
    buy_product: Yup.string().required("This field is required"),
    feed_back: Yup.string().required("This field is required"),
  });

  //SEND FEEDBACK
  const handleSendFeedBack = (data) => {
    console.log(data);
  };

  return (
    <Grid className={classes.root}>
      {/* ========= HEADER ========= */}
      <Grid>
        <PrimaryHeading>Provide Feedback</PrimaryHeading>
        <Typography variant="body2">
          Provide Feedback to the brand and supplier about this specific product
          and complete profile to receive rewards{" "}
        </Typography>
        <Grid container className={classes.feedBackProductDispalyContainer}>
          <Grid>
            <img src={feedBackImg} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.feedBackText}>
            <Typography>
              <span className={classes.darkWord}> Product Name:</span> Knight
              Rider Tablet
            </Typography>
            <Typography>
              <span className={classes.darkWord}>Batch Number:</span>{" "}
              G2788050-15055154444
            </Typography>
            <Typography className={classes.feedBackRating}>
              <span className={classes.darkWord}>Product Rate</span> :{" "}
              <Rating value={5} readOnly />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* ========= FORM =========  */}
      <Formik
        initialValues={{
          recycle_packaging: "Yes",
          buy_product: null,
          feed_back: null,
        }}
        validationSchema={feedBackValidation}
        onSubmit={handleSendFeedBack}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Grid className={classes.feedBackContainer}>
              <Grid className={classes.feedBackRadioButton}>
                <InputLabel required>Did you recycle the packaging?</InputLabel>
                <RadioGroup
                  name="recycle_packaging"
                  value={values.recycle_packaging}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio style={{ background: "none" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio style={{ background: "none" }} />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
              <HealthLoqTextField
                name="buy_product"
                required
                darkLabel
                onChange={handleChange}
                error={
                  touched.buy_product && errors?.buy_product !== undefined
                    ? true
                    : false
                }
                helperText={touched.buy_product && errors?.buy_product}
                onBlur={handleBlur}
                multiline
                minRows={2}
                label="where did you buy this product?"
              />
              <HealthLoqTextField
                name="feed_back"
                required
                multiline
                darkLabel
                minRows={6}
                error={
                  touched.feed_back && errors?.feed_back !== undefined
                    ? true
                    : false
                }
                helperText={touched.feed_back && errors?.feed_back}
                onBlur={handleBlur}
                label="Please provide Feedback"
              />
              <Grid className={classes.feedBackSubmitButton}>
                <Button type="submit" className={classes.button}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
