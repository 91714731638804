import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import {
  GramaIcon,
  SolutionFeature1,
  SolutionFeature2,
  SolutionFeature3,
} from "../../constants/svgIcons";
import { FeaturesIconFrame } from "../Common/IconFrames";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import { AppImages } from "../../constants";
import clsx from "clsx";
const { grma_image } = AppImages;

const useStyle = makeStyles((theme) => ({
  featureBoxList: {
    width: "100%",
    margin: "70px 0",
  },
  featureBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    maxHeight: 300,
    minHeight: 300,
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    "&>p": {
      marginTop: 25,
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
  grma_section: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    padding: "0px 25px",
    justifyContent: "center",
  },
  grma_section_container: {
    background: "#F3F8EB",
    padding: 10,
    marginTop: 70,
  },
  grma_text: {
    width: "100%",
    fontSize: "24px",
    fontWeight: 600,
  },
}));

export default function Features() {
  const classes = useStyle();
  const content = [
    {
      icon: <SolutionFeature1 />,
      content: "Implement at enterprise scale in less than a day",
    },
    {
      icon: <SolutionFeature2 />,
      content:
        "No changes to current business processes or document management systems",
    },
    {
      icon: <SolutionFeature3 />,
      content:
        "No document export required - Privacy maintained w/o changing partner agreements",
    },
  ];
  return (
    <SectionWithTreePlan position="right">
      <Grid className={classes.grma_section_container}>
        <Grid className={clsx(classes.grma_section, "bodyMaxWidth")}>
          <img src={grma_image} height="70px" width="250px" />
          {/* <Typography className={classes.grma_text}>
            HealthLOQ and GRMA announce the GRMA Product Integrity Program!
          </Typography> */}
        </Grid>
      </Grid>
      <Box className="bodyMaxWidth">
        <Grid container spacing={4} className={classes.featureBoxList}>
          {content?.map((item, key) => {
            return (
              <Grid item key={key} xs={12} sm={12} md={4}>
                <Box className={classes.featureBox}>
                  <FeaturesIconFrame icon={item?.icon} />
                  <Typography variant="body2">{item?.content}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
