import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HeaderSection from "../../components/Common/Section/HeaderSection";
import { ConsumerSolutionSection } from "../../components/ConsumerSolution";
import HealthloqSection from "../../components/ConsumerSolution/HealthloqSection";
import VerifyAProductOrigin from "../../components/ConsumerSolution/VerifyAProductOrigin";
import { PageWrapper } from "../../components/Wrappers";
import appImages from "../../constants/AppImage";

const {
  headerImages: { consumers },
} = appImages;

export default function ConsumerSolution() {
  return (
    <PageWrapper pageName="consumer solution">
      <HeaderSection
        headerImgSrc={consumers}
        headerText="Evidence-based confidence to customers of natural products."
        headerDescription="HealthLOQ builds trust with consumers by bringing transparency to supply chains, documentation and product testing through blockchain technology."
      />
      <ConsumerSolutionSection />
      <VerifyAProductOrigin />
      <HealthloqSection />
      <AsSeenInSection />
    </PageWrapper>
  );
}
