import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import PrimaryHeading from "../../Common/Typography/PrimaryHeading";
import { SectionWithTreePlan } from "../../Common/SectionWrappers";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: (props) =>
      props?.backgroundColor ? "#F7F7F7" : theme.palette.common.white,
    "&>div": {
      "&>div": {
        "&>div": {
          width: "100%",
          "&:first-child": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          },
          "&:last-child": {
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            "&>a": {
              padding: "18px 50px",
            },
            [theme.breakpoints.down("sm")]: {
              "&>a": {
                padding: 10,
                fontSize: 14,
              },
            },
          },
        },
        "&>p": {
          textAlign: "center",
        },
      },
    },
  },
}));

export default function TextSection({
  treePanPosition = "left",
  heading = "HealthLOQ",
  description = "",
  callToActionType = "LearnMore",
  redirectTo = "/",
  backgroundColor = false,
  openNewTab = false,
}) {
  const classes = useStyle({ backgroundColor });

  let callToActionInfo = {
    LearnMore: (
      <Button target={openNewTab && "_blank"} href={redirectTo}>
        Learn More
      </Button>
    ),
  };

  return (
    <SectionWithTreePlan position={treePanPosition} className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Box>
            <PrimaryHeading>{heading}</PrimaryHeading>
          </Box>
          <Typography variant="body2">{description}</Typography>
          <Box>{callToActionInfo[callToActionType]}</Box>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
