import React from "react";
import { AppImages } from "../../constants";
import { Body } from "../../components/Common";

const { imageTC } = AppImages;

const SubscriptionCancellation = () => (
  <Body seoTitle="HealthLOQ Cancellation Policy">
    <div className="page-tc">
      <div
        className="header-tc"
        style={{ backgroundImage: "url(" + imageTC + ")" }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="header-content d-flex align-items-end">
                <div>
                  <p className="txt-tc">HealthLOQ Cancellation Policy</p>
                  <p className="txt-carefully">
                    Please read these cancellation policy carefully before using
                    our service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12">
            <p className="txt-lastupdate text-right">
              Last updated: March 29, 2022
            </p>
            <h1>Introduction</h1>
            <p>
              You may terminate this Agreement and your Account at any time by
              notifying HealthLOQ of such termination. Payment for the services,
              which is received in advance for each month, will not be refunded
              for the month in which the cancellation of services occurs.
              HealthLOQ may suspend or terminate the services and/or this
              agreement for any reason at any time, with or without cause, and
              with or without notice. Upon the termination of this Agreement for
              any reason, all rights granted to you hereunder will cease, and
              you must promptly purge and remove all components from your
              environment. Termination of any services includes removal of
              access to such services and barring of further use of the
              services. Except as otherwise set forth in this agreement, upon
              termination of any service, your right to use such service will
              automatically terminate immediately. You understand that any
              termination of services may involve deletion of account data
              associated therewith from our live databases. We will not have any
              liability whatsoever to you for any suspension or termination,
              including for deletion of account data. All provisions of this
              agreement, which by their nature should survive, shall survive
              termination of services, including, without limitation, ownership
              provisions, your payment obligations, warranty disclaimer,
              limitations of liability, and indemnities.
            </p>
            <h2>Automatic renewals</h2>
            <p>
              By default, your subscription purchase is set to automatically
              renew at the end of your subscription. At any point during your
              subscription, you can upgrade to a larger subscription plan.
              You'll upgrade to your new subscription level immediately, and
              your credit card will be charged when you run out of credit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Body>
);
export default SubscriptionCancellation;
