import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Button from "../../components/Profile/Button";
import { updateUser } from "../../redux/actions/profileActions";
import {
  MuiTextfield,
  MuiSelect,
  PublicPrivateSwitch,
} from "../../components/Profile";
import { Link } from "react-router-dom";
import utils from "../../utils/commonUtils";

let updateUserFlag = false;

export const ProfileSignup = (props) => {
  const {
    match,
    signupData,
    handleSignupDataChange,
    verifySignupForm,
    history,
    updateUserData,
    updateUser,
    handleMasterToggle,
  } = props;
  const handleSubmit = () => {
    if (verifySignupForm("profile-signup")) {
      let obj = {
        data_visibility: {},
      };
      let formData = new FormData();
      for (let item of Object.entries(signupData)?.slice(4)) {
        const [key, value] = item;
        formData.append(key, value?.value);
        // obj[key] = value?.value;
        obj["data_visibility"] = {
          ...obj?.data_visibility,
          [key]: value?.isPublic,
        };
      }
      formData.append("data_visibility", JSON.stringify(obj?.data_visibility));
      // obj["data_visibility"] = JSON.stringify(obj?.data_visibility);
      updateUser(formData);
      updateUserFlag = true;
    }
  };
  useEffect(() => {
    if (updateUserData?.status === "1" && updateUserFlag) {
      updateUserFlag = false;
      history.push("/connections");
    }
  }, [updateUserData]);
  return (
    <Grid className="psl-root">
      <Grid className="psl-form-div">
        <Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            className="psl-form-master-toggle"
          >
            <PublicPrivateSwitch
              checked={
                9 ===
                Object.entries(signupData)
                  ?.slice(5)
                  ?.filter(([signupKey, item]) => item?.isPublic).length
              }
              onChange={handleMasterToggle("profile-signup")}
              isMasterToggle={true}
              id="signup-master-toggle"
            />
          </Grid>
          {Object.entries(signupData)
            ?.slice(4)
            ?.map((obj, key) => {
              const [signupKey, item] = obj;
              if (item?.inputType === "dropdown") {
                return (
                  <MuiSelect
                    key={key}
                    value={item?.value}
                    onChange={(e) =>
                      handleSignupDataChange(signupKey, e.target.value)
                    }
                    menuItems={item?.options}
                    placeholder={item?.placeholder}
                    match={match}
                    required={item.required}
                    error={item?.error}
                    showLockIcon={["name"].includes(signupKey) ? false : true}
                    lockIconState={item?.isPublic}
                    handleLockIconClick={(e) =>
                      handleSignupDataChange(signupKey, "", "toggleIsPublic")
                    }
                    switchId={`switch-${key}`}
                  />
                );
              }
              if (signupKey === "practitioner_bio") {
                return (
                  <MuiTextfield
                    key={key}
                    value={item?.value}
                    onChange={(e) =>
                      handleSignupDataChange(signupKey, e.target.value)
                    }
                    placeholder={item?.placeholder}
                    match={match}
                    required={item.required}
                    error={item?.error}
                    showLockIcon={["name"].includes(signupKey) ? false : false}
                    multiline
                    minRows={4}
                    maxRows={4}
                  />
                );
              }
              return (
                <MuiTextfield
                  key={key}
                  value={item?.value}
                  onChange={(e) =>
                    handleSignupDataChange(signupKey, e.target.value)
                  }
                  placeholder={item?.placeholder}
                  match={match}
                  required={item.required}
                  error={item?.error}
                  showLockIcon={["name"].includes(signupKey) ? false : true}
                  lockIconState={item?.isPublic}
                  handleLockIconClick={(e) =>
                    handleSignupDataChange(signupKey, "", "toggleIsPublic")
                  }
                  switchId={`switch-${key}`}
                />
              );
            })}
        </Grid>
        <Grid className="psl-footer">
          <Grid className="psl-footer-action-buttons">
            <Button
              profileTransparentBtn
              onClick={() => history.push("/connections")}
            >
              Skip
            </Button>
            <Button
              profileBgBtn
              onClick={handleSubmit}
              loading={updateUserData?.loading}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  ProfileReducers: { userData, updateUserData },
}) => ({
  userData,
  updateUserData,
});

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSignup);
