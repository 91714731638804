const actionType = {
  TOGGLE_LOADER: "TOGGLE_LOADER",
  INTEGRANTS_LIST: "INTEGRANTS_LIST",
  INTEGRANTS_DETAIL: "INTEGRANTS_DETAIL",
  INTEGRANTS_ERROR: "INTEGRANTS_ERROR",
  INTEGRANTS_LIST_IS_LOADING: "INTEGRANTS_LIST_IS_LOADING",
  INTEGRANTS_EXHIBITS_DETAIL: "INTEGRANTS_EXHIBITS_DETAIL",
  INTEGRANTS_BATCHES_LIST: "INTEGRANTS_BATCHES_LIST",
  PRODUCTLIST: "PRODUCTLIST",
  PRODUCTLIST_LOADING: "PRODUCTLIST_LOADING",
  BATCHLIST: "BATCHLIST",
  ORGANIZATIONLIST: "ORGANIZATIONLIST",
  ORGANIZATIONLISTLOADING: "ORGANIZATIONLISTLOADING",
  GET_ALL_INTEGRANT_TYPES_LIST_START: "GET_ALL_INTEGRANT_TYPES_LIST_START",
  GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS: "GET_ALL_INTEGRANT_TYPES_LIST_SUCCESS",
  BLOCKCHAINPROOF: "BLOCKCHAINPROOF",
  BLOCKCHAINPROOF_LOADING: "BLOCKCHAINPROOF_LOADING",
  INGREDIENTS_IDS: "INGREDIENTS_IDS",
  PRODUCT_CURRENT_TAB: "PRODUCT_CURRENT_TAB",
  GET_BLOG_NEWS_LIST: "GET_BLOG_NEWS_LIST",
  VERIFY_DOCUMENT_LOADING: "VERIFY_DOCUMENT_LOADING",
  VERIFY_DOCUMENT_SUCCESS: "VERIFY_DOCUMENT_SUCCESS",
  VERIFY_COA_DOCUMENT_LOADING: "VERIFY_COA_DOCUMENT_LOADING",
  VERIFY_COA_DOCUMENT_SUCCESS: "VERIFY_COA_DOCUMENT_SUCCESS",
  UPDATE_VERIFY_DOCUMENT: "UPDATE_VERIFY_DOCUMENT",
  SET_INITIAL_BLOCKCHAIN_DATA: "SET_INITIAL_BLOCKCHAIN_DATA",
  UPDATE_MAPDATA_INTEGRANT_ID: "UPDATE_MAPDATA_INTEGRANT_ID",
  GET_INTEGRANT_BATCH_LIST_START: "GET_INTEGRANT_BATCH_LIST_START",
  GET_INTEGRANT_BATCH_LIST_SUCCESS: "GET_INTEGRANT_BATCH_LIST_SUCCESS",
  GET_ORGANIZATION_DETAIL_START: "GET_ORGANIZATION_DETAIL_START",
  GET_ORGANIZATION_DETAIL_SUCCESS: "GET_ORGANIZATION_DETAIL_SUCCESS",
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START:
    "GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START",
  GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS:
    "GET_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS",
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START:
    "GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_START",
  GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS:
    "GET_ORGANIZATION_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS",
  GET_EXHIBIT_BLOCKCHAIN_PROOF_START: "GET_EXHIBIT_BLOCKCHAIN_PROOF_START",
  GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS: "GET_EXHIBIT_BLOCKCHAIN_PROOF_SUCCESS",
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START:
    "GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_START",
  GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS:
    "GET_LAB_DOCUMENT_HASH_BLOCKCHAIN_PROOF_SUCCESS",
};

export default actionType;
