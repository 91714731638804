import { Box, Button, Grid, Link, makeStyles } from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "70px 0",
    background: "#F7F7F7",
  },
  content: {
    margin: "auto",
  },
  heading: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
  btns: {
    display: "flex",
    marginTop: 30,
    "& button": {
      padding: "18px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": {
        fontSize: 14,
        padding: 10,
      },
    },
  },
  darkWord: {
    fontWeight: "700",
    color: "#000",
  },
}));

const {
  images: { ConsumerProfileImg },
} = appImages;

export default function Section2() {
  const classes = useStyle();

  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>HealthLOQ</PrimaryHeading>
            </Box>
            <Grid>
              <span className={classes.darkWord}>HealthLOQ</span> connects
              organizations with their customers using a fully-permissioned,
              social-media based platform. Consumers are in complete control of
              their profile data and can both extend and retract access to their
              information at any time. Organizations can reach out to specific
              audiences, tailor survey interactions, and offer incentives to
              consumers for participation. Consumers can connect with other
              product users and share experiences. Practitioners can connect
              with patients and brands they trust to make recommendations and
              offer their expertise. The focus is on connection.
            </Grid>
            <Box className={classes.btns}>
              {/* <SecondaryButton /> */}
              <Link href="/contact-us">
                <Button>Contact Us</Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={ConsumerProfileImg} alt="Image" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
