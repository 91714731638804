/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const AttachmentsTitle = ({
 imageUrl, title, isMapOption, isMap, handlerMap, shortCode, externalId
}) => {
  return (
      <div className="title-attachments">
          <div className="d-flex align-items-center">
              <div className="icon-attachments">
                  {imageUrl ? <img src={imageUrl} alt="" /> : <Link to={`/p/${shortCode}/b/${externalId}`}>title && title.charAt(0)</Link>}
              </div>
              <Link to={`/p/${shortCode}/b/${externalId}`} >{title}</Link>
          </div>
          {isMapOption && (
          <button type="button" className="icon-location" onClick={() => handlerMap(!isMap)}>
              <i className="material-icons md-18">{isMap ? "list" : "room"}</i>
          </button>
          )}
      </div>
  )
}
AttachmentsTitle.propTypes = {
  title: PropTypes.string,
  handlerMap: PropTypes.func,
  imageUrl: PropTypes.string,
  isMapOption: PropTypes.bool,
  isMap: PropTypes.bool,
  shortCode: PropTypes.string,
  externalId: PropTypes.string
}
AttachmentsTitle.defaultProps = {
  handlerMap: () => {},
  title: "",
  imageUrl: "",
  isMapOption: false,
  isMap: false,
  shortCode: '',
  externalId: ''
}

export default AttachmentsTitle
