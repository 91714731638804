import React from "react";
import { AppImages } from "../../constants";
import Collapsible from "react-collapsible";
import q from "./q.json";
import { Body } from "../../components/Common";
const { imageTC } = AppImages;

const Privacy = () => (
  <Body seoTitle="Privacy Policy - Privacy & Terms - HealthLOQ">
    <div className="page-privacy">
      <div
        className="header-tc"
        style={{ backgroundImage: "url(" + imageTC + ")" }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="header-content d-flex align-items-end">
                <div>
                  <p className="txt-tc">HealthLOQ Privacy Policy</p>
                  <p className="txt-carefully">
                    When you use our services, you're trusting us with your
                    information.
                    <br />
                    We understand that this is a big responsibility and we work
                    hard to protect your information and put you in control.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12">
            <p className="txt-lastupdate text-right">
              Last updated: March 08, 2022
            </p>
            <p>
              To keep this Notice simple, we have not provided a Definition
              Section. Any capitalized word in this Notice that is not defined
              in the text itself shall have the terms defined by the General
              Data Protection Regulation (&ldquo;GDPR&rdquo;) in its version as
              of the date this Notice was last modified on.&nbsp;
            </p>
            <p>
              Your privacy is important to&nbsp;HealthLOQ LLC (3458 E. Norwood
              Cir, Mesa AZ 85213).
            </p>
            <p>
              &ldquo;HealthLOQ&rdquo;, &ldquo;us&ldquo;, &ldquo;our&ldquo; and
              we are committed to protecting your Personal Data. This Privacy
              Notice (&ldquo;Notice&ldquo;) relates to our use of your Personal
              Data when you interact with us through
              our&nbsp;HealthLOQ&nbsp;website(s) (&ldquo;HealthLOQ
              Sites&rdquo;),&nbsp;social media or adverts and content on third
              party websites
              (collectively&nbsp;&ldquo;HealthLOQ&nbsp;Content&ldquo;) or
              otherwise engage with us, for example by contacting Customer
              Services, attending an event, tradeshow or
              other&nbsp;HealthLOQ&nbsp;event.&nbsp;
            </p>
            <p>
              This Notice explains who we are; what information we collect about
              you; how we use it (including who we share that information with);
              our use of cookies; and your rights and choices regarding your
              Personal Data.
            </p>
            <p>
              We are accountable for the Personal Data you provided to us;
              however, as we may share your Personal Data within our group,
              other HealthLOQ-related entities may also use your information in
              accordance with this Notice. &nbsp;
            </p>
            <p>
              If you have any questions or concerns about our use of your
              Personal Data, then please contact us using the contact details
              provided in the section &ldquo;How do I
              contact&nbsp;HealthLOQ?&rdquo; of this Notice.
            </p>
            <div className="according">
              {q.map((item, i) => {
                return (
                  <Collapsible
                    key={i}
                    openedClassName="isOpen"
                    trigger={item.title}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                  </Collapsible>
                );
              })}
            </div>
            <p>
              <em>
                <strong>
                  Thank you for taking the time to read this Notice.&nbsp;
                </strong>
              </em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Body>
);
export default Privacy;
