import React from "react";
import { useParams } from "react-router-dom"
const Document = ({list}) => {
  const urlParams = useParams()
  const { documentID, document } = urlParams
  if(Object.keys(list).length === 0){
    return <div className="content-document">Please Wait...</div>
  }
  return (
    <div className="content-document">
      {
        (typeof documentID !== 'undefined') 
          ? 
          list.map(item => {
            return item.history.map((subItem, i) => {
              if((typeof subItem.metadata !== 'undefined') && (subItem.metadata.id === documentID)){
                return <iframe
                className="document-frame"
                key={i}
                src={subItem.metadata.url}
                title={subItem.text}
                />
              }
              return null
            })
          })
          : 
          list.map(item => {
              return <RenderFrame list={item.exhibitsType} id={document} key={document} />
          })
      }
    </div>
  );
};

const RenderFrame = ({list, id}) => {
  return list.map(subItem => {
    if(subItem.id === id){
      return <iframe
        className="document-frame"
        key={id}
        src={subItem.url}
        title={subItem.title}
        />
    }
    return null
  })
}

export default Document;
