export const GET_ORGANIZATION_LIST_START = "GET_ORGANIZATION_LIST_START";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_TRENDING_ORGANIZATION_START =
  "GET_TRENDING_ORGANIZATION_START";
export const GET_TRENDING_ORGANIZATION_SUCCESS =
  "GET_TRENDING_ORGANIZATION_SUCCESS";
export const TOGGLE_PRODUCER_CONNECTIONS_START =
  "TOGGLE_PRODUCER_CONNECTIONS_START";
export const TOGGLE_PRODUCER_CONNECTIONS_SUCCESS =
  "TOGGLE_PRODUCER_CONNECTIONS_SUCCESS";
