import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import SEO from "../../components/SEO";
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core";
import "./style.scss";
import { AppImages } from "../../constants";
import CreateProfile from "./CreateProfile";
import ProfileLogin from "./ProfileLogin";
import ProfilePage from "./ProfilePage";
import ProfileSignup from "./ProfileSignup";
import ProfileDetail from "./ProfileDetail";
import ProductFeedback from "./ProductFeedback";
import Post from "./Post";
import Connections from "./Connection";
import Offers from "./Offers";
import Survey from "./Survey";
import Password from "./Password";
import ForgotPassword from "./ForgotPassword";
import SurveyHistory from "./SurveyHistory";
import FeedbackPage from "./FeedbackPage";
import InvitePage from "./InvitePage";
import VerifyOTP from "./VerifyOTP";
import PractitionerUserDetail from "./PractitionerUserDetail";
import clsx from "clsx";
import { NotificationManager } from "react-notifications";
import {
  BackIconComponent,
  Sidebar,
  RightSideForm,
} from "../../components/Profile";
import { connect } from "react-redux";
import ProfileTour from "../../components/Tours/ProfileTour";
import { resetTour } from "../../redux/actions/common";
import utils from "../../utils/commonUtils";

const {
  profileImages: { profileBg },
} = AppImages;

const signupDefaultData = {
  name: {
    placeholder: "Name",
    value: "",
    error: false,
    isPublic: true,
    inputType: "text",
    required: true,
  },
  address: {
    placeholder: "Home Address",
    value: "",
    error: false,
    isPublic: true,
    inputType: "text",
    required: true,
  },
  phone: {
    placeholder: "Phone Number",
    value: "",
    error: false,
    isPublic: true,
    inputType: "text",
    required: true,
  },
  email: {
    placeholder: "Email",
    value: "",
    error: false,
    isPublic: false,
    inputType: "text",
    required: true,
  },
  practitioner: {
    placeholder: "Are you a Practitioner?",
    value: "",
    error: false,
    isPublic: false,
    inputType: "dropdown",
    options: [{ label: "Select", value: "" }, "Yes", "No"],
    required: false,
  },
  gender: {
    placeholder: "Gender",
    value: "",
    error: false,
    isPublic: false,
    inputType: "dropdown",
    options: [{ label: "Select Gender", value: "" }, "Male", "Female"],
    required: false,
  },
  race: {
    placeholder: "Race",
    value: "",
    error: false,
    isPublic: false,
    inputType: "dropdown",
    options: [
      { label: "Select Race", value: "" },
      "White",
      "Black or African American",
      "American Indian and Alaska Native",
      "Asian",
      "Native Hawaiian and Other Pacific Islander",
    ],
    required: false,
  },
  age: {
    placeholder: "Age (in years)",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select Age", value: "" },
      "15 - 20",
      "21 - 25",
      "26 - 30",
      "31 - 35",
      "36 - 40",
      "41 - 45",
      "46 - 50",
      "51 - 55",
      "56 - 60",
      "61 - 65",
      "66 - 70",
      "71 - 75",
      "76 - 80",
      "81 - 85",
    ],
    required: false,
  },
  marital_status: {
    placeholder: "Marital status",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select Marital status", value: "" },
      "Married",
      "Single",
    ],
    required: false,
  },
  family_and_dependents: {
    placeholder: "Family and Dependents",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select Family and Dependents", value: "" },
      ...[...Array(20)].map((e, i) => String(i + 1)),
    ],
    required: false,
  },
  education_level: {
    placeholder: "Education Level",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select Education Level", value: "" },
      "No Formal Schooling",
      "Some High School or Less",
      "High School/GED",
      "Some College",
      "Associate's Degree",
      "Bachelor's Degree",
      "Some Graduate School",
      "Master's Degree",
      "Doctoral Degree",
    ],
    required: false,
  },
  general_wellness: {
    placeholder: "General Wellness",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select General Wellness", value: "" },
      "Healthy",
      "Somewhat Healthy",
      "Somewhat Unhealthy",
      "Unhealthy",
    ],
    required: false,
  },
  height: {
    placeholder: "Height (in inches)",
    value: "",
    error: false,
    isPublic: true,
    inputType: "dropdown",
    options: [
      { label: "Select Height", value: "" },
      ...[...Array(41)].map((el, i) => String(i + 45)),
    ],
    required: false,
  },
  weight: {
    placeholder: "Weight (in pounds)",
    value: "",
    error: false,
    isPublic: true,
    inputType: "text",
    required: false,
    // options: [...Array(211)].map((el, i) => String(i + 65)),
  },
  practitioner_bio: {
    placeholder: "Profile Bio",
    value: "",
    error: false,
    isPublic: true,
    inputType: "text",
    required: false,
  },
};

function Profile({
  userData,
  resetTour,
  organizationList,
  trendingOrganizationList,
  showTour,
}) {
  const location = useLocation();
  const history = useHistory();
  const [activePage, setActivePage] = useState(
    getActivePageIndex(location.pathname)
  );
  const [profileProgress, setProfileProgress] = useState(0);
  const theme = useTheme();
  const match = !useMediaQuery(theme.breakpoints.down(769));
  const obj = {
    history,
    match,
    userData,
    profileProgress,
    activePage,
  };
  // Signup
  const emailRegex = new RegExp(
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,6})+$/
  );
  const { is_verified } = utils.getLocalStorageItems();
  const [signupData, setSignupData] = useState(signupDefaultData);
  const [profile_picture, setProfile_picture] = useState(null);
  const handleClose = () => {
    setSignupData(signupDefaultData);
  };
  const handleSignupDataValidation = (key, value) => {
    switch (key) {
      case "phone":
        return value?.replace(/[^\d\+\-\(\)]/g, "");
      case "weight":
        return value?.replace(/\D/g, "");
      default:
        return value;
    }
  };
  const handleSignupDataChange = (key, value, type) => {
    const obj = { ...signupData };
    const currentObj = obj[key];
    if (type === "toggleIsPublic") {
      obj[key] = {
        ...currentObj,
        isPublic: !currentObj?.isPublic,
      };
    } else {
      obj[key] = {
        ...currentObj,
        value: handleSignupDataValidation(key, value),
        error: false,
      };
    }
    setSignupData(obj);
  };
  const handleMasterToggle = (type) => (e) => {
    setSignupData((pre) =>
      Object.fromEntries(
        Object.entries(pre).map(([key, value], i) => {
          return [
            key,
            {
              ...value,
              isPublic:
                type === "create-profile"
                  ? i < 4
                    ? e.target.checked
                    : value.isPublic
                  : i > 3
                  ? e.target.checked
                  : value.isPublic,
            },
          ];
        })
      )
    );
  };
  const verifySignupForm = (type) => {
    let isVerified = true;
    let obj = { ...signupData };
    let arr =
      type === "create-profile"
        ? Object.entries(signupData)?.slice(0, 4)
        : Object.entries(signupData)?.slice(4);
    for (let item of arr) {
      const [key, value] = item;
      if (!value?.value && value.required) {
        obj[key] = {
          ...value,
          error: true,
        };
        isVerified = false;
      } else if (
        key === "email" &&
        value?.value &&
        !emailRegex.test(value?.value) &&
        value.required
      ) {
        NotificationManager.error("Please enter valid email.", "", 5000);
        obj[key] = {
          ...value,
          error: true,
        };
        isVerified = false;
      }
    }
    setSignupData(obj);
    return isVerified;
  };
  const signupObj = {
    signupData,
    handleSignupDataChange,
    verifySignupForm,
    handleClose,
    profile_picture,
    setProfile_picture,
    handleMasterToggle,
  };
  // Profile Detail
  const [profileData, setProfileData] = useState([
    {
      key: "name",
      placeholder: "Name",
      value: "",
      error: false,
      isPublic: true,
      inputType: "text",
      required: true,
    },
    {
      key: "address",
      placeholder: "Home Address",
      value: "",
      error: false,
      isPublic: true,
      inputType: "text",
      required: true,
    },
    {
      key: "phone",
      placeholder: "Phone Number",
      value: "",
      error: false,
      isPublic: true,
      inputType: "text",
      required: true,
    },
    {
      key: "practitioner",
      placeholder: "Are you a Practitioner?",
      value: "",
      error: false,
      isPublic: false,
      inputType: "dropdown",
      options: [{ label: "Select", value: "" }, "Yes", "No"],
      required: false,
    },
    {
      key: "gender",
      placeholder: "Gender",
      value: "",
      error: false,
      isPublic: false,
      options: [{ label: "Select Gender", value: "" }, "Male", "Female"],
      inputType: "dropdown",
      required: false,
    },
    {
      key: "race",
      placeholder: "Race",
      value: "",
      error: false,
      isPublic: false,
      inputType: "dropdown",
      options: [
        { label: "Select Race", value: "" },
        "White",
        "Black or African American",
        "American Indian and Alaska Native",
        "Asian",
        "Native Hawaiian and Other Pacific Islander",
      ],
      required: false,
    },
    {
      key: "age",
      placeholder: "Age (in years)",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select Age", value: "" },
        "15 - 20",
        "21 - 25",
        "26 - 30",
        "31 - 35",
        "36 - 40",
        "41 - 45",
        "46 - 50",
        "51 - 55",
        "56 - 60",
        "61 - 65",
        "66 - 70",
        "71 - 75",
        "76 - 80",
        "81 - 85",
      ],
      required: false,
    },
    {
      key: "marital_status",
      placeholder: "Marital status",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select Marital Status", value: "" },
        "Married",
        "Single",
      ],
      required: false,
    },
    {
      key: "family_and_dependents",
      placeholder: "Family and Dependents",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select Family and Dependents", value: "" },
        ...[...Array(20)].map((e, i) => String(i + 1)),
      ],
      required: false,
    },
    {
      key: "education_level",
      placeholder: "Education Level",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select Education Level", value: "" },
        "No Formal Schooling",
        "Some High School or Less",
        "High School/GED",
        "Some College",
        "Associate's Degree",
        "Bachelor's Degree",
        "Some Graduate School",
        "Master's Degree",
        "Doctoral Degree",
      ],
      required: false,
    },
    {
      key: "general_wellness",
      placeholder: "General Wellness",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select General Wellness", value: "" },
        "Healthy",
        "Somewhat Healthy",
        "Somewhat Unhealthy",
        "Unhealthy",
      ],
      required: false,
    },
    {
      key: "height",
      placeholder: "Height (in inches)",
      value: "",
      error: false,
      isPublic: true,
      inputType: "dropdown",
      options: [
        { label: "Select Height", value: "" },
        ...[...Array(41)].map((el, i) => String(i + 45)),
      ],
      required: false,
    },
    {
      key: "weight",
      placeholder: "Weight (in pounds)",
      value: "",
      error: false,
      isPublic: true,
      inputType: "text",
      // options: [...Array(211)].map((el, i) => String(i + 65)),
      required: false,
    },
  ]);
  const profileDetailObj = {
    profileData,
    setProfileData,
  };
  const profileComponents = [
    {
      component: <ProductFeedback {...obj} />,
      seoTitle: "Product Feedback - HealthLOQ",
      seoDescription: "Product Feedback - HealthLOQ",
      pageHeading: "Provide feedback",
    },
    {
      component: <CreateProfile {...signupObj} {...obj} />,
      seoTitle: "Create Profile - HealthLOQ",
      seoDescription: "Create Profile - HealthLOQ",
      path: "/create-profile",
    },
    {
      component: <ProfileSignup {...signupObj} {...obj} />,
      seoTitle: "Sign Up - HealthLOQ",
      seoDescription: "Sign Up - HealthLOQ",
      path: "/profile-signup",
      pageHeading: "Demographic Info",
    },
    {
      component: <ProfileLogin {...obj} />,
      seoTitle: "Login - HealthLOQ",
      seoDescription: "Login - HealthLOQ",
      path: "/profile-login",
      pageHeading: "Login",
    },
    {
      component: <ProfilePage {...{ ...obj }} />,
      seoTitle: "Profile - HealthLOQ",
      seoDescription: "Profile - HealthLOQ",
      path: "/profile",
    },
    {
      component: <ProfileDetail {...obj} {...profileDetailObj} />,
      seoTitle: "Profile Details - HealthLOQ",
      seoDescription: "Profile Details - HealthLOQ",
      path: "/profile-details",
    },
    {
      component: <Offers {...obj} />,
      seoTitle: "Offers - HealthLOQ",
      seoDescription: "Offers - HealthLOQ",
      path: "/offers",
    },
    {
      component: <Survey {...obj} />,
      seoTitle: "Survey - HealthLOQ",
      seoDescription: "Survey - HealthLOQ",
      path: "/survey",
    },
    {
      component: <Password {...obj} type="create" />,
      seoTitle: "Create password - HealthLOQ",
      seoDescription: "Create password - HealthLOQ",
      path: "/create-password/:token",
    },
    {
      component: <Password {...obj} type="reset" />,
      seoTitle: "Reset password - HealthLOQ",
      seoDescription: "Reset password - HealthLOQ",
      path: "/reset-password/:token",
    },
    {
      component: <ForgotPassword {...obj} />,
      seoTitle: "Forgot password - HealthLOQ",
      seoDescription: "Forgot password - HealthLOQ",
      path: "/forgot-password",
      pageHeading: "Forgot Password",
    },
    {
      component: <SurveyHistory {...obj} />,
      seoTitle: "Survey History - HealthLOQ",
      seoDescription: "Survey History - HealthLOQ",
      path: "/survey-history",
    },
    {
      component: <Connections {...obj} />,
      seoTitle: "Connections - HealthLOQ",
      seoDescription: "Connections - HealthLOQ",
      path: "/connections",
    },
    {
      component: <FeedbackPage {...obj} />,
      seoTitle: "Feedback - HealthLOQ",
      seoDescription: "Feedback - HealthLOQ",
      path: "/feedback",
    },
    {
      component: <InvitePage {...obj} />,
      seoTitle: "Invite organizations - HealthLOQ",
      seoDescription: "Invite organizations - HealthLOQ",
      path: "/invite",
    },
    {
      component: <VerifyOTP {...obj} />,
      seoTitle: "Verify OTP - HealthLOQ",
      seoDescription: "Verify OTP - HealthLOQ",
      path: "/verify-otp",
      pageHeading: "Verify OTP",
    },
    {
      component: <Post {...obj} />,
      seoTitle: "Post - HealthLOQ",
      seoDescription: "Post - HealthLOQ",
      path: "/posts",
    },
    {
      component: <PractitionerUserDetail {...obj} {...profileDetailObj} />,
      seoTitle: "Practitioner Details - HealthLOQ",
      seoDescription: "Practitioner Details - HealthLOQ",
      path: "/user-detail/:user_id",
    },
  ];
  function getActivePageIndex(path) {
    switch (path?.split("/")[1]) {
      case "product-feedback":
        return 0;
      case "create-profile":
        return 1;
      case "profile-signup":
        return 2;
      case "profile-login":
        return 3;
      case "profile":
        return 4;
      case "profile-details":
        return 5;
      case "offers":
        return 6;
      case "survey":
        return 7;
      case "create-password":
        return 8;
      case "reset-password":
        return 9;
      case "forgot-password":
        return 10;
      case "survey-history":
        return 11;
      case "connections":
        return 12;
      case "feedback":
        return 13;
      case "invite":
        return 14;
      case "verify-otp":
        return 15;
      case "posts":
        return 16;
      case "user-detail":
        return 17;
      default:
        return 0;
    }
  }
  useEffect(() => {
    setActivePage(getActivePageIndex(location.pathname));
  }, [location.pathname]);
  useEffect(() => {
    if (!userData?.loading && userData?.status === "1") {
      let { data_visibility } = userData?.data;
      data_visibility = JSON.parse(data_visibility);
      setProfileData((pre) =>
        pre?.map((item) => {
          return {
            ...item,
            value: userData?.data[item?.key],
            isPublic: data_visibility[item?.key],
          };
        })
      );
      let progressCount = 0;
      for (let item of profileData) {
        if (userData?.data[item?.key]) {
          progressCount++;
        }
      }
      setProfileProgress(
        Math.floor((100 / profileData?.length) * progressCount)
      );
    }
  }, [userData]);
  useEffect(() => {
    if (
      !userData?.loading &&
      userData?.status === "1" &&
      userData?.data?.show_profile_tour &&
      ![2].includes(activePage)
    ) {
      resetTour("profile");
    }
  }, [userData, activePage]);
  return (
    <>
      <SEO
        title={profileComponents[activePage].seoTitle}
        description={profileComponents[activePage].seoDescription}
      />
      <Grid
        container
        className={clsx(
          "profile-container",
          activePage === 1 && "signup-profile-container",
          showTour && match && "profile-container-disableScroll"
        )}
        style={{
          backgroundImage:
            match && [0, 1, 2, 3, 8, 9, 10, 15].includes(activePage)
              ? `url(${profileBg})`
              : "unset",
        }}
      >
        {[0, 1, 2, 3, 8, 9, 10, 15].includes(activePage) ||
        ([4, 5, 6, 7, 11, 12, 16, 13, 14, 17].includes(activePage) &&
          !match) ? (
          <Grid className="profile-content center">
            {activePage === 3 && Boolean(is_verified) && (
              <Box className={"profile-msg-box"}>
                <p>
                  You will receive an email to complete your registration and
                  create a password for your account.
                </p>
              </Box>
            )}
            <Grid
              className={clsx(
                "profile-center",
                match ? "pc-desktop" : "pc-mobile"
              )}
            >
              {![1, 4, 5, 7, 8, 9, 11, 12, 16, 13, 14, 17].includes(
                activePage
              ) && (
                <BackIconComponent
                  path={
                    [3, 6].includes(activePage)
                      ? profileComponents[activePage - 2]?.path
                      : null
                  }
                  match={match}
                  pageHeading={profileComponents[activePage]?.pageHeading}
                  hideBackIcon={[2, 15].includes(activePage)}
                />
              )}
              {profileComponents[activePage]?.component}
            </Grid>
          </Grid>
        ) : (
          <Grid className="profile-desktop-view-content" container>
            <Sidebar {...obj} />
            <RightSideForm>
              {profileComponents[activePage]?.component}
            </RightSideForm>
          </Grid>
        )}
        <ProfileTour />
      </Grid>
    </>
  );
}

const mapStateToProps = ({
  ProfileReducers: { userData },
  OrganizationReducers: { organizationList, trendingOrganizationList },
  CommonReducers: { showTour },
}) => ({ userData, organizationList, trendingOrganizationList, showTour });

const mapDispatchToProps = {
  resetTour,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
