import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { SectionWithTreePlan } from "../Common/SectionWrappers";
import PrimaryHeading from "../Common/Typography/PrimaryHeading";
import appImages from "../../constants/AppImage";

const {
  images: { cpSection4Image },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "50px 0",
  },
  content: {
    "&>p": {
      marginBottom: 15,
      color: "#707070",
    },
    "& button": {
      padding: "18px 50px",
    },
  },
  heading: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 15,
  },
  sectionImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      maxWidth: "100%",
    },
  },
}));

export default function Section4() {
  const classes = useStyle();
  return (
    <SectionWithTreePlan position="left" className={classes.root}>
      <Box className="bodyMaxWidth">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} className={classes.content}>
            <Box className={classes.heading}>
              <PrimaryHeading>How it works</PrimaryHeading>
            </Box>
            <Typography variant="body2">
              HealthLOQ allows an individual or organization to verify that
              product documentation (e.g. certificate of analysis or "COA"),
              including the label, is legitimate and that the certifying
              organization itself is in fact been accredited by a known
              standards body.
            </Typography>
            <Typography variant="body2">
              The protection process is effective for any type of document in
              any industry. The privacy of the document content is preserved
              since there is no need to expose sensitive or proprietary
              information outside the security perimeter of the organization
              that creates the original document work product.
            </Typography>
            <Typography variant="body2">
              No document export is required and the tool can be implemented in
              just a few hours. Retailers don't have to manually validate - it
              happens automatically!
            </Typography>
            <Link href="/contact-us">
              <Button>Contact Us</Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.sectionImage}>
              <img src={cpSection4Image} alt="Image" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SectionWithTreePlan>
  );
}
