import React from "react";
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogActions,
  MuiDialogContent,
  MuiButton,
} from "./DialogCommonComponents";
import clsx from "clsx";
import { Paragraph } from "../Profile";

export default function AlertDialog({
  className = "",
  open,
  handleClose,
  heading = "",
  content = "",
  cancelBtnText = "No",
  actionBtnText = "Yes",
}) {
  return (
    <MuiDialog
      className={clsx(className)}
      open={open}
      onClose={() => handleClose(false)}
      maxWidth={"sm"}
    >
      <MuiDialogTitle onClose={() => handleClose(false)}>
        {heading}
      </MuiDialogTitle>
      <MuiDialogContent>
        <Paragraph>{content}</Paragraph>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton secondaryBtn={true} onClick={() => handleClose(false)}>
          {cancelBtnText}
        </MuiButton>
        <MuiButton onClick={() => handleClose(true)}>{actionBtnText}</MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}
